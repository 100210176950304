import { Divider, Flex, Radio, RadioChangeEvent, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { listTestCases, listTestSuites } from "../../features/testsSlice";
import { AwareTestType, ITestName } from "../../test-studio/models";
import { isEmpty } from "lodash";
import { setSnackBarProps } from "../../features/commonSlice";
import FieldUpdateRules from "./FieldUpdateRules";
import { setPreTestId, updatePreStepsType } from "../../features/testGenerationSlice";
import { PreStep } from "../models";

const PreSteps = () => {
  const dispatch = useAppDispatch();
  const { Text, Title } = Typography;
  const preStepsType = useAppSelector((state) => state.testGeneration.preStepsType);
  const testSuites = useAppSelector((state) => state.tests.testSuites);
  const [selectedSuite, setSelectedSuite] = useState<string | undefined>(undefined);
  const [testCases, setTestCases] = useState<ITestName[]>([]);
  const [testCasesLoading, setTestCasesLoading] = useState(false);
  const preTestId = useAppSelector((state) => state.testGeneration.preTestId);

  useEffect(() => {
    isEmpty(testSuites) && dispatch(listTestSuites({ type: AwareTestType.FS_TEST }));
  }, []);

  const onPreStepsSelectionChange = (e: RadioChangeEvent) => {
    dispatch(updatePreStepsType(e.target.value as PreStep));
  };

  const onSuiteChange = async (suite: string) => {
    setTestCases([]);
    dispatch(setPreTestId(undefined));
    setSelectedSuite(suite);

    try {
      setTestCasesLoading(true);
      const result = await dispatch(
        listTestCases({
          testSuiteRegex: suite,
          testType: AwareTestType.FS_TEST,
        })
      ).unwrap();
      setTestCases(result.testCases ?? []);
      setTestCasesLoading(false);
    } catch (error) {
      setTestCasesLoading(false);
      dispatch(
        setSnackBarProps({
          severity: "error",
          message: "Something went wrong while loading Test Cases",
        })
      );
    }
  };

  const onCaseChange = (testCase: string) => {
    dispatch(setPreTestId(testCase));
  };

  //   const onSuiteSearch = (value: string) => {
  //     console.log("search:", value);
  //   };

  return (
    <Flex
      style={{
        marginBottom: 12,
        paddingBottom: 12,
        marginTop: 12,
        paddingTop: 12,
        paddingRight: 16,
        marginLeft: 16,
        overflow: "auto",
      }}
      vertical
      flex={1}
    >
      <Radio.Group value={preStepsType} onChange={onPreStepsSelectionChange}>
        <Space direction="horizontal">
          <Radio value={PreStep.NONE}>No pre-steps</Radio>
          <Radio value={PreStep.UPTO}>Run steps from...</Radio>
        </Space>
      </Radio.Group>
      {preStepsType == PreStep.UPTO && (
        <>
          <Space direction="vertical" style={{ marginTop: 16, marginLeft: 24, marginBottom: 20 }}>
            <Flex align="baseline">
              <Text style={{ minWidth: "3em" }}>Suite</Text>
              <Select
                style={{ flex: 1, overflow: "hidden" }}
                showSearch
                placeholder="Select a Test Suite"
                optionFilterProp="label"
                value={selectedSuite}
                onChange={onSuiteChange}
                //onSearch={onSuiteSearch}
                options={testSuites.map((suite) => ({ label: suite, value: suite }))}
              />
            </Flex>
            <Flex align="baseline">
              <Text style={{ minWidth: "3em" }}>Test</Text>
              <Select
                style={{ flex: 1, overflow: "hidden" }}
                showSearch
                placeholder="Select a Test Case"
                optionFilterProp="label"
                loading={testCasesLoading}
                value={preTestId}
                onChange={onCaseChange}
                //onSearch={onSuiteSearch}
                options={testCases.map((testCase) => ({ label: testCase.name, value: testCase.testId }))}
              />
            </Flex>
          </Space>
          {preTestId && <FieldUpdateRules testCaseId={preTestId} />}
        </>
      )}
    </Flex>
  );
};

export default PreSteps;
