import { IGetSummariesRequestWithMetaDataInternal } from "../explore";
import { ISource, ITraceData } from "./Common";
import { ReactNode } from "react";
import { Guard } from "../../api/services/Guards";
import { QualifiedOperation } from "../../api/services/Common";
import { IRawRequest, IRawResponse } from "../../test-studio/models";
import { OasConfig, OperationWithDetail } from '../../models/config/models';

export interface PrefixSummary {
  numOccurrences: number;
  attributeSummaries: { [key: string]: DecoratedAttributeSummary };
  resourceAttributeSummaries: { [key: string]: DecoratedAttributeSummary };
  avgLatency: number;
  maxLatency: number;
  minLatency: number;
  errorRate: number;
  requestResponsePairs?: RequestResponse[];
}

export interface RequestResponse {
  request: IRawRequest;
  response: IRawResponse;
}

export type DecoratedAttributeSummary = {
  summary: AttributeSummary;
  guards: Guard[];
};

type AttributeSummary = {
  attributeValueSummaries: { [key: string]: AttributeValueSummary };
};

type AttributeValueSummary = {
  numOccurrences: number;
};


export interface IOverlayII {
  request?: IGetSummariesRequestWithMetaDataInternal;
  response: SummaryTree | null;
  traceColor: string;
  traceTree: any;
  traceData: any;
}

export interface PrefixSummaryTree {
  operation: QualifiedOperation;
  nodeId: string;
  prefixSummary: PrefixSummary;
  childPrefixSummaries: PrefixSummaryTree[];
  prefix: string;
}

export interface SummaryTree {
  projectId: string;
  prefixSummaryTree: PrefixSummaryTree;
  numTraces: number;
}

export interface TraceTree {
  key: string;
  isLeaf: boolean;
  prefix: string;
  operation: QualifiedOperation;
  title: string | ReactNode;
  self: string;
  sources: ISource[];
  children: TraceTree[] | null;
}

export interface IEntryPoints {
  [key: string]: IEntryPoint;
}

export interface IEntryPoint {
  overlays: {
    [key: string]: any;
  };
  aggregate: {
    traceTree: TraceTree | null;
    traceData: { [key: string]: ITraceData };
  };
}

export interface TraceCollectionSummaryTree {
  projectId: string;
  prefixSummaryTree: PrefixSummaryTree;
  numTraces: number;
}

export interface IGetTraceSummaryTreesForTraceRequest {
  traceId: string;
}

export interface IGetTraceSummaryTreesForTraceResponse {
  trees: TraceCollectionSummaryTree[];
  continuationState: ContinuationState;
}

export enum ContinuationState {
  UNKNOWN_CONTINUATION_STATE = "UNKNOWN_CONTINUATION_STATE",
  POTENTIALLY_INCOMPLETE = "POTENTIALLY_INCOMPLETE",
  COMPLETED = "COMPLETED"
}

export interface IListOperationsWithDetailsRequest{
  resourceId:number;
}

export interface IListOperationsWithDetailsResponse{
  oasConfig:OasConfig;
  operations:OperationWithDetail[];
}

