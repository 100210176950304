import React from "react";
import { useAppDispatch } from "../../app/hooks";
import { Flex, Result, Button } from "antd";
import { closeGenerationPopup } from "../../features/testGenerationSlice";

const ErrorStep = () => {
  const dispatch = useAppDispatch();

  return (
    <Flex vertical flex={1} justify="center">
      <Result
        status="error"
        title="Test generation failed"
        subTitle="Your test generation request failed"
        extra={[
          <Button type="primary" key="console" onClick={() => dispatch(closeGenerationPopup())}>
            Close
          </Button>,
        ]}
      />
    </Flex>
  );
};

export default ErrorStep;
