import React, { useEffect, useState } from 'react';
import { Button, Modal, Collapse, Input, message, Typography, Checkbox } from 'antd';
import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { ProjectTS } from '../api';
import { setProjectConfig } from '../admin/ProjectSettings/projectSettingsSlice';
import { HeadRemove } from 'mdi-material-ui';
import { fontStyle } from '@mui/system';
import StartCaptureImg from "../images/StartCaptureIllustrate.gif";
import EndCaptureImg from "../images/EndCaptureIllustrate.gif";
import SessionLinkImg from "../images/SessionLinkIllustrate.gif";
import CreateTestImg from "../images/CreateTestIllustrate.gif";
import SaveTestImg from "../images/SaveTestIllustrate.gif";
import GenScriptImg from "../images/GenScriptIllustrate.gif";
import LocalStorageManager from '../common/LocalStorageManager';

const { Panel } = Collapse;
interface QuickStartWizardProps {
    onComplete: () => void;
}
const QuickStartWizard: React.FC<QuickStartWizardProps> = ({ onComplete }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeKey, setActiveKey] = useState<string | string[]>('0');
    const [completedSteps, setCompletedSteps] = useState<boolean[]>(Array(8).fill(false));
    const [domain, setDomain] = useState<string>("");
    const [userMail, setUserMail] = useState<string>("");
    const selectedProject = useAppSelector((state) => state.common.selectedProject);
    const projectSettings = useAppSelector((state) => state.projectSettings);
    const sessionRecordApiKey = projectSettings.projectConfig.sessionRecordApiKey;
    const dispatch = useAppDispatch();

    const [urlRegex, setUrlRegex] = useState(`.*${domain}.*`);
    const [includeCognito, setIncludeCognito] = useState(false);
    const [includeFirebase, setIncludeFirebase] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState("Configure extension");

    const toggleOAuthEndpoint = (endpoint: string) => {
        let updatedRegex = urlRegex.split(",").map(str => str.trim());

        if (endpoint === "cognito") {
            if (includeCognito) {
                // Remove Cognito URL regex if it already exists
                updatedRegex = updatedRegex.filter(url => url !== ".*amazonaws.*");
            } else {
                // Add Cognito URL regex if it doesn't exist
                if (!updatedRegex.includes(".*amazonaws.*")) {
                    updatedRegex.push(".*amazonaws.*");
                }
            }
            setIncludeCognito(!includeCognito);
        } else if (endpoint === "firebase") {
            if (includeFirebase) {
                // Remove Firebase URL regex if it already exists
                updatedRegex = updatedRegex.filter(url => url !== ".*identitytoolkit.googleapis.*");
            } else {
                // Add Firebase URL regex if it doesn't exist
                if (!updatedRegex.includes(".*identitytoolkit.googleapis.*")) {
                    updatedRegex.push(".*identitytoolkit.googleapis.*");
                }
            }
            setIncludeFirebase(!includeFirebase);
        }

        setUrlRegex(updatedRegex.join(","));
    };

    const openExtensionOptions = () => {
        console.log("Sending message open options");
        window.postMessage({ type: "tc_open_options_page" }, "*");
    };

    useEffect(() => {
        if (selectedProject) {
            ProjectTS.getConfig({ projectId: selectedProject }).then(response => {
                dispatch(setProjectConfig(response.projectConfig));
            });
            let detailedUserInfo = LocalStorageManager.getDetailedUserInfo();
            if (detailedUserInfo) {
                const email = detailedUserInfo.userInfo?.email;
                const domain = getDomain(email ?? "");
                setDomain(domain);
                setUrlRegex(`.*${domain}.*`)
                setUserMail(email);
            }
        }
    }, [selectedProject, dispatch]);

    function getDomain(email: string): string {
        if (!email.includes("@")) {
            return ""; // Return an empty string if the email format is invalid
        }

        return email.split("@")[1];
    }
    const configureExtension = () => {
        setIsLoading(true);
        setButtonText("Configuring...");

        const payload = {
            projectId: selectedProject,
            sessionRecordingApiKey: sessionRecordApiKey,
            uriRegexToIntercept: urlRegex,
            currentUserId: userMail
        };

        console.log("Sending message to ext");
        window.postMessage({ type: "update_tc_ext_config", payload: payload }, "*");

        // Set a timeout for 1 second in case of no response
        const timeout = setTimeout(() => {
            setIsLoading(false);
            setButtonText("Could not configure extension.");
        }, 5000);

        // Listener for response from the extension
        const handleExtensionResponse = (event: MessageEvent) => {
            if (event.data.type === "update_tc_ext_config_response") {
                clearTimeout(timeout);  // Clear the timeout if a response is received
                console.log("Event recv", event);
                if (event.data.success) {
                    setIsLoading(false);
                    message.success("Extension configured successfully!");
                    setButtonText("Configured successfully!");
                    setTimeout(() => {
                        handleDoneClick(1);
                        setButtonText("Configure Extension");
                    }, 1000); // Delay to show success text before moving to next step
                } else {
                    setButtonText("Could not configure extension.");
                }

                // Clean up the event listener
                window.removeEventListener("message", handleExtensionResponse);
            }
        };

        window.addEventListener("message", handleExtensionResponse);
    };

    const steps = [
        {
            title: 'Install Chrome Extension',
            content: (
                <Typography>Install TestChimp Extension <a
                    href="https://chromewebstore.google.com/detail/testchimp-create-api-auto/ailhophdeloancmhdklbbkobcbbnbglm"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    from here.
                </a>
                </Typography>
            ),
        },
        {
            title: 'Configure Extension',
            content: (
                <>
                    <Typography style={{ marginTop: 20 }}>Enter backend API URL (regex) to capture. <i>(comma separate multiple values)</i>:</Typography>
                    <Input
                        value={urlRegex}
                        onChange={(e) => setUrlRegex(e.target.value)}
                        style={{ marginTop: 10, marginBottom: 10 }}
                        suffix={<CopyOutlined onClick={() => navigator.clipboard.writeText(urlRegex)} />}
                    />

                    <Checkbox
                        checked={includeCognito}
                        onChange={() => toggleOAuthEndpoint("cognito")}
                        style={{ marginTop: 10 }}
                    >
                        Add Cognito OAuth endpoint
                    </Checkbox>
                    <Checkbox
                        checked={includeFirebase}
                        onChange={() => toggleOAuthEndpoint("firebase")}
                        style={{ marginTop: 10 }}
                    >
                        Add Firebase OAuth endpoint
                    </Checkbox>
                    <Typography style={{ marginTop: 20 }}><i>You can change later in <a href="#" onClick={openExtensionOptions}>extension -&gt; Options</a>.</i></Typography>
                </>
            ),
        },
        {
            title: 'Start Capture',
            content: (
                <>
                    <Typography>Open your web app to test, and click <b>Start Capture</b> on the Extension.</Typography>
                    <Typography><br></br><i>Note: If your website was already open before installing the extension, reload the website.</i></Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={StartCaptureImg} width="300" height="auto" alt="Start Capture" />
                    </div>
                </>
            )
        },
        {
            title: 'End Capture',
            content: (
                <>
                    <Typography>Once done with testing a user journey, click <b>End Capture</b>.</Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={EndCaptureImg} width="300" height="auto" alt="End Capture" />
                    </div>
                </>
            )
        },
        {
            title: 'Go to Session Capture',
            content: (
                <>
                    <Typography>Click on the session link - <b>this will take a minute to finalize and load.</b></Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={SessionLinkImg} width="300" height="auto" alt="Session Capture Link" />
                    </div>
                </>
            )
        },
        {
            title: 'Create Test',
            content: (
                <>
                    <Typography>Click on <b>"Create Test"</b></Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={CreateTestImg} width="300" height="auto" alt="create Test" />
                    </div>
                </>
            )
        },
        {
            title: 'Save Test',
            content: (
                <>
                    <Typography>Click on <b>"Save Test"</b> in Test Studio</Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={SaveTestImg} width="300" height="auto" alt="Save Test" />
                    </div>
                </>
            )
        },
        {
            title: 'Generate Script',
            content: (
                <>
                    <Typography>Click on <b>"More" -&gt; "Generate Script"</b></Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={GenScriptImg} width="300" height="auto" alt="Generate Script" />
                    </div>
                </>
            )
        },
    ];

    const openModal = () => setIsModalVisible(true);
    const closeModal = () => {
        setIsModalVisible(false);
        setActiveKey('0');
        setCompletedSteps(Array(steps.length).fill(false)); // reset all steps
    };

    const handleDoneClick = (index: number) => {
        const updatedCompletedSteps = [...completedSteps];
        updatedCompletedSteps[index] = true;
        setCompletedSteps(updatedCompletedSteps);

        // Move to next step if there is one
        const nextStep = index + 1;
        if (nextStep < steps.length) {
            setActiveKey(String(nextStep));
        } else {
            closeModal();
            onComplete();
        }
    };

    const handlePanelChange = (key: string | string[]) => {
        setActiveKey(key);
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Button type="primary" className="secondary-button" onClick={openModal}>
                    Open Quick Start Wizard...
                </Button>
            </div>
            <Modal
                title="Quick Start Guide"
                visible={isModalVisible}
                onCancel={closeModal}
                footer={null}
                width={600}
            >
                <Collapse activeKey={activeKey} onChange={handlePanelChange} accordion>
                    {steps.map((step, index) => (
                        <Panel
                            header={
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {step.title}
                                    <CheckCircleOutlined
                                        style={{
                                            color: completedSteps[index] ? 'green' : 'gray',
                                            fontSize: '1.2em'
                                        }}
                                    />
                                </div>
                            }
                            key={String(index)}
                        >
                            {step.content}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
                                <Button
                                    type="primary"
                                    className="primary-button"
                                    loading={isLoading}
                                    onClick={() =>
                                        step.title === "Configure Extension"
                                            ? configureExtension()
                                            : handleDoneClick(index)
                                    }
                                >
                                    {step.title === "Configure Extension" ? buttonText : "Done"}
                                </Button>
                            </div>
                        </Panel>
                    ))}
                </Collapse>
            </Modal>
        </>
    );
};

export default QuickStartWizard;