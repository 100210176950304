import React, { useEffect, useState, forwardRef } from "react";
import StarsIcon from "@mui/icons-material/Stars";
import { Button } from "@mui/material";
import { UpgradePlan } from "./Modal";

const UpgradeButton = forwardRef((props, ref) => {
  const [orgBillingSource, setOrgBillingSource] = useState(props.orgBillingSource);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);

  const onUpgradePlanModalOpenHandler = () => {
    setIsUpgradePlanModalOpen(true);
  };
  useEffect(() => {
    console.log("orgBillingSource inside UpgradeButton:", props.orgBillingSource);
  }, [orgBillingSource]);
  const onUpgradePlanModalCloseHandler = () => {
    setIsUpgradePlanModalOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<StarsIcon />}
        onClick={onUpgradePlanModalOpenHandler}
        ref={ref}
      >
        Upgrade to Premium
      </Button>
      <UpgradePlan
        open={isUpgradePlanModalOpen}
        close={onUpgradePlanModalCloseHandler}
        billingSource={props.orgBillingSource}
      />
    </>
  );
});

export default UpgradeButton;
