import React, { Component } from 'react';
import { IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

class SubscriptionIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOn: this.props.flowRow.flowSubscriptionId > 0 ? true : false,
        };
        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle() {
        const { flowRow, onToggle } = this.props;
        const { isOn } = this.state;
        const newValue = !isOn;
        this.setState({ isOn: newValue });
        onToggle(flowRow, newValue);
    }

    render() {
        const { isOn } = this.state;
        return (
            <IconButton onClick={this.handleToggle}>
                {isOn ? <StarIcon color="secondary" /> : <StarBorderIcon />}
            </IconButton>
        );
    }
}

export default SubscriptionIcon;


