import { QualifiedOperation } from "../api/services/Common";
import { AttributeLocator, OperationLocator } from "../api/services/Guards";
import { TimeWindow } from "../models/explore";
import { IFilter, PrefixSummary } from "../models/overlays";
import { BoolTypedValue, FloatTypedValue, IntTypedValue, StringTypedValue, TypedValue } from "../models/typed/models";

export const getLastOperationName = (prefix: string): QualifiedOperation => {
  // Split by '|' to separate each pair
  const prefixParts: string[] = prefix.split("|");

  // Take the last portion (since you want the last operation name)
  const lastPart: string = prefixParts[prefixParts.length - 1].trim();

  // Remove curly braces if present
  const cleanLastPart = lastPart.replace(/[{}]/g, "");

  // Split by comma to separate resourceName and operationName
  const matches = cleanLastPart.split(",");

  if (matches && matches.length === 2) {
    const resourceName = matches[0].trim();
    const operationName = matches[1].trim();
    return { resourceName, operationName };
  } else {
    throw new Error(`Invalid operation string format: ${prefix}`);
  }
};

export const getOperationLocatorDisplayString = (
  locator: OperationLocator | undefined
): string => {
  if (!locator) {
    return "";
  }
  if (locator.pathPrefix) {
    return "...>>" + getLastOperationName(locator.pathPrefix!).operationName + ".";
  }
  return "";
};

export const getAttributeLocatorDisplayString = (locator: AttributeLocator): string => {
  if (!locator) {
    return "";
  }
  let text = "";
  if (locator.operation) {
    text += getOperationLocatorDisplayString(locator.operation);
  }
  if (locator.attributeKey) {
    text += locator.attributeKey;
  }
  return text;
}

export function getDisplayValue(val: TypedValue): string {
  if (val.stringValue) {
    return val.stringValue;
  } else if (val.floatValue) {
    return val.floatValue.toString();
  } else if (val.intValue) {
    return val.intValue.toString();
  } else if (val.boolValue) {
    return val.boolValue ? 'true' : 'false';
  } else {
    throw new Error('Unknown TypedValue type');
  }
}

function formatGoogleTimestamp(timestamp: string): string {
  const date = new Date(timestamp);
  return date.toISOString().slice(0, 16).replace('T', ' ');
}

export function toDisplayString(window: TimeWindow): string {
  if ('relative_window' in window) {
    const relativeWindow = window.relative_window;
    const seconds = parseInt(relativeWindow.replace('s', ''), 10);
    if (seconds % 86400 === 0) {
      return `last ${seconds / 86400} days`;
    } else if (seconds % 3600 === 0) {
      return `last ${seconds / 3600} hours`;
    } else if (seconds % 60 === 0) {
      return `last ${seconds / 60} minutes`;
    } else {
      return `last ${seconds} seconds`;
    }
  } else if ('fixed_window' in window) {
    const { start_time, end_time } = window.fixed_window;
    const startTimeString = start_time ? formatGoogleTimestamp(start_time) : 'N/A';
    const endTimeString = end_time ? formatGoogleTimestamp(end_time) : 'N/A';
    return `from ${startTimeString} to ${endTimeString}`;
  } else {
    throw new Error('Unknown TimeWindow type');
  }
}

export function filterToDisplayString(filter: IFilter): string {
  return `${filter.key} ${operationToDisplayString(filter.operator)} ${filter.value}`;
}

export function operationToDisplayString(operator: string): string {
  if (operator === "EQUALS") {
    return "=";
  } else if (operator === "NOT_EQUALS") {
    return "!=";
  } else if (operator === "GREATER_THAN") {
    return ">";
  } else if (operator === "LESS_THAN") {
    return "<";
  } else if (operator === "STARTS_WITH") {
    return "starts with";
  } else if (operator === "ENDS_WITH") {
    return "ends with";
  } else if (operator === "CONTAINS") {
    return "contains";
  }
  return operator;
}

export function getNodeStatus(prefixSummary:PrefixSummary):string{
  let summary=prefixSummary.attributeSummaries["testchimp.derived.status"].summary;
  return Object.keys(summary.attributeValueSummaries??"STATUS_CODE_OK").join(", ");
}