import React, { Component } from "react";
import Table from "@mui/material/Table";
import { IconButton, LinearProgress } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormControlLabel, Checkbox } from "@mui/material";
import Typography from "@mui/material/Typography";
import AttribSettingsPopup from "./AttribSettingsPopup.js";
import SettingsIcon from "@mui/icons-material/Settings";
import { ThemeColors } from "../styles/light-colors.js";
import { Project } from "../auth/Authorization.js";

class SpanAttribSummaryViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: this.props.context,
      hideZeros: true,
      selectedAttribute: "",
      hoveredAttribute: null,
    };
  }

  handleCheckboxChange = (event) => {
    this.setState({ hideZeros: event.target.checked });
  };

  onSaveAttributeSettings = () => {
    this.setState({ selectedAttribute: "" });
  };
  handleMouseEnter = (attribute) => {
    this.setState({ hoveredAttribute: attribute });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredAttribute: null });
  };

  render() {
    const { data, context } = this.props;
    const { hideZeros, selectedAttribute, hoveredAttribute } = this.state;
    let filteredData;
    if (data) {
      filteredData = hideZeros
        ? Object.entries(data).filter(([attribute, decoratedSummary]) =>
          Object.values(decoratedSummary.summary.attributeValueSummaries).some(
            ({ numOccurrences }) => Math.abs(numOccurrences) > 0.01
          )
        )
        : Object.entries(data);
    } else {
      filteredData = [];
    }
    const styles = {
      hideZerosBox: {
        margin: "1em",
      },
    };
    return (
      <div style={{ backgroundColor: ThemeColors.secondaryBackground }}>
        <FormControlLabel
          style={styles.hideZerosBox}
          control={
            <Checkbox
              checked={hideZeros}
              onChange={this.handleCheckboxChange}
            />
          }
          label={
            <Typography variant="body2" style={{ color: ThemeColors.darkText }}>
              Hide Zeros
            </Typography>
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: ThemeColors.darkText, fontSize: "1em" }}>
                Key
              </TableCell>
              <TableCell style={{ color: ThemeColors.darkText, fontSize: "1em" }}>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.map(([attribute, decoratedSummary]) => (
              <TableRow key={attribute}>
                <TableCell
                  onMouseEnter={() => this.handleMouseEnter(attribute)}
                  onMouseLeave={this.handleMouseLeave}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: ThemeColors.darkText,
                    }}
                  >
                    <Typography variant="body2" style={{ fontSize: "1.1em" }}>
                      {attribute}
                    </Typography>
                    {hoveredAttribute === attribute && Project.isEditor() ? (
                      <IconButton
                        style={{
                          height: "0.1rem",
                          marginLeft: "4px",
                          color: ThemeColors.darkText,
                        }}
                        onClick={() =>
                          this.setState({ selectedAttribute: attribute })
                        }
                      >
                        <SettingsIcon />
                      </IconButton>
                    ) : (
                      <div
                        style={{
                          display: "inline-block",
                          width: "1em",
                          height: "1em",
                          marginLeft: "4px",
                        }}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <ChildTable
                    data={decoratedSummary.summary.attributeValueSummaries}
                    hideZeros={hideZeros}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {selectedAttribute && (
            <AttribSettingsPopup
              attribute={selectedAttribute}
              projectId={context.projectId}
              operation={context.selectedOperation}
              onSave={this.onSaveAttributeSettings}
            />
          )}
        </Table>
      </div>
    );
  }
}

function ChildTable({ data, hideZeros }) {
  const filteredData = hideZeros
    ? Object.entries(data).filter(
      ([value, summary]) => summary.numOccurrences !== 0
    )
    : Object.entries(data);
  let topRow = <div></div>;
  if (filteredData.length > 0) {
    topRow = (
      <TableRow>
        <TableCell>
          <Typography variant="body2" style={{ color: ThemeColors.darkText }}>
            <b>Attribute Value</b>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" style={{ color: ThemeColors.darkText }}>
            <b>Number of Occurrences</b>
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <Table>
      <TableHead>{topRow}</TableHead>
      <TableBody>
        {filteredData?.map(([value, decoratedSummary]) => (
          <TableRow key={value}>
            <TableCell>
              <Typography variant="body2" style={{ color: ThemeColors.darkText }}>
                {value}
              </Typography>
            </TableCell>
            <TableCell>
              <a style={{ color: ThemeColors.darkText }}>
                {decoratedSummary.summary?.numOccurrences.toFixed(2)}
              </a>
              <LinearProgress
                variant="determinate"
                value={Math.abs(decoratedSummary.summary?.numOccurrences.toFixed(2))}
                max={30}
                style={{
                  height: "8px",
                  color: ThemeColors.darkText,
                  backgroundColor: "transparent",
                }}
                color={decoratedSummary.summary?.numOccurrences < 0 ? "secondary" : "primary"}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default SpanAttribSummaryViewer;
