import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Select,
  MenuItem,
  ThemeProvider,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Scenarios, { UserStory, Priority, SuggestUserStoriesRequest } from '../api/services/Scenarios';
import theme from "../common/theme";
import '../styles/SuggestUserStoriesDialog.css'; // Import CSS

interface SuggestUserStoriesDialogProps {
  open: boolean;
  onClose: (saved: boolean) => void;
  personaId: number | undefined;
  epicId: number | undefined;
  selectedSprintId?: number;
  selectedMilestoneId?: number;
}

const SuggestUserStoriesDialog: React.FC<SuggestUserStoriesDialogProps> = ({
  open,
  onClose,
  personaId,
  epicId,
}) => {
  const [userStories, setUserStories] = useState<UserStory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open && personaId) {
      setIsLoading(true);
      let req: SuggestUserStoriesRequest = { personaId: personaId!, epicId: epicId! };
      Scenarios.suggestUserStories(req)
        .then(response => setUserStories(response.suggestedUserStories))
        .finally(() => setIsLoading(false));
    }
  }, [open, personaId, epicId]);

  const handleSave = () => {
    if (personaId) {
      Scenarios.upsertUserStoryList({ userStories: userStories }).then(() => {
        onClose(true);
      });
    }
  };

  const handleDelete = (index: number) => {
    const newStories = [...userStories];
    newStories.splice(index, 1);
    setUserStories(newStories);
  };

  const handleChange = (index: number, field: keyof UserStory, value: any) => {
    const newStories = [...userStories];
    newStories[index][field] = value;
    setUserStories(newStories);
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog fullWidth maxWidth="md" open={open} onClose={() => onClose(false)} className="suggest-user-stories-dialog">
        <DialogContent>
        <label className="dialog-title">Here are some potential user scenarios to consider...</label>
          <div style={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}>
            {isLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                <CircularProgress />
              </Box>
            ) : userStories.length === 0 ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="body1" color="textSecondary">
                  Could not fetch any user stories, try making the epic more descriptive and adding product description.
                </Typography>
              </Box>
            ) : (
              <Table className="user-stories-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell className="fixed-width">Priority</TableCell>
                    <TableCell className="table-cell-actions">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userStories?.map((story, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant='standard'
                          value={story.title}
                          onChange={(e) => handleChange(index, 'title', e.target.value)}
                          className="full-height-textfield"
                          InputProps={{
                            disableUnderline: true, // Removes underline
                          }}
                        />
                      </TableCell>
                      <TableCell className="fixed-width">
                        <Select
                          value={story.priority || ''}
                          onChange={(e) => handleChange(index, 'priority', e.target.value as Priority)}
                          className="small-height-select"
                        >
                          {Object.values(Priority).map(priority => (
                            <MenuItem key={priority} value={priority}>
                              {priority}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell className="table-cell-actions">
                        <IconButton onClick={() => handleDelete(index)} className="delete-icon">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default SuggestUserStoriesDialog;