import { Button, Flex, Result } from "antd";
import React from "react";
import { useAppDispatch } from "../../app/hooks";
import { closeGenerationPopup } from "../../features/testGenerationSlice";

const SuccessStep = () => {
  const dispatch = useAppDispatch();

  return (
    <Flex vertical flex={1} justify="center">
      <Result
        status="success"
        title="Tests are being generated..."
        subTitle="Your test generation request submitted successfully. We will mail you once tests are built"
        extra={[
          <Button type="primary" className="primary-button" key="console" onClick={() => dispatch(closeGenerationPopup())}>
            Close
          </Button>,
        ]}
        style={{ color: "var(--primary-color)" }}
      />
    </Flex>
  );
};

export default SuccessStep;
