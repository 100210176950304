import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DiffCardContext,
  DrawerMode,
  IDateRange,
  IEntryPoints,
  IFilter,
  IOverlayII,
  ISamplingMode,
  ISource,
  ITrace,
  ITraceData,
  IValueConfig,
  Mode,
  PrefixSummary,
  SpanAttribute,
  SortingAlgo,
  SummaryTree,
  PrefixSummaryTree,
} from "../models/overlays";
import { isEmpty, isEqual } from "lodash";
import {
  generateHash,
  generateUniqueID,
  maskUUID,
  NEW_OVERLAY_COLOR,
  OverlayHelpers,
} from "../util";
import { get } from "lodash";
import { Explore } from "../api";
import { GenericAbortSignal } from "axios";
import { ISetVariableExpression } from "../models/assertion/IAssertion";
import {
  IGetSummariesRequestWithMetaData,
  IQueryWithMetaData,
} from "../models/explore";
import Guards, {
  Guard,
  IAddGuardRequest,
  OperationLocator,
} from "../api/services/Guards";
import ProjectTS, {
  IGetAttributeConfigRequest,
  IGetAttributeConfigResponse,
  IUpdateAttributeConfigRequest,
} from "../api/services/ProjectTS";
import SessionStorageManager from "../common/SessionStorageManager";
import { QualifiedOperation } from "../api/services/Common";

export interface OverlaysState {
  queries: IQueryWithMetaData[];
  attributeSortingAlgo: SortingAlgo;
  isDrawerOpen: boolean;
  drawerMode: DrawerMode;
  color: string;
  loading: boolean;
  summaryLoading: boolean;
  addGuardLoading: boolean;
  getAttributeConfigLoading: boolean;
  attributeSettings?: IGetAttributeConfigResponse;
  updateAttributeConfigLoading: boolean;
  valueConfigs: IValueConfig[];
  filters: IFilter[];
  dateRange: IDateRange;
  samplingMode: ISamplingMode;
  entryPoints: IEntryPoints;
  selectedEntryPointHash: string;
  selectedTrace?: ITrace;
  overlayId: string;
  isGuardOpen: boolean;
  isAttrubuteSettingsOpen: boolean;
  currentDiffCardContext?: DiffCardContext;
  ringAttribute: SpanAttribute;
  spanColorAttribute: SpanAttribute;
  spanColorRange: {
    min: number;
    max: number;
  };
}

const initialState: OverlaysState = {
  /* loading */
  loading: false,
  summaryLoading: false,
  addGuardLoading: false,
  getAttributeConfigLoading: false,
  updateAttributeConfigLoading: false,
  /* loading */

  /* initially loaded */
  queries: [],
  ringAttribute: SpanAttribute.FREQUENCY_SHORT_CODE,
  spanColorAttribute: SpanAttribute.ERROR_RATE,
  spanColorRange: { min: 1, max: 20 },
  attributeSortingAlgo: SortingAlgo.ASCENDING,
  isDrawerOpen: false,
  isGuardOpen: false,
  isAttrubuteSettingsOpen: false,
  drawerMode: DrawerMode.ADD,
  color: NEW_OVERLAY_COLOR,
  samplingMode: {
    mode: Mode.DYNAMIC_SAMPLING,
  },
  selectedTrace: undefined, //after automatic first entrypoint selection
  selectedEntryPointHash: "", //after automatic first entrypoint selection

  /* after initial apis */
  valueConfigs: [], // listConditionValues/fulfilled
  entryPoints: {}, // getSummaries/fulfilled
  /* initially loaded */

  /* after overlay drawer */
  overlayId: "",
  filters: [],
  dateRange: {}, // {} means relative. Will be filled if date range selected.
  /* after overlay drawer */

  /* after attribute-key action is selected */
  currentDiffCardContext: undefined,
  /* after attribute-key action is selected */

  /* after loading GetAttribute */
  attributeSettings: undefined,
  /* after loading GetAttribute */
};

export const getSummaries = createAsyncThunk(
  "overlays/getSummaries",
  async (request: IGetSummariesRequestWithMetaData, thunkAPI) => {
    const response = await Explore.getSummaries(
      {
        queries: request.queries?.map((query) => {
          return {
            filters: query.filters,
            time_window: query.time_window,
            sampling_mode: query.sampling_mode,
            sampling_rate: query.sampling_rate,
          };
        }),
      },
      thunkAPI.signal as GenericAbortSignal
    );
    return { response, request };
  }
);

export const listConditionValues = createAsyncThunk(
  "overlays/listConditionValues",
  async () => {
    return await Explore.listConditionValues();
  }
);

export const addGuard = createAsyncThunk(
  "overlays/addGuard",
  async (request: IAddGuardRequest) => {
    const response: { id: string } = await Guards.addGuard(request);
    return { request, response };
  }
);

export const getAttributeConfig = createAsyncThunk(
  "overlays/getAttributeConfig",
  async (request: IGetAttributeConfigRequest) => {
    return await ProjectTS.getAttributeConfig(request);
  }
);

export const updateAttributeConfig = createAsyncThunk(
  "overlays/updateAttributeConfig",
  async (request: IUpdateAttributeConfigRequest) => {
    return await ProjectTS.updateAttributeConfig(request);
  }
);

export const setExpressionFromOverlay = createAction<ISetVariableExpression>(
  "overlays/assertions/setExpression"
);

const overlaysSlice = createSlice({
  name: "overlays",
  initialState,
  reducers: {
    setQueries: (state, action: PayloadAction<IQueryWithMetaData[]>) => {
      state.queries = action.payload;
    },
    updateQueries: (state, action: PayloadAction<IQueryWithMetaData[]>) => {
      state.queries = action.payload;
      SessionStorageManager.setOverlayQueries(action.payload);
    },
    updateRingAttribute: (state, action: PayloadAction<SpanAttribute>) => {
      state.ringAttribute = action.payload;
    },
    updateSpanColorAttribute: (state, action: PayloadAction<SpanAttribute>) => {
      state.spanColorAttribute = action.payload;
    },
    updateSpanColorRange: (
      state,
      action: PayloadAction<{ min: number; max: number }>
    ) => {
      state.spanColorRange = action.payload;
    },
    updateAttribSortingAlgo: (state, action: PayloadAction<SortingAlgo>) => {
      state.attributeSortingAlgo = action.payload;
    },
    updateDiffcardContext: (
      state,
      action: PayloadAction<{ context: DiffCardContext; open: boolean }>
    ) => {
      state.currentDiffCardContext = action.payload.open
        ? action.payload.context
        : undefined;
    },
    openAddGuardPopup: (state) => {
      state.isGuardOpen = true;
    },
    closeAddGuardPopup: (state) => {
      state.isGuardOpen = false;
    },
    updateGuard: (state, action: PayloadAction<Guard>) => {
      const guard = action.payload;
      const guardOperation = guard.config.attribLocator.operation;

      const addGuard = (prefixSummary: PrefixSummary, guard: Guard) => {
        let summaryPath =
          prefixSummary.attributeSummaries[
          guard.config.attribLocator.attributeKey
          ] ??
          prefixSummary.resourceAttributeSummaries[
          guard.config.attribLocator.attributeKey
          ];

        if (!isEmpty(summaryPath)) {
          summaryPath.guards
            ? summaryPath.guards.push(guard)
            : (summaryPath.guards = [guard]);
        }
      };

      const findAndAddGuardWithinEntrypoints = (
        entryPointIDs: string[],
        operation: OperationLocator
      ) => {
        entryPointIDs.forEach((entryPoint) => {
          Object.values(state.entryPoints[entryPoint].overlays).forEach(
            (overlayData) => {
              const childPrefixSummaries =
                overlayData.response.prefixSummaryTree.childPrefixSummaries;
              findAndAddGuard(
                childPrefixSummaries,
                operation.contextualOperation!.operation,
                guard
              );
            }
          );
        });
      };

      const findAndAddGuard = (
        children: PrefixSummaryTree[],
        operation: QualifiedOperation,
        guard: Guard
      ) => {
        if (isEmpty(children)) {
          return;
        }

        children.forEach((child) => {
          if (isEqual(operation, child.operation)) {
            addGuard(child.prefixSummary, guard);
          }

          findAndAddGuard(child.childPrefixSummaries, operation, guard);
        });
      };

      //add guard(s) to the response state
      let entryPoints: string[];
      if (guardOperation.pathPrefix) {
        entryPoints = [state.selectedEntryPointHash];

        state.selectedTrace?.sources.forEach((source) => {
          const overlayTraceSource = get(state, source.path).source;
          const responseSourcePrefixSummary = get(
            state,
            overlayTraceSource
          ).prefixSummary;

          addGuard(responseSourcePrefixSummary, guard);
        });
      } else {
        entryPoints = isEmpty(guardOperation.contextualOperation!.entrypoint)
          ? Object.keys(state.entryPoints)
          : [state.selectedEntryPointHash];

        findAndAddGuardWithinEntrypoints(entryPoints, guardOperation);
      }

      //remove traceData in overlays and aggrigate
      entryPoints.forEach((entryPoint) => {
        const entryPointData = state.entryPoints[entryPoint];
        Object.values(entryPointData.overlays).forEach((overlayData) => {
          overlayData.traceData = {};
        });

        entryPointData.aggregate.traceData = {};
      });

      //reselect current trace
      overlaysSlice.caseReducers.selectTrace(state, {
        payload: state.selectedTrace!,
        type: overlaysSlice.actions.selectTrace.type,
      });
    },
    openAttributeSettingsPopup: (state) => {
      state.isAttrubuteSettingsOpen = true;
    },
    closeAttributeSettingsPopup: (state) => {
      state.isAttrubuteSettingsOpen = false;
    },
    openDrawer: (state, action: PayloadAction<DrawerMode>) => {
      state.isDrawerOpen = true;
      state.drawerMode = action.payload;
    },
    updateColor: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    },
    createOverlayId: (state) => {
      state.overlayId = generateUniqueID();
    },
    updateOverlayId: (state, action: PayloadAction<string>) => {
      state.overlayId = action.payload;
    },
    closeDrawer: (state) => {
      state.isDrawerOpen = false;
    },
    setSelectedTrace: (state, action: PayloadAction<ITrace>) => {
      state.selectedTrace = action.payload;
    },
    addAllFilters: (state, action: PayloadAction<IFilter[]>) => {
      state.filters = action.payload;
    },
    addFilter: (state, action: PayloadAction<IFilter>) => {
      state.filters.push(action.payload);
    },
    deleteFilter: (state, action: PayloadAction<number>) => {
      state.filters = state.filters.filter((_, i) => action.payload !== i);
    },
    updateFilters: (
      state,
      action: PayloadAction<{ index: number; field: string; value: string }>
    ) => {
      state.filters = state.filters?.map((filter, i) => {
        if (action.payload.index === i) {
          return {
            ...filter,
            [action.payload.field]: action.payload.value,
          };
        }
        return filter;
      });
    },
    updateDateRange: (state, action: PayloadAction<IDateRange>) => {
      if (isEmpty(action.payload)) {
        state.dateRange = {};
      } else {
        state.dateRange = { ...state.dateRange, ...action.payload };
      }
    },
    updateSamplingMode: (state, action: PayloadAction<Mode>) => {
      if (action.payload == Mode.FIXED_SAMPLING) {
        state.samplingMode = {
          mode: action.payload,
          manualSamplingRate: state.samplingMode.manualSamplingRate || 0.1,
        };
      } else if (action.payload == Mode.DYNAMIC_SAMPLING) {
        state.samplingMode = { mode: action.payload };
      } else {
        console.error("Unknown sampling mode");
      }
    },
    updateSamplingRate: (state, action: PayloadAction<number>) => {
      state.samplingMode.manualSamplingRate = action.payload;
    },
    setTraceTreeRoot: (
      state,
      {
        payload,
      }: PayloadAction<{ overlay: IOverlayII; entryPointHash: string }>
    ) => {
      let id = payload.overlay.request!.query.id;
      let node = payload.overlay.response?.prefixSummaryTree;

      payload.overlay.traceTree = {
        nodeId: maskUUID(node?.nodeId as string),
        self: `entryPoints.${payload.entryPointHash}.overlays.${id}.traceTree`,
        source: `entryPoints.${payload.entryPointHash}.overlays.${id}.response.prefixSummaryTree`,
        prefix: node?.prefix,
        operation: node?.operation,
        hasChildNodes: node?.childPrefixSummaries?.length
          ? node?.childPrefixSummaries?.length > 0
          : false,
        childNodes: null,
        frequency: node?.prefixSummary.numOccurrences || 0,
        latency: node?.prefixSummary.avgLatency || 0,
        errorRate: node?.prefixSummary.errorRate || 0,
      };

      let entryPoint = state.entryPoints[payload.entryPointHash];

      if (entryPoint) {
        entryPoint.overlays[id] = payload.overlay;
      } else {
        state.entryPoints[payload.entryPointHash] = {
          overlays: {
            [id]: payload.overlay,
          },
          aggregate: {
            traceTree: null,
            traceData: {},
          },
        };
      }
    },
    setAggregatedTraceTreeRoot: (
      state,
      { payload: entryPointHash }: PayloadAction<string>
    ) => {
      const overlays = state.entryPoints[entryPointHash].overlays;

      if (!isEmpty(overlays)) {
        state.entryPoints[entryPointHash].aggregate.traceTree = null;
        state.entryPoints[entryPointHash].aggregate.traceData = {};

        const firstOverlayId = Object.keys(overlays)[0];
        const firstOverlay = overlays[firstOverlayId];

        state.entryPoints[entryPointHash].aggregate.traceTree = {
          key: generateUniqueID(),
          self: `entryPoints.${entryPointHash}.aggregate.traceTree`,
          sources: [],
          prefix: firstOverlay.traceTree.prefix,
          operation: firstOverlay.traceTree.operation,
          title: firstOverlay.traceTree.operation.operationName,
          isLeaf: true,
          children: null,
        };
        Object.keys(overlays).forEach((overlayId) => {
          let traceTree = overlays[overlayId].traceTree;
          state.entryPoints[entryPointHash].aggregate.traceTree?.sources.push({
            nodeId: maskUUID(traceTree.nodeId),
            path: traceTree.self,
          });

          let currentTraceTree =
            state.entryPoints[entryPointHash].aggregate.traceTree;
          if (currentTraceTree) {
            currentTraceTree.isLeaf =
              currentTraceTree.isLeaf && !traceTree.hasChildNodes;
          }
        });
      }
    },
    setSelectedEntryPointHash: (state, action: PayloadAction<string>) => {
      state.selectedEntryPointHash = action.payload;
    },
    /*
     Click trace reducer
     */
    setAggregatedTraceData: (state, { payload }: PayloadAction<ITrace>) => {
      let entryPointHash = payload.self.split(".")[1];

      overlaysSlice.caseReducers.setSelectedEntryPointHash(state, {
        payload: entryPointHash,
        type: overlaysSlice.actions.setSelectedEntryPointHash.type,
      });

      const aggrigatedTraceData =
        state.entryPoints[entryPointHash].aggregate.traceData[payload.id];

      if (isEmpty(aggrigatedTraceData)) {
        const sourceTraceData: ITraceData[] = [];

        payload.sources.forEach((source: ISource) => {
          const { traceTreePath, overlayId, nodeId } =
            OverlayHelpers.getSourceMetaData(source);

          const traceTree = get(state, traceTreePath);
          let traceData: ITraceData =
            state.entryPoints[entryPointHash].overlays[overlayId].traceData[
            nodeId
            ];
          if (!traceData) {
            const sourceData: PrefixSummary = get(
              state,
              traceTree.source
            ).prefixSummary;

            traceData = OverlayHelpers.processSourceData(sourceData, overlayId);

            state.entryPoints[entryPointHash].overlays[overlayId].traceData[
              nodeId
            ] = traceData;
          }
          sourceTraceData.push(traceData);
        });

        state.entryPoints[entryPointHash].aggregate.traceData[payload.id] =
          OverlayHelpers.aggregateTraceData(sourceTraceData);
      }
    },
    /*
     Expand trace reducer
     */
    setAggregatedTraceTreeChildren: (state, action: PayloadAction<ITrace>) => {
      let children = get(state, action.payload.self).children;
      if (isEmpty(children)) {
        let traceTreeChildrenList: ITrace[] = [];

        action.payload.sources.forEach((source) => {
          let traceTree = get(state, source.path);

          if (traceTree.hasChildNodes) {
            if (!traceTree.childNodes) {
              overlaysSlice.caseReducers.setTraceTreeChildren(state, {
                payload: {
                  traceTree: traceTree,
                  path: source.path,
                },
                type: overlaysSlice.actions.setTraceTreeChildren.type,
              });
            }
            traceTreeChildrenList.push(traceTree.childNodes);
          }
        });

        get(state, action.payload.self).children =
          OverlayHelpers.aggregateTraceTreeChildren(
            action.payload.self,
            traceTreeChildrenList
          );
      }
    },
    setTraceTreeChildren: (
      state,
      action: PayloadAction<{ traceTree: any; path: string }>
    ) => {
      let { path, traceTree } = action.payload;
      let sourceTraceTreeChildren = get(
        state,
        traceTree.source
      ).childPrefixSummaries;

      let traceTreeChildren = OverlayHelpers.processSourceTraceTreeChildren(
        traceTree.source,
        sourceTraceTreeChildren,
        path
      );

      get(state, path).childNodes = traceTreeChildren;
    },
    resetOverlays: (state) => {
      state.entryPoints = {};
    },
    deleteOverlay: (state, action: PayloadAction<string>) => {
      let blankEntryPointHashes: string[] = [];

      Object.entries(state.entryPoints).forEach(
        ([entryPointHash, entryPoint]) => {
          if (entryPoint.overlays[action.payload]) {
            delete entryPoint.overlays[action.payload];
            if (Object.keys(entryPoint.overlays).length == 0) {
              blankEntryPointHashes.push(entryPointHash);
            } else {
              overlaysSlice.caseReducers.setAggregatedTraceTreeRoot(state, {
                payload: entryPointHash,
                type: overlaysSlice.actions.setAggregatedTraceTreeRoot.type,
              });
            }
          }
        }
      );

      //remove blank entrypoints
      blankEntryPointHashes.forEach((hash) => {
        delete state.entryPoints[hash];
      });

      //selecting the first tree root node
      const entryPointValues = Object.values(state.entryPoints);
      const firstTraceTreeRootNode = isEmpty(entryPointValues)
        ? undefined
        : entryPointValues[0].aggregate.traceTree;

      firstTraceTreeRootNode &&
        overlaysSlice.caseReducers.selectTrace(state, {
          payload: {
            id: firstTraceTreeRootNode.key,
            self: firstTraceTreeRootNode.self,
            sources: firstTraceTreeRootNode.sources,
            prefix: firstTraceTreeRootNode.prefix,
            operation: firstTraceTreeRootNode.operation,
          },
          type: overlaysSlice.actions.selectTrace.type,
        });
    },
    selectTrace: (state, { payload }: PayloadAction<ITrace>) => {
      overlaysSlice.caseReducers.setAggregatedTraceData(state, {
        payload: payload,
        type: overlaysSlice.actions.setAggregatedTraceData.type,
      });
      overlaysSlice.caseReducers.setSelectedTrace(state, {
        payload: payload,
        type: overlaysSlice.actions.setSelectedTrace.type,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttributeConfig.pending, (state) => {
        state.getAttributeConfigLoading = true;
      })
      .addCase(getAttributeConfig.rejected, (state) => {
        state.getAttributeConfigLoading = false;
      })
      .addCase(getAttributeConfig.fulfilled, (state, action) => {
        state.getAttributeConfigLoading = false;
        state.attributeSettings = action.payload;
      })
      .addCase(updateAttributeConfig.pending, (state) => {
        state.updateAttributeConfigLoading = true;
      })
      .addCase(updateAttributeConfig.rejected, (state) => {
        state.updateAttributeConfigLoading = false;
      })
      .addCase(updateAttributeConfig.fulfilled, (state) => {
        state.updateAttributeConfigLoading = false;

        overlaysSlice.caseReducers.closeAttributeSettingsPopup(state);
      })
      .addCase(addGuard.pending, (state) => {
        state.addGuardLoading = true;
      })
      .addCase(addGuard.rejected, (state) => {
        state.addGuardLoading = false;
      })
      .addCase(addGuard.fulfilled, (state, action) => {
        state.addGuardLoading = false;

        overlaysSlice.caseReducers.closeAddGuardPopup(state);
        const guard: Guard = {
          id: action.payload.response.id,
          config: action.payload.request.config,
        };
        overlaysSlice.caseReducers.updateGuard(state, {
          payload: guard,
          type: overlaysSlice.actions.updateGuard.type,
        });
      })
      .addCase(listConditionValues.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listConditionValues.fulfilled, (state, action) => {
        state.loading = false;
        state.valueConfigs = action.payload.valueConfigs;
      })
      .addCase(listConditionValues.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSummaries.pending, (state, { payload }) => {
        state.summaryLoading = true;
      })
      .addCase(getSummaries.fulfilled, (state, action) => {
        state.summaryLoading = false;

        const request = action.payload.request;
        const response = action.payload.response;

        response?.results?.forEach(
          (result: { trees: SummaryTree[] }, index: number) => {
            result?.trees?.forEach((summaryTree: SummaryTree) => {
              let overlay: IOverlayII = {
                request: { query: request.queries[index] },
                response: summaryTree,
                traceColor: request.queries[index].color,
                traceTree: {},
                traceData: {},
              };
              const entryPointHash = generateHash(
                summaryTree.prefixSummaryTree.operation
              );
              overlaysSlice.caseReducers.setTraceTreeRoot(state, {
                payload: { overlay, entryPointHash },
                type: overlaysSlice.actions.setTraceTreeRoot.type,
              });
              overlaysSlice.caseReducers.setAggregatedTraceTreeRoot(state, {
                payload: entryPointHash,
                type: overlaysSlice.actions.setAggregatedTraceTreeRoot.type,
              });
            });
          }
        );

        //selecting the first tree root node
        const entryPointValues = Object.values(state.entryPoints);
        const firstTraceTreeRootNode = isEmpty(entryPointValues)
          ? undefined
          : entryPointValues[0].aggregate.traceTree;

        firstTraceTreeRootNode &&
          overlaysSlice.caseReducers.selectTrace(state, {
            payload: {
              id: firstTraceTreeRootNode.key,
              self: firstTraceTreeRootNode.self,
              sources: firstTraceTreeRootNode.sources,
              prefix: firstTraceTreeRootNode.prefix,
              operation: firstTraceTreeRootNode.operation,
            },
            type: overlaysSlice.actions.selectTrace.type,
          });
      })
      .addCase(getSummaries.rejected, (state, { payload }) => {
        state.summaryLoading = false;
      });
  },
});

export const {
  setQueries,
  updateQueries,
  updateRingAttribute,
  updateSpanColorAttribute,
  updateSpanColorRange,
  updateAttribSortingAlgo,
  updateDiffcardContext,
  openAddGuardPopup,
  closeAddGuardPopup,
  openAttributeSettingsPopup,
  closeAttributeSettingsPopup,
  openDrawer,
  updateColor,
  closeDrawer,
  setSelectedTrace,
  addAllFilters,
  addFilter,
  deleteFilter,
  updateFilters,
  updateDateRange,
  updateSamplingMode,
  updateSamplingRate,
  setTraceTreeRoot,
  setAggregatedTraceTreeRoot,
  setAggregatedTraceData,
  setSelectedEntryPointHash,
  setAggregatedTraceTreeChildren,
  resetOverlays,
  deleteOverlay,
  selectTrace,
  createOverlayId,
  updateOverlayId,
} = overlaysSlice.actions;
export default overlaysSlice.reducer;
