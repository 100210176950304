import { Modal } from "antd";
import GenerationSteps from "./GenerationSteps";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeGenerationPopup } from "../../features/testGenerationSlice";

const TestGenerationPopup = () => {
  const dispatch = useAppDispatch();
  const isGenerationPopupOpen = useAppSelector((state) => state.testGeneration.isGenerationPopupOpen);

  return (
    <Modal
      centered
      width={"70%"}
      open={isGenerationPopupOpen}
      title="Test Generation Wizard"
      onCancel={() => dispatch(closeGenerationPopup())}
      footer={null}
    >
      <GenerationSteps />
    </Modal>
  );
};

export default TestGenerationPopup;
