import React, { useState, useEffect } from "react";
import { Select, Table } from "antd";
import {
  listGuardEvaluations,
  GuardEvaluationDetail,
  ListGuardEvaluationsRequest,
  GuardConfig,
  OperationLocator,
} from "../api/services/Guards";
import "../styles/TestCaseViewer.css";
import { formatTimestamp } from "../util/Timestamps";
import {
  getOperationFromPathPrefix,
  toPathFormatString,
  getEntryFromPathPrefix,
} from "../api/services/Common";
import { Converters } from "../util";

interface Project {
  id: string;
  name: string;
}

interface Resource {
  id: string;
  name: string;
}

const { Option } = Select;

const GuardEvaluationsViewer: React.FC = () => {
  const [evaluations, setEvaluations] = useState<GuardEvaluationDetail[]>([]);
  const [guardConfig, setGuardConfig] = useState<GuardConfig>();
  const urlParams = new URLSearchParams(window.location.search);
  const guardId = urlParams.get("guard_id") || "";

  const fetchEvaluations = async () => {
    const request: ListGuardEvaluationsRequest = {
      guardId: guardId,
      paginationRequest: {
        pageSize: 100,
        paginationToken: "",
      },
    };
    const response = await listGuardEvaluations.listGuardEvaluations(request);
    setEvaluations(response.guardEvaluationDetails);
    setGuardConfig(response.guardConfig);
  };

  const renderTimestamp = (evaluationDetail: GuardEvaluationDetail) => {
    if (evaluationDetail && evaluationDetail.evaluationTimestamp) {
      const timestamp = evaluationDetail.evaluationTimestamp;
      return formatTimestamp(timestamp + ""); // Convert timestamp to readable format
    }
    return null; // If timestamp or evaluation detail is not available
  };

  const renderEvaluation = (evaluationDetail: GuardEvaluationDetail) => {
    if (evaluationDetail) {
      const evaluation = evaluationDetail.evaluation;
      const result = evaluation.result;

      let circleColor = "";
      let evaluationText = "";

      switch (result) {
        case "SUCCESS":
          circleColor = "green";
          evaluationText = "Success";
          break;
        case "FAIL":
          circleColor = "red";
          evaluationText = "Fail";
          break;
        default:
          circleColor = "gray";
          evaluationText = "Unknown";
      }

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: 12,
              height: 12,
              borderRadius: "50%",
              backgroundColor: circleColor,
              marginRight: 5,
            }}
          />
          <span>
            Expected: {evaluation.rightValue ?? ""}, Was:{" "}
            {evaluation.leftValue ?? ""}
          </span>
        </div>
      );
    }
    return null; // If evaluation detail is not available
  };

  useEffect(() => {
    fetchEvaluations();
  }, []);

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (_text: string, record: GuardEvaluationDetail) =>
        renderTimestamp(record),
    },
    {
      title: "Release Version",
      dataIndex: "resourceVersion",
      key: "resourceVersion",
      render: (_text: string, record: GuardEvaluationDetail) =>
        record.evaluatedContext.resourceVersion,
    },
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      render: (_text: string, record: GuardEvaluationDetail) =>
        record.evaluatedContext.environment,
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      render: (_text: string, record: GuardEvaluationDetail) =>
        renderEvaluation(record),
    },
  ];

  function getOperationLocatorString(
    operation: OperationLocator
  ): string {
    if (operation.pathPrefix) {
      return (
        toPathFormatString(getEntryFromPathPrefix(operation.pathPrefix)) +
        "-> ... ->" +
        toPathFormatString(getOperationFromPathPrefix(operation.pathPrefix)) +
        " "
      );
    } else {
      let ret: string = toPathFormatString(
        operation.contextualOperation!.operation
      );
      if (operation.contextualOperation!.entrypoint) {
        ret +=
          " in " + toPathFormatString(operation.contextualOperation!.entrypoint);
      }
      return ret + " ";
    }
  }

  function getGuardDescriptor(guardConfig: GuardConfig | undefined): string {
    if (!guardConfig) {
      return "";
    }
    let ret: string = "";
    if (guardConfig.attribLocator.attributeKey) {
      ret += guardConfig.attribLocator.attributeKey + " @ ";
    } else {
      ret += "Operation presence of ";
    }
    ret += getOperationLocatorString(guardConfig.attribLocator.operation);
    if (guardConfig.operation) {
      ret += guardConfig.operation;
    }
    if (guardConfig.value) {
      ret += " " + Converters.typedValueToString(guardConfig.value) + " ";
    }
    ret += "(" + guardConfig.severity + ")";
    return ret;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          padding: "1em",
          justifyContent: "center",
          alignItems: "center",
          height: "5em",
          backgroundColor: "var(--light-color)",
        }}
      >
        <h3>
          Recent evaluations for:{" "}
          <span style={{ fontFamily: "courier new" }}>
            {getGuardDescriptor(guardConfig)}
          </span>
        </h3>
      </div>
      <div id="guard-eval-container">
        <Table<GuardEvaluationDetail>
          rowKey="timestamp"
          dataSource={evaluations}
          columns={columns} // Pass the columns to the Table component
        />
      </div>
    </div>
  );
};

export default GuardEvaluationsViewer;
