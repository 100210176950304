import { Box, Tab, Tabs } from "@mui/material";
import { grey } from "@mui/material/colors";
import { MainTab } from "../models";
import { isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";
import ReadOnlyHTTPMessage from "./ReadOnlyHTTPMessage";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { emptyRawRequest, emptyRawResponse } from "../../test-studio/models";
import AttributeSummaryTable from "../../test-studio/components/AttributeSummaryTable";

interface HTTPReplayProps {
  traceId: string;
}

const HTTPReplay: FC<HTTPReplayProps> = ({ traceId }) => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState(MainTab.REQUEST);
  const rootPrefixes = useAppSelector((state) => state.replays.rootPrefixes);
  const prefixSummaries = useAppSelector(
    (state) => state.replays.prefixSummaries
  );
  const selectedPrefix = useAppSelector(
    (state) => state.replays.selectedPrefix
  );

  const getRequestResponseToShow = () => {
    let prefix = rootPrefixes[traceId];
    if (selectedPrefix[traceId]) {
      prefix = selectedPrefix[traceId];
    }
    if (!prefix) {
      return { request: emptyRawRequest, response: emptyRawResponse };
    }
    return prefixSummaries[traceId][prefix] &&
      prefixSummaries[traceId][prefix].requestResponsePairs
      ? prefixSummaries[traceId][prefix].requestResponsePairs![0]
      : { request: emptyRawRequest, response: emptyRawResponse };
  };

  const getAttributeSummariesToShow = () => {
    let prefix = rootPrefixes[traceId];
    if (selectedPrefix[traceId]) {
      prefix = selectedPrefix[traceId];
    }
    if (!prefix) {
      return {};
    }
    return prefixSummaries?.[traceId]?.[prefix]?.attributeSummaries ?? {};
  };

  return (
    <Box
      className="fade-in"
      key={"main"}
      display={"flex"}
      position={"relative"}
      overflow={"hidden"}
      flexDirection={"column"}
      flexGrow={1}
      paddingX={2}
      paddingY={0.7}
      bgcolor={grey[200]}
    >
      <Tabs value={tab} onChange={(_, value: MainTab) => setTab(value)}>
        <Tab key={MainTab.REQUEST} value={MainTab.REQUEST} label={"Request"} />
        <Tab
          key={MainTab.RESPONSE}
          value={MainTab.RESPONSE}
          label={"Response"}
        />
        <Tab
          key={MainTab.ATTRIBUTES}
          value={MainTab.ATTRIBUTES}
          label={"Attributes"}
        />
      </Tabs>
      {tab == MainTab.REQUEST && (
        <ReadOnlyHTTPMessage
          message={getRequestResponseToShow()?.request}
          isResponse={false}
        />
      )}
      {tab == MainTab.RESPONSE && (
        <ReadOnlyHTTPMessage
          message={getRequestResponseToShow()?.response}
          isResponse={true}
        />
      )}
      {tab == MainTab.ATTRIBUTES && (
        <AttributeSummaryTable
          attributeSummaries={getAttributeSummariesToShow()}
        />
      )}
    </Box>
  );
};

export default HTTPReplay;
