import { Divider, Flex, Typography } from "antd";
import { FC } from "react";
import { IDecoratedFieldUpdateRule } from "../models";
import Rule from "./Rule";
import NewRule from "./NewRule";

const RuleList: FC<{ rules: IDecoratedFieldUpdateRule[] }> = ({ rules }) => {
  const { Text } = Typography;

  return (
    <Flex vertical style={{ marginTop: 16 }} flex={1} gap={8}>


      {rules?.map((rule, index) => <Rule key={index} rule={rule} index={index} />)}
      <Divider plain dashed style={{ marginTop: 1, marginBottom: 0, border: 0.2 }}>
        Add new field update
      </Divider>
      <NewRule />
    </Flex>
  );
};

export default RuleList;
