import {
  ContinuationState,
  IGetTraceSummaryTreesForTraceRequest,
  IGetTraceSummaryTreesForTraceResponse,
  IListOperationsWithDetailsRequest,
  IListOperationsWithDetailsResponse,
} from "../../models/overlays";
import { delay } from "../../util/TimeUtils";
import client from "../client";

export interface AddIgnoredEntrypointRequest{
  operationId:number;
  resourceId:number;
}

export interface AddIgnoredEntrypointResponse{

}

const Collectors = {
  getTraceSummaryTreesForTrace: async (
    request: IGetTraceSummaryTreesForTraceRequest
  ) => {
    const res = await client.post(
      "/collectors/get_trace_summaries_for_trace",
      request
    );
    return res.data;
  },
  listOperationsWithDetails: async (request: IListOperationsWithDetailsRequest): Promise<IListOperationsWithDetailsResponse> => {
    const response = await client.post("/collectors/list_operations_with_details", request);
    return response.data;
  },
  addIgnoredEntrypoint: async (request: AddIgnoredEntrypointRequest): Promise<AddIgnoredEntrypointResponse> => {
    const response = await client.post("/collectors/add_ignored_entrypoint", request);
    return response.data;
  },
  // Loops (with delays in between) until a COMPLETED response is returned for the query.
  awaitGetTraceSummaryTreesForTrace: async (
    request: IGetTraceSummaryTreesForTraceRequest,
    initialWait: number | undefined
  ) => {
    if (initialWait && initialWait > 0) {
      await delay(initialWait);
    }
    let attempts = 0;
    const maxAttempts = 4;
    let response: IGetTraceSummaryTreesForTraceResponse = {
      trees: [],
      continuationState: ContinuationState.POTENTIALLY_INCOMPLETE,
    };
    while (attempts < maxAttempts) {
      console.log("Attempting to fetch trace summary for ", attempts, request);
      response = await Collectors.getTraceSummaryTreesForTrace(request);
      const { continuationState } = response;
      if (continuationState === ContinuationState.COMPLETED) {
        return response; // Return the response when completed
      } else if (
        continuationState === ContinuationState.POTENTIALLY_INCOMPLETE
      ) {
        await delay(3000);
        attempts++;
      }
    }
    return response;
  },
};

export default Collectors;
