import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Organization } from "../../../api";
import { useSnackbar } from "../../../hooks/useSnackbar";

const ManageUser = ({ open, close }) => {
  const { showSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsersForOrganization();
  }, [open]);

  const getUsersForOrganization = async () => {
    const payload = {};
    const res = await Organization.getUsers(payload);
    if (!res.error) {
      setUsers(res.data.userInfos);
    } else {
      console.error(res.error);
      showSnackbar("Get users for organization failed", "error");
    }
  };

  const removeUserFromOrg = async (userId) => {
    const payload = {
      user_id: userId,
    };

    const res = await Organization.removeUser(payload);
    if (!res.error) {
      showSnackbar("User removed from organization", "success");
      getUsersForOrganization();
    } else {
      console.error(res.error);
      showSnackbar("Removing user from organization failed", "error");
    }
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal open={open} onClose={close}>
        <Box sx={styles}>
          <Typography variant="h5">Manage Users</Typography>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User Email</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.map((user) => (
                    <TableRow key={user.userId}>
                      <TableCell>{user.email}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="error"
                          onClick={() => removeUserFromOrg(user.userId)}
                        >
                          <PersonRemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={close}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ManageUser;
