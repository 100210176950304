import React from 'react';
import { FormControlLabel, FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup, Button } from '@mui/material';
import { getTraceCollectionLabel } from "../common/Util";
import DiffOptionsPanel from './DiffOptionsPanel';
import { ThemeColors } from '../styles/light-colors';

class DiffTraceSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxChecked: this.props.isDiffEnabled,
      selectedItem: '',
      diffMode: 'value'
    };
  }

  handleCheckboxChange = (event) => {
    this.setState({ checkboxChecked: event.target.checked });
    this.props.onToggle(event.target.checked);
  };

  handleSelectionChange = (event) => {
    this.setState({ selectedItem: event.target.value });
    this.props.onSelect(event.target.value.collectorId);
  };

  onDiffModeSelection = (mode) => {
    this.setState({ diffMode: mode });
    this.props.onDiffModeSelection(mode);
  };

  render() {
    const styles = {
      selectContainer: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      select: {
        maxWidth: "100%",
      },
    };
    const { onDiffTraceSelect, selectedDiffSummaryDesc } = this.props;
    const { checkboxChecked, selectedItem } = this.state;
    let diffOptionsContent = (<div></div>);
    if (checkboxChecked) {
      diffOptionsContent = (
        <FormControl >
          <DiffOptionsPanel disabled={!checkboxChecked} onOptionChange={this.onDiffModeSelection} />
        </FormControl>
      )
    }
    return (
      <div style={{ height: '100%', margin: '10px' }}>
        <FormGroup>
          <FormControlLabel style={{ color: ThemeColors.darkText }}
            control={<Checkbox checked={checkboxChecked} style={{ color: ThemeColors.primary }} onChange={this.handleCheckboxChange} />}
            label="Diff with..."
          />
        </FormGroup>
        <FormGroup>
          <FormControl disabled={!checkboxChecked} style={{ maxWidth: '100%' }}>
            <Button style={{ color: checkboxChecked ? ThemeColors.darkText : ThemeColors.inactive }} variant="outlined" onClick={onDiffTraceSelect} disabled={!checkboxChecked}>{selectedDiffSummaryDesc ? selectedDiffSummaryDesc : "Select a Diff Collection..."}</Button>
          </FormControl>
          {diffOptionsContent}
        </FormGroup>
      </div>
    );
  }

}

export default DiffTraceSelector;
