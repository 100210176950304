import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    Box,
} from '@mui/material';
import { ITraceDetail } from '../../models/sessions';

interface Step {
    name: string;
}

interface CreateTestDialogProps {
    open: boolean;
    onClose: () => void;
    traces: ITraceDetail[];
    onCreateTest: (selectedTraces: ITraceDetail[]) => void;
}

const CreateTestDialog: React.FC<CreateTestDialogProps> = ({ open, onClose, traces, onCreateTest }) => {
    const [selectedSteps, setSelectedSteps] = useState<Set<number>>(new Set());

    const handleSelectAll = () => {
        setSelectedSteps(new Set(traces?.map((_, index) => index)));
    };

    const handleDeselectAll = () => {
        setSelectedSteps(new Set());
    };

    const handleSelectStep = (index: number) => {
        const updatedSteps = new Set(selectedSteps);
        if (updatedSteps.has(index)) {
            updatedSteps.delete(index);
        } else {
            updatedSteps.add(index);
        }
        setSelectedSteps(updatedSteps);
    };

    const handleCreateTest = () => {
        const selectedTraces = Array.from(selectedSteps??[]).map(index => traces[index]);
        onCreateTest(selectedTraces);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Select Steps</DialogTitle>
            <DialogContent>
                <Button onClick={handleSelectAll}>Select All</Button>
                <Button onClick={handleDeselectAll}>Deselect All</Button>
                <Box sx={{ maxHeight: 300, overflowY: 'auto', mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Select</TableCell>
                                <TableCell>Step Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {traces?.map((step, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedSteps.has(index)}
                                            onChange={() => handleSelectStep(index)}
                                        />
                                    </TableCell>
                                    <TableCell>{step.entryOperation?.operationName ?? ""}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleCreateTest} color="primary" variant="contained">
                    Create Test
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateTestDialog;