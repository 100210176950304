import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from "react";
import { Box, ListItem, Typography, IconButton, Paper, ThemeProvider } from "@mui/material";
import { grey } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip, Tree } from "antd";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { IStep, TreeLoadState } from "../models";
import { getLastOperationName } from "../../util/TraceUtils";
import theme from "../../common/theme";
import "../../styles/TestStep.css"; // Assuming you have CSS modules set up
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface TestStepProps {
  step: IStep;
  index: number;
  isSelected: boolean;
  shouldHighlight: boolean;
  opacity: number;
  onAddButtonClick?: (index: number) => void;
  onCopyButtonClick?: (index: number) => void;
  onDeleteButtonClick?: (index: number) => void;
  onSelectStep: (index: number) => void;
  onSelectPrefix: (pathPrefix: string) => void;
  onTreeToggle?: (expanded: boolean) => void; // New prop for handling tree toggle
  firstTimeHighlight?: boolean;
}

export interface TestStepRef {
  setChildNodesMap: (
    callTreeLoadingState: TreeLoadState,
    rootPrefix: string,
    nodes: { [pathPrefix: string]: string[] },
    nodeStatus: { [pathPrefix: string]: string }
  ) => void;
  scrollIntoView: (options: ScrollIntoViewOptions) => void;
}

const getResourceNameForStep = (step: IStep): string => {
  if (step.context.internalContext) {
    return step.context.internalContext.operation.resourceName;
  } else if (step.context.externalContext) {
    return "External";
  } else if (step.context.testStep) {
    if (step.context.testStep?.internalEndpointConfig?.operation) {
      return step.context.testStep.internalEndpointConfig.operation.resourceName ?? "Unknown";
    } else if (step.context.testStep.externalEndpoint) {
      return "External";
    }
  }
  return "Unknown";
};

const TestStep: React.ForwardRefRenderFunction<TestStepRef, TestStepProps> = (
  {
    step,
    index,
    isSelected,
    shouldHighlight,
    opacity,
    onAddButtonClick,
    onCopyButtonClick,
    onDeleteButtonClick,
    onSelectStep,
    onSelectPrefix,
    onTreeToggle,
    firstTimeHighlight,
  },
  ref
) => {
  const [isHovered, setIsHovered] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]); // State for selected tree keys
  const [showCallTree, setShowCallTree] = useState(false);
  const [rootPrefix, setRootPrefix] = useState<string>("");
  const [callTreeLoadingState, setCallTreeLoadingState] = useState<TreeLoadState>(TreeLoadState.NOT_STARTED);
  const [childNodes, setChildNodes] = useState<{
    [pathPrefix: string]: string[];
  }>({});
  const [nodeStatus, setNodeStatus] = useState<{
    [pathPrefix: string]: string;
  }>({});
  const [containerHeight, setContainerHeight] = useState<number | null>(null); // State to manage container height

  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    !isSelected && setSelectedKeys([]);
  }, [isSelected]);

  useImperativeHandle(ref, () => ({
    setChildNodesMap: (
      callTreeLoadingState: TreeLoadState,
      rootPrefix: string,
      nodes: { [pathPrefix: string]: string[] },
      nodeStatus: { [pathPrefix: string]: string }
    ) => {
      setCallTreeLoadingState(callTreeLoadingState);
      setRootPrefix(rootPrefix);
      setChildNodes(nodes);
      setNodeStatus(nodeStatus);
    },
    scrollIntoView: (options: ScrollIntoViewOptions) => domRef.current?.scrollIntoView(options),
  }));

  const resourceName = getResourceNameForStep(step);

  const handleListItemClick = () => {
    handleSelect([], {});
    console.log("Test step click", index);
    onSelectStep(index);
    setSelectedKeys([]); // Clear selected tree node
  };

  const getCallTreeLabel = () => {
    if (callTreeLoadingState == TreeLoadState.COMPLETE) {
      if (rootPrefix && (childNodes[rootPrefix] ?? []).length > 0) {
        return " Call Tree";
      }
      return "";
    }
    if (callTreeLoadingState == TreeLoadState.LOADING) {
      return " Call Tree...";
    }
    return "";
  };

  const handleExpand = () => {
    const newShowCallTree = !showCallTree;
    setShowCallTree(newShowCallTree);
    if (onTreeToggle) {
      onTreeToggle(newShowCallTree); // Notify parent about tree toggle
    }
  };

  const handleSelect = (selectedKeys: React.Key[], info: any) => {
    const selectedKey = selectedKeys[0] as string;
    onSelectPrefix(selectedKey);

    // Update the expandedKeys state based on currently expanded keys
    if (!expandedKeys.includes(selectedKey)) {
      setExpandedKeys((prevExpandedKeys) => [...prevExpandedKeys, selectedKey]);
    }
    setSelectedKeys([selectedKey]); // Set the selected tree key
  };

  const truncateWithTooltip = (text: string | undefined, maxLength: number) => {
    if (!text) {
      return "";
    }
    if (text.length <= maxLength) {
      return text;
    }
    return (
      <Tooltip title={text}>
        <span>{maxLength < 0 ? text : text.substring(0, maxLength) + "..."}</span>
      </Tooltip>
    );
  };

  const buildTreeData = (
    data: { [pathPrefix: string]: string[] },
    rootPrefix: string,
    nodeStatus: { [pathPrefix: string]: string }
  ): any => {
    // Find the node corresponding to the rootPrefix
    let rootColor: string = nodeStatus[rootPrefix] == "STATUS_CODE_OK" ? "var(--ok-color)" : "var(--error-color)";
    let borderColor: string = nodeStatus[rootPrefix] == "STATUS_CODE_OK" ? "#eee" : "var(--error-color)";
    const rootNode = {
      title: (
        <Paper
          style={{
            border: "1px solid ",
            borderColor: borderColor,
            color: rootColor,
            borderRadius: "6px",
            padding: "8px",
            marginBottom: "8px",
          }}
        >
          <div>{truncateWithTooltip(getLastOperationName(rootPrefix).operationName, 25)}</div>
          <div style={{ fontWeight: "200", color: "grey" }}>{getLastOperationName(rootPrefix).resourceName}</div>
        </Paper>
      ),
      key: rootPrefix,
      children: [],
    };

    // Recursively build children nodes
    const buildChildren = (prefix: string): any => {
      if (!data[prefix]) {
        return [];
      }
      return data[prefix]?.map((childPrefix) => ({
        title: (
          <div
            style={{
              border: "1px solid",
              borderColor: nodeStatus[childPrefix] == "STATUS_CODE_OK" ? "#eee" : "var(--error-color)",
              color: nodeStatus[childPrefix] == "STATUS_CODE_OK" ? "var(--ok-color)" : "var(--error-color)",
              borderRadius: "6px",
              padding: "8px",
              marginBottom: "8px",
            }}
          >
            <div>{truncateWithTooltip(getLastOperationName(childPrefix).operationName, 25)}</div>
            <div style={{ fontWeight: "100" }}>{getLastOperationName(childPrefix).resourceName}</div>
          </div>
        ),
        key: childPrefix,
        children: buildChildren(childPrefix),
      }));
    };

    rootNode.children = buildChildren(rootPrefix);
    return [rootNode];
  };

  // Update container height whenever call tree visibility changes
  useEffect(() => {
    if (showCallTree) {
      // Timeout to ensure the DOM updates after the tree is expanded
      setTimeout(() => {
        const paperHeight = document.getElementById(`paper-${step.id}`)?.clientHeight ?? 0;
        const callTreeHeight = 150; // Adjust this value based on your call tree's height
        const totalHeight = paperHeight + callTreeHeight;
        setContainerHeight(totalHeight);
      }, 0);
    } else {
      setContainerHeight(null); // Reset height when call tree is hidden
    }
  }, [showCallTree]);

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsHovered(false);
    }, 200);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        ref={domRef}
        sx={{
          marginBottom: 2.5,
          position: "relative",
          width: "90%",
          borderRadius: 3,
          marginLeft: "auto",
          marginRight: "auto",
          opacity: opacity,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => handleMouseLeave()}
      >
        <ListItem
          sx={{
            padding: 0,
            minHeight: "60px",
            position: "relative",
            display: "block",
            textOverflow: "ellipsis",
            borderRadius: 3,
            cursor: "pointer",
          }}
          disableGutters
          onClick={handleListItemClick}
        >
          <Typography
            variant="body2"
            sx={{
              color: "grey",
              zIndex: 1,
              paddingLeft: 1.5,
              borderBottomLeftRadius: 3,
              borderBottomRightRadius: 3,
            }}
          >
            {resourceName}
          </Typography>
          <Paper
            className={firstTimeHighlight ? "highlighted" : ""}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              borderRadius: 3,
              border: isSelected ? `1px solid var(--secondary-color)` : `1px solid ${grey[400]}`,
              bgcolor: isSelected
                ? `var(--dark-background-color)`
                : shouldHighlight
                ? `var(--background-color)`
                : "inherit",
              padding: "16px",
              position: "relative", // Ensure the position is relative for absolute children
            }}
          >
            <Typography
              variant="body1"
              sx={{
                wordBreak: "break-all", // Break text at any character to prevent overflow
              }}
            >
              {truncateWithTooltip(step.name, -1)}
            </Typography>
            {onAddButtonClick && onDeleteButtonClick && onCopyButtonClick && (
              <Box
                className="action-buttons"
                sx={{
                  opacity: isHovered ? 1 : 0,
                  position: "absolute",
                  right: 8,
                  top: "calc(100%)", // Place 8px below the ListItem
                  display: "flex",
                  flexDirection: "row", // Display buttons horizontally
                  gap: 0.2, // Gap between buttons
                  transition: "opacity 0.3s ease",
                }}
              >
                <IconButton size="small" onClick={() => onCopyButtonClick(index)} sx={{ fontSize: "small", zIndex: 1 }}>
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => onAddButtonClick(index)} sx={{ fontSize: "small", zIndex: 1 }}>
                  <AddIcon fontSize="small" />
                </IconButton>
                {index !== 0 && (
                  <IconButton size="small" onClick={() => onDeleteButtonClick(index)} sx={{ fontSize: "small" }}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            )}
          </Paper>
        </ListItem>
        <Box sx={{ padding: "0 16px" }}>
          {rootPrefix &&
            (Object.keys(childNodes[rootPrefix] ?? []).length > 0 ||
              callTreeLoadingState == TreeLoadState.LOADING ||
              callTreeLoadingState == TreeLoadState.COMPLETE) && (
              <Box sx={{ cursor: "pointer", marginTop: 1 }}>
                <Typography
                  sx={{ color: "gray", marginBottom: "5px" }}
                  className={firstTimeHighlight ? "highlightedText" : ""}
                  variant="body2"
                  onClick={handleExpand}
                >
                  {showCallTree ? (
                    <MinusSquareOutlined style={{ marginLeft: 4 }} />
                  ) : Object.keys(childNodes[rootPrefix] ?? []).length > 0 ? (
                    <PlusSquareOutlined style={{ marginLeft: 4 }} />
                  ) : (
                    <span />
                  )}
                  {showCallTree ? " Hide" : getCallTreeLabel()}
                </Typography>
                {showCallTree && (
                  <Tree
                    defaultExpandAll
                    onSelect={handleSelect}
                    treeData={buildTreeData(childNodes, rootPrefix, nodeStatus)[0].children}
                    expandedKeys={expandedKeys}
                    onExpand={(keys: React.Key[]) => setExpandedKeys(keys as string[])}
                    selectedKeys={selectedKeys} // Set the selected keys for the tree
                  />
                )}
              </Box>
            )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default forwardRef(TestStep);
