import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#008080',
      contrastText: "#fff"
    },
    // ...other palette configurations
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          textTransform: 'none', // Prevent automatic capitalization for contained primary buttons
        },
        root: {
          textTransform: 'none', // Prevent automatic capitalization for all buttons
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Prevent automatic capitalization for tab labels
        },
      },
    },
  },
});

export default theme;
