import LocalStorageManager from "../common/LocalStorageManager";

const Org = {
  isAdmin() {
    const userRole = LocalStorageManager.getOrgUserRole();
    return userRole === "ORG_ADMIN_ROLE";
  },
};

const Project = {
  isEditor() {
    const projectId = LocalStorageManager.getProjectId();
    const projectRole =
      LocalStorageManager.getProjectConfigMap()[projectId].role;
    return projectRole === "PROJECT_EDITOR";
  },
};

export { Org, Project };
