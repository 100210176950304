import { QualifiedOperation } from "../../api/services/Common";

export interface ISetConstantExpression {
  side: ExpressionSide;
  label: string;
  type: ExpressionType;
}

export interface ISetVariableExpression {
  side: ExpressionSide;
  label: string;
  prefix: string;
  attributeKey: string;
  type: ExpressionType;
  options: string[];
}

export interface IAddAssertionRequest {
  assertion: IAssertion;
  testCase: ITestCase;
}

export interface IDeleteAssertionRequest {
  assertionId: number;
}

export interface IListAssertiosnRequest {
  testCase: ITestCase;
}

export interface ITestCase {
  name: string;
  suite: string;
  type: string;
}

export interface IListAssertionsResponse {
  assertions: IAssertion[];
}

export interface IAssertion {
  id?: number;
  leftExpression: IVariableExpression | IConstantExpression;
  rightExpression: IVariableExpression | IConstantExpression;
  assertionOperation: AssertionOperation;
}

export enum AssertionOperation {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  IS_NULL = "IS_NULL",
  IS_NOT_NULL = "IS_NOT_NULL",
  STARTS_WITH = "STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  CONTAINS = "CONTAINS",
}

export enum ExpressionSide {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum ExpressionType {
  VARIABLE = "VARIABLE",
  CONSTANT = "CONSTANT",
}

export interface IVariableExpression {
  variableExpression: {
    attributeKey: string;
    pathParts: IPathPart[];
    pathPrefix?: string;
  };
}

export interface IPathPart {
  operation: QualifiedOperation;
}

export interface IConstantExpression {
  constantExpression: {
    stringValue: string;
  };
}
