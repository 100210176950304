import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { updateAttribSortingAlgo } from "../../features/overlaysSlice";
import { SortingAlgo } from "../../models/overlays";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import {
  OrderAlphabeticalAscending,
  OrderAlphabeticalDescending,
} from "mdi-material-ui";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppDispatch, RootState } from "../../app/store";

const AttributeSortingOptions = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const attributeSortingAlgo = useAppSelector(
    (state: RootState) => state.overlays.attributeSortingAlgo
  );
  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      gap={2}
      padding={1}
    >
      <Typography>Sorting options </Typography>
      <ToggleButtonGroup
        exclusive
        size="small"
        value={attributeSortingAlgo}
        onChange={(_, value) =>
          value && dispatch(updateAttribSortingAlgo(value as SortingAlgo))
        }
      >
        <ToggleButton value={SortingAlgo.ASCENDING} disableRipple>
          <Tooltip title={"Assending order"}>
            <OrderAlphabeticalAscending />
          </Tooltip>
        </ToggleButton>

        <ToggleButton value={SortingAlgo.DESCENDING} disableRipple>
          <Tooltip title={"Dessending order"}>
            <OrderAlphabeticalDescending />
          </Tooltip>
        </ToggleButton>

        <ToggleButton value={SortingAlgo.ATTRIBDIFF} disableRipple>
          <Tooltip title={"Attribute diff"}>
            <AlignHorizontalLeftIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default AttributeSortingOptions;
