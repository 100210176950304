import { configureStore } from "@reduxjs/toolkit";
import assertionsSlice from "../features/assertionsSlice";
import assertionSuggestionsSlice from "../features/asssertionSuggestionsSlice";
import overlaysSlice from "../features/overlaysSlice";
import testsSlice from "../features/testsSlice";
import testExecutionsSlice from "../features/testExecutionsSlice";
import projectSettingsSlice from "../admin/ProjectSettings/projectSettingsSlice";
import commonSlice from "../features/commonSlice";
import replaysSlice from "../features/replaysSlice";
import variableSuggestionSlice from "../features/variableSuggestionSlice";
import testListSlice from "../features/testListSlice";
import apisSlice from "../features/apisSlice";
import testGenerationSlice from "../features/testGenerationSlice";

export const store = configureStore({
  reducer: {
    common: commonSlice,
    overlays: overlaysSlice,
    projectSettings: projectSettingsSlice,
    assertions: assertionsSlice,
    assertionSuggestions: assertionSuggestionsSlice,
    variableSuggestions: variableSuggestionSlice,
    tests: testsSlice,
    testGeneration: testGenerationSlice,
    apis: apisSlice,
    testList: testListSlice,
    testExecutions: testExecutionsSlice,
    replays: replaysSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
