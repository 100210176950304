import React, { useState, useEffect } from 'react';
import { Modal, Select, Collapse, Button, Typography, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import DefineVarStep1 from "../images/DefineVarIllustrateStep1.gif";
import DefineVarStep2 from "../images/DefineVarIllustrateStep2.gif";
import DefineAssertStep1 from "../images/DefineAssertStep1.gif";
import DefineAssertStep2 from "../images/DefineAssertStep2.gif";
import DefineAssertStep3 from "../images/DefineAssertStep3.gif";
import ValueGenStep1 from "../images/ValueGenStep1.gif";
import FreeformValueGen from "../images/FreeformValueGen.gif";
import VocabValueGen from "../images/VocabValueGen.gif";
import ParameterizedTestsStep1 from "../images/ParameterizedTestsStep1.gif";
import ParameterizedTestsStep2 from "../images/ParameterizedTestsStep2.gif";
import ParameterizedTestsStep3 from "../images/ParameterizedTestsStep3.gif";
import ParameterizedTestsStep4 from "../images/ParameterizedTestsStep4.gif";
import EnvVar1 from "../images/EnvVar1.gif";
import EnvVar2 from "../images/EnvVar2.gif";
import EnvVar3 from "../images/EnvVar3.gif";
import EnvVar4 from "../images/EnvVar4.gif";
import GenScript1 from "../images/GenScript1.gif";
import GenScript2 from "../images/GenScript2.gif";
import ScriptValueGen from "../images/ScriptValueGen.gif";
import UseVar from "../images/UseVarIllustrate.gif";
import Admin from '../api/services/Admin';
import StartCaptureImg from "../images/StartCaptureIllustrate.gif";
import EndCaptureImg from "../images/EndCaptureIllustrate.gif";
import SessionLinkImg from "../images/SessionLinkIllustrate.gif";
import SearchSessionStep1 from "../images/SearchSessionStep1.gif";
import SearchSessionStep2 from "../images/SearchSessionStep2.gif";
import SearchSessionStep3 from "../images/SearchSessionStep3.gif";
import SearchSessionStep4 from "../images/SearchSessionStep4.gif";

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

type FeatureStep = {
  title: string;
  content: string;
  image?: string;
};

type FeatureData = {
  [topic: string]: FeatureStep[];
};

type ComponentGuides = {
  [component: string]: FeatureData;
};

interface GuideModalProps {
  visible: boolean;
  onClose: () => void;
  componentSelection?: string;
}

const GuideModal: React.FC<GuideModalProps> = ({ visible, onClose, componentSelection }) => {
  const [selectedComponent, setSelectedComponent] = useState<string>(componentSelection || "Test Studio");
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
  const [contactSupportVisible, setContactSupportVisible] = useState(false);
  const [contactSupportQuery, setContactSupportQuery] = useState<string>("");

  const guides: ComponentGuides = {
    "Test Studio": {
      "Define & Use Variables": [
        { title: "Right Click on Field -> Store As Variable", content: "Navigate to the request / response field you want to extract in to a variable. Right Click & select: <b>Store as variable</b>", image: DefineVarStep1 },
        { title: "Give a name and save", content: "The variable store will open. In the text field, enter a name for the variable, and save", image: DefineVarStep2 },
        { title: "Use in subsequent steps", content: "Variables can be referred in any subsequent steps with like so: <b>{{authToken}}</b>", image: UseVar },
      ],
      "Define Custom Assertions": [
        { title: "Right Click on Field -> Set as Left Expression of Assertion", content: "An assertion is represented as a left and right expression and the expected relation between them (equals / greater than etc.). Right click on any field and select <b>Set as Left Expression...</b> to use the field as the left side of current assertion being built.", image: DefineAssertStep1 },
        { title: "Set Operation", content: "Select the operation defining the relationship of the left side of assertion with the right side", image: DefineAssertStep2 },
        { title: "Set Right Expression of Assertion", content: "Set the right hand side of the assertion. An expression can be a fixed value, a variable <i>(eg: {{var_name}})</i> or can be another field <i>(right click -> Set as Right Expression...)</i>.", image: DefineAssertStep3 },
      ],
      "Using Value Generators": [
        { title: "Right Click on Field -> Set Generator", content: "Value generators determine how the value for a given field of a request is generated. It can be freeform text, a vocabulory from which values are picked randomly at each run, or a custom JS script.", image: ValueGenStep1 },
        { title: "Option 1: Freeform", content: "Type in the value directly. You can reference variables defined with {{ }}. <i>Eg: Bearer {{authToken}}</i>.", image: FreeformValueGen },
        { title: "Option 2: Vocabulary", content: "Specify a set of values. At each run of the test, a different value will be picked from the set. <i>If you want to run a test multiple times with each value, use test parameters instead</i> .", image: VocabValueGen },
        { title: "Option 3: Script", content: "Write a JS script that generates the desired value. You can choose from pre-defined scripts, or explain in plain english the expectated value.", image: ScriptValueGen },
      ],
      "Parameterizing Your Test": [
        { title: "Click More -> Parameterize Test", content: "Parameterizing a test lets you run a single test for <b>multiple value combinations</b> by simply uploading a CSV file.", image: ParameterizedTestsStep1 },
        { title: "Upload CSV", content: "Each row will be a separate test iteration representing a value combination. Each column can be referred as a variable by the column name", image: ParameterizedTestsStep2 },
        { title: "Use Parameters in Request Fields", content: "The parameters (column names) can be used just like variables, in anywhere in the test <i>(eg: {{country}})</i>", image: ParameterizedTestsStep3 },
        { title: "Run from Tests Tab", content: "When the test is run, it will execute the test for each value combination separately, allowing to cover wide range of scenarios with a single test", image: ParameterizedTestsStep4 },
      ],
      "Export as a script": [
        { title: "Click More -> Generate Script...", content: "You can export the test as a script in any of the 10 popuplar frameworks such as Playwright, Cypress, RestAssured etc.", image: GenScript1 },
        { title: "Select Language", content: "Select the scripting language in which to export the test", image: GenScript2 },
      ],
      "Using Environment Variables": [
        { title: "Click More -> Config Environment Variables", content: "Environment variables lets you use the same test in different environments <i>(Eg: Prod, Staging etc.)</i>.", image: EnvVar1 },
        { title: "Add New Environment", content: "Click <b>Add New Environment</b>, and give a name (eg: Prod, QA etc.)", image: EnvVar2 },
        { title: "Add new variables and env specific values", content: "In the tab for a given environment, add the variables and their corresponding values in that environment.", image: EnvVar3 },
        { title: "Use environment variables in the test", content: "Environment variables can be referred similarly to other variables <i>(eg: {{app_host}})</i>, anywhere in the test.", image: EnvVar4 },
      ],
    },
    "Sessions Tab": {
      "Capture manual test session": [
        { title: "Start Capture", content: "Go to your webapp. Open TestChimp Chrome Extension. Click <b>Start Capture</b>.", image: StartCaptureImg },
        { title: "End Capture", content: "Test a user journey in your webapp. Then open the extension and click <b>End Capture</b>.", image: EndCaptureImg },
        { title: "View Session", content: "Follow the link to the session to view the session. It will take <b>~2 minutes</b> for the UI recording to be complete.", image: SessionLinkImg },
      ],
      "Search for recorded session": [
        { title: "Select Project, Environment and Time range", content: "Click <b>Find Users</b> after selecting the project, environment and time range. This will list the users whose sessions are recorded.", image: SearchSessionStep1 },
        { title: "Select User -> Click Search", content: "From the users drop down, select the user (tester) of interest", image: SearchSessionStep2 },
        { title: "Choose session to view", content: "The list of sessions, along with APIs invoked in each session will be shown. Click on play icon to view the session.", image: SearchSessionStep3 },
      ],
      "Get a permalink for session": [
        { title: "In Sessions Tab - Select Project, Environment and Time range", content: "Click <b>Find Users</b> after selecting the project, environment and time range. This will list the users whose sessions are recorded.", image: SearchSessionStep1 },
        { title: "Select User -> Click Search", content: "From the users drop down, select the user (tester) of interest", image: SearchSessionStep2 },
        { title: "Find the session and get permalink", content: "Find the session of interest in the list, and click on Permalink icon. This will copy a unique url that can be added to your Jira tickets, reports etc. for referring to this session.", image: SearchSessionStep4 },
      ],
    },
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContactSupportQuery(event.target.value); // Update the state variable with the TextArea value
  };

  useEffect(() => {
    if (guides[selectedComponent]) {
      const topics = Object.keys(guides[selectedComponent]);
      setSelectedTopic(topics.length > 0 ? topics[0] : null);
    }
  }, [selectedComponent]);

  const handleComponentChange = (value: string) => {
    setSelectedComponent(value);
  };

  const handleTopicChange = (value: string) => {
    setSelectedTopic(value);
  };

  const openContactSupport = () => {
    setContactSupportVisible(true);
    onClose(); // Close the GuideModal
  };

  const contactSupport = () => {
    Admin.contactSupport({ message: contactSupportQuery })
    setContactSupportVisible(false);

  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        footer={null}
        closeIcon={<CloseOutlined />}
        title={null}
        bodyStyle={{ paddingTop: 20 }}
      >
        {/* Component Selection */}
        <Select
          placeholder="Select a component"
          value={selectedComponent}
          style={{ width: '100%', marginBottom: 20 }}
          onChange={handleComponentChange}
        >
          {Object.keys(guides).map((component) => (
            <Option key={component} value={component}>
              {component}
            </Option>
          ))}
        </Select>

        {/* Topic Selection */}
        {selectedComponent && guides[selectedComponent] && (
          <Select
            placeholder="Select a topic"
            value={selectedTopic || undefined}
            style={{ width: '100%', marginBottom: 20 }}
            onChange={handleTopicChange}
          >
            {(Object.keys(guides[selectedComponent] ?? {})).map((topic) => (
              <Option key={topic} value={topic}>
                {topic}
              </Option>
            ))}
          </Select>
        )}

        {/* Steps for Selected Topic */}
        {selectedTopic && (
          <Collapse accordion>
            {guides[selectedComponent][selectedTopic]?.map((step, index) => (
              <Panel header={<div dangerouslySetInnerHTML={{ __html: step.title }} />} key={index}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <p dangerouslySetInnerHTML={{ __html: step.content }} />
                  {step.image && <img src={step.image} alt={step.title} style={{ maxWidth: '100%' }} />}
                </div>
              </Panel>
            ))}
          </Collapse>
        )}

        {/* Contact Support Row */}
        <div style={{ marginTop: 20, textAlign: 'center' }}>
          <Typography.Text>Didn't find what you were looking for? </Typography.Text>
          <Button type="link" onClick={openContactSupport}>Contact Support</Button>
        </div>
      </Modal>

      {/* Contact Support Modal */}
      <Modal
        visible={contactSupportVisible}
        onCancel={() => setContactSupportVisible(false)}
        footer={null}
        title="Contact Support"
      >
        <TextArea rows={4} placeholder="Describe your issue or question here" style={{ marginBottom: 20 }} onChange={handleTextChange} />
        <Button type="primary" className="secondary-button" block onClick={() => contactSupport()}>
          Send
        </Button>
      </Modal>
    </>
  );
};

export default GuideModal;