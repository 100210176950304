import React from "react";
import { postData } from "../common/WebUtils";
import DimensionOrderTab from "./DimensionOrderTab";
import { Tabs, Tab, Box, Paper } from "@mui/material";
import { Button } from "@mui/material";
import LocalStorageManager from "../common/LocalStorageManager";

class UserOrgConfigViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      config: this.props.config,
      projectId: this.props.projectId,
      dimensions: [],
      activeTab: 0,
      loading: false,
    };
    this.dimensionOrderingChild = React.createRef();
  }

  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
  };

  handleSave = () => {
    const { projectId, config } = this.state;
    const { onHandleSave } = this.props;
    let updatedConfig = { ...config };
    console.log("Before update config:", updatedConfig);
    if (this.dimensionOrderingChild.current) {
      let dims = this.dimensionOrderingChild.current.getDimensionOrder();
      updatedConfig = {
        ...updatedConfig,
        dimensionViewConfig: { orderedDimensions: dims },
      };
      console.log("after update config:", updatedConfig);
    }
    onHandleSave(projectId, updatedConfig);
  };

  handleCancel = () => {
    const { onHandleCancel } = this.props;
    onHandleCancel();
  };

  update() {
    this.setState({ loading: true });
    postData("/collectors/list_dimensions", {})
      .then((response) => {
        this.setState(
          { dimensions: response.dimensions, loading: false },
          () => {
            if (this.dimensionOrderingChild.current) {
              this.dimensionOrderingChild?.current.update();
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error(error);
      });
  }

  componentDidMount() {
    this.update();
  }

  render() {
    const { projectId, config } = this.props;
    const { dimensions, activeTab, loading } = this.state;
    return (
      <div>
        <Paper style={{ marginTop: "20px" }}>
          <div>
            <Tabs value={activeTab} onChange={this.handleTabChange}>
              <Tab label="Dimension Ordering" />
            </Tabs>
            <Box p={3}>
              {activeTab === 0 && (
                <div>
                  <DimensionOrderTab
                    ref={this.dimensionOrderingChild}
                    onUpdateDimsOrdering={this.onUpdateDimsOrdering}
                    sortedDimensions={dimensions}
                    config={config}
                    projectId={projectId}
                    key={dimensions}
                    loading={loading}
                  />
                </div>
              )}
            </Box>
          </div>
        </Paper>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button variant="outlined" onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={this.handleSave}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default UserOrgConfigViewer;
