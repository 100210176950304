import React, { useEffect, useState } from "react";
import { Tree } from "antd";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { ITrace, TraceTree } from "../../models/overlays";
import {
  setAggregatedTraceTreeChildren,
  selectTrace,
} from "../../features/overlaysSlice";
import { isEmpty } from "lodash";
import { useAppDispatch } from "../../app/hooks";
import TreeNode from "./TreeNode";

const TreeView: React.FC = () => {
  const dispatch = useAppDispatch();
  const entryPoints = useAppSelector(
    (state: RootState) => state.overlays.entryPoints
  );
  const selectedTrace = useAppSelector(
    (state: RootState) => state.overlays.selectedTrace
  );
  const [treeData, setTreeData] = useState<TraceTree[]>([]);

  useEffect(() => {
    if (!isEmpty(entryPoints)) {
      const traceTree = Object.values(entryPoints).map((entryPoint) => {
        return entryPoint.aggregate.traceTree;
      });
      setTreeData(traceTree as TraceTree[]);
    }
  }, [entryPoints]);

  const onExpandHandler = (_: any, { node }: any) => {
    const trace: ITrace = {
      id: node.key,
      self: node.self,
      sources: node.sources,
      prefix: node.prefix,
      operation: node.operation,
    };
    if (node.children === null) {
      dispatch(setAggregatedTraceTreeChildren(trace));
    }
  };

  const onSelectHandler = (_: any, { node }: any) => {
    const trace: ITrace = {
      id: node.key,
      self: node.self,
      sources: node.sources,
      prefix: node.prefix,
      operation: node.operation,
    };
    dispatch(selectTrace(trace));
  };

  const onTreeNodeLoad = () => {
    console.log("loaded");
  };

  const getTitleNode = (node: TraceTree) => {
    return <TreeNode node={node} key={node.key} />;
  };

  return (
    <Tree
      treeData={treeData}
      onExpand={onExpandHandler}
      onSelect={onSelectHandler}
      blockNode={true}
      selectedKeys={selectedTrace?.id ? [selectedTrace.id] : []}
      showLine
      titleRender={(node) => getTitleNode(node)}
      onLoad={onTreeNodeLoad}
      rootStyle={{
        fontSize: "15px",
        background: "transparent",
      }}
    ></Tree>
  );
};

export default TreeView;
