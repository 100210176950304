import React, { Component } from 'react';
import './AttribSettingsPopup.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FormControlLabel, FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { postData } from '../common/WebUtils';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

const BI_DIMENSION = "BI_DIMENSION";
class AttribSettingsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = { isBIDimension: false, maxCardinality: 0, hashingAlgo: "VALUE" };
    }

    componentDidMount() {
        this.fetchState();
    }

    fetchState = () => {
        let request = {};
        request.attribConfigSemanticId = { "name": this.props.attribute, "projectId": this.props.projectId, "operation": this.props.operation };
        postData(`org/get_attrib_config`, request)
            .then(response => {
                if (JSON.stringify(response) === '{}') {
                    this.setState({ isBIDimension: false, maxCardinality: 0, hashingAlgo: "VALUE" });
                } else {
                    let config = response.attribConfig;
                    this.setState({ isBIDimension: config.tags && config.tags.includes(BI_DIMENSION) ? true : false, maxCardinality: config.valueRetentionConfig.maxCardinality, hashingAlgo: config.attribHashConfig.hashMethod });
                }
            })
    }
    handleBiDimensionChange = (event) => {
        this.setState({ isBIDimension: event.target.checked });
    }

    handleHashingAlgoSelection = (event) => {
        this.setState({ hashingAlgo: event.target.value });
    }

    handleSave = () => {
        const { isBIDimension, maxCardinality, hashingAlgo } = this.state;
        const { attribute, projectId, onSave, operation } = this.props;
        let request = {}
        request.attribConfigSemanticId = { "name": attribute, "projectId": projectId, "operation": operation };
        request.attribConfig = { "tags": isBIDimension ? [BI_DIMENSION] : [], "attribHashConfig": { "hashMethod": hashingAlgo }, "valueRetentionConfig": { "maxCardinality": maxCardinality } }
        postData(`org/update_attrib_config`, request)
            .then(response => {
                onSave();
            })
            .catch(error => {
                console.error(error);
                onSave();
            })
    }

    handleCancel = () => {
        const { attribute, onSave } = this.props;
        onSave();
    }

    handleMaxCardinalityChange = (event) => {
        this.setState({ maxCardinality: parseInt(event.target.value) });
    };

    render() {
        const styles = {
            button: {
                borderRadius: '5px',
                backgroundColor: '#87CEFA',
                color: '#FFFFFF',
                padding: '5px 10px',
                fontSize: '14px',
                textTransform: 'none',
                margin: '1em',
            }
        };
        const { isBIDimension, maxCardinality, hashingAlgo } = this.state;
        const { attribute } = this.props
        return (
            <Dialog open={true} PaperProps={{ style: { width: '400px' } }}>
                <DialogTitle style={{ textAlign: 'center' }}>Attribute Settings</DialogTitle>
                <FormGroup style={{ margin: '1em' }}>
                    <FormControlLabel
                        control={<Checkbox checked={isBIDimension} onChange={this.handleBiDimensionChange} />}
                        label="Is BI Dimension"
                    />
                </FormGroup>
                <FormControl style={{ margin: '1em' }}>
                    <Select value={hashingAlgo} onChange={this.handleHashingAlgoSelection} style={{ width: '100%' }} label="Masking Method">
                        <MenuItem value="VALUE" key="VALUE">No Masking</MenuItem>
                        <MenuItem value="BOOLEAN" key="BOOLEAN">Presence</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ margin: '1em' }}>
                    <TextField
                        label="Max Cardinality"
                        type="number"
                        value={maxCardinality}
                        onChange={this.handleMaxCardinalityChange}
                        InputProps={{
                            inputProps: { step: 1 },
                            endAdornment: (
                                <>
                                    <InputAdornment position="end">
                                        <ArrowUpward onClick={() => this.setState({ maxCardinality: this.state.maxCardinality + 1 })} />
                                    </InputAdornment>
                                    <InputAdornment position="end">
                                        <ArrowDownward onClick={() => this.setState({ maxCardinality: this.state.maxCardinality - 1 })} />
                                    </InputAdornment>
                                </>
                            )
                        }}
                    />
                </FormControl>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" style={styles.button} onClick={this.handleSave}>Save</Button>
                    <Button variant="outlined" style={styles.button} onClick={this.handleCancel}>Cancel</Button>
                </div>
            </Dialog>
        );
    }
}

export default AttribSettingsPopup;