import { IQuery } from "../models/explore";
import { IFilter } from "../models/overlays";
import { ITraceDetail } from "../models/sessions";
import { IInternalStepContext, IStep, StepContext } from "../test-studio/models";

export const createSteps = (traces: ITraceDetail[]): IStep[] => {
  return traces?.map((trace): IStep => {
    const filter: IFilter = {
      key: "traceId",
      operator: "EQUALS",
      value: trace.traceId,
    };

    const query: IQuery = {
      filters: [filter],
    };

    const internalContext: IInternalStepContext = {
      query: query,
      operation: trace.entryOperation,
      endpoint: trace.endpoint
    };

    return {
      id: trace.traceId,
      refTraceId: trace.traceId,
      context: {
        internalContext: internalContext,
      }
    };
  });
};
