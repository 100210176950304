import React, { useMemo } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IFilter, IValueConfig } from "../../models/overlays";

interface FilterProps {
  index: number;
  valueConfigs: IValueConfig[];
  keys: string[];
  displayValues: string[];
  filter: IFilter;
  onDelete: () => void;
  onChange: (index: number, field: string, value: string) => void;
}

const Filter: React.FC<FilterProps> = ({
  index,
  valueConfigs,
  keys,
  displayValues,
  filter,
  onDelete,
  onChange,
}) => {
  const operations = useMemo(() => {
    const valueConfig = valueConfigs.find(
      (config) => config.key === filter.key
    );
    return valueConfig ? valueConfig.operations : [];
  }, [filter]);

  const values = useMemo(() => {
    const valueConfig = valueConfigs.find(
      (config) => config.key === filter.key
    );
    return valueConfig ? valueConfig.values : [];
  }, [filter]);

  const getDisplayValueByKey = (key: string): string | undefined => {
    const matchingValue = valueConfigs.find((value) => value.key === key);
    return matchingValue ? matchingValue.displayValue : undefined;
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-filled-label">{`Key`}</InputLabel>
        <Select
          label={`Key`}
          onChange={(event) =>
            onChange(index, "key", event.target.value as string)
          }
          value={filter.key}
        >
          {keys?.map((key, index) => (
            <MenuItem key={index} value={key}>
              {getDisplayValueByKey(key)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-filled-label">Operator</InputLabel>
        <Select
          label="Operator"
          onChange={(event) =>
            onChange(index, "operator", event.target.value as string)
          }
          value={filter.operator}
        >
          {operations?.map((key, index) => (
            <MenuItem key={index} value={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label="Value"
          onChange={(event) =>
            onChange(index, "value", event.target.value as string)
          }
          value={filter.value}
          select={values && values.length > 0}
        >
          {values?.map((key, index) => (
            <MenuItem key={index} value={key}>
              {key}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
      <IconButton disabled={index == 0} onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default Filter;
