import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Project } from "../../../api";
import { useSnackbar } from "../../../hooks/useSnackbar";
import USER_ROLES from "../Constants/UserRoles";

const AddUser = ({ open, handleClose, project }) => {
  const { showSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [hasError, setHasError] = useState({
    showSnackBar: false,
    errorMessage: "",
  });
  const [user, setUser] = useState({
    email: "",
    role: USER_ROLES.PROJECT_VIEWER.value,
  });

  const onAddToProjectHandler = async () => {
    console.log(user);
    setSaving(true);

    const inviteUserRequest = {
      email: user.email,
      project_id: project.projectId,
      user_project_role: user.role,
    };

    const res = await Project.inviteUser(inviteUserRequest);
    if (!res.error) {
      showSnackbar("User added to project", "success");
      setSaving(false);
      handleClose();
    } else {
      showSnackbar("Adding user failed. Please try again.", "error");
      setSaving(false);
      console.log(res.error);
    }
  };

  const onErrorOnCloseHandler = () => {
    setHasError({
      showSnackBar: false,
      errorMessage: "",
    });
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={styles}>
          <Typography variant="h5">Add User</Typography>
          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            marginTop="1rem"
          >
            <Typography variant="subtitle1">Project Details</Typography>
            <TextField
              fullWidth
              label="Project Name"
              variant="outlined"
              value={project?.projectName}
              disabled
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            marginTop="1rem"
          >
            <Typography variant="subtitle1">User Details</Typography>
            <TextField
              fullWidth
              id="project-user-email"
              label="User Email"
              variant="outlined"
              value={user.email}
              onChange={(e) => {
                setUser({
                  ...user,
                  email: e.target.value,
                });
              }}
            ></TextField>
            <FormControl>
              <InputLabel id="user-role">Role</InputLabel>
              <Select
                labelId="user-role"
                label="User Role"
                value={user.role}
                onChange={(e) =>
                  setUser({
                    ...user,
                    role: e.target.value,
                  })
                }
                fullWidth
              >
                <MenuItem value={USER_ROLES.PROJECT_VIEWER.value}>
                  {USER_ROLES.PROJECT_VIEWER.label}
                </MenuItem>
                <MenuItem value={USER_ROLES.PROJECT_EDITOR.value}>
                  {USER_ROLES.PROJECT_EDITOR.label}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            gap="1rem"
            marginTop="1rem"
          >
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              variant="contained"
              loading={saving}
              onClick={onAddToProjectHandler}
            >
              Add to Project
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={hasError.showSnackBar}
        autoHideDuration={2000}
        onClose={onErrorOnCloseHandler}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {hasError.errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddUser;
