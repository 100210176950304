import React from 'react';
import { FormControl, Menu, MenuItem, Button, Dialog, DialogTitle, DialogContent, Paper } from '@mui/material';
import { postData } from '../common/WebUtils';
import DiffTraceSelector from './DiffTraceSelector';
import SummaryFetcher from './SummaryFetcher';
import Typography from '@mui/material/Typography';
import { ThemeColors } from "../styles/light-colors";
import { Tree } from 'antd';
import LoadingPopup from '../common/LoadingPopup';

const { TreeNode } = Tree;

class TraceCollectionViewer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      baseCollectionSummary: '', // base collection summary being explored.
      diffCollectionSummary: '',
      viewTree: {}, // the viewed collection summary - when no diff is selected, this is same as base.
      diffMode: 'VALUE_COUNT',
      anchorEl: null,
      isSummaryFetcherDialogOpen: false,
      isFetchingBase: false,
      baseSummaryDesc: '',
      diffSummaryDesc: '',
      context: this.props.context,
      expandedKeys: [],
      attribSummaryMap: {},
      resourceAttribSummaryMap: {},
      showLoadingPopup: false
    };
    this.updateAttribSummaries = this.updateAttribSummaries.bind(this);
  }

  /// --- Init Component Fns ---

  componentDidMount() {
    this.fetchAndUpdateViewTree();
  }

  fetchAndUpdateViewTree = () => {
    let context = this.props.context;
    let viewConditions = context?.viewCollectionQuery?.filters ?? [];
    let diffConditions = context?.diffCollectionQuery?.filters ?? [];

    let viewTimeWindow = context?.viewCollectionQuery?.time_window ?? {};
    let diffTimeWindow = context?.diffCollectionQuery?.time_window ?? {};

    if (viewConditions.length > 0) {
      this.setState({ showLoadingPopup: true });
      postData('collectors/get_summary', {  "resource_id": context.resourceId, "entry_point": context.entryPoint, "filters": viewConditions, "time_window": context.viewCollectionQuery.time_window })
        .then((response) => {
          this.onBaseTraceSummaryFetch(response.summaryTree??{}, viewConditions, viewTimeWindow, response.exemplars);
          this.setState({ showLoadingPopup: false });
        });
    }
    if (diffConditions.length > 0) {
      this.setState({ showLoadingPopup: true });
      postData('collectors/get_summary', {  "resource_id": context.resourceId, "entry_point": context.entryPoint, "filters": diffConditions, "time_window": context.diffCollectionQuery.time_window })
        .then((response) => {
          this.onDiffTraceSummaryFetch(response.summaryTree??{}, diffConditions, diffTimeWindow, response.exemplars);
          this.setState({ showLoadingPopup: false });
        });
    }
  }

  /// --- Context Menu Fns ---

  handleContextMenu = (info) => {
    const { event, node } = info;
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  }

  handleMenuItemClick = (event) => {
    this.handleMenuClose();
    const itemId = event.currentTarget.id;
  }


  /// --- Diff'ing related Fns ---

  handleDiffSelection = () => {
    this.updateDiffedView();
  };

  handleDiffToggle = (isDiffEnabled) => {
    let context = this.props.context;
    context.isDiffEnabled = isDiffEnabled;
    this.props.onContextUpdate(context);
    this.updateDiffedView();
  };

  onDiffModeSelection = (mode) => {
    this.setState({ diffMode: mode }, () => {
      this.updateDiffedView();
    });
  };

  updateDiffedView = () => {
    if (!this.props.context.isDiffEnabled || !this.state.diffSummaryDesc) {
      this.setState({ viewTree: this.state.baseCollectionSummary });
    } else {
      const context = this.props.context;
      let baseQuery = { "project_id": context.projectId, "resource_id": context.resourceId, "entry_point": context.entryPoint, filters: context.viewCollectionQuery?.filters, "time_window": context.viewCollectionQuery?.time_window }
      let diffQuery = { "project_id": context.projectId, "resource_id": context.resourceId, "entry_point": context.entryPoint, filters: context.diffCollectionQuery?.filters, "time_window": context.diffCollectionQuery?.time_window }
      this.setState({ showLoadingPopup: true });
      postData('collectors/get_trace_collection_diff', { 'base_query': baseQuery, 'diff_query': diffQuery, 'diff_mode': this.state.diffMode })
        .then(response => {
          this.setState({ showLoadingPopup: false });
          this.setState({
            viewTree: response?.summaryTrees[0] ?? {"prefixSummaryTree":{}},
          }, () => {
            this.updateAttribSummaries({
              operation: {},
              attribSummaries: [],
              resourceAttribSummaries: []
            })
          })
        })
        .catch(error => {
          console.error(error);
        });
    }
    const { onDiffToggle } = this.props
    onDiffToggle(this.props.context.isDiffEnabled, this.state.diffMode)
  }

  /// --- Tree Render Fns ---

  getColor = (errorRate) => {
    if (errorRate < 1) {
      return ThemeColors.success;
    }
    if (errorRate > 20) {
      return ThemeColors.error;
    }
    return ThemeColors.warning;
  }

  getOpacity = (frequency) => {
    return 0.7 + (Math.abs(frequency) / 100);
  }

  updateAttribSummaries(node) {
    this.props.onNodeClick(node.operation, node.attribSummaries, node.resourceAttribSummaries, node.prefix);
  }

  onTreeNodeSelect = (selectedKeys, info) => {
    this.updateAttribSummaries(info.node);
  }



  renderTreeNodes = (data) =>
    data.map((node) => {
      if (!node || !node.prefixSummary) {
        return (<TreeNode style={{ fontSize: '1.2em', marginBottom: '1em', opacity: this.getOpacity(0), color: this.getColor(0) }} label="No data found" title="No data found"></TreeNode>
        );
      }
      console.log("Rendering ",data);

      var num_occurrences = node.prefixSummary.numOccurrences ? node.prefixSummary.numOccurrences.toFixed(0) : "0";
      var avg_latency = node.prefixSummary.avgLatency ? node.prefixSummary.avgLatency.toFixed(2) : "<1";
      var error_rate = node.prefixSummary.errorRate ? node.prefixSummary.errorRate.toFixed(2) : "<1";
      var error_rate_val = node.prefixSummary.errorRate ? node.prefixSummary.errorRate.toFixed(2) : 0;
      let operationLabel = node.operation.operationName;
      let label = operationLabel + " [ frequency; " + num_occurrences + ", latency: " + avg_latency + " ms" + ". errors: " + error_rate + "]";
      if (node.childPrefixSummaries) {
        return (
          <TreeNode style={{ fontSize: '1.2em', marginBottom: '1em', opacity: this.getOpacity(num_occurrences), color: this.getColor(error_rate_val) }} prefix={node.prefix} operation={node.operation} key={node.nodeId} label={label} attribSummaries={node.prefixSummary.attributeSummaries} resourceAttribSummaries={node.prefixSummary.resourceAttributeSummaries} title={label}>
            {this.renderTreeNodes(node.childPrefixSummaries)}
          </TreeNode>
        );
      }
      return <TreeNode style={{ fontSize: '1.2em', marginBottom: '1em', opacity: this.getOpacity(num_occurrences), color: this.getColor(error_rate_val) }} operation={node.operation} key={node.nodeId} label={label} attribSummaries={node.prefixSummary.attributeSummaries} resourceAttribSummaries={node.prefixSummary.resourceAttributeSummaries} title={label} {...node} />;
    });



  /// --- Trace Summary Fetch related Fns ---

  getOperatorStr(op) {
    if (op == "EQUALS") {
      return "";
    }
    if (op == "NOT_EQUALS") {
      return "!";
    }
    if (op == "LESS_THAN") {
      return "<";
    }
    if (op == "GREATER_THAN") {
      return ">";
    }
    if (op == "CONTAINS") {
      return "~";
    }
    if (op == "STARTS_WITH") {
      return "^";
    }
    if (op == "ENDS_WITH") {
      return "$";
    }
    return "";
  }

  getConditionStr(list) {
    let result = list.map(obj => `${this.getOperatorStr(obj.operator)}${obj.value}`).join(',');
    if (result.length > 40) {
      result = result.substring(0, 40 - 3) + "..."; // truncate resulting string if longer than 40 characters
    }
    return result;
  }

  fetchBaseTraceCollection = () => {
    this.setState({ isSummaryFetcherDialogOpen: true, isFetchingBase: true });
  }

  onBaseTraceSummaryFetch = (summaryTree, conditions, timeWindow, exemplars) => {
    const { onTraceCollectionSelection } = this.props;
    let context = this.props.context;
    let newViewCollectionQuery = context.viewCollectionQuery;
    newViewCollectionQuery.filters = conditions;
    newViewCollectionQuery.time_window = timeWindow;
    context.viewCollectionQuery = newViewCollectionQuery;
    this.setState({ isSummaryFetcherDialogOpen: false, baseCollectionSummary: summaryTree, viewTree: summaryTree, baseSummaryDesc: this.getConditionStr(conditions) }, () => {
      onTraceCollectionSelection(conditions, exemplars);
      if (context.isDiffEnabled) {
        this.updateDiffedView();
      }
    });
    this.props.onContextUpdate(context);
  }

  onDiffTraceSummaryFetch = (summaryTree, conditions, timeWindow, exemplars) => {
    const { onDiffCollectionSelection } = this.props;
    let context = this.props.context;
    context.diffCollectionQuery ??= {};
    context.diffCollectionQuery.filters = conditions;
    context.diffCollectionQuery.time_window = timeWindow;
    this.setState({ isSummaryFetcherDialogOpen: false, diffCollectionSummary: summaryTree, diffSummaryDesc: this.getConditionStr(conditions) }, () => {
      this.updateDiffedView();
      onDiffCollectionSelection(conditions, exemplars);
    });
    this.props.onContextUpdate(context);
  }

  onSwap = () => {
    let context = this.props.context;
    let t = context.diffCollectionQuery
    context.diffCollectionQuery = context.viewCollectionQuery;
    context.viewCollectionQuery = t;
    this.props.onContextUpdate(context);
  }

  showDiffTraceSelector = () => {
    this.setState({ isSummaryFetcherDialogOpen: true, isFetchingBase: false });
  }

  handleClose = () => {
    this.setState({ isSummaryFetcherDialogOpen: false })
  }

  onExpand = (expandedKeys) => {
    this.setState({ expandedKeys: expandedKeys });
  };

  render() {
    const { showLoadingPopup, expandedKeys, isSummaryFetcherDialogOpen, anchorEl, viewTree, isFetchingBase, baseSummaryDesc, diffSummaryDesc } = this.state;
    const { context } = this.props;
    let baseSummaryConditions = context.viewCollectionQuery?.filters ?? [];
    let diffSummaryConditions = context.diffCollectionQuery?.filters ?? [];
    const styles = {
      treeViewContainer: {
        minHeight: '500px',
        height: "100vh",
        border:ThemeColors.border,
        padding: '2em',
        overflowX: "scroll",
        background: ThemeColors.secondaryBackground,
        marginBottom: '2em',
        marginLeft: '2em',
        marginRight: '2em'
      },
      diffTraceContainer: {
        border:ThemeColors.border,
        padding: '1em',
        marginLeft: '2em',
        marginTop: '1em',
        marginRight: '2em',
        marginBottom: '1em'
      },
    };


    return (
      <div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleMenuClose}
        >
        </Menu>
        <FormControl style={{ display: "flex", margin: "40px" }}>
          <Button style={{ color: ThemeColors.darkText }} variant="outlined" onClick={this.fetchBaseTraceCollection}>{baseSummaryDesc ? baseSummaryDesc : "Select a Trace Collection..."}</Button>
        </FormControl>
        <div style={styles.diffTraceContainer}>
          <DiffTraceSelector isDiffEnabled={context.isDiffEnabled} selectedDiffSummaryDesc={diffSummaryDesc} onDiffTraceSelect={this.showDiffTraceSelector} onSelect={this.handleDiffSelection} onToggle={this.handleDiffToggle} onDiffModeSelection={this.onDiffModeSelection} />
        </div>
        <Paper style={styles.treeViewContainer}>
          <Tree style={{ background: ThemeColors.secondaryBackground }}
            onExpand={this.onExpand}
            defaultExpandAll={true}
            showLine={true}
            onSelect={this.onTreeNodeSelect}
            onRightClick={this.handleContextMenu}
            expandedKeys={expandedKeys}
          >
            {this.renderTreeNodes([viewTree?.prefixSummaryTree??{}])}
          </Tree>
        </Paper>
        <LoadingPopup open={showLoadingPopup} />
        <Dialog open={isSummaryFetcherDialogOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
          <DialogTitle id="form-dialog-title"><Typography variant="header">Select Trace Collection</Typography></DialogTitle>
          <DialogContent >
            <SummaryFetcher context={context} onSummaryFetch={isFetchingBase ? this.onBaseTraceSummaryFetch : this.onDiffTraceSummaryFetch} timeWindow={isFetchingBase ? context.viewCollectionQuery?.time_window : context.diffCollectionQuery?.time_window} conditions={isFetchingBase ? baseSummaryConditions : (diffSummaryConditions.length > 0 ? diffSummaryConditions : baseSummaryConditions)} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default TraceCollectionViewer;
