import { initializeApp } from "firebase/app";
import {
  getAuth,
  UserCredential,
  User,
  signInWithEmailAndPassword as signInWithEmailAndPasswordAuth,
  sendPasswordResetEmail as sendPasswordResetEmailAuth,
  onAuthStateChanged as onAuthStateChangedAuth,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
} from "firebase/auth";
import LocalStorageManager from "../common/LocalStorageManager";
import {TestChimpSDK} from "testchimp-js";

class FirebaseService {
  private auth: any;
  private userKey: string;
  private tokenKey: string;
  private refreshTokenInterval: NodeJS.Timeout | null;

  constructor() {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
      measurementId:process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    const app = initializeApp(firebaseConfig);
    this.auth = getAuth(app);

    this.userKey = "user";
    this.tokenKey = "token";
    this.refreshTokenInterval = null;

    onAuthStateChangedAuth(this.auth, this.onAuthStateChanged);
  }

  async signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    try {
      const userCredential = await signInWithEmailAndPasswordAuth(
        this.auth,
        email,
        password
      );
      return userCredential;
    } catch (error) {
      throw error;
    }
  }

  getAuth() {
    return this.auth;
  }

  getCurrentUser(): User | null {
    return this.auth.currentUser;
  }

  getToken(): string | null {
    return this.auth.currentUser?.getIdToken() || null;
  }

  refreshToken=(user:User)=>{
    user.getIdToken().then((token) => {
      LocalStorageManager.setToken(token);
    });
  }

  isAuthenticated(): boolean {
    return !!this.getCurrentUser();
  }

  signOut() {
    this.auth.signOut();
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await sendPasswordResetEmailAuth(this.auth, email);
    } catch (error) {
      throw error;
    }
  }

  onAuthStateChanged = (user: User | null) => {
    if (user) {
      LocalStorageManager.setUser(JSON.stringify(user));
      // TODO Fix
      //TestChimpSDK.setCurrentUserId()
      this.refreshToken(user);
      this.refreshTokenInterval = setInterval(() => {
        this.refreshToken(user);
      }, 3 * 60 * 1000);
    } else {
      LocalStorageManager.removeUser();
      TestChimpSDK.endTrackedSession();
      LocalStorageManager.removeToken();
      if (this.refreshTokenInterval) {
        clearInterval(this.refreshTokenInterval);
        this.refreshTokenInterval = null;
      }
    }
  };

  async signInWithGoogle(): Promise<UserCredential> {
    const googleProvider = new GoogleAuthProvider();
    return await signInWithPopup(this.auth, googleProvider)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        throw error;
      });
  }
}

export default new FirebaseService();
