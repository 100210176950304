import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme/theme";
import { SnackbarProvider } from "./hooks/useSnackbar";
import { TestChimpSDK } from "testchimp-js";
import FullScreenBackdropLoader from "./common/FullScreenBackDropLoader";

const TC_PROJECT_ID=process.env.REACT_APP_TESTCHIMP_PROJECT_ID;
const TC_SESSION_RECORD_KEY=process.env.REACT_APP_TESTCHIMP_SESSION_RECORD_API_KEY;
const TC_EXPORT_ENDPOINT=process.env.REACT_APP_TESTCHIMP_EXPORT_ENDPOINT;

document.addEventListener("DOMContentLoaded", function () {
  TestChimpSDK.startRecording({
    projectId: TC_PROJECT_ID,
    sessionRecordingApiKey: TC_SESSION_RECORD_KEY,
    endpoint: TC_EXPORT_ENDPOINT,
    samplingProbabilityOnError: 0.1,
    samplingProbability: 1.0,
    maxSessionDurationSecs: 500,
    eventWindowToSaveOnError: 200,
    tracedUriRegexListToTrack: ".*\\.testchimp\\.io.*$",
  });
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Suspense fallback={<FullScreenBackdropLoader />}>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
