import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { encodeFilterList } from '../common/Util';
import { ThemeColors } from '../styles/light-colors';

class ExemplarChooser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.exemplars,
      context: this.props.context,
      selectedItem: '', // currently selected item
    };
  }

  handleClick = () => {
    let filteredList = [];
    filteredList.push({ key: "traceId", operator: "EQUALS", value: this.state.selectedItem })
    window.open(`/?view=explore&name=${this.state.context.entryPoint}&projectId=${this.state.context.projectId}&resourceId=${this.state.context.resourceId}&viewFilters="${encodeFilterList(filteredList)}"`, '_blank');
  };

  handleChange = (event) => {
    const selectedItem = event.target.value;
    this.setState({ selectedItem });
  };

  render() {
    const { items, selectedItem } = this.state;
    let content;
    if (!items) {
      content = (
        <div>
        </div>
      );
    } else {
      content = (<div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl style={{ backgroundColor: ThemeColors.textInputBackground, display: "flex", "flex": 1, margin: "40px" }}>
          <InputLabel id="item-select-label">Select an exemplar</InputLabel>
          <Select
            labelId="item-select-label"
            id="item-select"
            value={selectedItem}
            onChange={this.handleChange}
          >
            {items.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="outlined" style={{ minWidth: '100px', color: ThemeColors.primaryAction }} onClick={this.handleClick}>
          View Exemplar
        </Button>
      </div>);
    }
    return (
      <div style={{ color: ThemeColors.background }}>
        {content}
      </div>
    );
  }
}

export default ExemplarChooser;
