import { Box, CssBaseline } from '@mui/material'
import React from 'react'

function NotFoundView() {
  return (
    <>
        <CssBaseline />
        <h1 color={"while"}>NotFoundView</h1>
    </>
    
  )
}

export default NotFoundView