import { forwardRef, ForwardRefRenderFunction, Suspense, useImperativeHandle, useState } from "react";

import { IGeneratorKV, PlainBodyType } from "../models";
import ComponentLoader from "../../common/ComponentLoader";

import MonacoEditor from "@monaco-editor/react";
import { editor, MarkerSeverity } from "monaco-editor";
import { getGeneratorLabel } from "../../util/MessageUtils";

export interface RawEditorHandle {
  beautify: () => void;
}

interface RawEditorProps {
  bodyString: string;
  setBodyString: (body: string) => void;
  type: PlainBodyType;
  isReadOnly: boolean;
  isRequestGenerator: boolean;
  generators: IGeneratorKV;
  setHasErrors: (hasErrors: boolean) => void;
  onBlur: () => void;
}

const RawEditor: ForwardRefRenderFunction<RawEditorHandle, RawEditorProps> = (
  { bodyString, setBodyString, type, isReadOnly, isRequestGenerator, generators, setHasErrors, onBlur },
  ref
) => {
  const [editor, setEditor] = useState<editor.IStandaloneCodeEditor | undefined>();

  useImperativeHandle(ref, () => ({
    beautify: handleBeautify,
  }));

  const handleValidation = (markers: editor.IMarker[]) => {
    const severities = [MarkerSeverity.Error, MarkerSeverity.Warning];
    const hasErrors = markers.some((marker) => severities.includes(marker.severity));
    setHasErrors(hasErrors);
  };

  const handleBeautify = (newEditor?: editor.IStandaloneCodeEditor) => {
    (newEditor ?? editor)?.getAction("editor.action.formatDocument")?.run();
  };

  const handleOnMount = (editor: editor.IStandaloneCodeEditor) => {
    setEditor(editor);
    editor.onDidFocusEditorWidget(() => handleBeautify(editor));
    editor.focus();
  };

  return (
    <Suspense fallback={<ComponentLoader />}>
      <MonacoEditor
        language={type}
        theme="light"
        value={isRequestGenerator ? (isReadOnly ? getGeneratorLabel(generators["$"]) : bodyString) : bodyString}
        onChange={(value) => setBodyString(value ?? "")}
        onMount={handleOnMount}
        onValidate={handleValidation}
        options={{
          readOnly: !isRequestGenerator || isReadOnly,
          automaticLayout: true,
          formatOnType: true,
          formatOnPaste: true,
          minimap: {
            enabled: false, // Disable minimap
          },
          wordWrap: "on", // Enable word wrap
          tabSize: 2, // Set tab size
          insertSpaces: true, // Use spaces instead of tabs
          fontSize: 14, // Set font size
          scrollBeyondLastLine: false, // Disable scroll beyond last line
        }}
      />
    </Suspense>
  );
};

export default forwardRef(RawEditor);
