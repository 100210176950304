import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getContextFromSteps } from "../../features/testGenerationSlice";
import { Alert, Checkbox, Divider, Flex, Space, Spin, Tag, Typography } from "antd";
import { isEmpty } from "lodash";
import RuleList from "./RuleList";

const FieldUpdateRules: React.FC<{ testCaseId: string }> = ({ testCaseId }) => {
  const dispatch = useAppDispatch();
  const { Text } = Typography;

  const operationId = useAppSelector((state) => state.testGeneration.operationId);
  const isContextLoading = useAppSelector((state) => state.testGeneration.isContextLoading);
  const fieldUpdateRules = useAppSelector((state) => state.testGeneration.fieldUpdateRules);
  const variableRecordConfigs = useAppSelector((state) => state.testGeneration.variableRecordConfigs);

  useEffect(() => {
    dispatch(
      getContextFromSteps({
        testId: testCaseId,
        operationId: operationId!,
      })
    );
  }, [testCaseId]);
  return (
    <Spin spinning={isContextLoading}>
      <Flex vertical>
        {!isContextLoading ? (
          isEmpty(variableRecordConfigs) ? (
            <>
              <Alert message="No variables are available after running above" type="warning" showIcon />
            </>
          ) : (
            <>
              <Alert style={{ fontStyle: "italic" }} message="Following variables are available after running above. You can configure field update rules to apply on the API request using these." type="info" showIcon />
              <Space direction="horizontal" style={{ marginTop: 12 }}>
                {variableRecordConfigs.map((variable) => (
                  <Tag style={{ fontSize: 14, lineHeight: 1.8, backgroundColor: "#d3d3d3" }} key={variable.variableName}>
                    {variable.variableName}
                  </Tag>
                ))}
              </Space>
            </>
          )
        ) : (
          <>
            <Alert message="Variables will be available after running above" type="info" showIcon />
            <Space direction="horizontal" style={{ marginTop: 12 }}>
              <Tag style={{ visibility: "hidden" }}></Tag>
            </Space>
          </>
        )}
        {!isContextLoading && <RuleList rules={fieldUpdateRules} />}
      </Flex>
    </Spin>
  );
};

export default FieldUpdateRules;
