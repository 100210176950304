import React from 'react';
import { Paper } from '@mui/material';
import { ThemeColors } from '../styles/light-colors';

const NamedPanel = (props) => {
  const panelStyle = {
    border: ThemeColors.border,
    padding: '10px',
    position: 'relative',
    backgroundColor: ThemeColors.secondaryBackground,
    marginBottom: '20px'
  };

  const labelStyle = {
    position: 'absolute',
    top: '-10px',
    left: '10px',
    padding: '0 5px',
    backgroundColor: ThemeColors.secondaryBackground,
    fontSize: '12px',
    fontWeight: 'bold',
    color: ThemeColors.darkText,
    border: ThemeColors.border
  };

  return (
    <Paper style={panelStyle}>
      <div style={labelStyle}>{props.label}</div>
      {props.children}
    </Paper>
  );
};

export default NamedPanel;