import { ItemType } from "antd/es/menu/hooks/useItems";
import {
  DiffCardContext,
  IDecoratedValue,
  SortingAlgo,
} from "../../models/overlays";
import DiffCard from "./DiffCard";
import SummarySkeleton from "./SummarySkeleton";
import { Box, Chip, Divider, Stack } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { IQueryWithMetaData } from "../../models/explore";

interface AttributeSummariesProps {
  marginTop: number;
  label: string;
  summaryLoading: boolean;
  summaries:
    | {
        [attributeName: string]: IDecoratedValue;
      }
    | undefined;
  onlyBaseOverlay: boolean;
  baseOverlayQuery: IQueryWithMetaData;

  menuItems: ItemType[];
  onMenuClick: (key: string) => void;
}

const AttributeSummaries: React.FC<AttributeSummariesProps> = ({
  label,
  summaries,
  summaryLoading,
  marginTop,
  menuItems,
  onMenuClick,
  baseOverlayQuery,
  onlyBaseOverlay,
}) => {
  const attributeSortingAlgo = useAppSelector(
    (state: RootState) => state.overlays.attributeSortingAlgo
  );

  const sortAttributeKeys = (attributes: { [x: string]: IDecoratedValue }) => {
    const findAbsDiffScore = (percentages: { [overlayId: string]: number }) => {
      const overlayIds = Object.keys(percentages);
      const baseOverlayIndex = overlayIds.findIndex(
        (overlayId) => overlayId == baseOverlayQuery.id
      );
      if (overlayIds.length == 1) return percentages[overlayIds[0]];
      else if (baseOverlayIndex == -1) {
        return overlayIds.reduce(
          (prev: number, curr: string) => prev + percentages[curr],
          0
        );
      } else {
        const baseOverlayPercentage = percentages[baseOverlayQuery.id];
        return overlayIds.reduce(
          (prev: number, curr: string, currIndex: number) => {
            if (currIndex == baseOverlayIndex) {
              return prev;
            } else {
              return prev + Math.abs(baseOverlayPercentage - percentages[curr]);
            }
          },
          0
        );
      }
    };

    const compareAbsDiffScore = (firstKey: string, secondKey: string) => {
      const firstValues = attributes[firstKey].values;
      const secondValues = attributes[secondKey].values;

      const firstScore = Object.keys(firstValues).reduce(
        (prev: number, curr: string) =>
          prev + findAbsDiffScore(firstValues[curr]),
        0
      );

      const secondScore = Object.keys(secondValues).reduce(
        (prev: number, curr: string) =>
          prev + findAbsDiffScore(secondValues[curr]),
        0
      );

      return firstScore - secondScore;
    };

    const compareNoOfValues = (firstKey: string, secondKey: string) => {
      const firstValues = attributes[firstKey].values;
      const secondValues = attributes[secondKey].values;

      return Object.keys(firstValues).length - Object.keys(secondValues).length;
    };

    switch (attributeSortingAlgo) {
      case SortingAlgo.ASCENDING: {
        return Object.keys(attributes).sort();
      }
      case SortingAlgo.DESCENDING: {
        return Object.keys(attributes).sort().reverse();
      }
      case SortingAlgo.ATTRIBDIFF: {
        const compareLogic = onlyBaseOverlay
          ? compareNoOfValues
          : compareAbsDiffScore;
        return Object.keys(attributes).sort(compareLogic).reverse();
      }
      default: {
        console.error("Unknown sorting algorithm");
        return Object.keys(attributes);
      }
    }
  };

  return (
    <Box marginTop={marginTop}>
      <Divider>
        <Chip label={label} />
      </Divider>
      <Stack spacing={2} marginTop={2}>
        {summaryLoading ? (
          <SummarySkeleton />
        ) : (
          summaries &&
          sortAttributeKeys(summaries).map((attributeKey, index) => {
            const diffCardContext: DiffCardContext = {
              attributeKey,
              attributeValue: summaries[attributeKey],
            };
            return (
              <DiffCard
                key={`${index}+${attributeKey}`}
                context={diffCardContext}
                menuItems={menuItems}
                onMenuClick={onMenuClick}
              />
            );
          })
        )}
      </Stack>
    </Box>
  );
};

export default AttributeSummaries;
