import React, { useState, useEffect } from "react";
import {
  Select,
  Button,
  Table,
  Space,
  Tooltip,
  Modal,
  InputNumber,
} from "antd";
import {
  getProjects,
  listResources,
  ListResourcesRequest,
  listReleaseVersions,
  ListReleaseVersionsRequest,
  listEnvironments,
  ReleaseVersion,
} from "../api/services/User";
import {
  DeleteGuardRequest,
  listGuards,
  evaluateGuards,
  EvaluateGuardsRequest,
  EvaluateGuardsResponse,
  deleteGuard,
  GuardWithLastEvaluationResult,
  transformEvaluateGuardsResponse,
  updateGuardSubscription,
  UpdateGuardSubscriptionRequest,
} from "../api/services/Guards";
import LocalStorageManager from "../common/LocalStorageManager";
import "../styles/GuardsViewer.css";
import { ListGuardsRequest } from "../api/services/Guards";
import {
  getEntryFromPathPrefix,
  getOperationFromPathPrefix,
  toPathFormatString,
} from "../api/services/Common";
import { DeleteOutlined } from "@ant-design/icons";
import { ThemeProvider } from "@mui/material";
import theme from "../common/theme";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { ThemeColors } from "../styles/light-colors";
import { Converters } from "../util";

interface Project {
  id: string;
  name: string;
}

interface Resource {
  id: string;
  name: string;
}

const { Option } = Select;

const GuardsViewer: React.FC = () => {
  const { state: locationState } = useLocation();
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState<Resource[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<string | undefined>();
  const [selectedResource, setSelectedResource] = useState<
    string | undefined
  >();
  const [evaluationWindowInDays, setEvaluationWindowInDays] = useState<
    number | undefined
  >();
  const [selectedEnvironment, setSelectedEnvironment] = useState<
    string | undefined
  >();
  const [selectedResourceId, setSelectedResourceId] = useState<
    number | undefined
  >();
  const [guards, setGuards] = useState<GuardWithLastEvaluationResult[]>([]);
  const [guardsPresent, setGuardsPresent] = useState<boolean>(true);
  const [subscribeChecked, setSubscribeChecked] = useState<
    boolean | undefined
  >();
  const [fetchMode, setFetchMode] = useState<string>("search");

  const [showEvaluatePopup, setShowEvaluatePopup] = useState<boolean>(false);
  const [releaseVersions, setReleaseVersions] = useState<ReleaseVersion[]>([]);
  const [environments, setEnvironments] = useState<string[]>([]);
  const [selectedReleaseVersion, setSelectedReleaseVersion] = useState<
    string | undefined
  >();

  const handleOperationClick = (guardId: string) => {
    // Redirect to GuardEvaluationsViewer with guard_id in URL params in a new tab
    window.open(`/guard_evaluations?guard_id=${guardId}`, "_blank");
  };

  const fetchReleaseVersions = async () => {
    try {
      let request: ListReleaseVersionsRequest = {
        resourceId: selectedResourceId ?? 0,
      };
      const response = await listReleaseVersions.listReleaseVersions(request);
      setReleaseVersions(response.versions);
    } catch (error) {
      console.error("Error fetching release versions:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await getProjects.getProjects();
      setProjects(response.data.projects);
      updateSelectedProject(response.data.projects[0].id ?? "");
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const updateSelectedProject = async (selectedProject: string) => {
    setSelectedProject(selectedProject);
    LocalStorageManager.setProjectId(selectedProject);
    const request: ListResourcesRequest = {};
    const response = await listResources.listResources(request);
    const envResponse = await listEnvironments.listEnvironments({});
    setResources(response.resources || []);
    setEnvironments(envResponse.environments || []);
  };

  const updateSelectedResource = (selectedResource: string) => {
    setSelectedResource(selectedResource);
  };

  const updateSelectedResourceId = (selectedResourceId: number) => {
    setSelectedResourceId(selectedResourceId);
  };

  const handleDelete = async (id: string) => {
    let request: DeleteGuardRequest = {
      id: id,
    };
    const response = await deleteGuard.deleteGuard(request);
    setGuards((prevGuards) =>
      prevGuards.filter((guard) => guard.guard.id !== id)
    );
  };

  const handleEvaluateClick = async () => {
    await fetchReleaseVersions(); // Fetch release versions when the button is clicked
    setShowEvaluatePopup(true);
  };

  const handleEvaluateOk = async () => {
    // Perform evaluation API call using evaluateGuards.evaluateGuards with selectedReleaseVersion
    setSubscribeChecked(undefined);
    try {
      // Assuming evaluateGuards.evaluateGuards API call takes the selected release version
      let request: EvaluateGuardsRequest = {
        filter: {
          resource: selectedResource,
        },
        evaluationContext: {
          resource: selectedResource,
          resourceVersion: selectedReleaseVersion,
          environment: selectedEnvironment ?? "",
          timeWindow: {
            relativeWindow: (evaluationWindowInDays ?? 1) * 86400 + "s",
          },
        },
      };
      const response: EvaluateGuardsResponse =
        await evaluateGuards.evaluateGuards(request);
      setGuards(
        transformEvaluateGuardsResponse(
          response.guards,
          selectedResource + "",
          selectedReleaseVersion + ""
        )
      );
    } catch (error) {
      console.error("Error evaluating guards:", error);
    }

    setShowEvaluatePopup(false);
  };

  const handleEvaluateCancel = () => {
    setShowEvaluatePopup(false);
  };

  const updateSubscriptionStatus = async (newState: boolean) => {
    let request: UpdateGuardSubscriptionRequest = {
      resourceId: selectedResourceId ?? 0,
      subscriptionStatus: newState ? "SUBSCRIBED" : "UNSUBSCRIBED",
    };
    const response =
      await updateGuardSubscription.updateGuardSubscription(request);
    setSubscribeChecked(newState);
  };

  const fetchGuards = async () => {
    try {
      let request: ListGuardsRequest | undefined;
      if (selectedProject) {
        request = {
          filter: {
            resource: selectedResource,
          },
        };
      } else if (!isEmpty(locationState?.guards)) {
        request = {
          filter: {
            guardIds: locationState?.guards,
          },
        };
      }

      if (!isEmpty(request)) {
        setLoading(true);
        const response = await listGuards.listGuards(request);
        const guardList: GuardWithLastEvaluationResult[] = response.guards;
        setLoading(false);
        setSubscribeChecked(response.subscriptionStatus == "SUBSCRIBED");
        setGuardsPresent(guardList && guardList?.length > 0);
        console.log("guardlist", guardList);
        setGuards(guardList);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching test cases:", error);
    }
  };

  const renderLastEvaluation = (record: GuardWithLastEvaluationResult) => {
    const evaluationDetail = record.evaluationDetail;
    if (evaluationDetail) {
      const evaluation = evaluationDetail.evaluation;
      const result = evaluation.result;

      let circleColor = "";
      let evaluationText = "";
      let tooltipText = "";

      switch (result) {
        case "SUCCESS":
          circleColor = "green";
          evaluationText = "Success";
          break;
        case "FAIL":
          circleColor = "red";
          evaluationText = "Fail";
          break;
        default:
          circleColor = "gray";
          evaluationText = "Unknown";
      }

      tooltipText = `${evaluationText} - Expected: ${evaluation.rightValue ?? ""
        }, Was: ${evaluation.leftValue ?? ""}. On: (${evaluationDetail.evaluationTimestamp ?? ""
        })`;

      return (
        <Tooltip title={tooltipText}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: circleColor,
                marginRight: 5,
              }}
            />
            <span>{evaluation.leftValue ?? ""}</span>
          </div>
        </Tooltip>
      );
    }
    return null; // If evaluation detail is not available
  };

  useEffect(() => {
    fetchProjects();
    console.log("Resource selected is " + selectedResourceId);
  }, []);

  useEffect(() => {
    isEmpty(locationState?.guards) ? setGuards([]) : fetchGuards();
  }, [locationState]);

  const columns = [
    {
      title: "Flow",
      dataIndex: "flow",
      key: "flow",
      render: (text: string, record: GuardWithLastEvaluationResult) => {
        const operation = record.guard.config.attribLocator.operation;

        if (operation.contextualOperation) {
          return (
            operation.contextualOperation.entrypoint &&
            toPathFormatString(operation.contextualOperation.entrypoint)
          );
        } else {
          return toPathFormatString(
            getEntryFromPathPrefix(operation.pathPrefix!)
          );
        }
      },
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      render: (_text: string, record: GuardWithLastEvaluationResult) => {
        const operation = record.guard.config.attribLocator.operation;

        const operationText =
          operation.contextualOperation
            ? toPathFormatString(operation.contextualOperation.operation)
            : toPathFormatString(
              getOperationFromPathPrefix(operation.pathPrefix!)
            );

        return (
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => handleOperationClick(record.guard.id)}
          >
            {operationText}
          </span>
        );
      },
    },
    {
      title: "Attribute",
      dataIndex: "attribute",
      key: "attribute",
      render: (text: string, record: GuardWithLastEvaluationResult) => {
        return record.guard.config.attribLocator.attributeKey;
      },
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      render: (text: string, record: GuardWithLastEvaluationResult) => {
        return record.guard.config.operation && record.guard.config.value
          ? `${record.guard.config.operation} ${Converters.typedValueToString(
            record.guard.config.value
          )}`
          : "Operation Present";
      },
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      width: 50,
      render: (text: string, record: GuardWithLastEvaluationResult) => {
        return record.guard.config.severity;
      },
    },
    {
      title: "Last Evaluation",
      dataIndex: "lastEvaluation",
      key: "lastEvaluation",
      render: (_text: string, record: GuardWithLastEvaluationResult) =>
        renderLastEvaluation(record),
    },
    {
      title: "",
      key: "delete",
      dataIndex: "delete",
      width: 25,
      render: (_text: string, record: GuardWithLastEvaluationResult) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.guard.id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        paddingTop: "10px",
        height: "100vh",
        backgroundColor: ThemeColors.background,
      }}
    >
      <ThemeProvider theme={theme}>
        {isEmpty(locationState?.guards) && (
          <div className="test-filter-panel">
            <div id="test-conditions" className="flex-row">
              <div className="flex-item">
                <Select
                  id="select-project"
                  style={{ width: "100%" }}
                  placeholder="Select Project"
                  onChange={(key) => updateSelectedProject(key)}
                  value={selectedProject}
                >
                  {projects?.map((project) => (
                    <Option key={project.id} value={project.id}>
                      {project.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="flex-item">
                <Select
                  id="select-resource"
                  style={{ width: "100%" }}
                  placeholder="Select Resource (Optional)"
                  onChange={(selectedId) => {
                    const selectedResource = resources.find(
                      (resource) => resource.id === selectedId
                    );
                    if (selectedResource) {
                      updateSelectedResource(selectedResource.name);
                      updateSelectedResourceId(selectedId);
                    } else {
                      updateSelectedResource(""); // Or any default value if no resource is found
                    }
                  }}
                >
                  {resources.map((resource) => (
                    <Option key={resource.id} value={resource.id}>
                      {resource.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="flex-row">
                <Button
                  id="search-button"
                  type="primary"
                  onClick={fetchGuards}
                  style={{ width: "3em" }}
                  disabled={selectedProject === undefined}
                >
                  Search
                </Button>
                <Button
                  id="evaluate-button"
                  type="primary"
                  onClick={handleEvaluateClick}
                  disabled={selectedResourceId === undefined}
                >
                  Evaluate...
                </Button>
              </div>
            </div>
          </div>
        )}
        {guards.length > 0 &&
          fetchMode === "search" &&
          selectedResourceId !== undefined &&
          subscribeChecked !== undefined && (
            <div className="test-filter-panel">
              <div className="flex-row">
                <div>
                  <input
                    type="checkbox"
                    id="subscribe-check"
                    name="subscribe-check"
                    checked={subscribeChecked}
                    onChange={() => updateSubscriptionStatus(!subscribeChecked)}
                  />
                  <label htmlFor="subscribe-check" style={{ color: "white" }}>
                    Subscribe to Guards of Resource
                  </label>
                </div>
              </div>
            </div>
          )}
        <div id="testcase-container">
          {guardsPresent ? (
            <Table<GuardWithLastEvaluationResult>
              rowKey={(record) => record.guard.id}
              dataSource={guards}
              columns={columns} // Pass the columns to the Table component
              loading={loading}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
                backgroundColor: "var(--light-color)",
              }}
            >
              <h3>
                It seems no Guards are configured yet.{" "}
                <a
                  href="https://awarelabs.io/blog/guards"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>{" "}
                to learn more about how to create and Use Guards.
              </h3>
            </div>
          )}
        </div>
        <Modal
          title="Evaluate Guards"
          visible={showEvaluatePopup}
          onOk={handleEvaluateOk}
          onCancel={handleEvaluateCancel}
        >
          <div style={{ marginBottom: "1rem" }}>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Release Version"
              onChange={(value) => setSelectedReleaseVersion(value)}
            >
              {releaseVersions?.map((version, index) => (
                <Option key={index} value={version.label}>
                  {version.label}
                </Option>
              ))}
            </Select>
          </div>

          {/* Additional fields */}
          <div style={{ marginBottom: "1rem" }}>
            <span style={{ marginRight: "0.5rem" }}>In the last</span>
            <InputNumber
              min={1}
              style={{ marginRight: "0.5rem" }}
              placeholder="days"
              value={evaluationWindowInDays}
              onChange={(value) => setEvaluationWindowInDays(value as number)}
            />
            <span> days</span>
          </div>

          <div style={{ marginBottom: "1rem" }}>
            <span style={{ marginRight: "0.5rem" }}>In</span>
            <Select
              style={{ width: "50%", marginRight: "0.5rem" }}
              placeholder="environment"
              value={selectedEnvironment}
              onChange={(value) => setSelectedEnvironment(value)}
            >
              {environments?.map((environment, index) => (
                <Option key={index} value={environment}>
                  {environment}
                </Option>
              ))}
            </Select>
            <span>environment</span>
          </div>
        </Modal>
      </ThemeProvider>
    </div>
  );
};

export default GuardsViewer;
