import { ChangeEvent, useState } from "react";
import { FieldDataType, HTTPMessageSection } from "../../test-studio/models";
import { Button, Flex, Input, Mentions, Select, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addNewRule } from "../../features/testGenerationSlice";
import { OptionProps } from "rc-mentions/lib/Option";

function NewRule() {
  const dispatch = useAppDispatch();
  const { Text } = Typography;
  const sectionOptions = Object.keys(HTTPMessageSection).map((key) => ({
    label: key,
    value: key,
  }));
  const [newSection, setNewSection] = useState<HTTPMessageSection | undefined>(undefined);
  const [newPath, setNewPath] = useState<string>("");
  const [newExpression, setNewExpression] = useState<string>("");

  const variables = useAppSelector((state) => state.testGeneration.variableRecordConfigs);

  const onSectionChange = (section: HTTPMessageSection) => {
    setNewSection(section);
  };

  const onPathChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPath(e.target.value);
  };

  const onAddNewRule = () => {
    dispatch(
      addNewRule({
        isEnable: true,
        section: newSection!,
        path: newPath,
        replaceConfig: {
          variableExpression: {
            expression: newExpression,
          },
          fieldType: FieldDataType.STRING,
        },
      })
    );
    setNewSection(undefined);
    setNewPath("");
    setNewExpression("");
  };

  return (
    <Flex align="center" gap={8} key={"new"} flex={1}>
      <Text style={{ minWidth: "1.6em" }}>Set</Text>
      <Select
        showSearch
        placeholder="Select section"
        optionFilterProp="label"
        value={newSection}
        onChange={onSectionChange}
        options={sectionOptions}
      />
      <Input placeholder="Path to field" value={newPath} onChange={onPathChange} />
      <Text style={{ minWidth: "1.1em" }}>as</Text>
      <Mentions
        prefix={"{{"}
        placeholder="Expression. eg: Bearer {{token}}"
        value={newExpression}
        onChange={(text: string) => setNewExpression(text)}
        //onSelect={(option: OptionProps, prefix: string) => setNewExpression(newExpression.trimEnd())}
        options={variables?.map((variable) => {
          return {
            key: variable.variableName,
            label: variable.variableName,
            value: `${variable.variableName}}}`,
          };
        })}
      />
      <Button size="small" type="primary" shape="circle" icon={<CheckOutlined />} onClick={onAddNewRule} />
    </Flex>
  );
}

export default NewRule;
