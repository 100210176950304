import React, { Component } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Grid } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

class FilterPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conditions: this.props.conditions.length > 0 ? this.props.conditions : [{ key: '', operator: 'EQUALS', value: '' }]
        };
    }

    handleAddCondition = () => {
        const { conditions } = this.state;
        this.setState({ conditions: [...conditions, { key: '', operator: '', value: '' }] });
    }

    handleRemoveCondition = (index) => {
        const { conditions } = this.state;
        this.setState({ conditions: conditions.filter((_, i) => i !== index) }, () => this.props.onConditionUpdate(this.state.conditions));
    }

    handleConditionChange = (index, key, operator, value) => {
        const { conditions } = this.state;
        const newConditions = [...conditions];
        newConditions[index] = { key, operator, value };
        this.setState({ conditions: newConditions }, () => this.props.onConditionUpdate(newConditions));
    }



    render() {
        const { valueConfigs } = this.props;
        const { conditions } = this.state;
        return (
            <div>
                <div>
                    {conditions.map((condition, index) => (
                        <div style={{ border: "1px gray solid", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", margin: "1em" }}>
                            <FormControl fullWidth style={{ margin: "5px" }}>
                                <InputLabel>{`Key ${index + 1}`}</InputLabel>
                                <Select value={condition.key} onChange={(event) => this.handleConditionChange(index, event.target.value, 'EQUALS', '')}>
                                    {valueConfigs.map((config, index) => (
                                        <MenuItem key={config.key} value={config.key}>
                                            {config.displayValue}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ margin: "5px" }}>
                                <InputLabel>Operator</InputLabel>
                                <Select value={condition.operator} onChange={(event) => this.handleConditionChange(index, condition.key, event.target.value, '')}>
                                    {valueConfigs.find((key) => key.key === condition.key)?.operations.map((operator) => (
                                        <MenuItem key={operator} value={operator}>
                                            {operator}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ margin: "5px" }}>
                                <InputLabel>Value</InputLabel>
                                <Select value={condition.value} onChange={(event) => this.handleConditionChange(index, condition.key, condition.operator, event.target.value)}>
                                    {valueConfigs.find((key) => key.key === condition.key)?.values.map((value) => (
                                        <MenuItem key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <Button style={{ width: "10px" }} onClick={() => this.handleRemoveCondition(index)} startIcon={<RemoveIcon />}></Button>
                        </div>
                    ))}
                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-start", alignItems: "flex-start" }}>
                        <Button onClick={this.handleAddCondition} startIcon={<AddIcon />}>Add Condition</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default FilterPanel;
