import { Box, Button, Container, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import successImage from "../../images/checked.png";
import failedImage from "../../images/failed.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Organization } from "../../api";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [upgradeFailed, setUpgradeFailed] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");
  const token = searchParams.get("token");

  useEffect(() => {
    updateOrganizationTier();
  }, []);

  const updateOrganizationTier = async () => {
    const payload = {
      new_tier: "PRO_TIER",
      verification_token: token,
    };

    setLoading(true);
    const res = await Organization.updateTier(payload);

    if (!res.error) {
      console.log(res);
      setLoading(false);
      setUpgradeFailed(false);
    } else {
      setLoading(false);
      setUpgradeFailed(true);
      console.error(res.error);
    }
  };

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ bgcolor: "white", height: "100vh" }}>
        <Box
          pt={2}
          pb={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : !upgradeFailed ? (
            <Box>
              <Box>
                <img src={successImage} alt="success-image" />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={3}
                gap={2}
              >
                <Typography>Upgraded to Premium Successfully!</Typography>
                <Button variant="contained" onClick={navigateToHome}>
                  Return Home
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box>
                <img src={failedImage} alt="success-image" />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={3}
                gap={2}
              >
                <Typography>Upgrading to Premium Failed!</Typography>
                <Button variant="contained" onClick={navigateToHome}>
                  Return Home
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};

export default PaymentSuccess;
