import React, { Component } from 'react';
import FilterPanel from '../common//FilterPanel';
import { postData } from '../common/WebUtils';
import { Tabs, Tab, Box, Button } from "@mui/material";
import TimeRangeSelector from '../common/TimeRangeSelector';
import { convertProtoTimestampToDate, getTimeWindowForDateRange } from '../common/Util';
import dayjs from 'dayjs';
import SummaryAdvancedSettings from '../common/SummaryAdvancedSettings';
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

class SummaryFetcher extends Component {
    constructor(props) {
        super(props);
        let defaultStartTime = dayjs().subtract(dayjs.duration({ 'days': 1 }));
        let defaultEndTime = dayjs();
        let inStartTime = this.props.timeWindow?.fixed_window?.start_time ? dayjs(convertProtoTimestampToDate(this.props.timeWindow?.fixed_window?.start_time)) : defaultStartTime;
        let inEndTime = this.props.timeWindow?.fixed_window?.end_time ? dayjs(convertProtoTimestampToDate(this.props.timeWindow?.fixed_window?.end_time)) : defaultEndTime;
        this.state = {
            projectId: '',
            resourceId: '',
            entryPoint: '',
            activeTab: 0,
            advancedSettings: { selectedSamplingMode: "dynamic", manualSamplingRate: 0.0 },
            conditions: this.props.conditions,
            timeWindow: { startTime: inStartTime, endTime: inEndTime },
            valueConfigs: []
        };
    }

    componentDidMount() {
        const { projectId, entryPoint, resourceId } = this.props.context;
        this.setState({ projectId: projectId, resourceId: resourceId, entryPoint: entryPoint })
        postData('collectors/list_condition_values', { "resource_id": resourceId, "entry_point": entryPoint })
            .then((response) => {
                this.setState({ valueConfigs: response.valueConfigs });
            });
    }

    handleSubmit = () => {
        const { onSummaryFetch } = this.props;
        const { conditions, timeWindow, advancedSettings } = this.state;
        let timeWindowProto = getTimeWindowForDateRange(timeWindow.startTime, timeWindow.endTime);
        postData('collectors/get_summary', { "resource_id": this.state.resourceId, "entry_point": this.state.entryPoint, "filters": conditions, "time_window": timeWindowProto, "sampling_mode": advancedSettings.selectedSamplingMode == "dynamic" ? "DYNAMIC_SAMPLING" : "FIXED_SAMPLING", "sampling_rate": advancedSettings.manualSamplingRate })
            .then((response) => {
                onSummaryFetch(response.summaryTree, conditions, timeWindowProto, response.exemplars);
            });

    }

    handleTabChange = (event, value) => {
        this.setState({ activeTab: value });
    };

    onRangeSelection = (startTime, endTime) => {
        this.setState({ timeWindow: { startTime: startTime, endTime: endTime } });
    }

    onConditionUpdate = (conditions) => {
        this.setState({ conditions: conditions });
    }

    handleAdvancedSettingsChange = (newSettings) => {
        this.setState({ advancedSettings: newSettings });
    };

    render() {
        const styles = {
            tabLabel: {
                textTransform: 'none',
            },
        }; const { valueConfigs, activeTab, timeWindow, advancedSettings } = this.state;
        const { conditions, context } = this.props;
        console.log("Context at summary fetcher", context);
        return (
            <>
                <div style={{ height: "100%" }}>
                    <Tabs value={activeTab} onChange={this.handleTabChange}>
                        <Tab label="Filters" style={styles.tabLabel} />
                        <Tab label="Time Range" style={styles.tabLabel} />
                        <Tab label="Advanced" style={styles.tabLabel} />
                    </Tabs>
                    <Box p={3} style={{ border: "1px gray solid" }}>
                        {activeTab === 0 &&
                            <div >
                                <FilterPanel conditions={conditions} valueConfigs={valueConfigs} onConditionUpdate={this.onConditionUpdate} />
                            </div>}
                        {activeTab === 1 &&
                            <div >
                                <TimeRangeSelector context={context} onRangeSelection={this.onRangeSelection} startTime={timeWindow.startTime} endTime={timeWindow.endTime} />
                            </div>}
                        {activeTab === 2 &&
                            <div >
                                <SummaryAdvancedSettings settings={advancedSettings} onChange={this.handleAdvancedSettingsChange} />
                            </div>}

                    </Box>
                </div>
                <div style={{ display: "flex", marginTop: "5px", justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <Button variant="contained" onClick={this.handleSubmit}>
                        Fetch
                    </Button>
                </div>
            </>);
    }
}

export default SummaryFetcher;
