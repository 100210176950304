import { FieldConfig, HTTPMessageSection, VariableRecordConfig } from "../test-studio/models";

export interface IGetContextFromTestStepsRequest {
  testId: string;
  operationId: number;
}

export interface IGetContextFromTestStepsResponse {
  variableRecordConfigs: VariableRecordConfig[];
  fieldUpdateRules: IFieldUpdateRule[];
}

export interface IFieldUpdateRule {
  section: HTTPMessageSection;
  path: string;
  replaceConfig: FieldConfig;
}

export interface IDecoratedFieldUpdateRule extends IFieldUpdateRule {
  isEnable: boolean;
}

export enum GenerationStep {
  GENERAL = "General",
  PRE_STEPS = "Pre-Steps",
  CUSTOM_RULES = "Custom Rules",
  SUCCESS = "Success",
  ERROR = "Error",
}

export enum PreStep {
  NONE = 1,
  UPTO,
}
