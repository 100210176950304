import client from "../client";
import {
  IGetSummariesRequest,
  IGetSummaryRequest,
} from "../../models/explore/";
import { GenericAbortSignal } from "axios";

const Explore = {
  getSummary: async (request: IGetSummaryRequest) => {
    const res = await client.post("collectors/get_summary", request);
    return res.data;
  },
  getSummaries: async (
    request: IGetSummariesRequest,
    signal: GenericAbortSignal
  ) => {
    const res = await client.post("collectors/get_summaries", request, {
      signal: signal,
    });
    return res.data;
  },
  listConditionValues: async () => {
    try {
      const res = await client.post("/collectors/list_condition_values", {});
      return res.data;
    } catch (e) {
      console.error(`Error calling Explore.listConditionValues`, e);
      return { error: e };
    }
  },
};

export default Explore;
