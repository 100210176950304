import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  InputAdornment,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ProjectsGrid from "./ProjectsGrid";
import { AddProject, Capacity, ManageUser } from "./Modal";
import { Organization } from "../../api";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "../../hooks/useSnackbar";
import UpgradeButton from "./UpgradeButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import theme from "../../common/theme";
import { Button } from "antd";
import { getVersionedUrl } from "../../common/Util";

const ORG_TIER = {
  FREE_TIER: "FREE_TIER",
  PRO_TIER: "PRO_TIER",
};

const AdminSettings = () => {
  const upgradeButtonRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [isManageUserModalOpen, setIsManageUserOpen] = useState(false);
  const [isCapacityModalOpen, setIsCapacityModalOpen] = useState(false);
  const [projects, setProjects] = useState(null);
  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [totalAllowedCapacity, setTotalAllowedCapacity] = useState(null);
  const [usedCapacities, setUsedCapacities] = useState(null);
  const [organizationTier, setOrganizationTier] = useState(ORG_TIER.FREE_TIER);
  const [orgnizationBillingSource, setOrganizationBillingSource] = useState('');
  const [isCapacitiesUpdating, setIsCapacitiesUpdating] = useState(false);
  const [isCreatingFirstProject, setIsCreatingFirstProject] = useState(false);

  const createFirstProject = () => {
    setIsCreatingFirstProject(true);
    setIsAddProjectModalOpen(true);
  };

  const addProjectModalOpenHandler = () => {
    setIsAddProjectModalOpen(true);
  };

  const addProjectModalCloseHandler = async () => {
    await getOrganizationDetails();
    setIsAddProjectModalOpen(false);
    if (isCreatingFirstProject) {
      navigate(getVersionedUrl("/trackedtests"));
    }
  };

  const manageUserModalOpenHandler = () => {
    setIsManageUserOpen(true);
  };

  const manageUserModalCloseHandler = () => {
    getOrganizationDetails();
    setIsManageUserOpen(false);
  };

  const capacityModalOpenHandler = () => {
    if (organizationTier === ORG_TIER.FREE_TIER) {
      upgradeButtonRef.current.click();
      //setIsCapacityModalOpen(true); //for testing
    } else if (organizationTier === ORG_TIER.PRO_TIER) {
      setIsCapacityModalOpen(true);
    }
  };

  const capacityModalCloseHandler = () => {
    setIsCapacityModalOpen(false);
  };

  useEffect(() => {
    if (location.state && location.state.openAddProjectModal) {
      addProjectModalOpenHandler();
      navigate(location.pathname, { replace: true });
    }
    getOrganizationDetails();
  }, []);

  const getOrganizationDetails = async () => {
    const organizationDetailResponse =
      await Organization.getOrganizationDetails();
    if (!organizationDetailResponse.error) {
      setOrganizationDetails(organizationDetailResponse.data);
      setTotalAllowedCapacity(
        organizationDetailResponse.data.orgCapacityConfig
          .totalAllowedStorageCapacity
      );
      setProjects(organizationDetailResponse.data.projectCapacityDetails);
      setOrganizationTier(organizationDetailResponse.data.organizationTier);
      setOrganizationBillingSource(organizationDetailResponse.data.billingSource);
      setUsedCapacities(organizationDetailResponse.data.projectUsedCapacities);
    } else {
      showSnackbar("Getting OrganizationDetails failed", "error");
      console.error(organizationDetailResponse.error);
    }
  };

  const onUpdateProjectCapacities = async () => {
    const request = {
      project_capacity_details: getUpdatedProjectCapacityDetails(),
    };

    setIsCapacitiesUpdating(true);
    const response = await Organization.updateProjectCapacities(request);
    if (!response.error) {
      setIsCapacitiesUpdating(false);
      showSnackbar("Project Capacities updated successfully!", "success");
    } else {
      setIsCapacitiesUpdating(false);
      showSnackbar("Project Capacities update failed", "error");
      console.error(response.error);
    }
  };

  const getUpdatedProjectCapacityDetails = () => {
    return projects?.map((project) => {
      return {
        project_id: project.projectId,
        project_capacity_config: {
          total_allowed_storage_capacity:
            project.projectCapacityConfig.totalAllowedStorageCapacity,
          daily_capacity_limit:
            project.projectCapacityConfig.dailyCapacityLimit,
        },
      };
    });
  };

  const onUpdateCapacity = async (newCapacity) => {
    const payload = {
      new_capacity: newCapacity === "" ? 0 : parseInt(newCapacity),
    };

    const response = await Organization.updateOrgCapacity(payload);
    if (!response.error) {
      showSnackbar("Organization capacity updated successfully", "success");
      setIsCapacityModalOpen(false);
      getOrganizationDetails();
    } else {
      showSnackbar("Updating Organization capacity failed", "error");
      console.error(response.error);
    }
  };

  return (
    <>

      <ThemeProvider theme={theme}>
        {projects?.length > 0 ? (<Container maxWidth="xl" sx={{ height: "100vh", bgcolor: "white", padding: "1em" }} clasName="fade-in">
          <Box
            pt={2}
            pb={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h6" component="h6">
              {organizationDetails?.name ?? ""}
            </Typography>
            <Box display="flex" gap="1rem">
              {organizationTier === ORG_TIER.FREE_TIER ? (
                <UpgradeButton ref={upgradeButtonRef} orgBillingSource={orgnizationBillingSource} />
              ) : (
                <></>
              )}
              <Link to="/audit_log">
                <Button type="primary" className="secondary-button">
                  View Audit Log
                </Button>
              </Link>              <Button
                type="primary"
                className="primary-button"
                endIcon={<AddIcon />}
                disabled={!organizationDetails?.remainingProjectQuota}
                onClick={addProjectModalOpenHandler}
              >
                Add Project
              </Button>
            </Box>
          </Box>
          <Divider variant="middle" component="hr" />
          <ProjectsGrid
            projects={projects}
            capacity={totalAllowedCapacity}
            usedCapacities={usedCapacities}
            setProjects={setProjects}
            organizationDetails={organizationDetails}
            updateProjectCapacities={onUpdateProjectCapacities}
            isSaving={isCapacitiesUpdating}
            setIsSaving={setIsCapacitiesUpdating}
          />
          <Box display="flex" alignItems="center" gap={2}>
            <Card border={1} padding={2}>
              <CardContent>
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography>Total Users</Typography>
                  <TextField
                    disabled
                    size="small"
                    value={
                      organizationDetails ? organizationDetails.totalUsers : ""
                    }
                  />
                  <Button color="primary" onClick={manageUserModalOpenHandler}>
                    Manage Users
                  </Button>
                </Box>
              </CardContent>
            </Card>
            <Card border={1} padding={2}>
              <CardContent>
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography>Total Capacity</Typography>
                  <TextField
                    disabled
                    value={totalAllowedCapacity || ""}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          Million EPM
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button color="primary" onClick={capacityModalOpenHandler}>
                    Enable More Capacity
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>) : (
          <div style={{
            display: "flex",
            justifyContent: "center",   // Horizontally center
            alignItems: "center",       // Vertically center
            height: "100vh",            // Full viewport height
            backgroundColor: "white",
          }}>
            <Button
              type="primary"
              className="primary-button fade-in"
              startIcon={<AddIcon />}
              disabled={!organizationDetails?.remainingProjectQuota}
              onClick={createFirstProject}
            >
              Create Project
            </Button>
          </div>

        )}
        <AddProject
          open={isAddProjectModalOpen}
          handleClose={addProjectModalCloseHandler}
        />
        <ManageUser
          open={isManageUserModalOpen}
          close={manageUserModalCloseHandler}
        />
        <Capacity
          open={isCapacityModalOpen}
          close={capacityModalCloseHandler}
          updateCapacity={onUpdateCapacity}
        />
      </ThemeProvider>
    </>
  );
};

export default AdminSettings;
