import jp from 'jsonpath';

import { TypedValue, TypedValueOrList } from '../models/typed/models';

export function extractJsonPathValue(json: any, jsonPath: string): TypedValueOrList {
    if (!json) {
        return {
            singleValue: { stringValue: '' },
        };
    }
    const result = jp.query(json, jsonPath);

    if (result.length === 0) {
        return {
            singleValue: { stringValue: '' },
        };
    }

    const typedValues: TypedValue[] = result?.map((value: any) => {
        if (typeof value === 'string') {
            return { stringValue: value };
        } else if (typeof value === 'number') {
            if (Number.isInteger(value)) {
                return { intValue: value };
            } else {
                return { floatValue: value };
            }
        } else if (typeof value === 'boolean') {
            return { boolValue: value };
        } else {
            throw new Error('Unsupported value type');
        }
    });

    if (typedValues.length === 1) {
        return { singleValue: typedValues[0] };
    } else {
        return { singleValue: typedValues[0], valueList: { value: typedValues } };
    }
}