import { Box, Typography } from "@mui/material";
import React from "react";

interface RingProps {
  color: string;
  label: string;
}

const Ring: React.FC<RingProps> = ({ color, label }) => {
  return (
    <Box
      width={32}
      height={32}
      borderRadius={50}
      border={2}
      borderColor={color}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography fontSize={12}>{label}</Typography>
    </Box>
  );
};

export default Ring;
