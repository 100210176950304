import { Box, Skeleton, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";

const AssertionsListSkeleton = () => {
  return (
    <>
      <Box
        key={1}
        sx={{ borderRadius: "7px" }}
        paddingLeft={4}
        paddingRight={4}
        paddingTop={1.7}
        paddingBottom={1.7}
        marginBottom={1}
        bgcolor={grey[200]}
      >
        <Stack direction="row" spacing={1}>
          <Skeleton
            variant="rounded"
            sx={{ fontSize: "1.7rem" }}
            width={"30%"}
          />
          <Skeleton
            variant="rounded"
            sx={{ fontSize: "1.7rem" }}
            width={"10%"}
          />
          <Skeleton
            variant="rounded"
            sx={{ fontSize: "1.7rem" }}
            width={"30%"}
          />
        </Stack>
      </Box>
      <Box
        key={2}
        sx={{ borderRadius: "7px" }}
        paddingLeft={4}
        paddingRight={4}
        paddingTop={1.7}
        paddingBottom={1.7}
        bgcolor={grey[200]}
      >
        <Stack direction="row" spacing={1}>
          <Skeleton
            variant="rounded"
            sx={{ fontSize: "1.7rem" }}
            width={"40%"}
          />
          <Skeleton
            variant="rounded"
            sx={{ fontSize: "1.7rem" }}
            width={"10%"}
          />
          <Skeleton
            variant="rounded"
            sx={{ fontSize: "1.7rem" }}
            width={"25%"}
          />
        </Stack>
      </Box>
    </>
  );
};

export default AssertionsListSkeleton;
