import { cloneDeep, flatten, groupBy, merge } from "lodash";
import { ISource, ITraceData, PrefixSummary } from "../models/overlays";
import hash from "object-hash";

export function generateUniqueID() {
  return maskUUID(crypto.randomUUID());
}

export function generateHash(value: object): string;
export function generateHash(value: string): string;
export function generateHash(value: object | string): string {
  if (typeof value === 'object') {
    return maskUUID(hash(value));
  } else if (typeof value === 'string') {
    return maskUUID(hash(value));
  } else {
    throw new Error('Invalid argument type. Expected object or string.');
  }
}

export function maskUUID(uuid: string) {
  return uuid.replace(/^\d/, "a").replace(/-/g, "");
}

export function getRandomLetter() {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  return alphabet[Math.floor(Math.random() * alphabet.length)];
}

export const NEW_OVERLAY_COLOR = "#9900EF";
export const BASE_OVERLAY_HIGHLIGHT_COLOR = "#F2F1EB";

export const OverlayHelpers = {
  getSourceMetaData: (source: ISource) => {
    let traceTreePath = source.path;
    let pathArray = traceTreePath.split(".");
    let overlayId = pathArray[3];

    let nodeId = maskUUID(source.nodeId);

    return { traceTreePath, overlayId, nodeId };
  },
  processSourceData: (sourceData: PrefixSummary, overlayId: string) => {
    let nodeAttributeSummaries = sourceData.attributeSummaries;
    let resourceAttributeSummaries = sourceData.resourceAttributeSummaries;

    let traceData: ITraceData = {
      nodeAttributeSummaries: {},
      resourceAttributeSummaries: {},
    };

    // Process nodeAttributeSummaries
    Object.entries(nodeAttributeSummaries).forEach(([key, attributes]) => {
      traceData.nodeAttributeSummaries[key] = { guards: [], values: {} };

      if (attributes.summary?.attributeValueSummaries) {
        traceData.nodeAttributeSummaries[key].guards = attributes.guards || [];
        Object.entries(attributes.summary.attributeValueSummaries).forEach(
          ([attributeKey, attributeValueSummary]) => {
            traceData.nodeAttributeSummaries[key].values[attributeKey] = {
              [overlayId]: attributeValueSummary.numOccurrences || 0,
            };
          }
        );
      }
    });

    // Process resourceAttributeSummaries
    Object.entries(resourceAttributeSummaries).forEach(([key, attributes]) => {
      traceData.resourceAttributeSummaries[key] = { guards: [], values: {} };

      if (attributes.summary?.attributeValueSummaries) {
        traceData.resourceAttributeSummaries[key].guards =
          attributes.guards || [];
        Object.entries(attributes.summary.attributeValueSummaries).forEach(
          ([attributeKey, attributeValueSummary]) => {
            traceData.resourceAttributeSummaries[key].values[attributeKey] = {
              [overlayId]: attributeValueSummary.numOccurrences || 0,
            };
          }
        );
      }
    });

    return traceData;
  },

  aggregateTraceData: (traceDataList: ITraceData[]) => {
    traceDataList = cloneDeep(traceDataList);

    let result!: ITraceData;

    traceDataList.forEach((data) => {
      result = merge(result, data);
    });

    return result;
  },
  processSourceTraceTreeChildren: (
    source: ISource,
    sourceTraceTreeChildren: any,
    path: string
  ) => {
    return sourceTraceTreeChildren?.map((child: any, index: number) => {
      return {
        nodeId: child.nodeId,
        self: `${path}.childNodes[${index}]`,
        source: `${source}.childPrefixSummaries[${index}]`,
        prefix: child.prefix,
        operation: child.operation,
        hasChildNodes: child.childPrefixSummaries?.length > 0,
        childNodes: null,
        frequency: child?.prefixSummary.numOccurrences || 0,
        latency: child?.prefixSummary.avgLatency || 0,
        errorRate: child?.prefixSummary.errorRate || 0,
      };
    });
  },
  aggregateTraceTreeChildren: (path: string, traceTreeChildrenList: any) => {
    let groupedTraceTreeChildren = groupBy(
      flatten(traceTreeChildrenList),
      (traceTree: any) =>
        `${traceTree.operation.resourceName},${traceTree.operation.operationName}`
    );

    return Object.keys(groupedTraceTreeChildren).map((key, index) => {
      let sourceChildList: any[] = groupedTraceTreeChildren[key];
      let aggregatedTraceTreeChildren = {
        key: generateUniqueID(),
        self: `${path}.children[${index}]`,
        sources: sourceChildList?.map((traceTree: any) => {
          return {
            nodeId: traceTree.nodeId,
            path: traceTree.self,
          };
        }),
        prefix: sourceChildList[0].prefix,
        operation: sourceChildList[0].operation,
        title: sourceChildList[0].operation.operationName,
        isLeaf: !sourceChildList.reduce(
          (result, sourceChild) => result || sourceChild.hasChildNodes,
          false
        ),
        children: null,
      };

      return aggregatedTraceTreeChildren;
    });
  },
};
