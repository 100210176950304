import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IListOperationsWithDetailsResponse } from "../models/overlays";
import Collectors from "../api/services/Collectors";
import { OasConfig, OperationWithDetail } from "../models/config/models";
import { AppDispatch, RootState } from "../app/store";
import { GenerateApiTestsResponse } from "../test-studio/models";
import ProjectTS, { IUpdateResourceConfigsRequest } from "../api/services/ProjectTS";

interface ApiState {
  selectedResource: number | undefined;
  selectedResourceName: string | undefined;
  oasConfig: OasConfig | undefined;
  oasFile: string | undefined;
  operations: OperationWithDetail[];
}

const initialState: ApiState = {
  selectedResource: undefined,
  selectedResourceName: undefined,
  oasConfig: undefined,
  oasFile: undefined,
  operations: [],
};

export const listOperationsWithDetails = createAsyncThunk<
  IListOperationsWithDetailsResponse,
  {},
  { dispatch: AppDispatch; state: RootState }
>("apis/listOperationsWithDetails", async ({}, { dispatch, getState }) => {
  const state = getState() as RootState;
  const response = await Collectors.listOperationsWithDetails({ resourceId: state.apis.selectedResource! });
  return response; // Return the response so extraReducer can use it
});

export const updateResourceConfigs = createAsyncThunk<
  IUpdateResourceConfigsRequest,
  {},
  { dispatch: AppDispatch; state: RootState }
>("apis/updateResourceConfigs", async ({}, { dispatch, rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;
    return await ProjectTS.updateResourceConfigs({
      configs: [{ resourceName: state.apis.selectedResourceName!, oasConfig: state.apis.oasConfig! }],
    });
  } catch (error: any) {
    // Handle error by rejecting with a value to handle in extraReducer
    return rejectWithValue(error.response?.data || error.message);
  }
});

export const ignoreApi = createAsyncThunk<
  GenerateApiTestsResponse,
  { operationId: number },
  { dispatch: AppDispatch; state: RootState }
>("apis/ignoreApi", async ({ operationId }, { rejectWithValue, dispatch, getState }) => {
  try {
    const state = getState() as RootState;
    return await Collectors.addIgnoredEntrypoint({
      operationId: operationId,
      resourceId: state.apis.selectedResource!,
    });
  } catch (error: any) {
    // Handle error by rejecting with a value to handle in extraReducer
    return rejectWithValue(error.response?.data || error.message);
  }
});

const apisSlice = createSlice({
  name: "apis",
  initialState,
  reducers: {
    setSelectedResource: (state, action: PayloadAction<number>) => {
      state.selectedResource = action.payload;
    },
    setSelectedResourceName: (state, action: PayloadAction<string>) => {
      state.selectedResourceName = action.payload; // Ensure you have this field in your state
    },
    setOasFile: (state, action: PayloadAction<string>) => {
      state.oasFile = action.payload;
    },
    setOasConfig: (state, action: PayloadAction<OasConfig>) => {
      state.oasConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listOperationsWithDetails.fulfilled, (state, action) => {
        const { oasConfig, operations } = action.payload as IListOperationsWithDetailsResponse;
        state.oasConfig = oasConfig;
        state.oasFile = oasConfig?.fileName ?? "";
        state.operations = operations
          .slice() // To avoid mutating the original array
          .sort(
            (a: OperationWithDetail, b: OperationWithDetail) => a.name.localeCompare(b.name) // Sort alphabetically by 'name'
          );
      })
      .addCase(ignoreApi.fulfilled, (state, action) => {
        const { operationId } = action.meta.arg;
        // Remove the operation from the state list
        state.operations = state.operations.filter((op) => op.operationId !== operationId);
      })
      .addCase(ignoreApi.rejected, (state, action) => {
        // Handle error state, if needed
        console.error("Failed to ignore API:", action.payload);
      });
  },
});

export const { setSelectedResource, setSelectedResourceName, setOasFile, setOasConfig } = apisSlice.actions;
export default apisSlice.reducer;
