import { useAppDispatch } from "../../app/hooks";
import { HTTPMessageSection, IGenerator, IVariable } from "../models";
import { addVariableToGenerator, setGenerator } from "../../features/testsSlice";

const useGeneratorTextField = () => {
  const dispatch = useAppDispatch();

  const handleAddVariable = (section: HTTPMessageSection, key: string, variable: IVariable) => {
    dispatch(addVariableToGenerator({ section, key, variable }));
  };

  const handleUpdate = (key: string, section: HTTPMessageSection, generator: IGenerator) => {
    dispatch(
      setGenerator({
        key,
        section,
        generator,
      })
    );
  };

  return { handleAddVariable, handleUpdate };
};

export default useGeneratorTextField;
