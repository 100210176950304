import { Box, CircularProgress } from "@mui/material";

const ComponentLoader: React.FC = () => {
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
      <CircularProgress size={70} />
    </Box>
  );
};

export default ComponentLoader;
