import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Select, InputLabel, FormControl, Grid, ThemeProvider, MenuItem, TextField, Fab, Box } from '@mui/material';
import Scenarios, { Priority, SuggestUserStoryDescriptionResponse, UserStory, UserStoryStatus } from '../api/services/Scenarios';
import theme from "../common/theme";
import 'react-quill/dist/quill.snow.css';
import { AutoAwesome } from '@mui/icons-material';
import { message } from 'antd';
import ReactQuill, { Quill } from 'react-quill';

interface PersonaMap {
    [key: number]: string;
}

interface MilestoneMap {
    [key: number]: string;
}

interface SprintMap {
    [key: number]: string;
}

interface UpsertUserStoryDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (userStory: UserStory) => void;
    personas: PersonaMap;
    milestones: MilestoneMap;
    sprints: SprintMap;
    epicId: number;
    userStoryToEdit?: UserStory; // Added for edit mode
}

const UpsertUserStoryDialog: React.FC<UpsertUserStoryDialogProps> = ({ open, onClose, onSave, personas, milestones, sprints, epicId, userStoryToEdit }) => {
    const [newTitle, setNewTitle] = useState('');
    const [newDescription, setNewDescription] = useState<string | undefined>('');
    const [newPersonaId, setNewPersonaId] = useState<number | ''>('');
    const [newMilestoneId, setNewMilestoneId] = useState<number | ''>('');
    const [newSprintId, setNewSprintId] = useState<number | ''>('');
    const [newPriority, setNewPriority] = useState<Priority | ''>('');
    const [newStatus, setNewStatus] = useState<UserStoryStatus>(UserStoryStatus.UNKNOWN_USER_STORY_STATUS);
    const [userStoryId, setUserStoryId] = useState<number | undefined>(undefined);
    const quillRef = useRef<ReactQuill | null>(null);

    const priorities = {
        [Priority.P0]: 'P0',
        [Priority.P1]: 'P1',
        [Priority.P2]: 'P2',
        [Priority.P3]: 'P3',
    };
    const statuses = {
        [UserStoryStatus.COMPLETED]: 'Completed',
        [UserStoryStatus.DELETED_USER_STORY]: 'Archived',
        [UserStoryStatus.IN_DEVELOPMENT]: 'In Development',
        [UserStoryStatus.IN_SCOPING]: 'In Scoping',
        [UserStoryStatus.IN_TESTING]: 'In Testing',
    };

    useEffect(() => {
        if (userStoryToEdit) {
            setNewTitle(userStoryToEdit.title || '');
            setNewPersonaId(userStoryToEdit.primaryPersonaId!);
            setNewMilestoneId(userStoryToEdit.milestoneId || '');
            setNewSprintId(userStoryToEdit.sprintId || '');
            setNewPriority(userStoryToEdit.priority || '');
            setNewStatus(userStoryToEdit.status || UserStoryStatus.UNKNOWN_USER_STORY_STATUS);
            setUserStoryId(userStoryToEdit.id);
            setNewDescription(userStoryToEdit.description || '');
        } else {
            setNewTitle('');
            setNewPersonaId('');
            setNewMilestoneId('');
            setNewStatus(UserStoryStatus.IN_SCOPING);
            setNewSprintId('');
            setNewDescription('');
            setNewPriority(Priority.P1);
            setUserStoryId(undefined);
        }
    }, [userStoryToEdit]);

    useEffect(() => {
        if (quillRef.current) {
            try {
                quillRef.current?.getEditor().setContents(JSON.parse(newDescription ?? "{}"))
            } catch (error) {
                quillRef.current?.getEditor().setContents(JSON.parse("{}"))
            }
        }
    }, [quillRef.current])

    const handleSave = () => {
        const userStory: UserStory = {
            id: userStoryId, // Include ID for edit mode
            title: newTitle,
            description: JSON.stringify(quillRef.current?.getEditor().getContents()),
            primaryPersonaId: newPersonaId || undefined,
            milestoneId: newMilestoneId || undefined,
            sprintId: newSprintId || undefined,
            epicId: epicId,
            status:newStatus,
            priority: newPriority || Priority.UNKNOWN_PRIORITY,
        };
        onSave(userStory);
        onClose(); // Close dialog after saving
    };

    const handleSuggestDescription = async () => {
        try {
            const response: SuggestUserStoryDescriptionResponse = await Scenarios.suggestUserStoryDescription({ title: newTitle, existingDescription: JSON.stringify(quillRef.current?.getEditor().getContents()), personaId: newPersonaId == '' ? 0 : newPersonaId, epicId: epicId });
            if (!response.description || response?.description?.length == 0) {
                message.error("Could not complete the description. Try again later");
            }
            quillRef.current?.getEditor().setContents(JSON.parse(response.description));
        } catch (error) {
            console.error("Error fetching suggested description:", error);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Persona</InputLabel>
                        <Select
                            value={newPersonaId}
                            onChange={(e) => setNewPersonaId(e.target.value as number)}
                            label="Persona"
                        >
                            {Object.entries(personas).map(([id, name]) => (
                                <MenuItem key={id} value={Number(id)}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        autoFocus
                        margin="normal"
                        label="Title"
                        fullWidth
                        variant="outlined"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1em' }}>
                            <Button
                                color="primary"
                                variant='outlined'
                                size="small"
                                disabled={!newTitle || newTitle?.length == 0}
                                onClick={handleSuggestDescription}
                            >
                                Write for me...
                            </Button>
                        </div>
                        <ReactQuill
                            ref={quillRef}
                            theme="snow"
                            style={{
                                height: '12em', // Set the height to accommodate approximately 10 lines
                                maxHeight: '12em',
                                overflowY: 'inherit', // Enable vertical scrolling
                                marginBottom: '70px'
                            }}
                        />
                    </div>
                    <Box display="flex" justifyContent="space-between" gap={2}>
                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel>Priority</InputLabel>
                            <Select
                                value={newPriority}
                                onChange={(e) => setNewPriority(e.target.value as Priority)}
                                label="Priority"
                            >
                                {Object.entries(priorities).map(([value, label]) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={newStatus}
                                onChange={(e) => setNewStatus(e.target.value as UserStoryStatus)}
                                label="Status"
                            >
                                {Object.entries(statuses).map(([value, label]) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel>Sprint</InputLabel>
                            <Select
                                value={newSprintId}
                                onChange={(e) => setNewSprintId(e.target.value as number)}
                                label="Sprint"
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {Object.entries(sprints).map(([id, name]) => (
                                    <MenuItem key={id} value={Number(id)}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel>Milestone</InputLabel>
                            <Select
                                value={newMilestoneId}
                                onChange={(e) => setNewMilestoneId(e.target.value as number)}
                                label="Milestone"
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {Object.entries(milestones).map(([id, name]) => (
                                    <MenuItem key={id} value={Number(id)}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default UpsertUserStoryDialog;