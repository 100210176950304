export const ThemeColors = {
  primary: '#9C9DE3', // Primary color for title, headings, etc.
  primaryAction: '#008080', // Primary action color.
  inactive: '#707070', // Primary action color.
  background: '#d9d9d9',
  secondaryBackground:'#F3F3F3',
  border: '1px solid #010101',
  titleBackground: '#333333',
  progress:"#8FBC8F",
  success: '#00b894', // Success color for notifications, etc.
  error: ' #FF4136', // Error color for error messages, alerts, etc.
  warning: '#f39c12', // Warning color for warnings, notifications, etc.
  text: '#f5f5f5', // Text color for body text, headings, etc.
  darkText:'#333333',
  white: '#f5f5f5', // White color for backgrounds, etc.
  black:'#000000',
  lightBackground: '#F5F5F5', // Light background color for the page or container
  primaryAttribBar: '#FFA500',
  textInputBackground:'#f5f5f5',
  secondaryAttribBar: '#005AFF',
};

