import client from "../client";

const Project = {
  getConfig: async (id) => {
    try {
      let projectConfig = await client.post("/admin/get_project_config", {
        project_id: id,
      });
      console.log("received project config:", projectConfig);
      return projectConfig;
    } catch (error) {
      console.error(`Error calling Project.getConfig(${id})`, error);
      return { error: error };
    }
  },
  setConfig: async (config) => {
    try {
      let projectConfig = await client.post(
        "admin/update_project_config",
        config
      );
      return projectConfig;
    } catch (error) {
      console.error(`Error calling Project.setConfig(${config})`, error);
      return { error: error };
    }
  },
  add: async (project) => {
    try {
      console.log(project);
      return await client.post("admin/add_project", project);
    } catch (error) {
      console.error(`Error calling Project.add(${project})`, error);
      return { error: error };
    }
  },
  addUser: async (projectUser) => {
    console.log(projectUser);
    try {
      return await client.post("admin/add_user_to_project", projectUser);
    } catch (error) {
      console.error(`Error calling Project.addUser(${projectUser})`, error);
      return { error: error };
    }
  },
  inviteUser: async (user) => {
    try {
      console.log(user);
      return await client.post("admin/invite_user", user);
    } catch (error) {
      console.error(`Error calling Project.inviteUser(${user})`, error);
      return { error: error };
    }
  },
  removeUser: async (payload) => {
    console.log(payload);
    try {
      return await client.post("admin/remove_user_from_project", payload);
    } catch (error) {
      console.error(`Error calling Project.removeUser(${payload})`, error);
      return { error: error };
    }
  },
  getUsers: async (projectId) => {
    try {
      console.log(projectId);
      return await client.post("admin/list_users_for_project", projectId);
    } catch (e) {
      console.error(`Error calling Project.getUsers(${projectId})`, e);
      return { error: e };
    }
  },
  updateUserRoles: async (updatedUsers) => {
    try {
      console.log(updatedUsers);
      return await client.post("admin/update_user_project_roles", updatedUsers);
    } catch (e) {
      console.error(
        `Error calling Project.updateUserRoles(${updatedUsers})`,
        e
      );
      return { error: e };
    }
  },
  getRemainingUserQuota: async (organization) => {
    try {
      console.log(organization);
      return await client.post(
        "capacity/get_remaining_user_quota",
        organization
      );
    } catch (e) {
      console.error(
        `Error calling Project.getRemainingUserQuota(${organization})`,
        e
      );
      return { error: e };
    }
  },
};

export default Project;
