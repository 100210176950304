import {
  IListSessionEventsRequest,
  IListSessionRecordingsRequest,
  IListSessionUsersRequest,
  IListTracesForRecordedSessionRequest,
  IMarkSessionAsPermanentRequest,
} from "../../models/sessions/ISession";
import client from "../client";

interface IListSessionEvents {
  listSessionEvents: (request: IListSessionEventsRequest) => Promise<any>;
}


interface IMarkSessionAsPermanent {
  markSessionAsPermanent: (request: IMarkSessionAsPermanentRequest) => Promise<any>;
}


interface IListSessionUsers {
  listSessionUsers: (request: IListSessionUsersRequest) => Promise<any>;
}

interface IListSessionRecordings {
  listSessionRecordings: (
    request: IListSessionRecordingsRequest
  ) => Promise<any>;
}

const SessionEvents = {
  listSessionEvents: async (request: IListSessionEventsRequest) => {
    const response = await client.post(
      "/sessions/list_session_events",
      request
    );
    return response.data;
  },
  listTracesForRecordedSession: async (
    request: IListTracesForRecordedSessionRequest
  ) => {
    const response = await client.post(
      "/sessions/list_traces_for_recorded_session",
      request
    );
    return response.data;
  }
};

const markSessionAsPermanent: IMarkSessionAsPermanent = {
  markSessionAsPermanent: async (request: IMarkSessionAsPermanentRequest) => {
    try {
      let response = await client.post(
        "/sessions/mark_session_as_permanent",
        request
      );
      return response.data;
    } catch (error) {
      return { error: error };
    }
  },
};

const listSessionUsers: IListSessionUsers = {
  listSessionUsers: async (request: IListSessionUsersRequest) => {
    try {
      let response = await client.post("/sessions/list_session_users", request);
      return response.data;
    } catch (error) {
      return { error: error };
    }
  },
};

const listSessionRecordings: IListSessionRecordings = {
  listSessionRecordings: async (request: IListSessionRecordingsRequest) => {
    try {
      let response = await client.post(
        "/sessions/list_session_recordings",
        request
      );
      return response.data;
    } catch (error) {
      return { error: error };
    }
  },
};

export {
  listSessionRecordings as listSessionRecordings,
  listSessionUsers as listSessionUsers,
  markSessionAsPermanent as markSessionAsPermanent,
};

export default SessionEvents;
