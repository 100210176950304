import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { AppDispatch, RootState } from "../../app/store";
import {
  resetOverlays,
  addFilter,
  closeDrawer,
  deleteFilter,
  listConditionValues,
  updateFilters,
  updateColor,
  updateDateRange,
  updateQueries,
} from "../../features/overlaysSlice";
import Filter from "./Filter";
import DateRange from "./DateRange";
import SamplingMode from "./SamplingMode";
import { DrawerMode, IFilter } from "../../models/overlays";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ColorResult, TwitterPicker } from "react-color";
import { AddCircle } from "@mui/icons-material";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { IQueryWithMetaData } from "../../models/explore";

const OverlayDrawer: React.FC = () => {
  const [drawer, setDrawer] = useState({
    formLabel: "New Overlay",
    buttonLabel: "Add Overlay",
  });
  const dispatch: AppDispatch = useAppDispatch();

  const [expandedPanel, setExpandedPanel] = useState<string | undefined>(
    "filters"
  );

  const queries = useAppSelector((state: RootState) => state.overlays.queries);

  const isDrawerOpen = useAppSelector(
    (state: RootState) => state.overlays.isDrawerOpen
  );
  const overlayId = useAppSelector(
    (state: RootState) => state.overlays.overlayId
  );
  const drawerMode = useAppSelector(
    (state: RootState) => state.overlays.drawerMode
  );
  const color = useAppSelector((state: RootState) => state.overlays.color);
  const valueConfigs = useAppSelector(
    (state: RootState) => state.overlays.valueConfigs
  );
  const filters = useAppSelector((state: RootState) => state.overlays.filters);
  const dateRange = useAppSelector(
    (state: RootState) => state.overlays.dateRange
  );
  const samplingMode = useAppSelector(
    (state: RootState) => state.overlays.samplingMode
  );

  useEffect(() => {
    dispatch(listConditionValues());

    return () => {
      dispatch(resetOverlays());
    };
  }, []);

  useEffect(() => {
    if (drawerMode == DrawerMode.ADD) {
      setDrawer({
        formLabel: "New Overlay",
        buttonLabel: "Add Overlay",
      });
    } else if (drawerMode == DrawerMode.EDIT) {
      setDrawer({
        formLabel: "Edit Overlay",
        buttonLabel: "Edit Overlay",
      });
    } else {
      console.error("Unknown drawer mode");
    }
  }, [drawerMode]);

  const onColorChange = (color: ColorResult) => {
    dispatch(updateColor(color.hex));
  };

  const closeDrawerHandler = (): void => {
    dispatch(closeDrawer());
  };

  const handleAccordionChange = (panel: string, isExpanded: boolean) => {
    setExpandedPanel(isExpanded ? panel : undefined);
    if (isExpanded && panel == "dateRange" && isEmpty(dateRange)) {
      dispatch(
        updateDateRange({
          start: dayjs()
            .subtract(dayjs.duration({ days: 1 }))
            .toISOString(),
          end: dayjs(new Date()).toISOString(),
        })
      );
    }
  };

  const addFilterHandler = (): void => {
    const newFilter: IFilter = {
      key: "",
      value: "",
      operator: "",
    };
    dispatch(addFilter(newFilter));
  };

  const deleteFilterHandler = (index: number): void => {
    dispatch(deleteFilter(index));
  };

  const filterValueChangeHandler = (
    index: number,
    field: string,
    value: string
  ): void => {
    dispatch(updateFilters({ index, field, value }));
  };

  const onFetchHandler = () => {
    const timeWindow = isEmpty(dateRange)
      ? { relative_window: "86400s" }
      : {
          fixed_window: {
            start_time: dateRange.start,
            end_time: dateRange.end,
          },
        };

    let newQuery: IQueryWithMetaData = {
      id: overlayId,
      color: color,
      filters: filters,
      time_window: timeWindow,
      sampling_mode: samplingMode.mode,
      sampling_rate: samplingMode.manualSamplingRate,
    };

    let updatedQueries = [];
    if (drawerMode == DrawerMode.ADD) {
      updatedQueries = [...queries, newQuery];
    } else if (drawerMode == DrawerMode.EDIT) {
      updatedQueries = [...queries];
      const queryIndex = updatedQueries.findIndex(
        (query) => query.id == overlayId
      );
      updatedQueries[queryIndex] = {
        ...updatedQueries[queryIndex],
        ...newQuery,
      };
    } else {
      console.error("Unknown drawer mode");
      return;
    }

    dispatch(updateQueries(updatedQueries));

    if (isDrawerOpen) {
      dispatch(closeDrawer());
    }
  };

  const keys = valueConfigs?.map((config) => config.key);
  const displayValues = valueConfigs?.map((config) => config.displayValue);

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawerHandler}>
      <Box width={530} paddingTop={10} paddingX={2}>
        <Box>
          <Typography
            variant={"h6"}
            textAlign={"center"}
            fontWeight={650}
            paddingBottom={3}
          >
            {drawer.formLabel}
          </Typography>
        </Box>
        <Box>
          <form>
            <Accordion
              defaultExpanded
              expanded={expandedPanel === "filters"}
              onChange={(_, expanded) =>
                handleAccordionChange("filters", expanded)
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                <Typography fontWeight={600} fontSize={15}>
                  Filters
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1}>
                  {filters?.map((filter, index) => {
                    return (
                      <Filter
                        key={index}
                        valueConfigs={valueConfigs}
                        keys={keys}
                        displayValues={displayValues}
                        index={index}
                        filter={filter}
                        onDelete={() => deleteFilterHandler(index)}
                        onChange={filterValueChangeHandler}
                      />
                    );
                  })}
                </Stack>
                <Box marginTop={1}>
                  <Button variant="outlined" onClick={addFilterHandler}>
                    Add Filter
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedPanel === "dateRange"}
              onChange={(_, expanded) =>
                handleAccordionChange("dateRange", expanded)
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                <Typography fontWeight={600} fontSize={15}>
                  Date Range
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <DateRange />
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedPanel === "samplingMode"}
              onChange={(_, expanded) =>
                handleAccordionChange("samplingMode", expanded)
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                <Typography fontWeight={600} fontSize={15}>
                  Sampling Mode
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <SamplingMode />
                </Box>
              </AccordionDetails>
            </Accordion>
          </form>
        </Box>
        <Box>
          <Typography
            fontWeight={600}
            fontSize={15}
            paddingTop={3}
            paddingLeft={2}
          >
            Color
          </Typography>
          <TwitterPicker
            width="auto"
            color={color}
            onChange={onColorChange}
            triangle="hide"
          />
        </Box>
        <Box position="absolute" left={0} right={0} bottom={0}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<AddCircle />}
            onClick={onFetchHandler}
          >
            {drawer.buttonLabel}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default OverlayDrawer;
