import { ThemeColors } from "../styles/light-colors";

const CURRENT_FE_VERSION = process.env.REACT_APP_CURRENT_VE_VERSION;

export function getTraceCollectionLabel(traceCollection) {
  let desc = "(";
  let sliceKeyValues = traceCollection.sliceKeyValues.sliceKeyValues;
  for (let i = 0; i < sliceKeyValues.length; i++) {
    const sliceKeyValue = sliceKeyValues[i];
    if (
      sliceKeyValue.key != "entryDeducedName" &&
      sliceKeyValue.key != "entryResourceName" &&
      sliceKeyValue.key != "projectId"
    ) {
      desc += sliceKeyValue.key + ": " + sliceKeyValue.value + " ";
    }
  }
  desc += ")";
  return traceCollection.templateName + " " + desc;
}

export function encodeFilterList(filterList) {
  console.log("filterlist", filterList);
  const separator = "~";
  return filterList
    .map((filter) => {
      const escapedValue = filter.value.replace(/~/g, "~\\");
      return `${filter.key},${filter.operator},${escapedValue}`;
    })
    .join(separator);
}

export function decodeFilterList(filterString) {
  const separator = "~";
  return filterString.split(separator).map((filter) => {
    const [key, operator, ...valueParts] = filter.split(",");
    const value = valueParts.join(",").replace(/~\\/g, "~");
    return { key, operator, value };
  });
}

// Input should be Date objs
export function getTimeWindowForDateRange(start, end) {
  return {
    fixed_window: {
      start_time: convertDateToProtoTimestamp(start),
      end_time: convertDateToProtoTimestamp(end),
    },
  };
}

// Input should be a Date obj
export function convertDateToProtoTimestamp(date) {
  return date.toISOString();
}

// Input should be a protobuf.timestamp
export function convertProtoTimestampToDate(timestamp) {
  return new Date(timestamp);
}

export function getVersionedUrl(url) {
  // Check if the URL already contains query parameters
  const hasQueryParams = url.includes("?");

  // Determine the appropriate separator ('?' or '&') for the version parameter
  const separator = hasQueryParams ? "&" : "?";

  // Construct the new URL with the version parameter
  const updatedUrl = `${url}${separator}v=${CURRENT_FE_VERSION}`;

  return updatedUrl;
}

export function getErrorRateColor(errorRate) {
  if (errorRate < 1) {
    return ThemeColors.success;
  }
  if (errorRate > 20) {
    return ThemeColors.error;
  }
  return ThemeColors.warning;
}

export function parseTimeWindow(windowStr) {
  let parts = windowStr.split("=");
  if (parts.length == 0) {
    return {};
  }
  if (parts[0] == "rw") {
    return { relative_window: parts[1] };
  }
  // TODO do fixed window;
}

export function objectToMap(obj) {
  const map = new Map();

  for (const [key, value] of Object.entries(obj)) {
    map.set(key, value);
  }

  return map;
}

const SECONDSINHOUR = 3600 * 24;

//TODO : can be refactored with proto implementation.
export const secondsToHours = (secondsWithS) => {
  let seconds = secondsWithS.substring(0, secondsWithS.length - 1);
  return Math.round(seconds / SECONDSINHOUR);
};

export const hoursToSeconds = (hours) => {
  let seconds = hours * SECONDSINHOUR;
  return `${seconds}s`;
};
