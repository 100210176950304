import { Box, Skeleton, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { IQueryWithMetaData } from "../../models/explore";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

const TreeSkeleton = () => {
  const queries = useAppSelector((state: RootState) => state.overlays.queries);
  return (
    <>
      <Box
        key={1}
        sx={{ borderRadius: "7px" }}
        paddingLeft={0.6}
        paddingRight={1}
        paddingTop={0.2}
        marginLeft={3}
        bgcolor={grey[200]}
      >
        <Skeleton variant="text" sx={{ fontSize: "1.1rem" }} width={"40%"} />
        <Stack direction="row" spacing={1}>
          {queries?.map((_: IQueryWithMetaData, index: number) => (
            <Skeleton key={index} variant="circular" width={40} height={36} />
          ))}
        </Stack>
      </Box>
      <Box
        key={2}
        sx={{ borderRadius: "7px" }}
        paddingLeft={0.6}
        paddingRight={1}
        paddingTop={0.2}
        marginLeft={3}
        marginTop={0.5}
        bgcolor={grey[200]}
      >
        <Skeleton variant="text" sx={{ fontSize: "1.1rem" }} width={"70%"} />
        <Stack direction="row" spacing={1}>
          {queries?.map((_: IQueryWithMetaData, index: number) => (
            <Skeleton key={index} variant="circular" width={40} height={36} />
          ))}
        </Stack>
      </Box>
    </>
  );
};

export default TreeSkeleton;
