import { Modal, Row, Col, Card, Button, List, Divider, Typography } from 'antd';
import React, { useEffect, useState } from "react";
import { Organization } from "../../../api";

const UpgradePlan = ({ open, close, billingSource }) => {
  const [paymentMethodSetupURL, setPaymentMethodSetupURL] = useState();
  const [message, setMessage] = useState('');

  useEffect(() => {
    open && getPaymentMethodSetupURL();
    console.log("Billing source received: ", billingSource);
  }, [open]);

  const getPaymentMethodSetupURL = async () => {
    if (billingSource != "stripe") {
      return;
    }
    const payload = {};

    const res = await Organization.getPaymentMethod(payload);
    if (!res.error) {
      setPaymentMethodSetupURL(res.data.paymentMethodSetupUrl);
    } else {
      console.error(res.error);
      //TODO: Need to handle this error better way
    }
  };

  const redirectToStripe = () => {
    window.location.href = paymentMethodSetupURL;
  };

  const upgradeAwsSubscribedOrgToPremium = async () => {
    const payload = {
      new_tier: "PRO_TIER",
    };
    const res = await Organization.updateTier(payload);
    setMessage("Successfully upgraded to Pro Tier. You will be billed via AWS consolidated billing.")
  };

  let messageUi = (<h5>{message}</h5>);
  let billingSetupUi = <></>;
  if (billingSource == "aws") {
    billingSetupUi = (<Button variant="contained" onClick={upgradeAwsSubscribedOrgToPremium}>
      Upgrade To Premium (AWS Consolidated Billing)
    </Button>)
  } else {
    billingSetupUi = (<Button type="primary" style={{ backgroundColor: 'var(--cta-color)' }} onClick={redirectToStripe}>
      Upgrade
    </Button>)
  }
  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Typography.Title level={5}>Upgrade to Premium</Typography.Title>
        </div>
      }
      visible={open}
      onCancel={close}
      footer={null}
      width={600}
    >
      <Row gutter={[16, 16]}>
        {/* Upgrade Features */}
        <Col xs={24}>
          <Card>
            <List
              bordered
              dataSource={[
                'Unlimited Projects',
                '1000 Test Runs per Seat / Month',
                '1000 Session Record Minutes per Seat / Month',
                'Use Forever',
                'TestChimp Customer Support',
              ]}
              renderItem={(item) => (
                <>
                  <List.Item>{item}</List.Item>
                  <Divider style={{ margin: 0 }} />
                </>
              )}
            />
          </Card>
        </Col>
      </Row>

      {/* Action Buttons */}
      <Row gutter={[16, 16]} justify="end" style={{ marginTop: '16px' }}>
        <Col>
          {billingSetupUi}
        </Col>
        <Col>
          <Button onClick={close}>Cancel</Button>
        </Col>
      </Row>

      {/* Message Section */}
      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        <Col>{messageUi}</Col>
      </Row>
    </Modal>
  );
};

export default UpgradePlan;
