import React, { Component } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeColors } from '../styles/light-colors';

class ProgressBar extends Component {
    render() {
        const { totalResults, remainingResults } = this.props;

        // Calculate the progress percentage
        let progress = 0;
        if (totalResults == 0) {
            progress = 0;
        } else {
            progress = ((totalResults - remainingResults) / totalResults) * 100;
        }
        console.log("progress " + progress);
        // Determine whether to show the loading bar or hide it when the progress is 100%
        const showLoadingBar = progress < 100;

        return (
            <div style={{ width: '100%', height: '8px', marginBottom: "0px" }}>
                {showLoadingBar && (
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{
                            width: '100%', // Make it full-width
                            height: '100%', // Set the height of the progress bar
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: ThemeColors.progress, // Set the progress bar color to green
                            },
                        }}
                    />
                )}
            </div>
        );
    }
}

export default ProgressBar;
