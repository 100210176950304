import React, { useState, useEffect } from "react";
import { Select, Button, Table, Dropdown, Menu, Tooltip, Switch, Spin, Modal, message, Divider } from "antd";
import { getProjects } from "../api/services/User";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Paper, Chip } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import theme from "../common/theme";
import "../styles/AwareTestViewer.css";
import { formatTimestamp } from "../util/Timestamps";
import Test from "../api/services/Test";

import {
  AwareTestType,
  IInvocationDetail,
  IListTestCasesWithRunDetailsRequest,
  ITestCaseWithRunData,
  ITestInvocationResult,
  ITestName,
  ITestNameWithId,
  IUpdateTestCaseStatusRequest,
  ParameterizedTestRunData,
  TestAssertionEvaluation,
  TestCaseStatus,
  TestCreationSource,
  TestExecutionState,
  TestParameterizeStatus,
} from "../test-studio/models";
import SessionStorageManager from "../common/SessionStorageManager";
import { generateUniqueID } from "../util";
import TestRunDropdown from "./components/TestRunDropDown";
import { listEnvironments, setSelectedProject } from "../features/commonSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { MenuProps } from "antd/lib";
import { deselectAllTests, listParameterizedTestRuns, listTestCasesWithRunDetails, listTestSuites, runTests, selectAllTests, selectedTestIds, setIsFirstTestCreationFlow, setSelectedSuite, toggleTestSelection, updateTestCaseStatus } from "../features/testListSlice";
import { useSearchParams } from "react-router-dom";
import QuickStartWizard from "./FirstTestWizard";

const { Option } = Select;
const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

interface Project {
  id: string;
  name: string;
}

const TestCaseFinder: React.FC = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.testList);
  const testSuites = useAppSelector((state) => state.testList.testSuites);
  const selectedProject = useAppSelector((state) => state.common.selectedProject);
  const selectedTests = useAppSelector((state) => state.testList.selectedTests);
  const selectedSuite = useAppSelector((state) => state.testList.selectedSuite);
  const testCases = useAppSelector((state) => state.testList.testCases);
  const isFirstTestCreationFlow = useAppSelector((state) => state.testList.isFirstTestCreationFlow);
  const executionStates = useAppSelector((state) => state.testList.executionStates);
  const parameterizedRows = useAppSelector((state) => state.testList.parameterizedRows);
  const loadingRows = useAppSelector((state) => state.testList.loadingRows);
  const visibleExecutionStates = useAppSelector((state) => state.testList.visibleExecutionStates);
  const isLoading = useAppSelector((state) => state.testList.isLoading);
  const selectedTestIdsInSlice = useAppSelector(selectedTestIds);
  const [showConfetti, setShowConfetti] = useState<boolean>();
  const [searchParamSuite, setSearchParamSuite] = useState<number | undefined>();

  const environments = useAppSelector((state) => state.common.environments);
  const [projects, setProjects] = useState<Project[]>([]);
  const [searchParams] = useSearchParams();
  const [contextRequest, setContextRequest] = useState<IListTestCasesWithRunDetailsRequest | undefined>();

  const handleRunSelectedMenuClick: MenuProps['onClick'] = (e) => {
    dispatch(runTests({ testCases: selectedTests, environment: e.key }));
  };



  const handleExpand = async (expanded: boolean, record: ITestCaseWithRunData) => {
    dispatch(listParameterizedTestRuns(record.testCase.testId));
  };

  const onCompleteQuickStartWizard = async () => {
    dispatch(setIsFirstTestCreationFlow(true));
    await handleSearch();
  };

  useEffect(() => {
    if (searchParamSuite && testSuites?.length > 0) {
      console.log("Search param suite: ", searchParamSuite);
      console.log("test suites:", testSuites);
      // Find the suite whose id matches searchParamSuite
      const selectedSuite = testSuites.find(suite => suite.id == searchParamSuite);
      console.log("Selected suite: ", selectedSuite);
      if (selectedSuite) {
        dispatch(setSelectedSuite(selectedSuite.name)); // Dispatch the action with the suite name
        handleSearchBySuite(selectedSuite.name);
        setSearchParamSuite(undefined);
      }
    }
  }, [searchParamSuite, testSuites, dispatch]);

  useEffect(() => {
    const contextUid: string | null = searchParams.get("uid");
    const projectId: string | null = searchParams.get("projectId");
    const suitId: string | null = searchParams.get("suite");
    if (contextUid) {
      if (projectId) {
        dispatch(setSelectedProject(projectId));
      }
      let contextRequest: IListTestCasesWithRunDetailsRequest | undefined = SessionStorageManager.getTestFindContext(contextUid);
      if (contextRequest) {
        setContextRequest(contextRequest);
        handleSearchByContext(contextRequest);
      }
    }
    if (suitId) {
      const parsedSuiteId = Number(suitId); // Convert suitId to number
      if (!isNaN(parsedSuiteId)) {
        setSearchParamSuite(parsedSuiteId); // Update state only if parsed successfully
      }
    }
    const fetchProjects = async () => {
      try {
        const response = await getProjects.getProjects();
        setProjects(response.data.projects);
        if (response.data.projects) {
          if (projectId) {
            handleProjectChange(projectId);
          } else {
            handleProjectChange(response.data.projects[0].id);
          }
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, [searchParams]);

  useEffect(() => {
    if (testCases.length > 0) {
      dispatch(setIsFirstTestCreationFlow(false));
    }
    if (isFirstTestCreationFlow && testCases.length == 1) {
      message.success("Congrats on creating your first test!");
    }
  }, [testCases]);

  const handleProjectChange = async (projectId: string) => {
    await dispatch(setSelectedProject(projectId));
    await dispatch(listTestSuites());
    await dispatch(listEnvironments());
  };

  const handleSuiteChange = (suite: string) => {
    dispatch(setSelectedSuite(suite));
  };

  const handleSearch = async () => {
    dispatch(listTestCasesWithRunDetails({ testSuite: selectedSuite }));
  };

  const handleCreate = () => {
    const uid = generateUniqueID();
    SessionStorageManager.setTestStudioSteps(uid, []);
    console.log("Selected projectL: ", selectedProject);
    window.open("/test-studio?uid=" + uid + (selectedProject ? "&project_id=" + selectedProject : ""), "_blank");
  };

  const handleSearchBySuite = async (suite: string) => {
    dispatch(listTestCasesWithRunDetails({ testSuite: suite }));
  }

  const handleSearchByContext = async (contextRequest: IListTestCasesWithRunDetailsRequest) => {
    dispatch(listTestCasesWithRunDetails(contextRequest!));
  }
  const handleSelectAll = () => {
    dispatch(selectAllTests());
  };

  const handleDeselectAll = () => {
    dispatch(deselectAllTests());
  };

  const handleCheckboxChange = (test: ITestNameWithId) => {
    // If selectedTestIds doesnt contain the testId, 
    dispatch(toggleTestSelection(test));
  };

  const getExecutionStateString = (result: ITestInvocationResult) => {
    if (!result) {
      return "";
    }

    const state = result.state;
    const invocationId = result.invocationId;

    const hasErrors =
      result.result?.awareApiTestExecutionResult?.assertionResults?.some(
        (assertion) => assertion.evaluation === TestAssertionEvaluation.FAILED
      ) || false;

    switch (state) {
      case TestExecutionState.ENQUEUED_TO_EXECUTE:
        return <div style={{ color: "gray" }}>Enqueued</div>;
      case TestExecutionState.SKIPPED_EXECUTION:
        return <div style={{ color: "gray" }}>Skipped</div>;
      case TestExecutionState.EXECUTION_IN_PROGRESS:
        return <div style={{ color: "#DAA520" }}>Running</div>;
      case TestExecutionState.EXECUTION_COMPLETED:
        return (
          <div>
            <a
              target="_blank"
              href={`${BASE_APP_URL}/test-execution?invocation_id=${invocationId}&project_id=${selectedProject}`}
              style={{
                textDecoration: "underline",
                color: hasErrors ? "red" : "green",
              }}
            >
              Completed
            </a>
          </div>
        );
      case TestExecutionState.EXCEPTION_IN_TEST_RUN:
        return <div style={{ color: "red" }}>Error in run</div>;
      default:
        return <div style={{ color: "gray" }}>Loading...</div>;
    }
  };

  const getExecutionColor = (invocation: IInvocationDetail): string => {
    if (
      invocation.failureAssertions > 0 ||
      invocation.state == TestExecutionState.EXCEPTION_IN_TEST_RUN
    ) {
      return "red";
    }
    if (
      invocation.state == TestExecutionState.ENQUEUED_TO_EXECUTE ||
      invocation.state == TestExecutionState.UNKNOWN_TEST_EXECUTION_STATE
    ) {
      return "gray";
    }
    if (invocation.state == TestExecutionState.EXECUTION_IN_PROGRESS) {
      return "yellow";
    }
    return "green";
  };

  const editTest = async (record: ITestCaseWithRunData) => {
    if (record.testCase.testId) {
      window.open("/test-studio?test_id=" + record.testCase.testId + "&project_id=" + selectedProject, "_blank");
    } else {
      const testName: ITestName = {
        name: record.testCase.name,
        suite: record.testCase.suite,
      };
      try {
        const response = await Test.getAwareApiTest({ testName });
        const { testConfig } = response;
        const uid = generateUniqueID();
        SessionStorageManager.setTestStudioTest(uid, testConfig);
        window.open("/test-studio?uid=" + uid + "&project_id=" + selectedProject, "_blank");
      } catch (error) {
        console.error("Error fetching aware API test:", error);
      }
    }
  };

  const { confirm } = Modal;

  const deleteTest = async (record: ITestCaseWithRunData) => {
    if (record.testCase.testId) {
      confirm({
        title: 'Are you sure you want to delete this test case?',
        content: 'This action cannot be undone.',
        okText: 'Yes',
        cancelText: 'No',
        onOk: async () => {
          try {
            let req: IUpdateTestCaseStatusRequest = {
              testIds: [record.testCase.testId],
              testType: AwareTestType.FS_TEST,
              newStatus: TestCaseStatus.DELETED
            };
            dispatch(updateTestCaseStatus({ request: req }));
            message.success('Test case deleted successfully');
          } catch (error) {
            message.error('Failed to delete test case');
          }
        },
        onCancel() {
          console.log('Deletion cancelled');
        },
      });
    }
  };

  const renderExecutionHistory = (invocations: IInvocationDetail[]) => {
    if (!invocations) {
      return <>Not Executed Yet.</>;
    }
    return (
      <>
        {invocations?.map((invocation, index) => (
          <Tooltip
            key={index}
            title={
              <div
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  {formatTimestamp(invocation.timestamp)}
                </div>
                <div
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >{`${invocation.successAssertions} / ${invocation.successAssertions + invocation.failureAssertions
                  } assertions passed`}</div>
                <div style={{ marginBottom: "5px", textAlign: "right" }}>
                  <a
                    href={`/test-execution?invocation_id=${invocation.invocationId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Execution
                  </a>
                </div>
              </div>
            }
            overlayInnerStyle={{ backgroundColor: "white", color: "black" }}
          >
            <div
              style={{
                display: "inline-block",
                marginRight: "4px",
                width: "12px",
                height: "12px",
                backgroundColor: getExecutionColor(invocation),
                cursor: "pointer",
              }}
            />
          </Tooltip>
        ))}
      </>
    );
  };

  const handleStatusChange = async (testCase: ITestNameWithId, newStatus: boolean) => {
    const status = newStatus ? TestCaseStatus.ENABLED : TestCaseStatus.DISABLED;
    try {
      let req: IUpdateTestCaseStatusRequest = {
        testIds: [testCase.testId],
        testType: AwareTestType.FS_TEST,
        newStatus: newStatus ? TestCaseStatus.ENABLED : TestCaseStatus.DISABLED,
      };
      dispatch(updateTestCaseStatus({ request: req }));
    } catch (error) {
      console.error("Error updating test case status:", error);
    }
  };

  const columns = [
    {
      title: (
        <input
          type="checkbox"
          onChange={(e) =>
            e.target.checked ? handleSelectAll() : handleDeselectAll()
          }
          checked={selectedTestIdsInSlice.length === testCases.length}
        />
      ),
      dataIndex: "checkbox",
      key: "checkbox",
      width: 50, // Set a fixed width for consistency
      render: (text: string, record: ITestCaseWithRunData) => (
        <input
          type="checkbox"
          checked={selectedTestIdsInSlice.includes(record.testCase.testId)}
          onChange={() => handleCheckboxChange(record.testCase)}
        />
      ),
    },
    {
      title: "Test Case Name",
      dataIndex: ["testCase", "name"],
      key: "name",
      width: "calc(100%-770px)", // Adjust as needed
    },
    {
      title: "Test Suite",
      dataIndex: ["testCase", "suite"],
      key: "suite",
      width: 150, // Adjust as needed
    },
    {
      title: "Run",
      key: "run",
      width: 100, // Set width for consistency
      render: (text: string, record: ITestCaseWithRunData) => {
        const testKey = `${record.testCase.suite}#${record.testCase.name}`;
        const isInProgress =
          executionStates[testKey]?.state === TestExecutionState.ENQUEUED_TO_EXECUTE ||
          executionStates[testKey]?.state === TestExecutionState.EXECUTION_IN_PROGRESS;

        const handleRun = (environment: string) => {
          dispatch(runTests({ testCases: [record.testCase], environment: environment }));
        };
        return (
          <TestRunDropdown
            environments={environments}
            onRun={handleRun}
            isInProgress={isInProgress}
            isEnabled={record.status === TestCaseStatus.ENABLED}
          />
        );
      },
    },
    {
      title: "Run State",
      key: "runState",
      width: 120, // Set width for consistency
      render: (text: string, record: ITestCaseWithRunData) => {
        const testKey = `${record.testCase.suite}#${record.testCase.name}`;
        return (
          visibleExecutionStates[testKey] &&
          executionStates[testKey] && (
            <span className="fade-in">
              {record.parameterizeStatus === TestParameterizeStatus.IS_PARAMETERIZED ? "" : getExecutionStateString(executionStates[testKey])}
            </span>
          )
        );
      },
    },
    {
      title: "Enabled",
      key: "status",
      width: 100, // Set width for consistency
      render: (text: string, record: ITestCaseWithRunData) => (
        <Switch
          checked={record.status === TestCaseStatus.ENABLED}
          onChange={(checked) => handleStatusChange(record.testCase, checked)}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 100, // Set width for consistency
      render: (text: string, record: ITestCaseWithRunData) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" onClick={() => editTest(record)}>
                Edit
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => deleteTest(record)}>
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <ArrowDropDownIcon style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
    {
      title: "Execution History",
      key: "executionHistory",
      width: 200, // Adjust width to match your design
      render: (text: string, record: ITestCaseWithRunData) =>
        record.parameterizeStatus === TestParameterizeStatus.IS_PARAMETERIZED
          ? ""
          : renderExecutionHistory(record.latestInvocations),
    },
  ];

  const menu = (
    <Menu onClick={handleRunSelectedMenuClick}>
      {environments?.map((env) => (
        <Menu.Item key={env}>{env}</Menu.Item>
      ))}
    </Menu>
  );

  const expandedRowRender = (record: ITestCaseWithRunData) => {
    const data = parameterizedRows[record.testCase.testId] || [];

    // Define columns for the child table
    const columns = [
      {
        title: "Parameters",
        dataIndex: "paramsString",
        key: "paramsString",
        width: `calc(100% - 420)`, // Adjust according to the fixed widths of Execution History and Run State
        render: (text: string) => (
          <div style={{ width: '100%' }}>
            <span style={{ display: 'inline-block', width: '80px' }}></span>
            <span>{text}</span>
          </div>
        ),
      },
      {
        title: "Run State",
        key: "runState",
        width: 120, // Match the parent table width
        render: (text: string, row: ParameterizedTestRunData) => {
          const testKey = `${record.testCase.suite}#${record.testCase.name}` +
            (row.paramsHash ? `|${row.paramsString}` : "");
          console.log("Test Key:", testKey);
          const executionState = executionStates[testKey];

          if (executionState) {
            return <span>{getExecutionStateString(executionState)}</span>;
          } else {
            return <span></span>; // Handle the case when no state is available
          }
        },
      },
      {
        title: "", // Empty column to align with parent table
        key: "empty",
        width: 200, // Adjust width to match parent table column widths
        render: () => <div />, // Empty content
      },
      {
        title: "Execution History",
        key: "executionHistory",
        width: 200, // Adjust width to match parent table column widths
        render: (text: string, row: ParameterizedTestRunData) => {
          // Use the latestInvocations to render the execution history
          return renderExecutionHistory(row.latestInvocations);
        },
      },
    ];

    return (
      (data && data.length > 0) ?
        <Table
          columns={columns}
          dataSource={data?.map((paramTest) => ({
            key: `${record.testCase.name}#${paramTest.paramsHash}`,
            ...paramTest,
          }))}
          pagination={false}
          showHeader={false} // Optional: Hide header for child table if desired
        />
        : null
    );
  };

  const getChipText = (req: IListTestCasesWithRunDetailsRequest): string => {
    let text: string = "";
    const maxLength = 200;

    try {
      if (req?.query) {
        if (req.query.testCreationSource == TestCreationSource.AI_GENERATED) {
          return "Generated tests for operation";
        } else {
          return "All tests that includes the operation";
        }
      }
    } catch (error) {
      return "External Context";
    }
    return "External Context";
  };


  return (
    <div className="top-container fade-in">
      <ThemeProvider theme={theme}>
        <div id="test-filter-panel">
          {contextRequest ? (
            <div className="flex-row">
              <Chip onDelete={() => setContextRequest(undefined)} label={getChipText(contextRequest)} sx={{ backgroundColor: "var(--secondary-cta-color)", color: "var(--dark-color)" }} />
            </div>) : (<div className="flex-row">
              <Select
                style={{ flexGrow: 1, marginRight: "10px" }}
                placeholder="Select Project"
                onChange={handleProjectChange}
                value={selectedProject}
              >
                {projects?.map((project) => (
                  <Option key={project.id} value={project.id}>
                    {project.name}
                  </Option>
                ))}
              </Select>
              <Select
                style={{ flexGrow: 1, marginRight: "10px" }}
                placeholder="Select Test Suite"
                onChange={handleSuiteChange}
                value={selectedSuite}
              >
                {testSuites?.map((suite) => (
                  <Option key={suite.id} value={suite.name}>
                    {suite.name}
                  </Option>
                ))}
              </Select>
              <Button
                className="primary-button"
                type="primary"
                style={{ width: "120px" }}
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                className="secondary-button"
                type="primary"
                style={{ width: "120px", marginLeft: "10px" }}
                onClick={handleCreate}
              >
                Create Test...
              </Button>
            </div>)}
        </div>
        {testCases.length > 0 && (
          <div id="control-panel">
            <div className="button-group">
              <Button className="secondary-button" onClick={handleSelectAll}>Select All</Button>
              <Button className="secondary-button" onClick={handleDeselectAll} style={{ marginLeft: "5px" }}>
                Deselect All
              </Button>
            </div>
            <Dropdown overlay={menu} trigger={['click']} disabled={selectedTests.length == 0}>
              <Button
                type="primary"
                className="secondary-button"
                style={{ width: '120px' }}
                disabled={selectedTests.length == 0}
              >
                Run Selected
              </Button>
            </Dropdown>

          </div>
        )}
        <Paper id="testcase-container">
          {projects && projects.length > 0 ? (
            testCases && testCases.length > 0 ? (
              <Table
                columns={columns}
                dataSource={testCases}
                rowKey={(record) => record.testCase.name}
                pagination={false}
                loading={isLoading}
                expandable={{
                  expandedRowRender,
                  rowExpandable: record => record.parameterizeStatus === TestParameterizeStatus.IS_PARAMETERIZED,
                  onExpand: (expanded, record) => {
                    handleExpand(expanded, record);
                  },
                }}
                locale={{ emptyText: "No results found" }} // Set the empty text message
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100vh - 250px)",
                  width: "100%",
                  overflow: "auto",
                  flexDirection: "column",
                }}
              >
                <QuickStartWizard onComplete={onCompleteQuickStartWizard}></QuickStartWizard>
              </div>
            )
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
                width: "100%",
              }}
            >
              <h3 style={{ color: "var(--dark-color)" }}>
                Create a Project via Admin Settings -&gt; Add Project first
              </h3>
            </div>
          )}
        </Paper>
      </ThemeProvider>
    </div>
  );
};

export default TestCaseFinder;
