import { Typography } from "@mui/material";
import React, { Component } from "react";
import NamedPanel from "../common/NamedPanel";
import { ThemeColors } from "../styles/light-colors";
import ExemplarChooser from "./ExemplarChooser";
import SpanAttribSummaryViewer from "./SpanAttribSummaryViewer";

class RawDataTab extends Component {
  constructor(props) {
    super(props);
    this.state = { context: this.props.context };
  }

  render() {
    const { attribMap, resourceAttribMap, exemplars, context } = this.props;
    let content;
    if (attribMap?.length === 0) {
      content = (<>
        <div style={{ color: ThemeColors.secondaryBackground }}>
          <NamedPanel label={<Typography variant="body2">Node Attribute Summaries</Typography>}>
            <SpanAttribSummaryViewer context={context} data={[]} />
          </NamedPanel>
        </div>
        <div style={{ backgroundColor: ThemeColors.secondaryBackground }}>
          <NamedPanel label={<Typography variant="body2">Resource Attribute Summaries</Typography>}>
            <SpanAttribSummaryViewer key={context} context={context} data={[]} />
          </NamedPanel>
        </div></>);
    } else {
      content = (
        <div style={{ backgroundColor: ThemeColors.secondaryBackground }}>
          <div style={{ color: ThemeColors.background }}>
            <NamedPanel label={<Typography variant="body2">Node Attribute Summaries</Typography>}>
              <SpanAttribSummaryViewer context={context} data={attribMap} />
            </NamedPanel>
          </div>
          <div style={{ backgroundColor: ThemeColors.secondaryBackground }}>
            <NamedPanel label={<Typography variant="body2">Resource Attribute Summaries</Typography>}>
              <SpanAttribSummaryViewer key={context} context={context} data={resourceAttribMap} />
            </NamedPanel>
          </div>
          <div style={{ backgroundColor: ThemeColors.secondaryBackground }}>
            <NamedPanel label={<Typography variant="body2">Exemplars</Typography>} style={{ margin: "40px" }}>
              <ExemplarChooser collection="view" exemplars={context.viewExemplars} context={context} />
            </NamedPanel>
          </div>
        </div>
      )
    }
    return (
      <div style={{ backgroundColor: ThemeColors.secondaryBackground }}>
        {content}
      </div>
    );
  }
}

export default RawDataTab;