import client from "../client";

const Organization = {
  getOrganizationDetails: async () => {
    try {
      return await client.post("admin/get_organization_details", {});
    } catch (error) {
      console.error(
        `Error calling Organization.getOrganizationDetails`,
        error
      );
      return { error: error };
    }
  },
  getUsers: async () => {
    try {
      return await client.post("admin/list_users_for_org", {});
    } catch (e) {
      console.error(`Error calling Organization.list_users_for_org`, e);
      return { error: e };
    }
  },
  listEventLogs: async (paginationToken, pageSize) => {
    try {
      let payload = { "pagination_request": { "pagination_token": paginationToken, "page_size": pageSize } }
      return await client.post("admin/list_event_log", payload);
    } catch (e) {
      console.error(`Error calling Organization.list_event_log`, e);
      return { error: e };
    }
  },
  removeUser: async (user) => {
    try {
      console.log(user);
      return await client.post("admin/delete_user", user);
    } catch (e) {
      console.error(`Error calling Organization.removeUser(${user})`, e);
      return { error: e };
    }
  },
  updateOrgCapacity: async (capacity) => {
    try {
      console.log(capacity);
      return await client.post("capacity/update_org_capacity", capacity);
    } catch (e) {
      console.error(
        `Error calling Organization.updateOrgCapacity(${capacity})`,
        e
      );
      return { error: e };
    }
  },
  updateProjectCapacities: async (capacities) => {
    try {
      console.log(capacities);
      return await client.post(
        "capacity/update_project_capacities_of_org",
        capacities
      );
    } catch (e) {
      console.error(
        `Error calling Organization.updateProjectCapacities(${capacities})`,
        e
      );
      return { error: e };
    }
  },
  getPaymentMethod: async () => {
    try {
      return await client.post(
        "admin/get_payment_method_setup_url"

      );
    } catch (e) {
      console.error(
        `Error calling Organization.getPaymentMethod`,
        e
      );
      return { error: e };
    }
  },
  updateTier: async (tier) => {
    try {
      console.log(tier);
      return await client.post("admin/update_organization_tier", tier);
    } catch (e) {
      console.error(`Error calling Organization.updateTier(${tier})`, e);
      return { error: e };
    }
  },
};

export default Organization;
