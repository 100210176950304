export type Timestamp = {
  seconds: number;
  nanos: number;
};

export type FixedWindow = {
  startTime: Timestamp;
  endTime: Timestamp;
};

export type TimeWindow = {
  relativeWindow?: string;
  fixedWindow?: FixedWindow;
};

export type QualifiedOperation = {
  resourceName: string;
  operationName: string;
};

export type QualifiedOperationWithStatus = {
 operation:QualifiedOperation;
 status:string;
};

export type PaginationRequest = {
  pageSize: number;
  paginationToken?: Uint8Array | string;
};

export type PaginationResult = {
  state: string;
  paginationToken?: Uint8Array | string;
  remainingResultCount: number;
};

export const qualifiedOperationFromString = (
  str: string
): QualifiedOperation => {
  // Assuming the string format is "(resourceName, operationName)"
  const cleanedStr = str.substring(1, str.length - 1); // Remove the parentheses
  const [resourceName, operationName] = cleanedStr
    .split(",")
    .map((field) => field.trim());
  return { resourceName, operationName };
};

export const toPathFormatString = (op: QualifiedOperation): string => {
  return op.resourceName + "/" + op.operationName;
};

export const getEntryFromPathPrefix = (prefix: string): QualifiedOperation => {
  if (!prefix) {
    return { resourceName: "", operationName: "" };
  }
  const prefixParts: string[] = prefix.split("|");
  if (prefixParts.length > 0) {
    const operationStr: string = prefixParts[0];
    // Assuming QualifiedOperationName.fromString and toQualifiedOperation functions return the correct object type
    return qualifiedOperationFromString(operationStr);
  }
  return { resourceName: "", operationName: "" };
};

export const getOperationFromPathPrefix = (
  prefix: string
): QualifiedOperation => {
  const prefixParts: string[] = prefix.split("|");
  const lastPartIndex: number = prefixParts.length - 1;
  if (lastPartIndex >= 0) {
    const operationStr: string = prefixParts[lastPartIndex];
    // Assuming QualifiedOperationName.fromString and toQualifiedOperation functions return the correct object type
    return qualifiedOperationFromString(operationStr);
  }
  return { resourceName: "", operationName: "" };
};

export const getCurrentTimestamp = (): Timestamp => {
  const currentTime = new Date();
  const seconds = Math.floor(currentTime.getTime() / 1000); // Get seconds
  const nanos = currentTime.getMilliseconds() * 1000000; // Get nanoseconds

  return {
    seconds,
    nanos,
  };
};
