import React, { useState, useEffect } from "react";
import {
    Table,
    Input,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    MenuItem,
    ThemeProvider,
    TextField
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { message } from "antd";
import Scenarios, { Priority, TestScenario, TestScenarioStatus, UserStoryWithTestScenarios, SuggestTestScenariosRequest } from "../api/services/Scenarios"; // Adjust import as needed
import theme from "../common/theme";
import '../styles/ManageTestScenariosDialog.css'; // Import CSS

interface ManageTestScenariosDialogProps {
    open: boolean;
    onClose: () => void;
    userStoryWithScenarios: UserStoryWithTestScenarios | null;
    handleSaveOfScenarios: (updatedScenarios: TestScenario[], deletedScenarios: number[]) => void;
}

const ManageTestScenariosDialog: React.FC<ManageTestScenariosDialogProps> = ({
    open,
    onClose,
    userStoryWithScenarios,
    handleSaveOfScenarios,
}) => {
    const [scenarios, setScenarios] = useState<TestScenario[]>([]);
    const [newScenarios, setNewScenarios] = useState<TestScenario[]>([]);
    const [deletedScenarios, setDeletedScenarios] = useState<number[]>([]);
    const [userStoryId, setUserStoryId] = useState<number>(0);

    useEffect(() => {
        if (userStoryWithScenarios) {
            const updatedScenarios = userStoryWithScenarios.testScenarios
                .filter(scenario => scenario.status !== TestScenarioStatus.DELETED_TEST_SCENARIO)
                .map(scenario => ({ ...scenario, userStoryId: userStoryWithScenarios.userStory.id! }));

            setScenarios(updatedScenarios);
            setUserStoryId(userStoryWithScenarios.userStory.id!);
            if(updatedScenarios.length==0){
                handleAddNewRow();
            }
        }
    }, [userStoryWithScenarios]);

    const handleInputChange = (index: number, field: keyof TestScenario, value: any, isNewScenario: boolean) => {
        if (isNewScenario) {
            const updatedNewScenarios = [...newScenarios];
            updatedNewScenarios[index] = { ...updatedNewScenarios[index], [field]: value };
            setNewScenarios(updatedNewScenarios);
        } else {
            const updatedScenarios = [...scenarios];
            updatedScenarios[index] = { ...updatedScenarios[index], [field]: value };
            setScenarios(updatedScenarios);
        }
    };

    const handleAddNewRow = () => {
        setNewScenarios([...newScenarios, { title: '', description: '', priority: Priority.P1, status: TestScenarioStatus.ACTIVE_TEST_SCENARIO, userStoryId }]);
    };

    const handleDeleteRow = (index: number, scenarioId: number | undefined, isNewScenario: boolean) => {
        if (isNewScenario) {
            const updatedNewScenarios = newScenarios.filter((_, i) => i !== index);
            setNewScenarios(updatedNewScenarios);
        } else {
            const updatedScenarios = [...scenarios];
            if (scenarioId !== undefined) {
                setDeletedScenarios([...deletedScenarios, scenarioId]);
            }
            updatedScenarios[index].status = TestScenarioStatus.DELETED_TEST_SCENARIO;
            setScenarios(updatedScenarios);
        }
    };

    const handleSave = () => {
        if (newScenarios.some(scenario => !scenario.title || !scenario.priority)) {
            message.error("All fields must be filled out.");
            return;
        }

        const combinedScenarios = [...scenarios, ...newScenarios];
        handleSaveOfScenarios(combinedScenarios, deletedScenarios);
        clear();
    };

    const handleClose = () => {
        clear();
    };

    const clear = () => {
        setScenarios([]);
        setNewScenarios([]);
        setDeletedScenarios([]);
        setUserStoryId(0);
        onClose();
    };

    const handleSuggestScenarios = async () => {
        try {
            const request: SuggestTestScenariosRequest = {
                userStoryId: userStoryId,
                newScenarios: newScenarios
            };

            const response = await Scenarios.suggestTestScenarios(request);

            if (response?.suggestedTestScenarios) {
                const filteredScenarios = newScenarios.filter(scenario => scenario.title && scenario.title.trim() !== "");
                const updatedNewScenarios = [...filteredScenarios, ...response.suggestedTestScenarios];
                setNewScenarios(updatedNewScenarios);
                message.success("Suggested scenarios added successfully.");
            } else {
                message.error("Failed to fetch suggested scenarios.");
            }
        } catch (error) {
            message.error("An error occurred while fetching suggested scenarios.");
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" className="manage-test-scenarios-dialog">
                <DialogTitle className="manage-test-scenarios-dialog-title">Manage Test Scenarios</DialogTitle>
                <DialogContent>
                    <div style={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}>
                        <Table className="test-scenarios-table">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th className="fixed-width">Priority</th>
                                    <th className="fixed-width">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scenarios.filter(scenario => scenario.status !== TestScenarioStatus.DELETED_TEST_SCENARIO).map((scenario, index) => (
                                    <tr key={scenario.id}>
                                        <td>
                                            <TextField
                                                value={scenario.title}
                                                onChange={(e) => handleInputChange(index, 'title', e.target.value, false)}
                                                required
                                                fullWidth
                                                variant="standard"
                                                className="editable-input full-width-input"
                                                InputProps={{
                                                    disableUnderline: true, // Removes underline
                                                  }}
                                            />
                                        </td>
                                        <td className="fixed-width">
                                            <Select
                                                value={scenario.priority}
                                                onChange={(e) => handleInputChange(index, 'priority', e.target.value, false)}
                                                className="priority-select small-height-select"
                                            >
                                                <MenuItem value={Priority.P0}>P0</MenuItem>
                                                <MenuItem value={Priority.P1}>P1</MenuItem>
                                                <MenuItem value={Priority.P2}>P2</MenuItem>
                                                <MenuItem value={Priority.P3}>P3</MenuItem>
                                            </Select>
                                        </td>
                                        <td className="fixed-width">
                                            <Button onClick={() => handleDeleteRow(index, scenario.id, false)} className="centered-button">
                                                <DeleteIcon className="delete-icon" />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                                {newScenarios?.map((scenario, index) => (
                                    <tr key={`new-${index}`}>
                                        <td>
                                            <Input
                                                value={scenario.title}
                                                onChange={(e) => handleInputChange(index, 'title', e.target.value, true)}
                                                required
                                                className="editable-input full-width-input"
                                            />
                                        </td>
                                        <td className="fixed-width">
                                            <Select
                                                value={scenario.priority}
                                                onChange={(e) => handleInputChange(index, 'priority', e.target.value, true)}
                                                className="priority-select small-height-select"
                                            >
                                                <MenuItem value={Priority.P0}>P0</MenuItem>
                                                <MenuItem value={Priority.P1}>P1</MenuItem>
                                                <MenuItem value={Priority.P2}>P2</MenuItem>
                                                <MenuItem value={Priority.P3}>P3</MenuItem>
                                            </Select>
                                        </td>
                                        <td className="fixed-width">
                                            <Button onClick={() => handleDeleteRow(index, undefined, true)} className="centered-button">
                                                <DeleteIcon className="delete-icon" />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <div>
                            <Button onClick={handleAddNewRow} variant="contained" sx={{ color: "var(--dark-color)", backgroundColor: "var(--secondary-cta-color)", marginRight: "10px" }}>
                                Add Scenario
                            </Button>
                            <Button onClick={handleSuggestScenarios} variant="contained" sx={{ backgroundColor: "var(--cta-color)" }}>
                                Suggest Scenarios...
                            </Button>
                        </div>
                        <div>
                            <Button onClick={handleSave} color="primary">
                                Save
                            </Button>
                            <Button onClick={handleClose} sx={{ color: "var(--dark-color)" }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
};

export default ManageTestScenariosDialog;