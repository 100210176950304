import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import styled from "styled-components";

interface ITestRunDropdownProps {
  environments: string[];
  onRun: (environment: string) => void;
  isInProgress: boolean;
  isEnabled:boolean;
}
const PlayIcon = styled(PlayCircleOutlineIcon)`
  &:hover {
    color: var(--cta-color);
  }
`;

const TestRunDropdown: React.FC<ITestRunDropdownProps> = ({
  environments,
  onRun,
  isInProgress,
  isEnabled
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  const handleMenuClick = (info: MenuInfo) => {
    onRun(info.key as string);
    setIsMenuVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {environments?.map(environment => (
        <Menu.Item key={environment}>{environment}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      visible={isMenuVisible}
      onVisibleChange={setIsMenuVisible}
    >
      <PlayIcon 
        onClick={(e) => {
          if (!isInProgress && isEnabled) {
            setIsMenuVisible(true);
          }
        }}
        style={{
          cursor: isInProgress || !isEnabled ? 'not-allowed' : 'pointer',
          fontSize: '24px'
        }}
      />
    </Dropdown>
  );
};

export default TestRunDropdown;