import { FC, useEffect, useState } from "react";
import { IDecoratedFieldUpdateRule } from "../models";
import { Checkbox, Flex, Input, Mentions, Select, Typography } from "antd";
import { FieldDataType, GeneratorType, HTTPMessageSection } from "../../test-studio/models";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateExistingRule } from "../../features/testGenerationSlice";
import { OptionProps } from "rc-mentions/lib/Option";

const Rule: FC<{ rule: IDecoratedFieldUpdateRule; index: number }> = ({ rule, index }) => {
  const dispatch = useAppDispatch();
  const { Text } = Typography;
  const { section, path, replaceConfig } = rule;
  const sectionOptions = Object.keys(HTTPMessageSection).map((key) => ({
    label: key,
    value: key,
  }));

  const [config, setConfig] = useState({ type: GeneratorType.FREEFORM, expression: "" });
  const variables = useAppSelector((state) => state.testGeneration.variableRecordConfigs);

  const onUpdateExistingRule = (rule: IDecoratedFieldUpdateRule) => {
    dispatch(updateExistingRule({ index, rule }));
  };

  useEffect(() => {
    if (replaceConfig.variableExpression) {
      setConfig({ type: GeneratorType.FREEFORM, expression: replaceConfig.variableExpression.expression });
    } else if (replaceConfig.scriptConfig) {
      setConfig({ type: GeneratorType.SCRIPT, expression: `@script(${replaceConfig.scriptConfig.nickname})` });
    } else if (replaceConfig.vocabConfig) {
      setConfig({ type: GeneratorType.VOCABULORY, expression: `@vocab(${replaceConfig.vocabConfig.nickname})` });
    }
  }, [replaceConfig]);

  return (
    <Flex
      //style={{ marginBottom: 8 }}
      align="center"
      gap={8}
      key={index}
      flex={1}
      onClick={(e) => e.stopPropagation()}
    >
      <Checkbox
        key={index}
        checked={rule.isEnable}
        onChange={(e: CheckboxChangeEvent) => onUpdateExistingRule({ ...rule, isEnable: e.target.checked })}
      />
      <Text style={{ minWidth: "1.6em" }}>Set</Text>
      <Select
        showSearch
        placeholder="Select a section"
        optionFilterProp="label"
        value={section}
        onChange={(section: HTTPMessageSection) => onUpdateExistingRule({ ...rule, section })}
        options={sectionOptions}
      />
      <Input
        placeholder="Enter the path"
        value={path}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onUpdateExistingRule({ ...rule, path: e.target.value })}
      />
      <Text style={{ minWidth: "1.1em" }}>as</Text>
      <Mentions
        readOnly={config.type != GeneratorType.FREEFORM}
        allowClear={config.type != GeneratorType.FREEFORM}
        prefix={"{{"}
        placeholder="Enter the expression"
        value={config.expression}
        onChange={(text: string) =>
          onUpdateExistingRule({
            ...rule,
            replaceConfig: { variableExpression: { expression: text }, fieldType: FieldDataType.STRING },
          })
        }
        // onSelect={(option: OptionProps, prefix: string) =>
        //   onUpdateExistingRule({
        //     ...rule,
        //     replaceConfig: {
        //       variableExpression: {
        //         fieldType: FieldDataType.STRING,
        //         expression: `${config.expression}${option.value}}}`,
        //       },
        //     },
        //   })
        // }
        options={variables?.map((variable) => {
          return {
            key: variable.variableName,
            label: variable.variableName,
            value: `${variable.variableName}}}`,
          };
        })}
      />
    </Flex>
  );
};

export default Rule;
