import { Box, CardContent, Card, Skeleton, Grid } from "@mui/material";
import { IQueryWithMetaData } from "../../models/explore";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

const randomPercentage = () => {
  return `${Math.random() * 100}%`;
};

const SummarySkeleton = () => {
  const queries = useAppSelector((state: RootState) => state.overlays.queries);

  return (
    <>
      <Card>
        <Box marginLeft={1} marginTop={1.5}>
          <Skeleton width={"20%"} sx={{ fontSize: "1.3rem" }} />
        </Box>
        <CardContent>
          <Grid container spacing={3} columns={16}>
            <Grid item xs={8} key={"1"}>
              <Skeleton width={"20%"} sx={{ fontSize: "0.8rem" }} />
              {queries.map((_: IQueryWithMetaData, index: number) => (
                <Skeleton key={index} width={randomPercentage()} />
              ))}
            </Grid>
            <Grid item xs={8} key={"2"}>
              <Skeleton width={"30%"} sx={{ fontSize: "0.8rem" }} />
              {queries.map((_: IQueryWithMetaData, index: number) => (
                <Skeleton key={index} width={randomPercentage()} />
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <Box marginLeft={1} marginTop={1.5}>
          <Skeleton width={"10%"} sx={{ fontSize: "1.3rem" }} />
        </Box>
        <CardContent>
          <Grid container spacing={3} columns={16}>
            <Grid item xs={8} key={"1"}>
              <Skeleton width={"20%"} sx={{ fontSize: "0.8rem" }} />
              {queries.map((_: IQueryWithMetaData, index: number) => (
                <Skeleton key={index} width={randomPercentage()} />
              ))}
            </Grid>
            <Grid item xs={8} key={"2"}>
              <Skeleton width={"30%"} sx={{ fontSize: "0.8rem" }} />
              {queries.map((_: IQueryWithMetaData, index: number) => (
                <Skeleton key={index} width={randomPercentage()} />
              ))}
            </Grid>
            <Grid item xs={8} key={"3"}>
              <Skeleton width={"20%"} sx={{ fontSize: "0.8rem" }} />
              {queries.map((_: IQueryWithMetaData, index: number) => (
                <Skeleton key={index} width={randomPercentage()} />
              ))}
            </Grid>
            <Grid item xs={8} key={"4"}>
              <Skeleton width={"30%"} sx={{ fontSize: "0.8rem" }} />
              {queries.map((_: IQueryWithMetaData, index: number) => (
                <Skeleton key={index} width={randomPercentage()} />
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default SummarySkeleton;
