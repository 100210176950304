import React, { useState, useEffect } from "react";
import { Select, Button, Table } from "antd";
import { Organization } from "../../api";
import { formatTimestamp } from "../../util/Timestamps";

interface EventLog {
  project_id: string;
  user_email: string;
  // Add other fields as needed
  raw_request_payload: string;
  event_type: string;
  status: string;
  failure_reason: string;
  timestamp: string; // You can use a string for the timestamp or define a custom Date type
}

interface ListEventLogsResponse {
  eventLogs: EventLog[];
  paginationResult: { state: string; paginationToken: string };
}

const { Option } = Select;

const AuditLogViewer: React.FC = () => {
  const [eventLogs, setEventLogs] = useState<EventLog[]>([]);
  const [paginationToken, setPaginationToken] = useState<string | undefined>(
    undefined
  );

  const columns = [
    {
      title: "Project",
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: "User Email",
      dataIndex: "userEmail",
      key: "userEmail",
    },
    {
      title: "Event Type",
      dataIndex: "eventType",
      key: "eventType",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Raw Request",
      dataIndex: "rawRequestPayload",
      key: "rawRequestPayload",
      // Set max width and allow text wrapping
      ellipsis: {
        showTitle: false,
      },
      render: (rawRequestPayload: string) => (
        <div
          style={{
            maxWidth: "50em",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
        >
          {rawRequestPayload}
        </div>
      ),
    },
    {
      title: "Failure Reason",
      dataIndex: "failureReason",
      key: "failureReason",
    },
    {
      title: "Event Details",
      dataIndex: "eventDetails",
      key: "eventDetails",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: string) => formatTimestamp(timestamp),
    },
  ];

  const fetchEventLogs = async (
    paginationToken: string | undefined,
    pageSize: number
  ) => {
    try {
      const response = await Organization.listEventLogs(
        paginationToken,
        pageSize
      ); // Replace with your API call
      const res: any = response;
      console.log("Data", res.data);
      if (res.data.paginationResult?.state === "CAN_CONTINUE") {
        setPaginationToken(res.data.paginationResult.paginationToken);
      } else {
        setPaginationToken(undefined);
      }

      setEventLogs([...eventLogs, ...res.data.eventLogs]);
    } catch (error) {
      console.error("Error fetching event logs:", error);
    }
  };

  useEffect(() => {
    fetchEventLogs(undefined, 20); // Initial data fetch
  }, []);

  const loadMore = () => {
    if (paginationToken) {
      fetchEventLogs(paginationToken, 10);
    }
  };

  return (
    <div>
      <Table<EventLog>
        dataSource={eventLogs}
        columns={columns}
        pagination={false} // Disable default pagination
      />
      {paginationToken && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={loadMore} style={{ marginTop: "10px" }}>
            Load More...
          </Button>
        </div>
      )}
    </div>
  );
};

export default AuditLogViewer;
