import React from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { IFilter } from "../../models/overlays";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { BASE_OVERLAY_HIGHLIGHT_COLOR } from "../../util";

interface OverlayItemProps {
  filters: IFilter[] | null | undefined;
  traceColor: string;
  overlayId: string;
  onDeleteOverlay: (overlayId: string) => void;
  onEditOverlay: (overlayId: string) => void;
  isBaseItem: boolean;
}

const OverlayItem: React.FC<OverlayItemProps> = ({
  filters,
  traceColor,
  overlayId,
  onDeleteOverlay,
  onEditOverlay,
  isBaseItem,
}) => {
  const valueConfigs = useAppSelector(
    (state: RootState) => state.overlays.valueConfigs
  );

  const getDisplayValueByKey = (key: string): string | undefined => {
    const matchingValue = valueConfigs?.find((value) => value.key === key);
    return matchingValue ? matchingValue.displayValue : undefined;
  };

  const titles = filters?.map((filter) => {
    return (
      <Box
        key={filter.key}
        display="flex"
        alignItems="center"
        gap={1}
        bgcolor="#f2f2f8"
        paddingX={0.5}
        borderRadius={2}
      >
        <Typography>{filter.key}</Typography>
        <Typography fontWeight={600} fontSize={13}>
          {filter.operator}
        </Typography>
        <Typography>{filter.value}</Typography>
      </Box>
    );
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingX={2}
      paddingBottom={0.5}
      borderRadius={2}
      bgcolor={isBaseItem ? BASE_OVERLAY_HIGHLIGHT_COLOR : "inherit"}
    >
      <Box display="flex" gap={1} width="75%" flexWrap="wrap">
        {titles}
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Box bgcolor={traceColor} height={15} width={40}></Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <IconButton onClick={() => onEditOverlay(overlayId)}>
          <Edit />
        </IconButton>
        <IconButton
          disabled={isBaseItem}
          onClick={() => onDeleteOverlay(overlayId)}
        >
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
};

export default OverlayItem;
