import React from "react";
import { Box, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useSelector, useDispatch } from "react-redux";
import { updateDateRange } from "../../features/overlaysSlice";
import { RootState } from "../../app/store";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";

const DateRange: React.FC = () => {
  const dispatch = useDispatch();
  const dateRange = useSelector((state: RootState) => state.overlays.dateRange);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={1}>
        <Box>
          <DemoContainer components={["DateTimePicker"]}>
            <DateTimePicker
              label="Select start date"
              value={dayjs(dateRange.start)}
              onChange={(value) =>
                dispatch(updateDateRange({ start: value?.toISOString() }))
              }
            />
          </DemoContainer>
        </Box>
        <Box>
          <DemoContainer components={["DateTimePicker"]}>
            <DateTimePicker
              label="Select end date"
              value={dayjs(dateRange.end)}
              onChange={(value) =>
                dispatch(updateDateRange({ end: value?.toISOString() }))
              }
            />
          </DemoContainer>
        </Box>
      </Stack>
    </LocalizationProvider>
  );
};

export default DateRange;
