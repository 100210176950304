import { Box, Tab, Tabs } from "@mui/material";
import TreeSkeleton from "./TreeSkeleton";
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppDispatch, RootState } from "../../app/store";
import { useEffect, useState } from "react";
import TreeView from "./TreeView";
import { IGetSummariesRequestWithMetaData } from "../../models/explore";
import {
  getSummaries,
  resetOverlays,
  setQueries,
} from "../../features/overlaysSlice";
import SessionStorageManager from "../../common/SessionStorageManager";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { grey } from "@mui/material/colors";
import { Resizable } from "re-resizable";
import TreeSettings from "./TreeSettings";
import AttributeSortingOptions from "./AttributeSortingOptions";
import AttributeSummaries from "./AttributeSummaries";

interface BaseOverlayProps {
  menuItems: ItemType[];
  onMenuClick: (key: string) => void;
}

const BaseOverlay: React.FC<BaseOverlayProps> = ({
  menuItems,
  onMenuClick,
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<string>("nodeSummaries");

  useEffect(() => {
    const queries = SessionStorageManager.getOverlayQueries();
    queries && dispatch(setQueries(queries));
  }, []);

  const queries = useAppSelector((state: RootState) => state.overlays.queries);

  useEffect(() => {
    !isEmpty(queries) && fetchAllOverlays();
  }, [queries]);

  const summaryLoading = useAppSelector(
    (state: RootState) => state.overlays.summaryLoading
  );

  const entryPoints = useAppSelector(
    (state: RootState) => state.overlays.entryPoints
  );

  const selectedTrace = useAppSelector(
    (state: RootState) => state.overlays.selectedTrace
  );

  const selectedEntryPointHash = useAppSelector(
    (state: RootState) => state.overlays.selectedEntryPointHash
  );

  const attributeSummaries = useAppSelector((state: RootState) =>
    selectedTrace
      ? state.overlays.entryPoints[selectedEntryPointHash]?.aggregate.traceData[
          selectedTrace.id
        ]?.nodeAttributeSummaries
      : undefined
  );

  const resourceAttributeSummaries = useAppSelector((state: RootState) =>
    selectedTrace
      ? state.overlays.entryPoints[selectedEntryPointHash]?.aggregate.traceData[
          selectedTrace.id
        ]?.resourceAttributeSummaries
      : undefined
  );

  const fetchAllOverlays = () => {
    dispatch(resetOverlays());

    const getSummariesRequestWithMetaData: IGetSummariesRequestWithMetaData = {
      queries: queries,
    };
    dispatch(getSummaries(getSummariesRequestWithMetaData));
  };

  const onTabChange = (_: React.SyntheticEvent, tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <Box
      key="overlay-summaries"
      display={"flex"}
      flexDirection={"row"}
      flexGrow={1}
      minHeight={0}
    >
      <Box
        key="overlay-tree-box"
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        overflow={"auto"}
        gap={1}
        flexGrow={1}
        boxShadow={1}
        marginBottom={0.4}
      >
        <Box
          key={"overlay-tree"}
          overflow={"auto"}
          width={"100%"}
          height={"100%"}
          flexGrow={1}
        >
          {summaryLoading ? (
            <TreeSkeleton />
          ) : (
            !isEmpty(entryPoints) && <TreeView />
          )}
        </Box>
        <Box
          key={"overlay-tree-settings"}
          boxShadow={4}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
        >
          <TreeSettings />
        </Box>
      </Box>
      <Resizable
        key="overlay-data"
        boundsByDirection={true}
        minWidth="50%"
        maxWidth="75%"
        defaultSize={{
          width: "75%",
          height: "auto",
        }}
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          marginLeft: 8,
          marginBottom: 3.2,
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
          backgroundColor: grey[200],
          padding: 12,
        }}
        handleStyles={{
          left: {
            width: "7px",
            left: "-12px",
          },
        }}
      >
        <Tabs value={selectedTab} onChange={onTabChange}>
          <Tab value="nodeSummaries" label="Operation Summary" />
        </Tabs>
        {selectedTab === "nodeSummaries" && (
          <>
            <Box
              bgcolor={grey[100]}
              paddingX={3}
              border={2}
              borderColor={grey[300]}
              marginTop={1}
            >
              <AttributeSortingOptions />
            </Box>
            <Box
              bgcolor="white"
              padding={2}
              borderRadius={2}
              marginTop={1}
              overflow={"auto"}
            >
              <AttributeSummaries
                marginTop={0}
                label={"Node Attribute Summaries"}
                summaries={attributeSummaries}
                baseOverlayQuery={queries[0]}
                onlyBaseOverlay={queries.length == 1}
                summaryLoading={summaryLoading}
                menuItems={menuItems}
                onMenuClick={onMenuClick}
              />
              <AttributeSummaries
                marginTop={5}
                label={"Resource Attribute Summaries"}
                summaries={resourceAttributeSummaries}
                baseOverlayQuery={queries[0]}
                onlyBaseOverlay={queries.length == 1}
                summaryLoading={summaryLoading}
                menuItems={menuItems}
                onMenuClick={onMenuClick}
              />
            </Box>
          </>
        )}
      </Resizable>
    </Box>
  );
};

export default BaseOverlay;
