import {
  IAddAssertionRequest,
  IDeleteAssertionRequest,
  IListAssertiosnRequest,
} from "../../models/assertion";
import client from "../client";

const Assertion = {
  addAssertion: async (request: IAddAssertionRequest) => {
    const res = await client.post("/trackedtests/add_assertion", request);
    return res.data;
  },
  deleteAssertion: async (request: IDeleteAssertionRequest) => {
    const res = await client.post("/trackedtests/delete_assertion", request);
    return res.data;
  },
  listAssertions: async (request: IListAssertiosnRequest) => {
    const res = await client.post("/trackedtests/list_assertions", request);
    return res.data;
  },
};

export default Assertion;
