import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GenerationStep,
  IDecoratedFieldUpdateRule,
  IGetContextFromTestStepsRequest,
  PreStep,
} from "../test-generation/models";
import Test from "../api/services/Test";
import {
  GenerateApiTestsRequest,
  GenerateApiTestsResponse,
  GenerateTestCategory,
  VariableRecordConfig,
} from "../test-studio/models";

export interface TestGenerationState {
  generationSteps: GenerationStep[];
  currentStep: number;
  isGenerationPopupOpen: boolean;
  operationId?: number;
  selectedCategories: GenerateTestCategory[];
  additionalPrompt?: string;
  preStepsType: PreStep;
  testSuite?: string;
  preTestId?: string;
  variableRecordConfigs: VariableRecordConfig[];
  fieldUpdateRules: IDecoratedFieldUpdateRule[];
  isContextLoading: boolean;
  generateTestsLoading: boolean;
}

const initialState: TestGenerationState = {
  generationSteps: [GenerationStep.GENERAL, GenerationStep.PRE_STEPS],
  currentStep: 0,
  isGenerationPopupOpen: false,
  selectedCategories: Object.keys(GenerateTestCategory)
    .filter((key) => !isNaN(Number(key)))
    .map((key) => Number(key) as GenerateTestCategory),
  preStepsType: PreStep.NONE,
  variableRecordConfigs: [],
  fieldUpdateRules: [],
  isContextLoading: false,
  generateTestsLoading: false,
};

export const getContextFromSteps = createAsyncThunk(
  "getContextFromSteps",
  async (request: IGetContextFromTestStepsRequest) => {
    return await Test.getContextFromSteps(request);
  }
);

export const generateApiTests = createAsyncThunk<GenerateApiTestsResponse, GenerateApiTestsRequest>(
  "apis/generateApiTests",
  async (request: GenerateApiTestsRequest) => {
    return await Test.generateApiTests(request);
  }
);

const testGenerationSlice = createSlice({
  name: "testGenerationSlice",
  initialState,
  reducers: {
    openGenerationPopup: (state, action: PayloadAction<{ operationId: number }>) => {
      state.operationId = action.payload.operationId;
      state.isGenerationPopupOpen = true;
    },
    closeGenerationPopup: (state) => {
      state.isGenerationPopupOpen = false;
      testGenerationSlice.caseReducers.resetState(state);
    },
    updateCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    updateSelectedCategories: (state, action: PayloadAction<number[]>) => {
      state.selectedCategories = action.payload;
    },
    updateAdditionalPrompt: (state, action: PayloadAction<string>) => {
      state.additionalPrompt = action.payload;
    },
    updateTestSuite: (state, action: PayloadAction<string>) => {
      state.testSuite = action.payload;
    },
    updatePreStepsType: (state, action: PayloadAction<PreStep>) => {
      state.preStepsType = action.payload;
    },
    setPreTestId: (state, action: PayloadAction<string | undefined>) => {
      state.preTestId = action.payload;
    },
    addNewRule: (state, action: PayloadAction<IDecoratedFieldUpdateRule>) => {
      state.fieldUpdateRules.push(action.payload);
    },
    updateCheckedRules: (state, action: PayloadAction<number[]>) => {
      state.fieldUpdateRules.forEach((rule, index) => {
        rule.isEnable = action.payload.includes(index);
      });
    },
    updateExistingRule: (state, action: PayloadAction<{ index: number; rule: IDecoratedFieldUpdateRule }>) => {
      state.fieldUpdateRules[action.payload.index] = action.payload.rule;
    },
    resetState: (state) => {
      state.generationSteps = initialState.generationSteps;
      state.currentStep = 0;
      state.operationId = undefined;
      state.additionalPrompt = "";
      state.testSuite = undefined;
      state.preStepsType = PreStep.NONE;
      state.preTestId = undefined;
      state.variableRecordConfigs = [];
      state.fieldUpdateRules = [];
      state.isContextLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContextFromSteps.fulfilled, (state, action) => {
        state.isContextLoading = false;
        state.variableRecordConfigs = action.payload.variableRecordConfigs ?? [];
        state.fieldUpdateRules = action.payload.fieldUpdateRules?.map((rule) => ({ ...rule, isEnable: true })) ?? [];
      })
      .addCase(getContextFromSteps.rejected, (state) => {
        state.isContextLoading = false;
      })
      .addCase(getContextFromSteps.pending, (state) => {
        state.isContextLoading = true;
        state.variableRecordConfigs = [];
        state.fieldUpdateRules = [];
      })
      .addCase(generateApiTests.fulfilled, (state) => {
        state.generationSteps.push(GenerationStep.SUCCESS);
        testGenerationSlice.caseReducers.updateCurrentStep(state, {
          payload: state.currentStep + 1,
          type: testGenerationSlice.actions.updateCurrentStep.type,
        });
        state.generateTestsLoading = false;
      })
      .addCase(generateApiTests.rejected, (state) => {
        state.generationSteps.push(GenerationStep.ERROR);
        testGenerationSlice.caseReducers.updateCurrentStep(state, {
          payload: state.currentStep + 1,
          type: testGenerationSlice.actions.updateCurrentStep.type,
        });
        state.generateTestsLoading = false;
      })
      .addCase(generateApiTests.pending, (state) => {
        state.generateTestsLoading = true;
      });
  },
});

export const {
  openGenerationPopup,
  closeGenerationPopup,
  updateCurrentStep,
  updateAdditionalPrompt,
  updateSelectedCategories,
  updateTestSuite,
  updatePreStepsType,
  setPreTestId,
  addNewRule,
  updateCheckedRules,
  updateExistingRule,
} = testGenerationSlice.actions;
export default testGenerationSlice.reducer;
