import { OasConfig, Project, ProjectConfig } from "../../models/config/models";
import { ResponseHeader } from "../Response";
import client from "../client";
import { QualifiedOperation } from "./Common";
import { ListResourcesRequest } from "./User";

interface IAttribConfigSemanticId {
  name: string;
  projectId: string;
  operation: QualifiedOperation;
}

interface IAttribConfig {
  tags: string[];
  attribHashConfig: {
    hashMethod: string;
  };
  valueRetentionConfig: {
    maxCardinality: number;
  };
}

export interface IUpdateAttributeConfigRequest {
  attribConfigSemanticId: IAttribConfigSemanticId;
  attribConfig: IAttribConfig;
}

export interface IGetAttributeConfigRequest {
  attribConfigSemanticId: IAttribConfigSemanticId;
}

export interface IGetAttributeConfigResponse {
  attribName: string;
  projectId: string;
  operation: QualifiedOperation;
  attribConfig: IAttribConfig;
}

export interface IGetProjectConfig {
  projectId: string;
}

export interface IGetProjectConfigResponse {
  projectConfig: ProjectConfig;
}

export interface IUpdateProjectConfigRequest {
  projectId: string;
  projectConfig: ProjectConfig;
}

export interface IListEntryPointRequest {
  resourceId: number;
}

export interface IUpdateResourceConfigsRequest {
  configs: ResourceConfig[];
}

export interface IUpdateResourceConfigsResponse {

}

export interface IGetUserProjectsResponse {
  responseHeader?: ResponseHeader;
  projects: Project[];
}

export interface ResourceConfig {
  resourceName: string;
  oasConfig?: OasConfig;
}

const ProjectTS = {
  updateAttributeConfig: async (request: IUpdateAttributeConfigRequest) => {
    const response = await client.post("org/update_attrib_config", request);
    return response.data;
  },
  getAttributeConfig: async (request: IGetAttributeConfigRequest) => {
    const response = await client.post("org/get_attrib_config", request);
    return response.data;
  },
  updateResourceConfigs: async (request: IUpdateResourceConfigsRequest) => {
    const response = await client.post("org/update_resource_configs", request);
    return response.data;
  },
  getConfig: async (request: IGetProjectConfig): Promise<IGetProjectConfigResponse> => {
    const response = await client.post("/admin/get_project_config", request);
    return response.data;
  },
  updateProjectConfig: async (request: IUpdateProjectConfigRequest) => {
    const response = await client.post("/admin/update_project_config", request);
    return response.data;
  },
  listResources: async (request: ListResourcesRequest) => {
    const response = await client.post("collectors/list_resources", request);
    return response.data;
  },
  listEntrypoints: async (request: IListEntryPointRequest) => {
    const response = await client.post("collectors/list_entrypoints", request);
    return response.data;
  },
  listEnvironments: async () => {
    const response = await client.post("collectors/list_environments", {});
    return response.data;
  },
  listProjects: async (): Promise<IGetUserProjectsResponse> => {
    const response = await client.post("user/get_projects", {});
    return response.data;
  },
};

export default ProjectTS;
