import React, { Component } from 'react';

class SummaryAdvancedSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSamplingMode: this.props.settings?.samplingMode ?? "dynamic",
            manualSamplingRate: this.props.settings?.samplingRate ?? 0.0,
        };
    }

    handleRadioChange = (event) => {
        const { value } = event.target;
        this.setState({ selectedSamplingMode: value });
        this.props.onChange({ selectedSamplingMode: value, manualSamplingRate: this.state.manualSamplingRate });
    };

    handleManualSamplingRateChange = (event) => {
        const { value } = event.target;
        this.setState({ manualSamplingRate: parseFloat(value) });
        this.props.onChange({ selectedSamplingMode: this.state.selectedSamplingMode, manualSamplingRate: parseFloat(value) });
    };

    render() {
        const { selectedSamplingMode, manualSamplingRate } = this.state;

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: '10px' }}>
                    <label>
                        <input
                            type="radio"
                            value="dynamic"
                            checked={selectedSamplingMode === 'dynamic'}
                            onChange={this.handleRadioChange}
                        />
                        Dynamic Sampling
                    </label>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <label>
                        <input
                            type="radio"
                            value="manual"
                            checked={selectedSamplingMode === 'manual'}
                            onChange={this.handleRadioChange}
                        />
                        Fixed Sampling
                    </label>
                    {selectedSamplingMode === 'manual' && (
                        <input style={{ marginLeft: '10px' }}
                            type="number"
                            value={manualSamplingRate}
                            onChange={this.handleManualSamplingRateChange}
                            step="0.1" min="0"
                            max="1"

                        />
                    )}
                </div>
            </div>
        );
    }
}

export default SummaryAdvancedSettings;
