import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Chip, TextField } from "@mui/material";
import { useAppSelector } from '../../app/hooks';
import { addEnvironment, setEnvironmentVariable, removeEnvironmentVariable, updateTemplatedPaths, updateResourceConfigs, saveResourceConfigs } from './projectSettingsSlice';
import yaml from "js-yaml";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useDispatch } from 'react-redux';
import { Button } from "antd";

const PathTemplates: React.FC = () => {
  const dispatch = useDispatch();
  const projectConfig = useAppSelector(state => state.projectSettings.projectConfig);
  const { showSnackbar } = useSnackbar();

  const [value, setValue] = useState("");
  const [swaggerUrl, setSwaggerUrl] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const templatedPaths = projectConfig.entrypointsConfig?.templatedPaths || [];

  useEffect(() => {
    setIsButtonEnabled(swaggerUrl.trim().length > 0);
  }, [swaggerUrl]);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onSwaggerUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSwaggerUrl(e.target.value);
  };

  const onEnterPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && value.trim()) {
      handleAddTemplatedPath(value);
      setValue("");
    }
  };

  const handleAddTemplatedPath = (path: string) => {
    if (!templatedPaths.includes(path)) {
      const updatedPaths = [...templatedPaths, path];
      dispatch(updateTemplatedPaths(updatedPaths));
    }
  };

  const handleDelete = (index: number) => {
    const updatedPaths = templatedPaths.filter((_, i) => i !== index);
    dispatch(updateTemplatedPaths(updatedPaths));
  };

  const handleLoadFromSwagger = async () => {
    try {
      const response = await fetch(swaggerUrl);
      if (!response.ok) {
        showSnackbar("Failed to fetch Swagger file");
        return;
      }
      const contentType = response.headers.get("content-type") || "";
      const isYaml = contentType.includes("yaml") || contentType.includes("yml");
      const swaggerData = isYaml ? yaml.load(await response.text()) : await response.json();
      const newTemplatedPaths = extractTemplatedPathsFromSwagger(swaggerData);
      updateTemplatedPathsList(newTemplatedPaths);
    } catch (error) {
      console.error("Error loading swagger", error);
      showSnackbar("Error loading Swagger file");
    }
  };

  const extractTemplatedPathsFromSwagger = (swaggerData: any): string[] => {
    const paths = swaggerData.paths || {};
    return Object.keys(paths).map((path) => {
      return path.replace(/{(\w+)}/g, "{{$1}}");
    });
  };

  const updateTemplatedPathsList = (newPaths: string[]) => {
    const updatedPaths = [...templatedPaths];
    newPaths.forEach((path) => {
      if (!templatedPaths.includes(path)) {
        updatedPaths.push(path);
      }
    });
    dispatch(updateTemplatedPaths(updatedPaths));
  };

  return (
    <Card>
      <CardContent>
        <Box marginBottom={2}>
          <TextField
            placeholder="Add a templated path."
            fullWidth
            onKeyDown={onEnterPressed}
            value={value}
            onChange={onValueChange}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={2} marginBottom={2}>
          <TextField
            placeholder="Enter Swagger URL"
            value={swaggerUrl}
            onChange={onSwaggerUrlChange}
            margin="normal"
            style={{ flex: 1 }}
          />
          <Button
            onClick={handleLoadFromSwagger}
            disabled={!isButtonEnabled}
            type="primary"
            className="primary-button"
          >
            Load from Swagger file
          </Button>
        </Box>
        <Box
          marginTop={3}
          display="flex"
          alignItems="center"
          gap={1}
          flexWrap="wrap"
        >
          {templatedPaths?.map((path, index) => (
            <Chip
              label={path}
              key={index}
              onDelete={() => handleDelete(index)}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PathTemplates;