import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  closeAttributeSettingsPopup,
  getAttributeConfig,
  updateAttributeConfig,
} from "../../features/overlaysSlice";
import { LoadingButton } from "@mui/lab";
import {
  IGetAttributeConfigRequest,
  IUpdateAttributeConfigRequest,
} from "../../api/services/ProjectTS";
import LocalStorageManager from "../../common/LocalStorageManager";
import { isEmpty } from "lodash";

const AttributeSettingsPopup = () => {
  const maskingOptions = {
    VALUE: "No Masking",
    BOOLEAN: "Presence",
  };

  const [isBIDimension, setIsBIDemension] = useState(false);
  const [masking, setMasking] = useState(Object.keys(maskingOptions)[0]);
  const [maxCardinality, setMaxCardinaliry] = useState(0);

  const dispatch = useAppDispatch();
  const projectID = LocalStorageManager.getProjectId();

  const isAttrubuteSettingsOpen = useAppSelector(
    (state: RootState) => state.overlays.isAttrubuteSettingsOpen
  );

  const updateAttributeConfigLoading = useAppSelector(
    (state: RootState) => state.overlays.updateAttributeConfigLoading
  );

  const currentDiffCardContext = useAppSelector(
    (state: RootState) => state.overlays.currentDiffCardContext
  );

  const selectedTrace = useAppSelector(
    (state: RootState) => state.overlays.selectedTrace
  );

  const getAttributeConfigLoading = useAppSelector(
    (state: RootState) => state.overlays.getAttributeConfigLoading
  );

  const attributeSettings = useAppSelector(
    (state: RootState) => state.overlays.attributeSettings
  );

  useEffect(() => {
    if (isAttrubuteSettingsOpen) {
      resetState();
      const request: IGetAttributeConfigRequest = {
        attribConfigSemanticId: {
          name: currentDiffCardContext!.attributeKey,
          projectId: projectID,
          operation: selectedTrace!.operation,
        },
      };
      dispatch(getAttributeConfig(request));
    }
  }, [isAttrubuteSettingsOpen]);

  useEffect(() => {
    if (!isEmpty(attributeSettings)) {
      setIsBIDemension(
        attributeSettings.attribConfig.tags?.includes("BI_DIMENSION")
      );
      setMasking(attributeSettings.attribConfig.attribHashConfig.hashMethod);
      setMaxCardinaliry(
        attributeSettings.attribConfig.valueRetentionConfig.maxCardinality
      );
    }
  }, [attributeSettings]);

  const onSubmitHandler = () => {
    const request: IUpdateAttributeConfigRequest = {
      attribConfigSemanticId: {
        name: currentDiffCardContext!.attributeKey,
        projectId: projectID,
        operation: selectedTrace!.operation,
      },
      attribConfig: {
        tags: isBIDimension ? ["BI_DIMENSION"] : [],
        attribHashConfig: {
          hashMethod: masking,
        },
        valueRetentionConfig: {
          maxCardinality: maxCardinality,
        },
      },
    };

    dispatch(updateAttributeConfig(request));
  };

  const onCloseHandler = () => {
    dispatch(closeAttributeSettingsPopup());
  };

  const resetState = () => {
    setIsBIDemension(false);
    setMasking(Object.keys(maskingOptions)[0]);
    setMaxCardinaliry(0);
  };

  return (
    <Dialog open={isAttrubuteSettingsOpen} onClose={onCloseHandler}>
      <DialogTitle marginX={"auto"}>Attribute Settings</DialogTitle>
      <DialogContent>
        <form>
          <Stack direction={"column"} spacing={3} width={300}>
            {getAttributeConfigLoading ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.2rem" }}
                width={150}
              />
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBIDimension}
                    onChange={(e) => setIsBIDemension(e.target.checked)}
                  />
                }
                label="Is BI Dimension"
              />
            )}

            {getAttributeConfigLoading ? (
              <Skeleton variant="rounded" width={"100%"} height={60} />
            ) : (
              <FormControl fullWidth>
                <InputLabel id="masking-label">Masking</InputLabel>
                <Select
                  labelId="masking-label"
                  id="masking"
                  value={masking}
                  label="Masking"
                  onChange={(e) => setMasking(e.target.value)}
                >
                  {Object.entries(maskingOptions).map(([key, value]) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            {getAttributeConfigLoading ? (
              <Skeleton variant="rounded" width={"100%"} height={60} />
            ) : (
              <TextField
                fullWidth
                id="max-cardinality"
                type="number"
                inputProps={{ min: 0, max: 100, step: 1 }}
                value={maxCardinality}
                onChange={(e) => setMaxCardinaliry(parseInt(e.target.value))}
                label="Max Cardinality"
                variant="outlined"
              />
            )}
            <Box display="flex" justifyContent="flex-end" gap={2}>
              {getAttributeConfigLoading ? (
                <Skeleton variant="rounded" width={70} height={38} />
              ) : (
                <LoadingButton
                  loading={updateAttributeConfigLoading}
                  variant="contained"
                  onClick={onSubmitHandler}
                >
                  Save
                </LoadingButton>
              )}
              {getAttributeConfigLoading ? (
                <Skeleton variant="rounded" width={85} height={38} />
              ) : (
                <Button
                  variant="outlined"
                  onClick={onCloseHandler}
                  disabled={updateAttributeConfigLoading}
                >
                  Cancel
                </Button>
              )}
            </Box>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AttributeSettingsPopup;
