import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { CaretDownOutlined } from "@ant-design/icons";
import LockIcon from "@mui/icons-material/Lock";
import Attribute from "./Attribute";
import { Dropdown, MenuProps } from "antd";
import { DiffCardProps } from "../../models/overlays";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { Guard } from "../../api/services/Guards";
import { useAppDispatch } from "../../app/hooks";
import { updateDiffcardContext } from "../../features/overlaysSlice";
import { getVersionedUrl } from "../../common/Util";
import { Converters } from "../../util";

const DiffCard: React.FC<DiffCardProps> = ({
  context,
  menuItems,
  onMenuClick,
}) => {
  const menuProps: MenuProps = {
    items: menuItems,
    onClick: ({ key }) => {
      onMenuClick(key, context);
    },
  };

  const dispatch = useAppDispatch();

  const attributeKey = context.attributeKey;
  const attributeValues = context.attributeValue?.values;
  const guards = context.attributeValue?.guards;

  const guardConditionTooltip = (guards: Guard[]) => {
    return (
      <Stack direction={"column"}>
        {guards?.map((guard) => {
          return (
            <Box key={guard.id}>
              {guard.config.operation}{" "}
              {Converters.typedValueToString(guard.config.value)}
            </Box>
          );
        })}
      </Stack>
    );
  };

  return (
    <Card>
      <Stack padding={1} direction={"row"} alignItems={"center"}>
        <Typography fontWeight={600}>{attributeKey}</Typography>
        {!isEmpty(guards) && (
          <Tooltip title={guardConditionTooltip(guards)}>
            <IconButton
              component={Link}
              to={getVersionedUrl("/guards")}
              state={{ guards: guards?.map((guard) => guard.id) }}
            >
              <LockIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {menuItems && menuItems.length > 0 && (<Dropdown
          menu={menuProps}
          onOpenChange={(open) =>
            dispatch(updateDiffcardContext({ context, open }))
          }
        >
          <IconButton>
            <CaretDownOutlined style={{ fontSize: "20px" }} />
          </IconButton>
        </Dropdown>)}
      </Stack>

      <CardContent>
        <Grid container spacing={3} columns={16}>
          {attributeValues &&
            Object.keys(attributeValues).map((attributeValue, index) => {
              return (
                <Grid
                  item
                  xs={8}
                  key={`${index}+${attributeValue}`}
                  zeroMinWidth
                >
                  <Attribute
                    attributeKey={attributeKey}
                    attributeValue={attributeValue}
                    percentages={attributeValues[attributeValue]}
                  />
                </Grid>
              );
            })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DiffCard;
