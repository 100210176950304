import { Box, IconButton } from "@mui/material";
import { FC, memo, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { eventWithTime } from "rrweb";
import Replayer from "./Replayer";

interface FloatingReplayerProps {
  timestamp?: number;
  dimensions: { width: number; height: number };
  position: { x: number; y: number };
  events: eventWithTime[];
  areEventsLoading: boolean;
  showController: boolean;
  play: boolean;
  onPlay: (play: boolean) => void;
  onMaximize: (play: boolean) => void;
}

const ItemType = "BOX";

const FloatingReplayer: FC<FloatingReplayerProps> = ({
  timestamp,
  dimensions,
  position,
  events,
  areEventsLoading,
  showController,
  play,
  onPlay,
  onMaximize,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { type: ItemType },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  console.log("Floating player loading");
  const [isAppearing, setIsAppearing] = useState(true);

  const animateAndMaximize = () => {
    setIsAppearing(false);
    onMaximize(play);
  }

  return (
    <Box
      className="floating-replayer"
      display={"flex"}
      flexDirection={"column"}
      ref={drag}
      border={0.1}

      bgcolor={"white"}
      sx={{
        opacity: isDragging ? 0.3 : 0.9,
        cursor: "move",
        position: "absolute",
        top: position.y,
        left: position.x,
        zIndex: 2001,
        transformOrigin: "center center",
        // Apply the shrink animation when the component is loaded
        animation: isAppearing ? "transformX(-100%)" : "expandOut 0.3s ease-in forwards",
      }}
    >
      <Replayer
        play={play}
        timestamp={timestamp}
        dimensions={dimensions}
        events={events}
        areEventsLoading={areEventsLoading}
        showController={showController}
      />
      <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} paddingX={5} bgcolor={"white"}>
        <IconButton size="small" onClick={() => onPlay(!play)}>
          {play ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton size="small" onClick={() => animateAndMaximize()}>
          <FullscreenIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default memo(FloatingReplayer);
