import { useState } from "react";
import { Box, Slider } from "@mui/material";
import { ThemeColors } from "../styles/light-colors";

const ColortedRangeSlider = (props) => {
  const {
    width,
    reverse,
    defaultValues,
    min,
    max,
    style,
    onRangeUpdate,
    ...rest
  } = props;

  const [value, setValue] = useState(
    reverse ? defaultValues.map((val) => -val) : defaultValues
  );
  const [perc, setPerc] = useState(
    reverse
      ? defaultValues.map((val) => parseInt((1 - Math.abs(val / max)) * 100))
      : defaultValues.map((val) => (val * 100) / max)
  );

  const onChange = (e, tValues) => {
    const [minVal, maxVal] = tValues;
    if (maxVal > minVal && maxVal !== minVal) {
      setValue(tValues);
      if (!reverse) {
        const perc = tValues?.map((val) => (val * 100) / max);
        setPerc(perc);
        onRangeUpdate(perc);
      } else {
        const perc = tValues?.map((val) =>
          parseInt((1 - Math.abs(val / max)) * 100)
        );
        setPerc(perc);
        onRangeUpdate(perc);
      }
    }
  };

  return (
    <Box width={width} marginX={2} marginTop={3.5}>
      <Slider
        sx={{
          "& .MuiSlider-track": {
            background: ThemeColors.warning,
            borderColor: "white",
          },
          "& .MuiSlider-thumb": {
            [`&:nth-of-type(${1}n)`]: {
              background: ThemeColors.success,
              "& span": {
                background: ThemeColors.success,
              },
            },
            [`&:nth-of-type(${2}n)`]: {
              background: ThemeColors.error,
              "& span": {
                background: ThemeColors.error,
              },
            },
          },
          "& .MuiSlider-mark": {
            background: "none",
          },
          "& .MuiSlider-rail": {
            background: `linear-gradient(to right, ${ThemeColors.success} 0% ${perc[0]}%, ${ThemeColors.warning} ${perc[0]}% ${perc[1]}%, ${ThemeColors.error} ${perc[1]}% 100%)`,
          },
          "& .MuiSlider-valueLabel": {},
          ...style,
        }}
        valueLabelDisplay="on"
        valueLabelFormat={(x) => (x == perc[0] ? `< ${x}` : `> ${x}`)}
        value={value}
        min={reverse ? -max : min}
        max={reverse ? -min : max}
        scale={(x) => (reverse ? -x : x)}
        marks={[
          { value: reverse ? -max : min, label: reverse ? max : min },
          { value: reverse ? -min : max, label: reverse ? min : max },
        ]}
        onChange={onChange}
        {...rest}
      />
    </Box>
  );
};

export default ColortedRangeSlider;
