import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProjectTS, Test } from "../api";
import {
  IGetProjectConfig,
  IGetUserProjectsResponse,
  IListEntryPointRequest,
} from "../api/services/ProjectTS";
import { ListResourcesRequest } from "../api/services/User";
import { QualifiedOperation } from "../api/services/Common";
import { addAssertion, listAssertions } from "./assertionsSlice";
import { addGuard, getSummaries, updateAttributeConfig } from "./overlaysSlice";
import { SummaryTree } from "../models/overlays";
import { isEmpty } from "lodash";
import {
  generateTestRequest,
  pushAssertions,
  runTest,
  saveTest,
} from "./testsSlice";
import { IRequestTemplate } from "../test-studio/models";
import { Project } from "../models/config/models";
import LocalStorageManager from "../common/LocalStorageManager";
import { Modal } from "antd";

export interface CommonState {
  snackBarProps: {
    severity: string;
    message: string;
  };
  environmentsLoading: boolean;
  environments: string[];
  resourcesLoading: boolean;
  resources: { id: number; name: string }[];
  entrypointsLoading: boolean;
  entrypoints: QualifiedOperation[];
  requestTemplatesLoading: boolean;
  requestTemplates: IRequestTemplate[];
  projects: Project[];
  selectedProject: string | undefined;
}

const initialState: CommonState = {
  snackBarProps: {
    severity: "",
    message: "",
  },
  environmentsLoading: false,
  environments: [],
  resourcesLoading: false,
  resources: [],
  entrypointsLoading: false,
  entrypoints: [],
  requestTemplatesLoading: false,
  requestTemplates: [],
  projects: [],
  selectedProject: undefined,
};

export type SnackbarPropsPayload = {
  severity: string;
  message: string;
};

export const getProjectConfig = createAsyncThunk(
  "getProjectConfig",
  async (request: IGetProjectConfig) => {
    return await ProjectTS.getConfig(request);
  }
);

export const listEnvironments = createAsyncThunk(
  "listEnvironments",
  async () => {
    return await ProjectTS.listEnvironments();
  }
);

export const listProjects = createAsyncThunk<IGetUserProjectsResponse>(
  "listProjects",
  async () => {
    return await ProjectTS.listProjects();
  }
);
export const listResources = createAsyncThunk(
  "listResources",
  async (request: ListResourcesRequest) => {
    return await ProjectTS.listResources(request);
  }
);

export const listEntrypoints = createAsyncThunk(
  "listEntrypoints",
  async (request: IListEntryPointRequest) => {
    return await ProjectTS.listEntrypoints(request);
  }
);

export const listRequestTemplates = createAsyncThunk(
  "listRequestTemplates",
  async () => {
    return await Test.listRequestTemplates({});
  }
);

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setSnackBarProps: (state, action: PayloadAction<SnackbarPropsPayload>) => {
      state.snackBarProps = {
        severity: action.payload.severity,
        message: action.payload.message
      }
    },
    setSelectedProject: (state, action: PayloadAction<string>) => {
      state.selectedProject = action.payload;
      LocalStorageManager.setProjectId(action.payload);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(listEnvironments.pending, (state) => {
        state.environmentsLoading = true;
      })
      .addCase(listEnvironments.rejected, (state) => {
        state.environmentsLoading = false;
      })
      .addCase(listEnvironments.fulfilled, (state, action) => {
        console.log("Environments recv", action.payload.environments);
        state.environmentsLoading = false;
        state.environments = action.payload.environments;
      })
      .addCase(listProjects.fulfilled, (state, action) => {
        console.log("Projects recv", action.payload.projects);
        state.projects = action.payload.projects;
      })
      .addCase(listResources.pending, (state) => {
        state.resourcesLoading = true;
      })
      .addCase(listResources.rejected, (state) => {
        state.resourcesLoading = false;
      })
      .addCase(listResources.fulfilled, (state, action) => {
        state.resourcesLoading = false;
        state.resources = action.payload.resources;
      })
      .addCase(listEntrypoints.pending, (state) => {
        state.entrypointsLoading = true;
      })
      .addCase(listEntrypoints.rejected, (state) => {
        state.entrypointsLoading = false;
      })
      .addCase(listEntrypoints.fulfilled, (state, action) => {
        state.entrypointsLoading = false;
        state.entrypoints = action.payload.operations;
      })
      .addCase(listRequestTemplates.pending, (state) => {
        state.requestTemplatesLoading = true;
      })
      .addCase(listRequestTemplates.rejected, (state) => {
        state.requestTemplatesLoading = false;
      })
      .addCase(listRequestTemplates.fulfilled, (state, action) => {
        state.requestTemplatesLoading = false;
        state.requestTemplates = action.payload.templates;
      })
      .addCase(pushAssertions, (state) => {
        state.snackBarProps = {
          severity: "success",
          message: "Assertion(s) added successfully",
        };
      })
      .addCase(listAssertions.rejected, (state) => {
        state.snackBarProps = {
          severity: "error",
          message: "Something went wrong while loading assertions",
        };
      })
      .addCase(updateAttributeConfig.rejected, (state) => {
        state.snackBarProps = {
          severity: "error",
          message: "Something went wrong while updating attribute config",
        };
      })
      .addCase(updateAttributeConfig.fulfilled, (state) => {
        state.snackBarProps = {
          severity: "success",
          message: "Attribute config updated successfully",
        };
      })
      .addCase(addGuard.rejected, (state) => {
        state.snackBarProps = {
          severity: "error",
          message: "Something went wrong while adding guard",
        };
      })
      .addCase(addGuard.fulfilled, (state, action) => {
        state.snackBarProps = {
          severity: "success",
          message: "Guard added successfully",
        };
      })
      .addCase(getSummaries.fulfilled, (state, action) => {
        const response = action.payload.response;
        let noOfEmptyTrees = 0;

        response?.results?.forEach((result: { trees: SummaryTree[] }) => {
          if (isEmpty(result?.trees)) {
            noOfEmptyTrees++;
          }
        });

        if (noOfEmptyTrees === 1) {
          state.snackBarProps = {
            severity: "warning",
            message: `No data returned for ${noOfEmptyTrees} overlay query`,
          };
        } else if (noOfEmptyTrees > 1) {
          state.snackBarProps = {
            severity: "warning",
            message: `No data returned for ${noOfEmptyTrees} overlay queries`,
          };
        }
      })
      .addCase(generateTestRequest.rejected, (state) => {
        state.snackBarProps = {
          severity: "error",
          message: "Something went wrong while generating the Test request",
        };
      })
      .addCase(runTest.rejected, (state, action) => {
        // Check if the error code is 504 for a socket timeout
        if (action.error.code === "504") {
          // Show a specific modal message for socket timeouts
          Modal.error({
            title: "Endpoint not reachable",
            content: (`
              <>
                <p>It seems the SUT is not reachable from our servers.</p>
                <p>
                  You can run the test locally by installing our Chrome extension and enabling 
                  "Run tests locally in Test Studio".
                </p>
              </>`
            ),
            okText: "Got it",
          });
        } else {
          // For other errors, log a generic error message and show the snack bar
          console.error("Error while running the Test:", action.error.message);
          console.log("Action payload:", action.payload); // Logs payload if there is any
          
          // Update the snackBarProps in the state with a generic error message
          state.snackBarProps = {
            severity: "error",
            message: "Something went wrong while running the Test. Check your test configuration and try again later.",
          };
        }
      
        // Optional: Log the entire state for further analysis
        console.log("Current state after error:", state);
      })
      .addCase(saveTest.rejected, (state) => {
        state.snackBarProps = {
          severity: "error",
          message: "Something went wrong while saving the Test",
        };
      })
      .addCase(saveTest.fulfilled, (state) => {
        state.snackBarProps = {
          severity: "success",
          message: "Test saved successfully",
        };
      });
  },
});

export const { setSnackBarProps, setSelectedProject } = commonSlice.actions;
export default commonSlice.reducer;
