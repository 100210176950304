import React, { Component } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import RawDataTab from "./RawDataTab"
import { ThemeColors } from "../styles/light-colors";

class ExploreTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      flowToBotConvoItems: this.props.flowToBotConvoItems,
      isWaitingForResponse: false,
      attribMap: this.props.attribMap,
      context: this.props.context
    };
    this.botTabRef = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    const { attribMap, context } = this.props;
    if (attribMap !== prevProps.attribMap || context !== prevProps.context) {
      this.setState({ attribMap: attribMap, context: context });
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
  };

  render() {
    const styles = {
      tabLabel: {
        textTransform: 'none',
        color: ThemeColors.primaryAction
      },
    };
    const { flowToBotConvoItems, activeTab } = this.state;
    const { context, attribMap, resourceAttribMap } = this.props;
    let botConvoItems = flowToBotConvoItems[context.entryPoint];
    if (!botConvoItems) {
      botConvoItems = []
    }
    return (
      <div style={{ height: "100vh" }}>
        <Tabs value={activeTab} onChange={this.handleTabChange}>
          <Tab label="Stats" style={styles.tabLabel} />
        </Tabs>
        <Box p={3}>
          {activeTab === 0 && <div><RawDataTab attribMap={attribMap} context={context} resourceAttribMap={resourceAttribMap} key={context} /></div>}
        </Box>
      </div>
    );
  }
}

export default ExploreTabs;