import { IQueryWithMetaData } from "../models/explore";
import {
  IRequestFetchQuery,
  IListTestCasesWithRunDetailsRequest,
  StepContext,
  IAwareApiTest,
  IStep,
} from "../test-studio/models";

import {
  IListSessionRecordingsRequest,
} from "../models/sessions/ISession";

const OVERLAY_QUERIES = "overlay-queries";
const TESTSTUDIO_LIST_REQUESTS_CONTEXT = "teststudio-steps-context";
const SESSION_FIND_CONTEXT_REQUEST = "session-find-context-request";
const TEST_FIND_CONTEXT_REQUEST = "test-find-context-request";
const TESTSTUDIO_TEST = "teststudio-test";

const SessionStorageManager = {
  clear() {
    sessionStorage.clear();
  },

  setOverlayQueries(queries: IQueryWithMetaData[]) {
    sessionStorage.setItem(OVERLAY_QUERIES, JSON.stringify(queries));
  },

  getOverlayQueries() {
    let queriesString = sessionStorage.getItem(OVERLAY_QUERIES);
    return queriesString
      ? (JSON.parse(queriesString) as IQueryWithMetaData[]) ?? undefined
      : undefined;
  },

  setTestStudioTest(uid: string, testConfig: IAwareApiTest) {
    sessionStorage.setItem(
      uid + "-" + TESTSTUDIO_TEST,
      JSON.stringify(testConfig)
    );
  },

  clearTestStudioTest() {
    sessionStorage.removeItem(TESTSTUDIO_TEST);
  },

  getTestStudioTest(uid: string): IAwareApiTest | undefined {
    let contextString = sessionStorage.getItem(uid + "-" + TESTSTUDIO_TEST);
    return contextString
      ? (JSON.parse(contextString) as IAwareApiTest) ?? undefined
      : undefined;
  },

  setTestStudioSteps(uid: string, steps: IStep[]) {
    sessionStorage.setItem(
      uid + "-" + TESTSTUDIO_LIST_REQUESTS_CONTEXT,
      JSON.stringify(steps)
    );
  },

  setSessionFindContext(uid: string, request: IListSessionRecordingsRequest) {
    sessionStorage.setItem(
      uid + "-" + SESSION_FIND_CONTEXT_REQUEST,
      JSON.stringify(request)
    );
  },

  getSessionFindContext(uid: string): IListSessionRecordingsRequest | undefined {
    let contextString = sessionStorage.getItem(uid + "-" + SESSION_FIND_CONTEXT_REQUEST);
    return contextString
      ? (JSON.parse(contextString) as IListSessionRecordingsRequest) ?? undefined
      : undefined;
  },

  setTestFindContext(uid: string, request: IListTestCasesWithRunDetailsRequest) {
    sessionStorage.setItem(
      uid + "-" + TEST_FIND_CONTEXT_REQUEST,
      JSON.stringify(request)
    );
  },

  getTestFindContext(uid: string): IListTestCasesWithRunDetailsRequest | undefined {
    let contextString = sessionStorage.getItem(uid + "-" + TEST_FIND_CONTEXT_REQUEST);
    return contextString
      ? (JSON.parse(contextString) as IListTestCasesWithRunDetailsRequest) ?? undefined
      : undefined;
  },

  clearTestStudioSteps() {
    sessionStorage.removeItem(TESTSTUDIO_LIST_REQUESTS_CONTEXT);
  },

  getTestStudioSteps(uid: string) {
    let contextString = sessionStorage.getItem(
      uid + "-" + TESTSTUDIO_LIST_REQUESTS_CONTEXT
    );
    return contextString
      ? (JSON.parse(contextString) as IStep[]) ?? []
      : [];
  },
};

export default SessionStorageManager;
