import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { getBaseURIWithoutQuery } from "../util/UriUtils";
import {
  GeneratorType,
  HTTPMessageSection,
  ICreateTestSuiteRequest,
  IDecoratedDrawerContext,
  IGenerateTestRequestRequest,
  IGenerator,
  IGetRequestObjectRequest,
  IGetRequestObjectResponse,
  IHTTPBodyTreeNode,
  IListFieldVocabsRequest,
  IListRequestIdsForContextRequest,
  IListTestCasesWithRunDetailsRequest,
  IListTestSuitesRequest,
  IRawRequest,
  IRawResponse,
  ISaveTestRequest,
  IStep,
  ITestAssertion,
  IVariable,
  MainTab,
  RunTestsRequest,
  TestAssertionOperation,
  TestAssertionSide,
  TestExpression,
  VariableStoreTab,
  ITestStepExecutionRecord,
  ITestName,
  RunTestsResponse,
  TestStep,
  IListScriptsRequest,
  ScriptWithMetadata,
  IListTestCasesRequest,
  IListTestStepsFromTestRequest,
  TestAssertionResult,
  IEvaluateAssertionsRequest,
  IAwareApiTest,
  TreeLoadState,
  ContextType,
  ValueLocator,
  IEvaluateAssertionResponse,
  FieldConfig,
  IOperationContextualVariableRecordConfig,
  VariableRecordConfig,
  BodyValueLocator,
  UpdateLinkedScenariosForTestRequest,
  UpdateLinkedScenariosForTestResponse,
  IGetAwareApiTestRequest,
  IGetAwareApiTestResponse,
  ParamRow,
  TestAssertionOperationType,
  FieldDataType,
} from "../test-studio/models";
import { Test } from "../api";
import { isEmpty, isObject } from "lodash";
import { QualifiedOperation } from "../api/services/Common";
import { TypedValue, TypedValueOrList } from "../models/typed/models";
import { AppDispatch, RootState } from "../app/store";
import { set } from "lodash";
import { Primitive } from "../models/basic/model";
import { Converters, generateHash, generateUniqueID } from "../util";
import { IQuery } from "../models/explore";
import { extractJsonPathValue } from "../util/JsonPathUtils";
import {
  getOperationContextualVariables,
  getRequestConfig,
  getOperationNonContextualVariables,
  getContextType,
} from "../test-studio/Utills";
import { createJsonMessageStructure, processMessage } from "../util/MessageUtils";
import {
  ContinuationState,
  IGetTraceSummaryTreesForTraceResponse,
  PrefixSummary,
  PrefixSummaryTree,
  RequestResponse,
  TraceCollectionSummaryTree,
} from "../models/overlays";
import Collectors from "../api/services/Collectors";
import TestExpressionUtils from "../util/TestExpressionUtils";
import { OperationLocator } from "../api/services/Guards";
import { delay } from "../util/TimeUtils";
import Scenarios, { TestScenarioSimple } from "../api/services/Scenarios";
import { getNodeStatus } from "../util/TraceUtils";
import { Modal, message } from "antd";
import { ProjectConfig } from "../models/config/models";
import React from "react"; // Ensure React is imported

export interface TestsState {
  testName: ITestName;
  initStepReferenceQuery?: IQuery; //only set via setInitialSteps. Won't set by changing state.steps[0].
  steps: IStep[];
  selectedStepIndex: number; // selected step index
  currentStepIndex?: number; // step index which the action button is clicked
  selectedStep?: IStep;
  isAddStepOpen: boolean;
  evalAssistSuggestionsForStep: boolean;
  listTestSuitesLoading: boolean;
  isSaveTestOpen: boolean;
  addStepLoading: boolean;
  listFieldVocabsLoading: boolean;
  // TODO Update this to be per step
  listRequestIdsLoading: boolean;
  getRequestLoading: boolean;
  generateTestRequestLoading: boolean;
  runTestLoading: boolean;
  saveTestLoading: boolean;
  createTestSuiteLoading: boolean;
  isGeneratorDrawerOpen: boolean;
  isVariableDrawerOpen: boolean;
  isAssertionsDrawerOpen: boolean;
  isAssertionOpen: boolean;
  skipAutoAuthHeaderUpdate: boolean;
  currentMenuContext?: IDecoratedDrawerContext;
  variableStoreMenuContext?: IDecoratedDrawerContext;
  generatorMenuContext?: IDecoratedDrawerContext;
  assertionMenuContext?: IDecoratedDrawerContext;

  renderCount: number;

  activeVariableStoreTab: VariableStoreTab;

  requestIds: { [stepId: string]: string[] };
  selectedRequestId: { [stepId: string]: string };

  rawRequest: { [stepId: string]: { [requestId: string]: IRawRequest } };
  testRequest: { [stepId: string]: IRawRequest }; // generated request
  testResponse: { [stepId: string]: IRawResponse | undefined }; // response for generated request

  requestGenBodyString: { [stepId: string]: { [prefix: string]: string } };
  testRequestBodyString: { [stepId: string]: { [prefix: string]: string } };
  testResponseBodyString: { [stepId: string]: { [prefix: string]: string } };

  requestGenBodyTree: { [stepId: string]: { [prefix: string]: IHTTPBodyTreeNode[] } };
  testRequestBodyTree: { [stepId: string]: { [prefix: string]: IHTTPBodyTreeNode[] } };
  testResponseBodyTree: { [stepId: string]: { [prefix: string]: IHTTPBodyTreeNode[] } };

  linkedScenarios: TestScenarioSimple[];

  bodyGenerators: {
    [stepId: string]: { [requestId: string]: { [key: string]: IGenerator } };
  };
  headerGenerators: {
    [stepId: string]: { [requestId: string]: { [key: string]: IGenerator } };
  };
  autoAuthHeaderGenerator: IGenerator | undefined;
  queryParamGenerators: {
    [stepId: string]: { [requestId: string]: { [key: string]: IGenerator } };
  };
  pathParamGenerators: {
    [stepId: string]: { [requestId: string]: { [key: string]: IGenerator } };
  };

  traceId: { [stepId: string]: string | undefined }; // Trace id map for each step (for test step executions)

  selectedTab: { [stepId: string]: { [prefix: string]: MainTab } };
  environment: { [stepId: string]: string };
  endpoint: { [stepId: string]: string };
  environmentEndpoints: {
    [stepId: string]: { [environment: string]: string[] };
  };
  fieldVocabs: {
    [stepId: string]: { [key: string]: { values: TypedValue[] } };
  };
  variables: { [stepId: string]: IVariable[] };
  scripts: ScriptWithMetadata[];
  leftExpression?: TestExpression;
  leftExpressionChip: string;
  rightExpression?: TestExpression;
  rightExpressionChip: string;
  isTestCreateWizardOpen: boolean;
  isTestCreateWizardShown: boolean;
  isRightExpressionEnabled: boolean;
  operation: TestAssertionOperation;
  operationTypes: TestAssertionOperationType[];
  testRunButtonState: TestRunState;
  assertions: ITestAssertion[];
  testSuites: string[];
  parameters: ParamRow[] | undefined;

  // root prefixes of call trees for a trace id
  rootPrefixes: { [traceId: string]: string };
  // map of list of child path prefixes for a given pathPrefix of a trace
  childNodes: { [traceId: string]: { [pathPrefix: string]: string[] } };
  // map of child path prefixes to the status of that path prefix node.
  nodeStatus: { [traceId: string]: { [pathPrefix: string]: string } };
  // PrefixSummary at a given path prefix of a trace
  prefixSummaries: {
    [traceId: string]: { [pathPrefix: string]: PrefixSummary };
  };
  summaryTreeLoadState: { [traceId: string]: TreeLoadState };
  selectedPrefix?: { traceId: string; prefix: string };

  // Assist chat bubble related state
  isAssistChatBubbleOpen: boolean;
  assistChatMessage: string;
  showOkButtonInAssistChat: boolean;
  showSkipButtonInAssistChat: boolean;

  // Assertion results view related state
  isAssertResultsPopupOpen: boolean;
  isLinkTestScenarioPopupOpen: boolean;
  isParameterizePopupOpen: boolean;
  isParamRowSelectPopupOpen: boolean;
  assertionEvaluations: { [id: string]: TestAssertionResult };
}

export enum TestRunState {
  UNKNOWN = "UNKNOWN",
  RUNNING = "RUNNING",
  NOT_RUNNING = "NOT_RUNNING",
  STOPPED = "STOPPED",
}

const initialState: TestsState = {
  testName: { name: "", suite: "" },
  steps: [],
  selectedStepIndex: 0,
  isSaveTestOpen: false,
  isAddStepOpen: false,
  listTestSuitesLoading: false,
  skipAutoAuthHeaderUpdate: false,
  addStepLoading: false,
  evalAssistSuggestionsForStep: false,
  fieldVocabs: {},
  listFieldVocabsLoading: false,
  listRequestIdsLoading: false,
  getRequestLoading: false,
  generateTestRequestLoading: false,
  testRunButtonState: TestRunState.NOT_RUNNING,
  runTestLoading: false,
  saveTestLoading: false,
  createTestSuiteLoading: false,
  requestIds: {},
  selectedRequestId: {},
  selectedTab: {},
  isGeneratorDrawerOpen: false,
  isVariableDrawerOpen: false,
  isAssertionsDrawerOpen: false,
  isAssertionOpen: false,
  environment: {},
  endpoint: {},
  environmentEndpoints: {},
  variables: {},
  linkedScenarios: [],
  activeVariableStoreTab: VariableStoreTab.AVAILABLE,
  rawRequest: {},
  testRequest: {},
  testResponse: {},
  traceId: {},
  scripts: [],
  requestGenBodyString: {},
  testRequestBodyString: {},
  testResponseBodyString: {},
  requestGenBodyTree: {},
  testRequestBodyTree: {},
  testResponseBodyTree: {},
  bodyGenerators: {},
  headerGenerators: {},
  autoAuthHeaderGenerator: undefined,
  queryParamGenerators: {},
  pathParamGenerators: {},
  leftExpressionChip: "",
  rightExpressionChip: "",
  isTestCreateWizardOpen: false,
  isTestCreateWizardShown: false,
  isRightExpressionEnabled: true,
  operation: TestAssertionOperation.EQUALS,
  operationTypes: [TestAssertionOperationType.VALUE, TestAssertionOperationType.EMPTY, TestAssertionOperationType.NULL],
  renderCount: 0,
  assertions: [],
  testSuites: [],
  parameters: undefined,
  rootPrefixes: {},
  childNodes: {},
  nodeStatus: {},
  prefixSummaries: {},
  summaryTreeLoadState: {},

  assertionEvaluations: {},
  isAssistChatBubbleOpen: false,
  isLinkTestScenarioPopupOpen: false,
  isParameterizePopupOpen: false,
  isParamRowSelectPopupOpen: false,
  assistChatMessage: "",
  showOkButtonInAssistChat: false,
  showSkipButtonInAssistChat: false,

  isAssertResultsPopupOpen: false,
};

export type SetTraceIdForStepPayload = {
  traceId: string;
  stepId: string;
};

export type PushAssertionsPayload = {
  assertions: ITestAssertion[];
};

export type RemoveAssertionPayload = {
  assertionId: string;
};

// super type for all payloads that require a step to operate on (use this instead of relying on selectedStep to allow for reusing the reducers from anywhere).
export type StepContextualPayload = {
  step: IStep;
};

// A generic payload that carries a string value inside it. Use this instead of PayloadAction<string> for step contextual reducers.
export interface StepContextualStringPayload extends StepContextualPayload {
  value: string;
}

export interface SetEndpointPayload extends StepContextualPayload {
  endpoint: string;
}

export type SetAutoAuthHeaderGeneratorPayload = {
  generator: IGenerator;
};

export type SelectPrefixPayload = {
  stepId: string;
  prefix: string;
};

export const selectTestStepExecutionRecords = (state: TestsState): ITestStepExecutionRecord[] => {
  return state.steps?.map((step) => {
    const rawRequest = state.testRequest[step.id];
    const rawResponse = state.testResponse[step.id];
    const traceId = state.traceId[step.id];
    return {
      stepName: step.name || "", // Provide a default value in case step.name is undefined
      rawRequest: rawRequest || ({} as IRawRequest), // Provide a default value if rawRequest is undefined
      rawResponse: rawResponse || ({} as IRawResponse), // Provide a default value if rawResponse is undefined
      traceId: traceId ?? "",
    };
  });
};

const getStepIdForTraceId = (state: TestsState, traceId: string): string | undefined => {
  const entries = Object.entries(state.traceId).filter(([stepId, id]) => id === traceId);
  return entries.length > 0 ? entries[0][0] : undefined;
};

const initMapsForStep = (state: TestsState, step: IStep): void => {
  if (!state.rawRequest[step.id]) {
    state.rawRequest[step.id] = {};
  }
  if (!state.headerGenerators[step.id]) {
    state.headerGenerators[step.id] = {};
  }
  if (!state.bodyGenerators[step.id]) {
    state.bodyGenerators[step.id] = {};
  }
  if (!state.queryParamGenerators[step.id]) {
    state.queryParamGenerators[step.id] = {};
  }
  if (!state.pathParamGenerators[step.id]) {
    state.pathParamGenerators[step.id] = {};
  }
};
export const getStepByName = (state: TestsState, stepName: string): IStep | undefined => {
  return state.steps.find((step) => step.name === stepName);
};

export const getVariableNameToValueMap = (state: TestsState): { [name: string]: TypedValueOrList } => {
  const nameToValueMap: { [name: string]: TypedValueOrList } = {};

  Object.values(state.variables).forEach((variableArray) => {
    variableArray.forEach((variable) => {
      nameToValueMap[variable.name] = variable.value;
    });
  });

  return nameToValueMap;
};

export const getAwareApiTest = createAsyncThunk<IGetAwareApiTestResponse, { request: IGetAwareApiTestRequest }>(
  "getAwareApiTest",
  async ({ request }) => {
    // Fetch the list of request IDs
    let response: IGetAwareApiTestResponse = await Test.getAwareApiTest(request);
    return response;
  }
);

export const listRequestIdsForContextForStep = createAsyncThunk<
  string[],
  { request: IListRequestIdsForContextRequest; step: IStep }
>("listRequestIdsForContextForStep", async ({ request, step }) => {
  // Fetch the list of request IDs
  const response: { ids: string[] } = await Test.listRequestIdsForContext(request);

  // Return the list of IDs or an empty array if undefined/null
  return response.ids ?? [];
});
export const updateLinkedScenariosForTest = createAsyncThunk<void, { request: UpdateLinkedScenariosForTestRequest }>(
  "updateLinkedScenariosForTest",
  async ({ request }) => {
    const response: UpdateLinkedScenariosForTestResponse = await Test.updateLinkedScenariosForTest(request);
  }
);

export const getRequestObject = createAsyncThunk("getRequestObject", async (request: IGetRequestObjectRequest) => {
  return (await Test.getRequestObject(request)) as IGetRequestObjectResponse;
});

export const fetchRequestObjectForStepAndSetGenerators = createAsyncThunk<
  IGetRequestObjectResponse,
  { request: IGetRequestObjectRequest; step: IStep },
  { state: RootState }
>("fetchRequestObjectForStepAndSetGenerators", async ({ request, step }) => {
  return (await Test.getRequestObject(request)) as IGetRequestObjectResponse;
});

export const generateAndExecuteStep = createAsyncThunk<
  void,
  void, { dispatch: AppDispatch; state: RootState }
>('generateAndExecuteStep', async (_, { dispatch, getState }) => {
  // Step 1: Dispatch the generateTestRequestForStepId action
  const rootState = getState() as RootState;
  const state = rootState.tests;

  if (state.selectedStep) {
    let payload: any = {
      stepId: state.selectedStep.id,
      environment: state.environment[state.selectedStep.id],
    };
    if (state.parameters && state.parameters.length > 0) {
      payload.paramRow = state.parameters[0];
    }
    await dispatch(generateTestRequestForStepId(payload));

    // Step 2: After the first dispatch completes, dispatch executeStep
    await dispatch(executeStep({ step: state.selectedStep, awaitCallTreeLoading: false }));
  }
});

export const generateTestRequestForStepId = createAsyncThunk<
  void,
  { stepId: string; environment: string; paramRow?: ParamRow },
  { dispatch: AppDispatch; state: RootState }
>("generateTestRequestForStepId", async ({ stepId, environment, paramRow }, { dispatch, getState }) => {
  const rootState = getState() as RootState;
  const state = rootState.tests;

  const baseVariableValues = getMemoizedAvailableVariables(rootState).reduce(
    (previous: IGenerateTestRequestRequest["variableValues"], current) => {
      previous[current.name] = current.value;
      return previous;
    },
    {}
  );

  const additionalVariableValues = paramRow ? paramRow.values : {};

  const request: IGenerateTestRequestRequest = {
    requestConfig: getRequestConfig(
      state.headerGenerators[stepId][state.selectedRequestId[stepId]],
      state.bodyGenerators[stepId][state.selectedRequestId[stepId]],
      state.queryParamGenerators[stepId][state.selectedRequestId[stepId]],
      state.pathParamGenerators[stepId][state.selectedRequestId[stepId]],
      state.rawRequest[stepId][state.selectedRequestId[stepId]].httpMethod,
      state.variables[stepId]
    ),
    variableValues: {
      ...baseVariableValues,
      ...additionalVariableValues,
    },
    environment: environment,
  };

  await dispatch(generateTestRequest(request));
});

export const evaluateAssertions = createAsyncThunk<
  void, // Return type of the async thunk
  void, // Payload type (no payload expected)
  { dispatch: AppDispatch; state: RootState } // Thunk API configuration
>("evaluateAssertions", async (_, { dispatch, getState }) => {
  const rootState = getState() as RootState;
  const executionRecords = selectTestStepExecutionRecords(rootState.tests);
  const variables = getVariableNameToValueMap(rootState.tests);
  let request: IEvaluateAssertionsRequest = {
    executionRecords: executionRecords,
    variables: variables,
    assertions: rootState.tests.assertions,
  };
  const maxAttempts = 5;
  let attempt = 0;
  let response: IEvaluateAssertionResponse = {
    continuationState: ContinuationState.UNKNOWN_CONTINUATION_STATE,
    results: [],
  };
  while (attempt < maxAttempts) {
    response = await Test.evaluateAssertions(request);
    if (response.continuationState == ContinuationState.POTENTIALLY_INCOMPLETE) {
      console.log("Trying eval asserts later");
      await delay(4000);
      attempt++;
    } else {
      console.log("Eval asserts complete");
      break;
    }
  }
  if (response?.results) {
    dispatch(setAssertionEvaluations(response.results));
    dispatch(setIsAssertResultsPopupOpen(true));
  }
});

export const generateTestRequest = createAsyncThunk(
  "generateTestRequest",
  async (request: IGenerateTestRequestRequest) => {
    return await Test.generateTestRequest(request);
  }
);

export const listFieldVocabs = createAsyncThunk("listFieldVocabs", async (request: IListFieldVocabsRequest) => {
  return await Test.listFieldVocabs(request);
});

export const listScripts = createAsyncThunk("listScripts", async (request: IListScriptsRequest) => {
  return await Test.listScripts(request);
});
export const getEnvironmentEndpoints = createAsyncThunk(
  "getEnvironmentEndpoints",
  async (request: { operation: QualifiedOperation; environments: string[] }) => {
    const result = Promise.all(
      request.environments?.map((env) =>
        Test.getEndpointsForOperation({
          environment: env,
          operation: request.operation,
        })
      )
    );

    return result;
  }
);


export const runTest = createAsyncThunk<
  { isDryRun: boolean; response: RunTestsResponse },
  { request: RunTestsRequest },
  { state: RootState }
>(
  "runTest",
  async (
    { request }: { request: RunTestsRequest },
    { getState }
  ): Promise<{ isDryRun: boolean; response: RunTestsResponse }> => {
    try {
      const resolveVariables = (endpoint: string, environment: string): { resolvedEndpoint: string, missingVariables: string[] } => {
        const state = getState() as RootState;
        const environmentConfigs = state.projectSettings.projectConfig.environmentConfigs;

        const envConfig = environmentConfigs[environment];
        if (!envConfig) {
          console.warn(`Environment ${environment} not found in environmentConfigs.`);
          return { resolvedEndpoint: endpoint, missingVariables: [] };
        }

        const variableOverrides = envConfig.variableOverrides;
        const missingVariables: string[] = [];

        // Replace each {{variable}} with its corresponding value in variableOverrides
        const resolvedEndpoint = endpoint.replace(/\{\{(\w+)\}\}/g, (_, variableName) => {
          const value = variableOverrides?.[variableName];
          if (value === undefined) {
            missingVariables.push(variableName);
            return `{{${variableName}}}`; // Keep unresolved if not found
          }
          return value;
        });

        return { resolvedEndpoint, missingVariables };
      };

      if (request.rawTestStepExecution) {
        // Check for the extension presence by sending a message to the window
        const extensionCheck = await new Promise<{ success: boolean }>((resolve, reject) => {
          window.postMessage({ type: "check_extension" }, "*");
          const listener = (event: MessageEvent) => {
            if (event.data.type === "check_extension_response") {
              resolve(event.data);
              window.removeEventListener("message", listener);
            }
          };
          window.addEventListener("message", listener);
          const timeout = setTimeout(() => {
            window.removeEventListener("message", listener);
            resolve({ success: false });
          }, 1000);
        });


        if (!extensionCheck.success) {
          // If the extension is not installed or disabled, proceed with the normal API call
          const response: RunTestsResponse = await Test.runTest(request);
          return {
            isDryRun: "rawTestStepExecution" in request,
            response,
          };
        }

        // Resolve variables in the endpoint configurations
        let varResolvedEndpoint: string | undefined;
        let missingVariables: string[] = [];

        if (request.rawTestStepExecution?.internalEndpointConfig?.preferredEndpoint) {
          const { resolvedEndpoint, missingVariables: internalMissingVars } = resolveVariables(
            request.rawTestStepExecution.internalEndpointConfig.preferredEndpoint,
            request.environment!
          );
          request.rawTestStepExecution.internalEndpointConfig.preferredEndpoint = resolvedEndpoint;
          varResolvedEndpoint = resolvedEndpoint;
          missingVariables = internalMissingVars;
        }

        if (request.rawTestStepExecution?.externalEndpoint) {
          const { resolvedEndpoint, missingVariables: externalMissingVars } = resolveVariables(
            request.rawTestStepExecution.externalEndpoint,
            request.environment!
          );
          request.rawTestStepExecution.externalEndpoint = resolvedEndpoint;
          varResolvedEndpoint = varResolvedEndpoint || resolvedEndpoint;
          missingVariables = [...missingVariables, ...externalMissingVars];
        }

        // If there are missing variables, return an error response
        if (missingVariables.length > 0) {
          return {
            isDryRun: true,
            response: {
              errorReport: {
                errorType: "Missing environment variables.",
                errorDescription: `[${missingVariables.join(", ")}] are not available in ${request.environment!}`,
                sutEndpoint: varResolvedEndpoint,
              }
            }
          };
        }

        const extensionResponse = await new Promise<RunTestsResponse>((resolve, reject) => {
          window.postMessage({ type: "run_tests_request", raw: request }, "*");
          window.addEventListener("message", function listener(event) {
            if (event.data.type === "run_tests_response") {
              if (event.data.error) {
                reject(new Error(event.data.error));
              } else {
                resolve(event.data.response); // Expecting { data: RunTestsResponse }
              }
              window.removeEventListener("message", listener); // Clean up the listener
            }
          });
        });

        return {
          isDryRun: "rawTestStepExecution" in request,
          response: extensionResponse,
        };
      } else {
        // If run test is not for a single step execution, need to run through the testchimp servers.
        const response: RunTestsResponse = await Test.runTest(request);
        return {
          isDryRun: "rawTestStepExecution" in request,
          response,
        };
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Run test failed:", error);
        throw new Error("Run test failed: " + error.message);
      } else {
        console.error("An unknown error occurred:", error);
        throw new Error("An unknown error occurred");
      }
    }
  }
);

export const executeStep = createAsyncThunk<
  { response: RunTestsResponse },
  { step: IStep; awaitCallTreeLoading: boolean; environment?: string },
  { dispatch: AppDispatch; state: RootState }
>("executeStep", async ({ step, awaitCallTreeLoading, environment }, { dispatch, getState }): Promise<{ response: RunTestsResponse }> => {

  const rootState = getState() as RootState;
  const state = rootState.tests;
  let request: RunTestsRequest;

  if (step.context.internalContext) {
    request = {
      rawTestStepExecution: {
        internalEndpointConfig: {
          environment: state.environment[step.id],
          operation: step.context.internalContext.operation,
          preferredEndpoint: state.endpoint[step.id],
        },
        rawRequest: state.testRequest[step.id],
      },
      environment: environment ?? state.environment[step.id],
    };
  } else if (step.context.externalContext) {
    request = {
      rawTestStepExecution: {
        externalEndpoint: state.endpoint[step.id] || "",
        rawRequest: state.testRequest[step.id],
      },
      environment: environment ?? state.environment[step.id],
    };
  } else if (step.context.testStep) {
    if (step.context.testStep.internalEndpointConfig) {
      request = {
        rawTestStepExecution: {
          internalEndpointConfig: {
            environment: state.environment[step.id],
            operation: step.context.testStep.internalEndpointConfig.operation,
            preferredEndpoint: state.endpoint[step.id],
          },
          rawRequest: state.testRequest[step.id],
        },
        environment: environment ?? state.environment[step.id],
      };
    } else {
      request = {
        rawTestStepExecution: {
          externalEndpoint: state.endpoint[step.id] || "",
          rawRequest: state.testRequest[step.id],
        },
        environment: environment ?? state.environment[step.id],
      };
    }
  } else {
    request = {};
  }

  console.log("Request", request);
  if (request) {
    const runTestResult = await dispatch(runTest({ request })).unwrap();
    const traceId = runTestResult.response.traceId;
    if (traceId && /[1-9a-zA-Z]/.test(traceId)) {
      if (awaitCallTreeLoading) {
        console.log("Awaiting until the complete call tree is fethced for " + traceId);
        await dispatch(loadTraceSummary({ traceId: traceId })).unwrap();
      } else {
        console.log("Async loading call tree for " + traceId);
        dispatch(loadTraceSummary({ traceId: traceId }));
      }
    }
    return { response: runTestResult.response };
  }
  return { response: {} };
});

export const getAssertionHash = createAsyncThunk("tests/getAssertionHash", async (_, { getState }) => {
  const state = getState() as RootState;
  const leftExpression = state.tests.leftExpression!;
  const rightExpression = state.tests.rightExpression!;
  const operation = state.tests.operation;

  const response = await Test.getAssertionHash({
    assertion: {
      leftExpression: leftExpression,
      rightExpression: rightExpression,
      assertionOperation: operation,
      assertionId: "",
    },
  });
  return response.hash;
});

export const saveTest = createAsyncThunk("saveTest", async (request: ISaveTestRequest) => {
  const response = await Test.saveTest(request);
  return { response, request };
});

export const listTestSuites = createAsyncThunk("listTestSuites", async (request: IListTestSuitesRequest) => {
  return await Test.listTestSuites(request);
});

export const listTestCases = createAsyncThunk("listTestCases", async (request: IListTestCasesRequest) => {
  return await Test.listTestCases(request);
});

export const listTestStepsFromTest = createAsyncThunk(
  "listTestStepsFromTest",
  async (request: IListTestStepsFromTestRequest) => {
    return await Test.listTestStepsFromTest(request);
  }
);

export const listTestCasesWithRunDetails = createAsyncThunk(
  "listTestCasesWithRunDetails",
  async (request: IListTestCasesWithRunDetailsRequest) => {
    return await Test.listTestCasesWithRunDetails(request);
  }
);

export const createTestSuite = createAsyncThunk("createTestSuite", async (request: ICreateTestSuiteRequest) => {
  return await Test.createTestSuite(request);
});

export const selectEnvVariableNames = createSelector(
  (state: RootState) => state.projectSettings.projectConfig, // Extract projectConfig from state
  (projectConfig: ProjectConfig | undefined) => {
    if (!projectConfig || !projectConfig.environmentConfigs) {
      return [];
    }

    // Get all the environment configs
    const envConfigs = Object.values(projectConfig.environmentConfigs);

    // Collect all the variable names from variableOverrides
    const allVariableNames = envConfigs.reduce((acc: Set<string>, envConfig) => {
      const variableKeys = Object.keys(envConfig.variableOverrides || {});
      variableKeys.forEach((key) => acc.add(key)); // Using a Set to avoid duplicates
      return acc;
    }, new Set<string>());

    // Convert the Set to an array of variable names
    return Array.from(allVariableNames);
  }
);

export const selectParameterNames = createSelector(
  (state: RootState) => state.tests.parameters, // Assuming your parameters are in state
  (parameters: ParamRow[] | undefined) => {
    if (!parameters || parameters.length === 0) {
      return [];
    }

    // Collect all keys from the `values` Record<string, TypedValueOrList> in each ParamRow
    const parameterKeys = parameters.flatMap((paramRow) => Object.keys(paramRow.values));

    // To return unique keys (in case keys repeat across different ParamRows):
    return Array.from(new Set(parameterKeys));
  }
);

export const getMemoizedAvailableVariables = createSelector(
  [
    (state: RootState) => state.tests.steps,
    (state: RootState) => state.tests.variables,
    (state: RootState) => state.tests.selectedStepIndex,
  ],
  (steps, variables, stepIndex) => {
    let availableVariables = [];

    for (let index = 0; index < stepIndex; index++) {
      const stepVariables = variables[steps[index].id] ?? [];
      availableVariables.push(...stepVariables);
    }

    return availableVariables;
  }
);

export const isNotAllStepsRun = createSelector([(state: RootState) => state.tests], (tests) => {
  const steps = tests.steps;
  const testResponses = tests.testResponse;
  return steps.some((step) => {
    const rawResponse = testResponses[step.id];
    return !rawResponse;
  });
});

const getHttpMethod = (state: TestsState, stepId: string) => {
  if (state.testRequest[stepId]) {
    return state.testRequest[stepId].httpMethod;
  }
  if (state.rawRequest[stepId] && state.rawRequest[stepId][state.selectedRequestId[stepId]]) {
    return state.rawRequest[stepId][state.selectedRequestId[stepId]].httpMethod;
  }
  return "POST";
};

export const createTestStep = (state: TestsState, step: IStep): TestStep => {
  const httpMethod: string = getHttpMethod(state, step.id);
  let testStep: TestStep = {
    name: step.name!,
    requestConfig: getRequestConfig(
      state.headerGenerators[step.id][state.selectedRequestId[step.id]],
      state.bodyGenerators[step.id][state.selectedRequestId[step.id]],
      state.queryParamGenerators[step.id][state.selectedRequestId[step.id]],
      state.pathParamGenerators[step.id][state.selectedRequestId[step.id]],
      httpMethod,
      state.variables[step.id]
    ),
    responseConfig: {
      variableRecordConfigs: getOperationNonContextualVariables(state.variables[step.id], ContextType.RESPONSE),
    },
    callTreeConfig: {
      variableRecordConfigs: getOperationContextualVariables(state.variables[step.id]),
    },
  };

  if (step.context.internalContext) {
    testStep.internalEndpointConfig = {
      environment: state.environment[step.id],
      operation: step.context.internalContext.operation,
      preferredEndpoint: state.endpoint[step.id],
    };
  } else if (step.context.externalContext) {
    testStep.externalEndpoint = step.context.externalContext.uri || "";
  } else if (step.context.testStep) {
    let testStepContext = step.context.testStep;
    if (testStepContext.internalEndpointConfig) {
      testStep.internalEndpointConfig = {
        ...testStepContext.internalEndpointConfig,
        preferredEndpoint: state.endpoint[step.id],
      };
    } else if (testStepContext.externalEndpoint) {
      testStep.externalEndpoint = state.endpoint[step.id];
    }
  }

  return testStep;
};

export const getSerializedTest = (tests: TestsState): IAwareApiTest => {
  let config: IAwareApiTest = {
    testName: {
      name: tests.testName.name,
      suite: tests.testName.suite,
    },
    rootSteps: tests.steps?.map((step) => createTestStep(tests, step)),
    assertions: tests.assertions,
    parameterizeConfig: {
      rows: tests.parameters ?? [],
    },
  };
  return config;
};

const getPreferredEndpointHash = (testStep: TestStep) => {
  if (testStep.internalEndpointConfig) {
    return generateHash(testStep.internalEndpointConfig.operation);
  } else if (testStep.externalEndpoint) {
    return generateHash(testStep.externalEndpoint);
  }
  return "";
};

const setOrderBasedDisplayName = (newSteps: IStep[], existingSteps: IStep[]) => {
  const operationHashToCountMap: { [hash: string]: number } = {};

  function addOperationHash(operationHash: string) {
    const existingCount = operationHashToCountMap[operationHash];
    const newCount = existingCount ? existingCount + 1 : 1;
    operationHashToCountMap[operationHash] = newCount;
    return newCount;
  }

  existingSteps.forEach((step) => {
    let hash = "";
    if (step.context.internalContext) {
      hash = generateHash(step.context.internalContext.operation);
    } else if (step.context.externalContext) {
      if (step.context.externalContext.template) {
        // If standard template used for external endpoint, use the name of the template for the step name.
        hash = generateHash(step.context.externalContext.template?.name + "");
      } else {
        // else, use the base uri
        hash = generateHash(getBaseURIWithoutQuery(step.context.externalContext.uri));
      }
    } else if (step.context.testStep) {
      let testStep: TestStep = step.context.testStep;
      hash = getPreferredEndpointHash(testStep);
    }
    addOperationHash(hash);
  });

  return newSteps?.map((step) => {
    let hash = "";
    if (step.context.internalContext) {
      hash = generateHash(step.context.internalContext.operation);
      const suffix = addOperationHash(hash) - 1;
      step.name = `${step.context.internalContext.operation.operationName}[${suffix}]`;
    } else if (step.context.externalContext) {
      if (step.context.externalContext.template) {
        hash = generateHash(step.context.externalContext.template?.name + "");
      } else {
        hash = generateHash(getBaseURIWithoutQuery(step.context.externalContext.uri));
      }
      const suffix = addOperationHash(hash) - 1;
      step.name = `${step.context.externalContext.template
        ? step.context.externalContext.template.name
        : getBaseURIWithoutQuery(step.context.externalContext.uri)
        }[${suffix}]`;
    } else if (step.context.testStep) {
      hash = getPreferredEndpointHash(step.context.testStep);
      const suffix = addOperationHash(hash) - 1;
      step.name = `${getNameWithoutTrailingNumber(step.context.testStep.name)}[${suffix}]`;
    }
    return step;
  });
};

function getNameWithoutTrailingNumber(name: string) {
  const match = name.match(/^(.*?)(\[\d+\])?$/);
  return match ? match[1].trim() : name;
}

const fieldConfigToIGenerator = (fieldConfig: FieldConfig) => {
  let gen: IGenerator = {
    type: GeneratorType.FREEFORM,
    value: "",
    displayValue: "",
    dataType: FieldDataType.STRING
  };
  if (fieldConfig.variableExpression) {
    gen.type = GeneratorType.FREEFORM;
    gen.value = fieldConfig.variableExpression.expression;
    gen.displayValue = fieldConfig.variableExpression.expression;
    gen.dataType = fieldConfig.fieldType;
  } else if (fieldConfig.scriptConfig) {
    gen.type = GeneratorType.SCRIPT;
    gen.value = fieldConfig.scriptConfig.script;
    gen.displayValue = fieldConfig.scriptConfig.nickname;
  } else if (fieldConfig.vocabConfig) {
    gen.type = GeneratorType.VOCABULORY;
    gen.value = fieldConfig.vocabConfig.options;
    gen.displayValue = fieldConfig.vocabConfig.nickname;
  }
  return gen;
};

const getEndpointForTestStep = (step: TestStep) => {
  if (step.externalEndpoint) {
    return step.externalEndpoint;
  }
  if (step.internalEndpointConfig) {
    return step.internalEndpointConfig.preferredEndpoint;
  }
  return "";
};

const processOperationContextualVariableRecordConfig = (
  dispatch: AppDispatch,
  opContextualVariableRecord: IOperationContextualVariableRecordConfig,
  id: string
) => {
  processVariableRecordConfig(
    dispatch,
    opContextualVariableRecord.config,
    id,
    getContextType(opContextualVariableRecord.payloadLocator),
    opContextualVariableRecord.operationLocator
  );
};

const processVariableRecordConfig = (
  dispatch: AppDispatch,
  variableRecord: VariableRecordConfig,
  id: string,
  contextType: ContextType,
  operationLocator: OperationLocator | undefined
) => {
  console.log("Processing var config: ", variableRecord, id, contextType);
  if (variableRecord.locator.headerKey) {
    dispatch(
      addVariableByStepAndLocator({
        name: variableRecord.variableName!,
        value: { singleValue: { stringValue: "" } },
        section: HTTPMessageSection.HEADER,
        contextType: contextType,
        locator: variableRecord.locator.headerKey,
        stepId: id,
        operationLocator: operationLocator,
      })
    );
  }
  if (variableRecord.locator.bodyValueLocator) {
    let bodyLocator: BodyValueLocator = variableRecord.locator.bodyValueLocator;
    if ("jsonFieldPath" in bodyLocator) {
      dispatch(
        addVariableByStepAndLocator({
          name: variableRecord.variableName!,
          value: { singleValue: { stringValue: "" } },
          section: HTTPMessageSection.BODY,
          contextType: contextType,
          locator: bodyLocator.jsonFieldPath + "",
          stepId: id,
          operationLocator: operationLocator,
        })
      );
    }
  }
  if (variableRecord.locator.queryParamKey) {
    dispatch(
      addVariableByStepAndLocator({
        name: variableRecord.variableName!,
        value: { singleValue: { stringValue: "" } },
        section: HTTPMessageSection.QUERY_PARAMS,
        contextType: contextType,
        locator: variableRecord.locator.queryParamKey,
        stepId: id,
        operationLocator: operationLocator,
      })
    );
  }
  if (variableRecord.locator.templateParamKey) {
    dispatch(
      addVariableByStepAndLocator({
        name: variableRecord.variableName!,
        value: { singleValue: { stringValue: "" } },
        section: HTTPMessageSection.PATH_PARAMS,
        contextType: contextType,
        locator: variableRecord.locator.templateParamKey,
        stepId: id,
        operationLocator: operationLocator,
      })
    );
  }
};


export const duplicateStepAndLoadData = createAsyncThunk<
  void,
  number, // Payload type: index of the step to duplicate
  { dispatch: AppDispatch; state: RootState }
>(
  'tests/duplicateStepAndLoadData',
  async (index, { dispatch, getState }) => {
    // Dispatch the duplicateStep action to add the duplicated step
    dispatch(testsSlice.actions.duplicateStep(index));

    // Access the updated state
    const rootState = getState() as RootState;
    const state = rootState.tests;

    // Retrieve the duplicated step
    const newStepIndex = index + 1; // Assuming new step is added at the next position
    const createdStep = state.steps[newStepIndex];

    // Call loadTestStepData with the duplicated step if it exists
    if (createdStep?.context?.testStep) {
      await dispatch(loadTestStepData(createdStep));
    }
  }
);

// Loads a test step in to the current test (used when loading an existing test / existing test step)
export const loadTestStepData = createAsyncThunk<void, IStep, { dispatch: AppDispatch }>(
  "steps/loadTestStepData",
  async (step, { dispatch }) => {
    const { testStep } = step.context;
    if (!testStep) return;
    dispatch(
      setEndpointForStep({
        step: step,
        endpoint: getEndpointForTestStep(step.context.testStep!),
      })
    );
    step.context.testStep?.requestConfig.variableRecordConfigs?.forEach((variableRecord) => {
      processVariableRecordConfig(dispatch, variableRecord, step.id, ContextType.REQUEST, undefined);
    });
    step.context.testStep?.responseConfig.variableRecordConfigs?.forEach((variableRecord) => {
      processVariableRecordConfig(dispatch, variableRecord, step.id, ContextType.RESPONSE, undefined);
    });
    step.context.testStep?.callTreeConfig?.variableRecordConfigs?.forEach((variableRecord) => {
      processOperationContextualVariableRecordConfig(dispatch, variableRecord, step.id);
    });
    const httpMethod = testStep.requestConfig.httpMethod;
    let headers = testStep.requestConfig.headerConfig.headerFields ?? {};
    const initHeaders: { [key: string]: string } = Object.keys(headers).reduce(
      (acc, key) => {
        acc[key] = "";
        return acc;
      },
      {} as { [key: string]: string }
    );

    let queryParams = testStep.requestConfig.queryPathParams ?? {};
    const initQueryParams: { [key: string]: string } = Object.keys(queryParams).reduce(
      (acc, key) => {
        acc[key] = "";
        return acc;
      },
      {} as { [key: string]: string }
    );

    let templateParams = testStep.requestConfig.templatePathParams ?? {};
    const initTemplateParams: { [key: string]: string } = Object.keys(templateParams).reduce(
      (acc, key) => {
        acc[key] = "";
        return acc;
      },
      {} as { [key: string]: string }
    );

    let body = testStep.requestConfig.jsonRequestBodyConfig?.fieldConfigs ?? {};
    let jsonBody = "";
    if (body) {
      jsonBody = createJsonMessageStructure(testStep.requestConfig.jsonRequestBodyConfig?.fieldConfigs ?? {});
    }
    const initRequest: IRawRequest = {
      httpMethod: httpMethod,
      headers: initHeaders,
      body: { jsonBody: jsonBody },
      queryParams: initQueryParams,
      templatePathParams: { keyValueMap: initTemplateParams },
    };

    dispatch(
      setRawRequestForStepIdAndRequestId({
        stepId: step.id,
        requestId: "TEMPLATE",
        rawRequest: initRequest,
      })
    );
    dispatch(selectRequestId({ step: step, value: "TEMPLATE" }));

    const headerGenerators = Object.entries(headers).reduce(
      (acc, [key, fieldConfig]) => {
        acc[key] = fieldConfigToIGenerator(fieldConfig);
        return acc;
      },
      {} as { [key: string]: IGenerator }
    );

    dispatch(
      setAllGenerators({
        step: step,
        section: HTTPMessageSection.HEADER,
        generators: headerGenerators,
      })
    );

    const qpGenerators = Object.entries(queryParams).reduce(
      (acc, [key, fieldConfig]) => {
        acc[key] = fieldConfigToIGenerator(fieldConfig);
        return acc;
      },
      {} as { [key: string]: IGenerator }
    );

    dispatch(
      setAllGenerators({
        step: step,
        section: HTTPMessageSection.QUERY_PARAMS,
        generators: qpGenerators,
      })
    );

    const tpGenerators = Object.entries(templateParams).reduce(
      (acc, [key, fieldConfig]) => {
        acc[key] = fieldConfigToIGenerator(fieldConfig);
        return acc;
      },
      {} as { [key: string]: IGenerator }
    );

    dispatch(
      setAllGenerators({
        step: step,
        section: HTTPMessageSection.PATH_PARAMS,
        generators: tpGenerators,
      })
    );

    const bodyGenerators = Object.entries(body).reduce(
      (acc, [key, fieldConfig]) => {
        acc[key] = fieldConfigToIGenerator(fieldConfig);
        return acc;
      },
      {} as { [key: string]: IGenerator }
    );

    dispatch(
      setAllGenerators({
        step: step,
        section: HTTPMessageSection.BODY,
        generators: bodyGenerators,
      })
    );

    //    if (testStep.internalEndpointConfig) {
    //     dispatch(setEndpoint(testStep.internalEndpointConfig.preferredEndpoint));
    //  } else if (testStep.externalEndpoint) {
    //   dispatch(setEndpoint(testStep.externalEndpoint));
    //}
  }
);

// Thunk to load trace summaries
export const loadTraceSummary = createAsyncThunk<
  void,
  { traceId: string },
  { dispatch: AppDispatch; state: RootState }
>("tests/loadTraceSummary", async ({ traceId }, { dispatch, getState }) => {
  console.log("Fetching for traceId: ", traceId);
  const response = await fetchTraceSummary(dispatch, traceId);

  if (response && response.trees && response.trees.length >= 1) {
    dispatch(
      processTraceSummaryForTrace({
        summaryTree: response.trees[0],
        tId: traceId,
        continuationState: response.continuationState,
      })
    );
  }
});

async function fetchTraceSummary(
  dispatch: AppDispatch,
  traceId: string,
  maxAttempts: number = 12
): Promise<IGetTraceSummaryTreesForTraceResponse | null> {
  let attempts = 0;

  dispatch(
    processTraceSummaryForTrace({
      summaryTree: undefined,
      tId: traceId,
      continuationState: ContinuationState.POTENTIALLY_INCOMPLETE,
    })
  );
  while (attempts < maxAttempts) {
    console.log("attemping to get call tree for " + traceId + " for " + attempts + " time");
    await delay(4000);
    console.log("proceeding with attempt after waiting");
    const response: IGetTraceSummaryTreesForTraceResponse = await Collectors.getTraceSummaryTreesForTrace({ traceId });
    const { continuationState } = response;

    if (continuationState === ContinuationState.COMPLETED) {
      console.log("complete call tree received for " + traceId);
      return response; // Return the response when completed
    } else if (continuationState === ContinuationState.POTENTIALLY_INCOMPLETE) {
      if (response.trees.length >= 0) {
        dispatch(
          processTraceSummaryForTrace({
            summaryTree: response.trees[0],
            tId: traceId,
            continuationState: ContinuationState.POTENTIALLY_INCOMPLETE,
          })
        );
      }
      attempts++;
    }
  }

  // Return null after max attempts
  console.log("Returning null after attempting to fetch for " + traceId);
  return null;
}

const constructValueLocatorExpression = (context: IDecoratedDrawerContext, name: string) => {
  const contextType: ContextType = context.contextType;
  const section = context.section;

  let locator;

  if (section == HTTPMessageSection.HEADER) {
    locator = { headerKey: context.headerKey! };
  } else if (section == HTTPMessageSection.BODY) {
    locator = { bodyValueLocator: { jsonFieldPath: context.bodyPath! } };
  }

  let expression: TestExpression;
  let valueLocator: ValueLocator;

  if (contextType == ContextType.REQUEST) {
    // Handle request specific http message sections: query params / path params
    if (section == HTTPMessageSection.QUERY_PARAMS) {
      locator = { queryParamKey: context.key };
    } else if (section == HTTPMessageSection.PATH_PARAMS) {
      locator = { templateParamKey: context.key };
    }

    valueLocator = {
      stepName: name,
      requestValueLocator: locator,
    };
  } else if (contextType == ContextType.RESPONSE) {
    // Handle response specific http message sections: response code
    if (section == HTTPMessageSection.RESPONSE_CODE) {
      locator = { responseCode: true };
    }
    valueLocator = {
      stepName: name,
      responseValueLocator: locator,
    };
  } else {
    // ContextType.ATTRIBUTES
    valueLocator = {
      stepName: name,
      attributeKey: context.key,
    };
  }

  if (context.operationLocator?.pathPrefix) {
    valueLocator = { operation: context.operationLocator, ...valueLocator };
  }

  expression = {
    valueLocator: valueLocator,
  };

  console.log("Constructured expr", expression);
  console.log("Chip text", TestExpressionUtils.getChipText(expression));
  return {
    expression,
    expressionLabel: TestExpressionUtils.getChipText(expression),
  };
};

const testsSlice = createSlice({
  name: "testsSlice",
  initialState: initialState,
  reducers: {
    setOperation: (state, action: PayloadAction<TestAssertionOperation>) => {
      state.operation = action.payload;
    },
    processTraceSummaryForTrace: (
      state,
      action: PayloadAction<{
        summaryTree: TraceCollectionSummaryTree | undefined;
        tId: string;
        continuationState: ContinuationState;
      }>
    ) => {
      const { summaryTree, tId, continuationState } = action.payload;

      if (summaryTree) {
        console.log("has summary tree", summaryTree);
        const processPrefixSummaryTree = (traceId: string, tree: PrefixSummaryTree) => {
          const { prefix, prefixSummary, childPrefixSummaries } = tree;

          if (!state.childNodes[traceId]) {
            state.childNodes[traceId] = {};
          }
          if (!state.prefixSummaries[traceId]) {
            state.prefixSummaries[traceId] = {};
          }
          if (!state.nodeStatus[traceId]) {
            state.nodeStatus[traceId] = {};
          }

          state.childNodes[traceId][prefix] = childPrefixSummaries?.map((child) => child.prefix);
          state.prefixSummaries[traceId][prefix] = prefixSummary;
          state.nodeStatus[traceId][prefix] = getNodeStatus(prefixSummary);

          childPrefixSummaries?.forEach((child) => {
            processPrefixSummaryTree(traceId, child);
          });
        };

        state.rootPrefixes[tId] = summaryTree.prefixSummaryTree.prefix;
        processPrefixSummaryTree(tId, summaryTree.prefixSummaryTree);
        state.summaryTreeLoadState[tId] =
          continuationState === ContinuationState.COMPLETED ? TreeLoadState.COMPLETE : TreeLoadState.LOADING;
        const stepIdForTrace = getStepIdForTraceId(state, tId);
        console.log("step id for trace", stepIdForTrace);
        if (stepIdForTrace && state.rootPrefixes[tId]) {
          let reqResp: RequestResponse[] | undefined =
            state.prefixSummaries[tId][state.rootPrefixes[tId]].requestResponsePairs;
          if (reqResp && reqResp?.length > 0) {
            state.testResponse[stepIdForTrace] = reqResp[0].response;
            state.testRequest[stepIdForTrace] = reqResp[0].request;
          }
        }
      } else {
        try {
          state.summaryTreeLoadState[tId] =
            continuationState === ContinuationState.COMPLETED ? TreeLoadState.NOT_AVAILABLE : TreeLoadState.LOADING;
        } catch (e) {
          console.log("Error", e);
        }
      }
    },
    selectPrefix: (state, action: PayloadAction<SelectPrefixPayload>) => {
      const selectedStepIndex = state.steps.findIndex((step) => step.id === action.payload.stepId);
      if (selectedStepIndex < 0) {
        return;
      }
      testsSlice.caseReducers.selectStep(state, {
        //Need to reset previous prefix selection
        payload: selectedStepIndex,
        type: testsSlice.actions.selectStep.type,
      });
      let traceId: string | undefined = state.traceId[action.payload.stepId];
      console.log("Trace id: ", traceId);
      if (traceId && action.payload.prefix) {
        state.selectedPrefix = { traceId, prefix: action.payload.prefix };
      } else {
        testsSlice.caseReducers.resetSelectedPrefix(state);
      }
    },
    resetSelectedPrefix: (state) => {
      state.selectedPrefix = undefined;
    },
    setInitialSteps: (state, action: PayloadAction<IStep[]>) => {
      const steps = action.payload;
      if (!isEmpty(steps)) {
        state.steps = [];
        state.steps = setOrderBasedDisplayName(steps, state.steps);

        if (steps[0].context.internalContext) {
          let context = steps[0].context.internalContext;
          const filter = context.query?.filters.find((filter) => filter.key == "environment");
          state.initStepReferenceQuery = {
            filters: filter ? [filter] : [],
            time_window: context.query?.time_window,
          };
        }
        testsSlice.caseReducers.selectStep(state, {
          payload: 0,
          type: testsSlice.actions.selectStep.type,
        });

        testsSlice.caseReducers.updateSelectedTab(state, {
          payload: { prefix: "$", tab: MainTab.REQUEST_GENERATOR },
          type: testsSlice.actions.updateSelectedTab.type,
        });
      }
    },
    addStep: (
      state,
      action: PayloadAction<{
        index: number;
        step: IStep;
      }>
    ) => {
      const step = setOrderBasedDisplayName([action.payload.step], state.steps)[0];
      if (state.initStepReferenceQuery) {
        if (step.context.internalContext) {
          step.context.internalContext.query = state.initStepReferenceQuery;
          state.endpoint[step.id] = step.context.internalContext.endpoint ?? "";
        }
      }
      state.steps.splice(action.payload.index, 0, step);
    },
    addAndSelectStep: (
      state,
      action: PayloadAction<{
        index: number;
        step: IStep;
      }>
    ) => {
      testsSlice.caseReducers.addStep(state, action);
      testsSlice.caseReducers.selectStep(state, {
        payload: action.payload.index,
        type: testsSlice.actions.selectStep.type,
      });
      state.isAddStepOpen && testsSlice.caseReducers.closeAddStepPopup(state);
    },
    updateEvalAssistSuggestionsForStep: (state, action: PayloadAction<boolean>) => {
      state.evalAssistSuggestionsForStep = action.payload;
    },
    setTestRunButtonState: (state, action: PayloadAction<TestRunState>) => {
      state.testRunButtonState = action.payload;
    },
    setAutoAuthHeaderGenerator: (state, action: PayloadAction<SetAutoAuthHeaderGeneratorPayload>) => {
      console.log("Setting autoauth slice");
      state.autoAuthHeaderGenerator = action.payload.generator;
    },
    setSkipAutoAuthHeaderUpdate: (state, action: PayloadAction<boolean>) => {
      state.skipAutoAuthHeaderUpdate = action.payload;
    },
    duplicateStep: (state, action: PayloadAction<number>) => {
      const originalStep = state.steps[action.payload];

      // Create a copy of the step and modify the necessary properties
      let newStep: IStep = {
        ...originalStep,
        id: generateUniqueID(), // Generate a new unique ID
      };
      newStep = setOrderBasedDisplayName([newStep], state.steps)[0];
      if (newStep.context.testStep) {
        const { requestConfig, ...restTestStep } = newStep.context.testStep;

        newStep.context.testStep = {
          ...restTestStep, // Keep all other properties of testStep
          requestConfig: {
            ...requestConfig, // Spread requestConfig properties
            variableRecordConfigs: [], // Remove variableRecordConfigs
          },
          responseConfig: { variableRecordConfigs: [] }, // Remove responseConfig
          callTreeConfig: undefined, // Remove callTreeConfig
        };
      }
      testsSlice.caseReducers.addAndSelectStep(state, { payload: { index: action.payload + 1, step: newStep }, type: testsSlice.actions.addAndSelectStep.type });
    },
    removeStep: (state, action: PayloadAction<number>) => {
      const stepId = state.steps[action.payload].id;

      state.steps.splice(action.payload, 1);

      delete state.requestIds[stepId];
      delete state.selectedRequestId[stepId];
      delete state.rawRequest[stepId];
      delete state.testRequest[stepId];
      delete state.testResponse[stepId];
      delete state.selectedTab[stepId];
      delete state.environmentEndpoints[stepId];
      delete state.fieldVocabs[stepId];
      delete state.variables[stepId];
      delete state.bodyGenerators[stepId];
      delete state.headerGenerators[stepId];
      delete state.environment[stepId];
      delete state.endpoint[stepId];
      delete state.requestGenBodyString[stepId];
      delete state.testRequestBodyString[stepId];
      delete state.testResponseBodyString[stepId];
      delete state.requestGenBodyTree[stepId];
      delete state.testRequestBodyTree[stepId];
      delete state.testResponseBodyTree[stepId];
      testsSlice.caseReducers.selectStep(state, {
        payload: 0,
        type: testsSlice.actions.selectStep.type,
      });
    },
    selectStep: (state, action: PayloadAction<number>) => {
      state.selectedStepIndex = action.payload;
      if (state.steps.length <= action.payload) {
        return;
      }
      state.selectedStep = state.steps[action.payload];
      // if (!state.selectedStep) {
      //   if (action.payload != 0) {
      //     testsSlice.caseReducers.selectStep(state, { payload: 0, type: testsSlice.actions.selectStep.type });
      //   }
      //   return;
      // }

      state.selectedPrefix && testsSlice.caseReducers.resetSelectedPrefix(state);

      const mainTab = state.selectedTab[state.selectedStep!.id]?.["$"];

      if (!mainTab) {
        testsSlice.caseReducers.updateSelectedTab(state, {
          payload: { prefix: "$", tab: MainTab.REQUEST_GENERATOR },
          type: testsSlice.actions.updateSelectedTab.type,
        });
      }
    },
    setAssistChatMessage: (state, action: PayloadAction<string>) => {
      state.assistChatMessage = action.payload;
    },
    setIsAssertResultsPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.isAssertResultsPopupOpen = action.payload;
    },
    setIsAssistChatBubbleOpen: (state, action: PayloadAction<boolean>) => {
      state.isAssistChatBubbleOpen = action.payload;
    },
    setIsLinkTestScenarioPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.isLinkTestScenarioPopupOpen = action.payload;
    },
    setIsParameterizePopupOpen: (state, action: PayloadAction<boolean>) => {
      state.isParameterizePopupOpen = action.payload;
    },
    setIsParamRowSelectPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.isParamRowSelectPopupOpen = action.payload;
    },
    setShowOkButtonInAssistChat: (state, action: PayloadAction<boolean>) => {
      state.showOkButtonInAssistChat = action.payload;
    },
    setShowSkipButtonInAssistChat: (state, action: PayloadAction<boolean>) => {
      state.showSkipButtonInAssistChat = action.payload;
    },
    setRawRequestForStepIdAndRequestId(
      state,
      action: PayloadAction<{
        stepId: string;
        requestId: string;
        rawRequest: IRawRequest;
      }>
    ) {
      const { stepId, requestId, rawRequest } = action.payload;
      if (!state.rawRequest[stepId]) {
        state.rawRequest[stepId] = {};
      }
      state.rawRequest[stepId][requestId] = rawRequest;
      state.requestIds[stepId] = [requestId];
    },
    openAssertion: (state) => {
      state.isAssertionOpen = true;
    },
    closeAssertion: (state) => {
      state.isAssertionOpen = false;
      testsSlice.caseReducers.clearDraftAssertion(state, {
        payload: undefined,
        type: testsSlice.actions.clearDraftAssertion.type,
      });
    },
    clearDraftAssertion: (state, action: PayloadAction<TestAssertionSide | undefined>) => {
      if (action.payload == undefined) {
        state.leftExpression = undefined;
        state.leftExpressionChip = "";
        state.rightExpression = undefined;
        state.rightExpressionChip = "";
        state.operation = TestAssertionOperation.EQUALS;
      } else if (action.payload == TestAssertionSide.LEFT) {
        state.leftExpression = undefined;
        state.leftExpressionChip = "";
      } else if (action.payload == TestAssertionSide.RIGHT) {
        state.rightExpression = undefined;
        state.rightExpressionChip = "";
      }
    },
    setIsTestCreateWizardOpen: (state, action: PayloadAction<boolean>) => {
      state.isTestCreateWizardOpen = action.payload;
    },
    setIsTestCreateWizardShown: (state, action: PayloadAction<boolean>) => {
      state.isTestCreateWizardShown = action.payload;
    },
    enableRightExpression: (state, action: PayloadAction<boolean>) => {
      state.isRightExpressionEnabled = action.payload;
    },
    setValueLocatorExpression: (state, action: PayloadAction<TestAssertionSide>) => {
      state.assertionMenuContext = state.currentMenuContext;

      const { expression, expressionLabel } = constructValueLocatorExpression(
        state.assertionMenuContext!,
        state.selectedStep!.name!
      );

      if (action.payload == TestAssertionSide.LEFT) {
        state.leftExpression = expression;
        state.leftExpressionChip = expressionLabel;
      } else if (action.payload == TestAssertionSide.RIGHT && state.isRightExpressionEnabled) {
        state.rightExpression = expression;
        state.rightExpressionChip = expressionLabel;
      }

      if (isObject(state.assertionMenuContext!.value)) {
        state.operationTypes = [TestAssertionOperationType.EMPTY];
        state.operation = TestAssertionOperation.IS_EMPTY;
      } else {
        state.operationTypes = [
          TestAssertionOperationType.VALUE,
          TestAssertionOperationType.NULL,
          TestAssertionOperationType.EMPTY,
        ];
        state.operation = TestAssertionOperation.EQUALS;
      }

      !state.isAssertionOpen && testsSlice.caseReducers.openAssertion(state);
    },
    setVariableExpression: (state, action: PayloadAction<{ side: TestAssertionSide; variable: IVariable }>) => {
      const expression: TestExpression = {
        variable: action.payload.variable.name,
      };
      const expressionLabel = "{{" + action.payload.variable.name + "}}";

      if (action.payload.side == TestAssertionSide.LEFT) {
        state.leftExpression = expression;
        state.leftExpressionChip = expressionLabel;
      } else if (action.payload.side == TestAssertionSide.RIGHT && state.isRightExpressionEnabled) {
        state.rightExpression = expression;
        state.rightExpressionChip = expressionLabel;
      }

      !state.isAssertionOpen && testsSlice.caseReducers.openAssertion(state);
    },
    setFixedExpression: (state, action: PayloadAction<{ side: TestAssertionSide; value: Primitive }>) => {
      let expression = undefined;
      let expressionLabel = "";

      if (!isEmpty(action.payload.value)) {
        expression = {
          fixedValues: {
            singleValue: Converters.primitiveToTypedValue(action.payload.value),
          },
        };
        expressionLabel = action.payload.value.toString();
      }

      if (action.payload.side == TestAssertionSide.LEFT) {
        state.leftExpression = expression;
        state.leftExpressionChip = expressionLabel;
      } else if (action.payload.side == TestAssertionSide.RIGHT && state.isRightExpressionEnabled) {
        state.rightExpression = expression;
        state.rightExpressionChip = expressionLabel;
      }

      !state.isAssertionOpen && testsSlice.caseReducers.openAssertion(state);
    },
    setTestName: (state, action: PayloadAction<ITestName>) => {
      state.testName = action.payload;
    },
    pushAssertions: (state, action: PayloadAction<PushAssertionsPayload>) => {
      const { assertions } = action.payload;

      const existingAssertionIds = state.assertions?.map((assertion) => assertion.assertionId);

      assertions?.forEach((assertion) => {
        if (!existingAssertionIds.includes(assertion.assertionId)) {
          state.assertions.push(assertion);
        }
      });
      testsSlice.caseReducers.closeAssertion(state);
    },
    clearAssertions: (state) => {
      state.assertions = [];
    },
    removeAssertion: (state, action: PayloadAction<RemoveAssertionPayload>) => {
      const { assertionId } = action.payload;
      state.assertions = state.assertions.filter((assertion) => assertion.assertionId !== assertionId);
    },
    setAssertionEvaluations: (state, action: PayloadAction<TestAssertionResult[]>) => {
      state.assertionEvaluations = action.payload.reduce(
        (acc, result) => {
          acc[result.assertionId] = result;
          return acc;
        },
        {} as { [id: string]: TestAssertionResult }
      );
    },
    setParameters: (state, action: PayloadAction<ParamRow[]>) => {
      state.parameters = action.payload;
    },
    selectRequestId: (state, action: PayloadAction<StepContextualStringPayload>) => {
      state.selectedRequestId[action.payload.step!.id] = action.payload.value;
    },
    updateSelectedTab: (state, action: PayloadAction<{ prefix: string; tab: MainTab }>) => {
      if (state.selectedTab[state.selectedStep!.id]) {
        state.selectedTab[state.selectedStep!.id][action.payload.prefix] = action.payload.tab;
      } else {
        state.selectedTab[state.selectedStep!.id] = {
          [action.payload.prefix]: action.payload.tab,
        };
      }
    },
    updateHTTPMethod: (state, action: PayloadAction<string>) => {
      state.rawRequest[state.selectedStep!.id][state.selectedRequestId[state.selectedStep!.id]].httpMethod =
        action.payload;
    },
    setBodyString: (state, action: PayloadAction<{ isResponse: boolean; prefix: string; bodyString: string }>) => {
      const currentTab = state.selectedTab[state.selectedStep!.id][action.payload.prefix];
      console.log("tab", currentTab);
      console.log("body", action.payload.bodyString);

      if (action.payload.isResponse) {
        if (state.testResponseBodyString[state.selectedStep!.id]) {
          state.testResponseBodyString[state.selectedStep!.id][action.payload.prefix] = action.payload.bodyString;
        } else {
          state.testResponseBodyString[state.selectedStep!.id] = {
            [action.payload.prefix]: action.payload.bodyString,
          };
        }
      } else {
        if (state.requestGenBodyString[state.selectedStep!.id]) {
          state.requestGenBodyString[state.selectedStep!.id][action.payload.prefix] = action.payload.bodyString;
        } else {
          state.requestGenBodyString[state.selectedStep!.id] = {
            [action.payload.prefix]: action.payload.bodyString,
          };
        }
      }
    },
    setBodyTree: (
      state,
      action: PayloadAction<{
        isResponse: boolean;
        prefix: string;
        bodyTree: IHTTPBodyTreeNode[];
      }>
    ) => {
      if (action.payload.isResponse) {
        if (!state.testResponseBodyTree[state.selectedStep!.id]) {
          state.testResponseBodyTree[state.selectedStep!.id] = {};
        }
        state.testResponseBodyTree[state.selectedStep!.id][action.payload.prefix] = action.payload.bodyTree;
      } else {
        if (!state.requestGenBodyTree[state.selectedStep!.id]) {
          state.requestGenBodyTree[state.selectedStep!.id] = {};
        }
        state.requestGenBodyTree[state.selectedStep!.id][action.payload.prefix] = action.payload.bodyTree;
      }
    },
    setAllGenerators: (
      state,
      action: PayloadAction<{
        step: IStep;
        section: HTTPMessageSection;
        generators: { [key: string]: IGenerator };
      }>
    ) => {
      const step = action.payload.step;
      switch (action.payload.section) {
        case HTTPMessageSection.HEADER:
          set(state.headerGenerators, `${step!.id}.${state.selectedRequestId[step!.id]}`, action.payload.generators);
          break;
        case HTTPMessageSection.BODY:
          set(state.bodyGenerators, `${step!.id}.${state.selectedRequestId[step!.id]}`, action.payload.generators);
          break;
        case HTTPMessageSection.QUERY_PARAMS:
          set(
            state.queryParamGenerators,
            `${step!.id}.${state.selectedRequestId[step!.id]}`,
            action.payload.generators
          );
          break;
        case HTTPMessageSection.PATH_PARAMS:
          set(state.pathParamGenerators, `${step!.id}.${state.selectedRequestId[step!.id]}`, action.payload.generators);
          break;
        default:
          break;
      }
    },
    removeGenerator: (state, action: PayloadAction<{ step: IStep; section: HTTPMessageSection; key: string }>) => {
      const { step, section, key } = action.payload;
      switch (section) {
        case HTTPMessageSection.BODY:
          delete state.bodyGenerators[step!.id][state.selectedRequestId[step!.id]][key];
          break;
        case HTTPMessageSection.HEADER:
          delete state.headerGenerators[step!.id][state.selectedRequestId[step!.id]][key];
          break;
        case HTTPMessageSection.QUERY_PARAMS:
          delete state.queryParamGenerators[step!.id][state.selectedRequestId[step!.id]][key];
          break;
        case HTTPMessageSection.PATH_PARAMS:
          delete state.pathParamGenerators[step!.id][state.selectedRequestId[step!.id]][key];
          break;

        default:
          console.error("Unknown HTTP Message Section");
          break;
      }
    },
    saveGenerator: (
      state,
      action: PayloadAction<{
        section: HTTPMessageSection;
        key: string;
        generator: IGenerator;
      }>
    ) => {
      testsSlice.caseReducers.setGenerator(state, action);
      testsSlice.caseReducers.closeGeneratorDrawer(state);
    },
    setGenerator: (
      state,
      action: PayloadAction<{
        section: HTTPMessageSection;
        key: string;
        generator: IGenerator;
      }>
    ) => {
      testsSlice.caseReducers.setGeneratorForStep(state, {
        payload: {
          step: state.selectedStep!,
          section: action.payload.section,
          key: action.payload.key,
          generator: action.payload.generator,
        },
        type: testsSlice.actions.setGeneratorForStep.type,
      });
    },
    setGeneratorForStep: (
      state,
      action: PayloadAction<{
        step: IStep;
        section: HTTPMessageSection;
        key: string;
        generator: IGenerator;
      }>
    ) => {
      const { step, section, key, generator } = action.payload;
      switch (section) {
        case HTTPMessageSection.BODY:
          state.bodyGenerators[step!.id][state.selectedRequestId[step!.id]][key] = generator;
          break;
        case HTTPMessageSection.HEADER:
          state.headerGenerators[step!.id][state.selectedRequestId[step!.id]][key] = generator;
          break;
        case HTTPMessageSection.QUERY_PARAMS:
          state.queryParamGenerators[step!.id][state.selectedRequestId[step!.id]][key] = generator;
          break;
        case HTTPMessageSection.PATH_PARAMS:
          state.pathParamGenerators[step!.id][state.selectedRequestId[step!.id]][key] = generator;
          break;

        default:
          console.error("Unknown HTTP Message Section");
          break;
      }
    },
    addVariableToGenerator: (
      state,
      action: PayloadAction<{
        section: HTTPMessageSection;
        key: string;
        variable: IVariable;
      }>
    ) => {
      let currentGenerator: IGenerator;

      switch (action.payload.section) {
        case HTTPMessageSection.HEADER:
          currentGenerator =
            state.headerGenerators[state.selectedStep!.id][state.selectedRequestId[state.selectedStep!.id]][
            action.payload.key
            ];
          break;
        case HTTPMessageSection.BODY:
          currentGenerator =
            state.bodyGenerators[state.selectedStep!.id][state.selectedRequestId[state.selectedStep!.id]][
            action.payload.key
            ];
          break;
        case HTTPMessageSection.QUERY_PARAMS:
          currentGenerator =
            state.queryParamGenerators[state.selectedStep!.id][state.selectedRequestId[state.selectedStep!.id]][
            action.payload.key
            ];
          break;
        case HTTPMessageSection.PATH_PARAMS:
          currentGenerator =
            state.pathParamGenerators[state.selectedStep!.id][state.selectedRequestId[state.selectedStep!.id]][
            action.payload.key
            ];
          break;
        default:
          return;
      }

      if (currentGenerator.type == GeneratorType.FREEFORM) {
        const newValue = currentGenerator.value
          ? `${currentGenerator.value}{{${action.payload.variable.name}}}`
          : `{{${action.payload.variable.name}}}`;
        currentGenerator.value = newValue;
        currentGenerator.displayValue = newValue;
      } else {
        testsSlice.caseReducers.setGenerator(state, {
          payload: {
            section: action.payload.section,
            key: action.payload.key,
            generator: {
              type: GeneratorType.FREEFORM,
              value: `{{${action.payload.variable.name}}} `,
              displayValue: `{{${action.payload.variable.name}}} `,
              dataType: FieldDataType.STRING
            },
          },
          type: testsSlice.actions.setGenerator.type,
        });
      }
    },
    openSaveTestPopup: (state) => {
      state.isSaveTestOpen = true;
    },
    closeSaveTestPopup: (state) => {
      state.isSaveTestOpen = false;
    },
    openAddStepPopup: (state, action: PayloadAction<number>) => {
      state.currentStepIndex = action.payload;
      state.isAddStepOpen = true;
    },
    closeAddStepPopup: (state) => {
      state.isAddStepOpen = false;
    },
    openGeneratorDrawer: (state) => {
      state.generatorMenuContext = state.currentMenuContext;
      testsSlice.caseReducers.closeVariableDrawer(state);
      testsSlice.caseReducers.closeAssertionsDrawer(state);
      state.isGeneratorDrawerOpen = true;
    },
    closeGeneratorDrawer: (state) => {
      state.isGeneratorDrawerOpen = false;
      testsSlice.caseReducers.resetGeneratorMenuContext(state);
    },
    toggleVariableDrawer: (state) => {
      state.isVariableDrawerOpen
        ? testsSlice.caseReducers.closeVariableDrawer(state)
        : testsSlice.caseReducers.openVariableDrawer(state, {
          payload: VariableStoreTab.AVAILABLE,
          type: testsSlice.actions.setActiveVariableStoreTab.type,
        });
    },
    openVariableDrawer: (state, action: PayloadAction<VariableStoreTab>) => {
      if (action.payload == VariableStoreTab.NEW) {
        state.variableStoreMenuContext = state.currentMenuContext;
      }
      testsSlice.caseReducers.closeGeneratorDrawer(state);
      testsSlice.caseReducers.closeAssertionsDrawer(state);
      testsSlice.caseReducers.setActiveVariableStoreTab(state, action);
      state.isVariableDrawerOpen = true;
    },
    closeVariableDrawer: (state) => {
      state.isVariableDrawerOpen = false;
      testsSlice.caseReducers.resetVariableStoreMenuContext(state);
    },
    toggleAssertionsDrawer: (state) => {
      state.isAssertionsDrawerOpen
        ? testsSlice.caseReducers.closeAssertionsDrawer(state)
        : testsSlice.caseReducers.openAssertionsDrawer(state);
    },
    openAssertionsDrawer: (state) => {
      testsSlice.caseReducers.closeGeneratorDrawer(state);
      testsSlice.caseReducers.closeVariableDrawer(state);
      state.isAssertionsDrawerOpen = true;
    },
    closeAssertionsDrawer: (state) => {
      state.isAssertionsDrawerOpen = false;
    },
    updateCurrentMenuContext: (state, action: PayloadAction<{ open: boolean; context: IDecoratedDrawerContext }>) => {
      state.currentMenuContext = action.payload.open ? action.payload.context : undefined;
    },
    resetVariableStoreMenuContext: (state) => {
      state.variableStoreMenuContext = undefined;
    },
    resetGeneratorMenuContext: (state) => {
      state.generatorMenuContext = undefined;
    },
    setActiveVariableStoreTab: (state, action: PayloadAction<VariableStoreTab>) => {
      state.activeVariableStoreTab = action.payload;
    },
    setEnvironment: (state, action: PayloadAction<string>) => {
      state.environment[state.selectedStep!.id] = action.payload;
    },
    setEndpoint: (state, action: PayloadAction<string>) => {
      state.endpoint[state.selectedStep!.id] = action.payload;
    },
    setEndpointForStep: (state, action: PayloadAction<SetEndpointPayload>) => {
      console.log("Setting endpoint for " + action.payload.step.name, action.payload.endpoint);
      state.endpoint[action.payload.step.id] = action.payload.endpoint;
    },
    updateStepWithAutoAuthHeader: (state, action: PayloadAction<{ step: IStep }>) => {
      if (!state.autoAuthHeaderGenerator) {
        return;
      }
      let step = action.payload.step;
      if (state.selectedRequestId[step.id]) {
        let headerGenerators = state.headerGenerators[step.id][state.selectedRequestId[step.id]];
        if (
          headerGenerators["authorization"] &&
          headerGenerators["authorization"].value.toString().startsWith("Bearer ")
        ) {
          state.headerGenerators[step.id][state.selectedRequestId[step.id]]["authorization"] =
            state.autoAuthHeaderGenerator;
        }

        if (
          headerGenerators["Authorization"] &&
          headerGenerators["Authorization"].value.toString().startsWith("Bearer ")
        ) {
          state.headerGenerators[step.id][state.selectedRequestId[step.id]]["Authorization"] =
            state.autoAuthHeaderGenerator;
        }
      }
    },
    addVariableByStepAndLocator: (
      state,
      action: PayloadAction<{
        name: string;
        value: TypedValueOrList;
        section: HTTPMessageSection;
        contextType: ContextType;
        locator: string;
        stepId: string;
        operationLocator: OperationLocator | undefined;
      }>
    ) => {
      let variable: IVariable = {
        name: action.payload.name,
        value: action.payload.value,
        locator: action.payload.locator,
        section: action.payload.section,
        contextType: action.payload.contextType,
      };
      if (action.payload.operationLocator?.pathPrefix) {
        variable.operationLocator = action.payload.operationLocator;
      }
      if (state.variables[action.payload.stepId]) {
        state.variables[action.payload.stepId].push(variable);
      } else {
        state.variables[action.payload.stepId] = [variable];
      }
    },
    addVariable: (
      state,
      action: PayloadAction<{
        name: string;
        value: TypedValueOrList;
        section: HTTPMessageSection;
        contextType: ContextType;
        step: IStep;
      }>
    ) => {
      const section = action.payload.section;
      const step = action.payload.step;
      let locator: string = "";

      if (section == HTTPMessageSection.BODY) {
        locator = state.variableStoreMenuContext!.bodyPath!;
      } else if (section == HTTPMessageSection.HEADER) {
        locator = state.variableStoreMenuContext!.headerKey!;
      } else if (section == HTTPMessageSection.QUERY_PARAMS) {
        locator = state.variableStoreMenuContext!.queryParamKey!;
      } else if (section == HTTPMessageSection.PATH_PARAMS) {
        locator = state.variableStoreMenuContext!.pathParamKey!;
      }

      let variable: IVariable = {
        name: action.payload.name,
        value: action.payload.value,
        locator: locator,
        section: action.payload.section,
        contextType: action.payload.contextType,
      };
      if (state.variableStoreMenuContext!.operationLocator.pathPrefix) {
        variable.operationLocator = state.variableStoreMenuContext!.operationLocator;
      }
      if (state.variables[step!.id]) {
        state.variables[step!.id].push(variable);
      } else {
        state.variables[step!.id] = [variable];
      }
      testsSlice.caseReducers.resetVariableStoreMenuContext(state);
    },
    removeVariable: (state, action: PayloadAction<number>) => {
      state.variables[state.selectedStep!.id].splice(action.payload, 1);
    },
    setTraceIdForStep: (state, action: PayloadAction<SetTraceIdForStepPayload>) => {
      state.traceId[action.payload.stepId] = action.payload.traceId;
    },
    updateVariables: (state, action: PayloadAction<{ stepId: string; variables: IVariable[] }>) => {
      state.variables[action.payload.stepId] = action.payload.variables;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listRequestIdsForContextForStep.pending, (state) => {
        state.listRequestIdsLoading = true;
      })
      .addCase(listRequestIdsForContextForStep.rejected, (state) => {
        state.listRequestIdsLoading = false;
      })
      .addCase(listRequestIdsForContextForStep.fulfilled, (state, action) => {
        const { request, step } = action.meta.arg; // Access step from the action meta
        const requestIds = action.payload;
        state.listRequestIdsLoading = false;
        state.requestIds[step.id] = requestIds;

        if (requestIds.length > 0) {
          testsSlice.caseReducers.selectRequestId(state, {
            payload: {
              step,
              value: state.selectedRequestId[step.id] ?? requestIds[0],
            },
            type: testsSlice.actions.selectRequestId.type,
          });
        }
      })
      .addCase(updateLinkedScenariosForTest.pending, (state) => { })
      .addCase(updateLinkedScenariosForTest.rejected, (state) => {
        console.log("Error linking to scenarios");
      })
      .addCase(updateLinkedScenariosForTest.fulfilled, (state) => {
        console.log("Successfully linked to scenarios");
      })
      .addCase(fetchRequestObjectForStepAndSetGenerators.pending, (state, action) => {
        const { step } = action.meta.arg;
        // Handle pending state if necessary
        console.log(`Pending request for step: ${step.id}`);
      })
      .addCase(fetchRequestObjectForStepAndSetGenerators.fulfilled, (state, action) => {
        const { step } = action.meta.arg;
        const response = action.payload;
        // Update the state with the fetched request object
        let requestId = response.requestObject.id;
        state.selectedRequestId[step.id] = requestId;
        initMapsForStep(state, step);
        state.rawRequest[step.id][requestId] = action.payload.requestObject?.rawRequest;
        let result = processMessage(response.requestObject.rawRequest);
        state.headerGenerators[step.id][requestId] = result.headerGenerators;
        state.bodyGenerators[step.id][requestId] = result.bodyGenerators;
        state.queryParamGenerators[step.id][requestId] = result.queryParamGenerators;
        state.pathParamGenerators[step.id][requestId] = result.pathParamGenerators;
      })
      .addCase(fetchRequestObjectForStepAndSetGenerators.rejected, (state, action) => {
        const { step } = action.meta.arg;
        // Handle rejected state if necessary
        console.error(`Failed request for step: ${step.id}`);
      })
      .addCase(getRequestObject.pending, (state) => {
        state.getRequestLoading = true;
      })
      .addCase(getRequestObject.rejected, (state) => {
        state.getRequestLoading = false;
      })
      .addCase(getRequestObject.fulfilled, (state, action) => {
        state.getRequestLoading = false;
        const stepId = state.selectedStep!.id;
        if (state.rawRequest[stepId]) {
          state.rawRequest[stepId][state.selectedRequestId[stepId]] = action.payload.requestObject?.rawRequest;
        } else {
          state.rawRequest[stepId] = {
            [state.selectedRequestId[stepId]]: action.payload.requestObject?.rawRequest,
          };
        }
      })
      .addCase(loadTraceSummary.fulfilled, (state, action: PayloadAction<void>) => { })
      .addCase(generateTestRequest.pending, (state) => {
        state.generateTestRequestLoading = true;
      })
      .addCase(generateTestRequest.rejected, (state) => {
        state.generateTestRequestLoading = false;
      })
      .addCase(generateTestRequest.fulfilled, (state, action) => {
        state.generateTestRequestLoading = false;

        const stepId = state.selectedStep!.id;
        let request = action.payload.request;
        request.httpMethod = state.rawRequest[stepId][state.selectedRequestId[stepId]].httpMethod;

        state.testRequest[state.selectedStep!.id] = request;
        state.renderCount++;
        testsSlice.caseReducers.updateSelectedTab(state, {
          payload: { prefix: "$", tab: MainTab.TEST_REQUEST },
          type: testsSlice.actions.updateSelectedTab.type,
        });

        let variables: IVariable[] = state.variables[stepId];
        variables?.forEach((variable) => {
          if (variable.contextType == ContextType.REQUEST) {
            let locator = variable.locator;
            if (variable.section == HTTPMessageSection.HEADER) {
              variable.value = Converters.typedValueToList(Converters.stringToTypedValue(request.headers[locator]));
            }
            if (variable.section == HTTPMessageSection.QUERY_PARAMS) {
              variable.value = Converters.typedValueToList(Converters.stringToTypedValue(request.queryParams[locator]));
            }
            if (variable.section == HTTPMessageSection.PATH_PARAMS) {
              variable.value = Converters.typedValueToList(
                Converters.stringToTypedValue(request.templatePathParams!.keyValueMap[locator])
              );
            } else if (variable.section == HTTPMessageSection.BODY) {
              if (request.body.jsonBody) {
                // TODO Handle other types of bodies
                try {
                  variable.value = extractJsonPathValue(JSON.parse(request.body.jsonBody), locator);
                } catch (error) {
                  console.error("Erroring during parsing json", request.body.jsonBody);
                }
              }
            }
          }
        });
      })
      .addCase(listFieldVocabs.pending, (state) => {
        state.listFieldVocabsLoading = true;
      })
      .addCase(listFieldVocabs.rejected, (state) => {
        state.listFieldVocabsLoading = false;
      })
      .addCase(listFieldVocabs.fulfilled, (state, action) => {
        state.listFieldVocabsLoading = false;
        state.fieldVocabs[state.selectedStep!.id] = action.payload.fieldToVocabs;
      })
      .addCase(listScripts.pending, (state) => { })
      .addCase(listScripts.rejected, (state) => { })
      .addCase(listScripts.fulfilled, (state, action) => {
        state.scripts = action.payload.scripts;
      })
      .addCase(getEnvironmentEndpoints.fulfilled, (state, action) => {
        //TODO: do we need other cases here??
        let envMap: { [environment: string]: string[] } = {};
        action.payload.forEach(({ environment, endpoints }) => {
          const nonEmptyEndpoints = endpoints ? endpoints.filter((ep: string) => ep.trim() != "") : [];
          envMap[environment] = nonEmptyEndpoints;
        });

        state.environmentEndpoints[state.selectedStep!.id] = envMap;
      })
      .addCase(executeStep.fulfilled, (state, action) => {
        console.log("Execute step fulfilled");
        const { response } = action.payload;
        console.log("Response received in payload", response);

        if (response.errorReport) {
          // Show error report in a modal
          Modal.error({
            title: "Execution Error",
            content: (
              `${response.errorReport.errorType} ${response.errorReport.errorDescription} occurred while calling ${response.errorReport.sutEndpoint}`
            ),
          });
        }
      })
      .addCase(runTest.pending, (state) => {
        state.runTestLoading = true;
      })
      .addCase(runTest.rejected, (state) => {
        state.runTestLoading = false;
      })
      .addCase(runTest.fulfilled, (state, action) => {
        state.runTestLoading = false;
        state.testResponse[state.selectedStep!.id] = action.payload.isDryRun
          ? action.payload.response.rawResponse ?? { body: {}, headers: {}, responseCode: 500 } : undefined;

        state.traceId[state.selectedStep!.id] = action.payload.isDryRun ? action.payload.response.traceId : undefined;
        testsSlice.caseReducers.updateSelectedTab(state, {
          payload: { prefix: "$", tab: MainTab.RESPONSE },
          type: testsSlice.actions.updateSelectedTab.type,
        });
        let variables: IVariable[] = state.variables[state.selectedStep!.id];
        if (action.payload.isDryRun && action.payload.response.rawResponse) {
          let rawResponse: IRawResponse = action.payload.response.rawResponse!;

          variables?.forEach((variable) => {
            if (variable.contextType == ContextType.RESPONSE) {
              let locator = variable.locator;
              if (variable.section == HTTPMessageSection.HEADER) {
                variable.value = Converters.typedValueToList(
                  Converters.stringToTypedValue(rawResponse.headers[locator])
                );
              } else if (variable.section == HTTPMessageSection.BODY) {
                if (rawResponse.body.jsonBody) {
                  // TODO Handle other types of bodies
                  try {
                    variable.value = extractJsonPathValue(JSON.parse(rawResponse.body.jsonBody), locator);
                  } catch (error) {
                    console.error("Erroring during parsing json", rawResponse.body.jsonBody);
                  }
                }
              }
            }
          });
        }
        if (action.payload.isDryRun) {
          state.evalAssistSuggestionsForStep = true;
        }
      })
      .addCase(listTestSuites.pending, (state) => {
        state.listTestSuitesLoading = true;
      })
      .addCase(listTestSuites.rejected, (state) => {
        state.listTestSuitesLoading = false;
      })
      .addCase(listTestSuites.fulfilled, (state, action) => {
        state.testSuites = action.payload.suites ?? [];
        state.listTestSuitesLoading = false;
      })
      .addCase(saveTest.pending, (state) => {
        state.saveTestLoading = true;
      })
      .addCase(saveTest.fulfilled, (state, action) => {
        const { request } = action.payload;
        state.saveTestLoading = false;
        state.testName = request.testConfig.testName; // Update state with testName from the request
        testsSlice.caseReducers.closeSaveTestPopup(state);
      })
      .addCase(saveTest.rejected, (state) => {
        state.saveTestLoading = false;
        // handle the error if needed
      })
      .addCase(createTestSuite.pending, (state) => {
        state.createTestSuiteLoading = true;
      })
      .addCase(createTestSuite.rejected, (state) => {
        state.createTestSuiteLoading = false;
      })
      .addCase(createTestSuite.fulfilled, (state) => {
        state.createTestSuiteLoading = false;
      });
  },
});

export const {
  openSaveTestPopup,
  closeSaveTestPopup,
  setOperation,
  setValueLocatorExpression,
  setVariableExpression,
  setFixedExpression,
  setInitialSteps,
  removeStep,
  addAndSelectStep,
  selectStep,
  openAddStepPopup,
  closeAddStepPopup,
  addVariableByStepAndLocator,
  selectRequestId,
  addVariableToGenerator,
  setBodyString,
  setBodyTree,
  setAllGenerators,
  updateSelectedTab,
  updateHTTPMethod,
  saveGenerator,
  setGenerator,
  setGeneratorForStep,
  removeGenerator,
  setEnvironment,
  setEndpoint,
  setEndpointForStep,
  openAssertion,
  closeAssertion,
  clearDraftAssertion,
  enableRightExpression,
  pushAssertions,
  openGeneratorDrawer,
  openAssertionsDrawer,
  closeGeneratorDrawer,
  toggleVariableDrawer,
  toggleAssertionsDrawer,
  closeAssertionsDrawer,
  removeAssertion,
  openVariableDrawer,
  closeVariableDrawer,
  updateCurrentMenuContext,
  setRawRequestForStepIdAndRequestId,
  updateEvalAssistSuggestionsForStep,
  setAutoAuthHeaderGenerator,
  setSkipAutoAuthHeaderUpdate,
  setActiveVariableStoreTab,
  addVariable,
  removeVariable,
  updateStepWithAutoAuthHeader,
  resetVariableStoreMenuContext,
  resetGeneratorMenuContext,
  setTestName,
  setAssistChatMessage,
  setIsAssistChatBubbleOpen,
  setShowOkButtonInAssistChat,
  setShowSkipButtonInAssistChat,
  updateVariables,
  clearAssertions,
  setParameters,
  setTestRunButtonState,
  setIsAssertResultsPopupOpen,
  setIsLinkTestScenarioPopupOpen,
  setIsParameterizePopupOpen,
  setAssertionEvaluations,
  setIsTestCreateWizardOpen,
  setIsTestCreateWizardShown,
  setTraceIdForStep,
  setIsParamRowSelectPopupOpen,
  processTraceSummaryForTrace,
  selectPrefix,
} = testsSlice.actions;
export default testsSlice.reducer;
