import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, styled, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Google } from '@mui/icons-material';
import '../styles/homestyles.css';
import { createUser } from '../api/services/User';
import OrganizationPreOobe from '../api/services/OrganizationPreOobe';
import { ResponseData } from '../api/Response'
import { UserCredential, getAdditionalUserInfo, AdditionalUserInfo } from 'firebase/auth';
import FirebaseService from '../auth/Firebase'
import { storeUserInfoInLocalStorage } from '../api/services/User';
import { postData } from '../common/WebUtils';
import { getVersionedUrl } from '../common/Util';
import LocalStorageManager from '../common/LocalStorageManager';
import LogoName from '../icons/LogoWithNameRed.png'; // Import the logo image

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [awsMarketplaceToken, setAwsMarketplaceToken] = useState('');
    const [preferredName, setPreferredName] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isExploreDemoFlow, setIsExploreDemoFlow] = useState(false);
    const [isInviteFlow, setIsInviteFlow] = useState(false);
    const [password, setPassword] = useState('');
    const [organization, setOrganization] = useState<string>('');
    const [hasOrganization, setHasOrganization] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const [message, setMessage] = useState<string | null>(null);
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [showSignInPrompt, setShowSignInPrompt] = useState(false);
    const [isSignUpButtonEnabled, setIsSignUpButtonEnabled] = useState(false);
    const [inviteeUserId, setInviteeUserId] = useState("");

    let timeoutId: NodeJS.Timeout | null = null;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const flow = urlParams.get('flow');
        const userId = urlParams.get('user_id') + "";
        const token = urlParams.get('token');
        let awsMarketplaceToken = urlParams.get('aws_marketplace_token');
        if (awsMarketplaceToken) {
            awsMarketplaceToken = decodeURIComponent(awsMarketplaceToken);
        }
        if (awsMarketplaceToken !== null && awsMarketplaceToken !== "") {
            setAwsMarketplaceToken(awsMarketplaceToken);
        }
        if (flow == "invite") {
            setIsInviteFlow(true);
            setInviteeUserId(userId);
            postData('admin/verify_user_email', { 'user_id': userId, 'token': token })
                .then(response => {
                    // Check the response status
                    if (response && response.responseHeader && response.responseHeader.status === 'SUCCESS') {
                    } else {
                        setIsErrorMessage(true);
                        setMessage("Unable to verify email address");
                    }
                })
                .catch(error => {
                    console.error(error);
                    setIsErrorMessage(true);
                    setMessage("Unable to verify email address");
                });
        }
        if (flow == "exploredemo") {
            setIsExploreDemoFlow(true);
        }
    }, []);

    useEffect(() => {
        updateSignUpButtonEnableStatus();
    }, []);

    const GoogleSignInButton = styled(Button)({
        background: 'white',
        borderRadius: '4px',
        border: '1px solid #e0e0e0',
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': {
            background: '#f1f1f1',
        },
    });
    const GoogleSignInIcon = styled(Google)({
        marginRight: '8px',
    });

    const handleSignUp = async () => {
        if (loading) {
            console.log("Loading");
            return;
        }
        setLoading(true); // Start loading when sign-up starts
        const isEmailVerified = "false";
        const provider = "EMAIL";
        try {
            const userResponse = await createUser.signupUser(email, preferredName, password, isEmailVerified, provider, "", inviteeUserId, awsMarketplaceToken);
            setIsErrorMessage(false);
            if (isInviteFlow) {
                setShowSignInPrompt(true);
            } else {
                setIsErrorMessage(false);
                setMessage("Your account is created successfully. Check your mailbox for a verification mail to complete sign up.");
            }
        } catch (error) {
            setIsErrorMessage(true);
            if (error + "" === "USER_FOR_EMAIL_ALREADY_EXISTS") {
                setMessage("Email is already registered. Please Sign in instead.");
            } else if (error + "" === "DOMAIN_IS_PUBLIC") {
                setMessage("Please sign up with your work email address instead.");
            } else {
                setMessage("An error occurred. Please try again later");
            }
        }
        setLoading(false); // Stop loading after sign-up is complete
    };

    const handleGoogleSignUp = async () => {
        try {
            const result: UserCredential = await FirebaseService.signInWithGoogle();
            const additionalUserInfo: AdditionalUserInfo | null = getAdditionalUserInfo(result)
            if (additionalUserInfo?.isNewUser) {
                const currentUser = await FirebaseService.getCurrentUser();
                if (currentUser && 'email' in currentUser && 'displayName' in currentUser) {
                    const currentUserEmail: string = typeof currentUser.email === 'string' ? currentUser.email : '';
                    const currentUserDispalyName: string = typeof currentUser.displayName === 'string' ? currentUser.displayName : '';
                    saveGoogleSignedUpUser(currentUserEmail, currentUserDispalyName, currentUser.emailVerified, currentUser.uid)
                }
            } else {
                setShowSignInPrompt(true);
            }
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Unable to Signup User with Google");
        }
    };

    const saveGoogleSignedUpUser = async (currentUserEmail: string, currentUserDispalyName: string, emailVerified: boolean, uid: string) => {
        try {
            const userResponse = await createUser.signupUser(currentUserEmail, currentUserDispalyName, "", String(emailVerified), "GOOGLE", uid, inviteeUserId, awsMarketplaceToken);
            await storeUserInfoAndRedirect(userResponse.data.organizationId);
        } catch (error) {
            setIsErrorMessage(true);
            if (error + "" === "USER_FOR_EMAIL_ALREADY_EXISTS") {
                setMessage("Email is already registered. Please Sign in instead.");
            } else if (error + "" === "DOMAIN_IS_PUBLIC") {
                setMessage("Please sign up with your work email address instead.");
            } else {
                setMessage("Error signing up with Google. Please try again later");
            }
        }
    }

    const storeUserInfoAndRedirect = async (orgId: string) => {
        let result = await storeUserInfoInLocalStorage();
        let organizationId: string = orgId;
        if (!orgId) {
            organizationId = LocalStorageManager.getOrganizationId();
        }
        let redirectUrl: string = BASE_APP_URL + "";
        if (!organizationId) {
            redirectUrl = BASE_APP_URL + "/createOrg";
        }
        console.log("Redirecting to", redirectUrl);
        window.location.href = redirectUrl;
    };

    const validateEmail = (email: string): boolean => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    };

    const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredEmail: string = e.target.value;
        setEmail(enteredEmail);
        updateSignUpButtonEnableStatus();
    }

    const updatePreferredName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const preferredName: string = e.target.value;
        setPreferredName(preferredName);
        updateSignUpButtonEnableStatus();
    }

    const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password: string = e.target.value;
        setPassword(password);
        updateSignUpButtonEnableStatus();
    }

    const updateSignUpButtonEnableStatus = () => {
        setIsSignUpButtonEnabled(email.length > 0 && password.length > 5 && preferredName.length > 0);
    }



    const handleEmailChange = (e: React.FocusEvent<HTMLInputElement>) => {
        const enteredEmail: string = e.target.value;
        setEmail(enteredEmail);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setIsValidEmail(validateEmail(enteredEmail));

        if (isValidEmail) {
            timeoutId = setTimeout(async () => {
                console.log("entered email: " + enteredEmail);
                await getOrganization(enteredEmail);
            }, 2000); // Delay for 2 seconds
        }
    };

    const getOrganization = async (email: string) => {
        const domain = email.split('@')[1];
        const response = await OrganizationPreOobe.getOrgByDomain(domain)

        if ('error' in response) {
            setOrganization("");
            setHasOrganization(false);
        }
        else {
            const data: ResponseData = response.data;
            console.log("data" + data.responseHeader.status + " " + data.responseHeader.failureReason);
            if (data.responseHeader.status === "FAILURE" && data.responseHeader.failureReason === "UNKNOWN_ORGANIZATION") {
                setOrganization("");
                setHasOrganization(false);
            } else if (data.responseHeader.status == "FAILURE" && data.responseHeader.failureReason == "DOMAIN_IS_PUBLIC") {
                console.log("public domain")
                setOrganization("");
                setHasOrganization(false);
                setIsValidEmail(false);
            } else if (data.responseHeader.status === "SUCCESS") {
                setOrganization(data.name);
                setHasOrganization(true);
            }
        }
    }

    let signinRedirect = "/signin";
    if (isExploreDemoFlow) {
        signinRedirect = signinRedirect + "?flow=exploredemo";
    }
    signinRedirect = getVersionedUrl(signinRedirect);
    let messageColor = "green";
    if (isErrorMessage) {
        messageColor = "red";
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            className="fade-in"
            sx={{
                background: 'radial-gradient(circle at 94.35384114583333% 89.61588541666666%, #FFFFFF 0%, 20%, rgba(255,255,255,0) 40%), radial-gradient(circle at 6.503906249999999% 88.037109375%, rgba(250,250,250,0.99) 0%, 25%, rgba(250,250,250,0) 50%), radial-gradient(circle at 6.165364583333333% 12.617187499999998%, #E3D5C5 0%, 42%, rgba(227,213,197,0) 70%), radial-gradient(circle at 71.54459635416667% 39.81119791666667%, #F0EAD6 0%, 42%, rgba(240,234,214,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%)'
            }}
        >
            <Box textAlign="center">
                <img src={LogoName} alt="Logo" style={{ marginBottom: '20px', width: '400px', height: 'auto' }} />
                <Box
                    p={4}
                    bgcolor="white"
                    borderRadius={4}
                    boxShadow={3}
                    maxWidth={400}
                    width="100%"
                    textAlign="center"
                    margin="0 auto"
                >
                    <TextField
                        label="Work Email"
                        placeholder='Email address (Work)'
                        error={!isValidEmail}
                        helperText={!isValidEmail ? "Not a valid work mail address" : ""}
                        variant="outlined"
                        sx={{ marginBottom: "20px" }}
                        type='email'
                        fullWidth value={email}
                        onBlur={handleEmailChange}
                        onChange={updateEmail}
                    />

                    <TextField
                        label="Preferred Name"
                        fullWidth variant="outlined"
                        value={preferredName}
                        sx={{ marginBottom: "20px" }}
                        onChange={updatePreferredName}
                    />
                    <TextField
                        label="Password"
                        fullWidth type={"password"}
                        variant="outlined"
                        sx={{ marginBottom: "20px" }}
                        value={password}
                        onChange={updatePassword}
                    />
                    {message && (
                        <Typography
                            color={isErrorMessage ? 'error' : 'primary'}
                            variant="body2"
                            sx={{ mt: 1 }}
                        >
                            {message}
                        </Typography>
                    )}

                    <Button
                        sx={{
                            mt: 2,
                            backgroundColor: isSignUpButtonEnabled ? 'var(--secondary-color)' : '#E0E0E0',
                            color: isSignUpButtonEnabled ? 'white' : '#A0A0A0'
                        }}
                        disabled={!isSignUpButtonEnabled}
                        variant="contained"
                        onClick={handleSignUp}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Sign Up"}
                    </Button>
                    <Typography variant="body2" sx={{ mt: 2, marginBottom: "10px" }}>
                        Or
                    </Typography>
                    <GoogleSignInButton fullWidth onClick={handleGoogleSignUp} >
                        <GoogleSignInIcon />
                        Continue with Google
                    </GoogleSignInButton>
                    <Link to="/signin">
                        <Typography variant="body2" color="textSecondary" marginTop="16px">
                            Already have an account? Sign in instead
                        </Typography>
                    </Link>
                    {showSignInPrompt && <Typography variant="body2" color="textSecondary" marginTop="16px">Your account is created successfully. Please <Link to={signinRedirect}>Sign In</Link> to continue.</Typography>}
                    <Typography variant="body2" color="textSecondary" marginTop="26px">
                        By Signing up, you are agreeing to our <a href="https://www.testchimp.io/terms/terms-%26-conditions" target='_blank'>terms and conditions</a> and <a href="https://www.testchimp.io/terms/privacy-policy" target='_blank'>privacy policy</a>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default SignUp;