import React from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  IConstantExpression,
  IListAssertiosnRequest,
  IPathPart,
  IVariableExpression,
} from "../../models/assertion";
import {
  closeAddAssertionForm,
  closeDeleteAssertionDialog,
  deleteAssertion,
  listAssertions,
  openDeleteAssertionDialog,
} from "../../features/assertionsSlice";
import { RootState } from "../../app/store";
import AssertionsListSkeleton from "./AssertionsListSkeleton";
import { LoadingButton } from "@mui/lab";

const AssertionsList: React.FC = () => {
  const queries = useAppSelector((state: RootState) => state.overlays.queries);

  const assertions = useAppSelector(
    (state: RootState) => state.assertions.assertions
  );

  const isListAssertionLoading = useAppSelector(
    (state: RootState) => state.assertions.isListAssertionLoading
  );

  const isDeletionLoading = useAppSelector(
    (state: RootState) => state.assertions.isDeleteAssertionLoading
  );

  const deleteAssertionDialog = useAppSelector(
    (state: RootState) => state.assertions.deleteAssertionDialog
  );

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(closeAddAssertionForm());
    const request: IListAssertiosnRequest = {
      testCase: {
        name: queries[0].filters.find((filter) => filter.key == "testName")!
          .value,
        suite: queries[0].filters.find((filter) => filter.key == "testSuite")!
          .value,
        type: queries[0].filters.find((filter) => filter.key == "testType")!
          .value,
      },
    };
    dispatch(listAssertions(request));
  }, []);

  const getExpressionLabel = (
    expression: IVariableExpression | IConstantExpression
  ) => {
    if ("variableExpression" in expression) {
      const variableExpression = (expression as IVariableExpression)
        .variableExpression;

      if (variableExpression.pathParts) {
        return (
          variableExpression.pathParts.reduce(
            (prev: string, curr: IPathPart) =>
              prev + "." + curr.operation.operationName,
            ""
          ) + variableExpression.attributeKey
        );
      } else if (variableExpression.pathPrefix) {
        const prefixes = variableExpression.pathPrefix.split("|");
        return (
          prefixes.reduce(
            (previous, current) =>
              previous + current.split(",")[1].slice(0, -1) + ".",
            ""
          ) + variableExpression.attributeKey
        );
      }
    } else if ("constantExpression" in expression) {
      return (expression as IConstantExpression).constantExpression.stringValue;
    } else {
      console.error("Unknown expression type");
    }
  };

  const handleClose = () => {
    dispatch(closeDeleteAssertionDialog());
  };

  return (
    <Box>
      {isListAssertionLoading ? (
        <AssertionsListSkeleton />
      ) : (
        assertions?.map((assertion) => {
          if (assertion.leftExpression && assertion.rightExpression) {
            return (
              <Card
                key={assertion.id!}
                sx={{ padding: "5px 20px", marginBottom: "10px" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingX={2}
                  paddingBottom={0.5}
                  borderRadius={2}
                >
                  <Box display="flex" gap={1}>
                    <Box
                      bgcolor={"#34d399"}
                      display="flex"
                      gap={1}
                      paddingX={2}
                      paddingBottom={0.4}
                      paddingTop={0.2}
                      sx={{ borderRadius: "8px", color: "#fff" }}
                      flexWrap="wrap"
                    >
                      {getExpressionLabel(assertion.leftExpression)}
                    </Box>
                    <Box
                      bgcolor={"#22d3ee"}
                      display="flex"
                      gap={1}
                      paddingX={2}
                      paddingBottom={0.4}
                      paddingTop={0.2}
                      sx={{ borderRadius: "8px", color: "#fff" }}
                      flexWrap="wrap"
                    >
                      {assertion.assertionOperation}
                    </Box>
                    <Box
                      bgcolor={"#34d399"}
                      display="flex"
                      gap={1}
                      paddingX={2}
                      paddingBottom={0.4}
                      paddingTop={0.2}
                      sx={{ borderRadius: "8px", color: "#fff" }}
                      flexWrap="wrap"
                    >
                      {getExpressionLabel(assertion.rightExpression)}
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" gap={2}>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ margin: "0 !important" }}
                    />
                    <IconButton
                      color="primary"
                      onClick={() =>
                        dispatch(openDeleteAssertionDialog(assertion.id!))
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Card>
            );
          }
        })
      )}
      <Dialog open={deleteAssertionDialog.isOpen} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Do you want to delete the assertion?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="error"
            loading={isDeletionLoading}
            onClick={() => {
              dispatch(
                deleteAssertion({ assertionId: deleteAssertionDialog.id })
              );
            }}
          >
            Delete
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AssertionsList;
