import { Box, Card, Typography, ThemeProvider, Tooltip, Paper } from "@mui/material";
import TestStepsList, { TestStepsListRef } from "../test-studio/components/TestStepsList";
import Replayer from "./components/Replayer";
import { useSearchParams } from "react-router-dom";
import { IListSessionEventsRequest, ITraceDetail } from "../models/sessions";
import {
  listSessionEvents,
  listTracesForRecordedSession,
  loadTraceSummary,
  updateSelectedPrefix,
} from "../features/replaysSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { debounce } from "lodash";
import HTTPReplay from "./components/HTTPReplay";
import SessionStorageManager from "../common/SessionStorageManager";
import { createSteps } from "../util/StepContextUtils";
import { TreeLoadState } from "../test-studio/models";
import React, { useEffect, useRef, useState } from "react";
import theme from "../common/theme";
import { generateUniqueID } from "../util";
import LocalStorageManager from "../common/LocalStorageManager";
import FloatingReplayer from "./components/FloatingReplayer";
import { useDrop } from "react-dnd";
import { Button } from "antd";
import CreateTestDialog from "./components/CreateTestDialog";
import { setSelectedProject } from "../features/commonSlice";
import "../styles/SessionReplay.css";

const SessionReplay = () => {
  const dispatch = useAppDispatch();

  const getPlayerDimensions = () => {
    return {
      width: (window.innerWidth - 33) * 0.79 - 30,
      height: window.innerHeight - 245,
    };
  };

  //console.log("session replay loading");

  const eventToTraceBuffer = 200;
  const [createTestDialogOpen, setCreateTestDialogOpen] = useState(false);

  const events = useAppSelector((state) => state.replays.events);
  const selectedPrefix = useAppSelector((state) => state.replays.selectedPrefix);
  const eventsLoading = useAppSelector((state) => state.replays.eventsLoading);
  const eventsPotentiallyIncomplete = useAppSelector((state) => state.replays.eventsPotentiallyIncomplete);
  const tracesLoading = useAppSelector((state) => state.replays.tracesLoading);
  const eventsLoaded = useAppSelector((state) => state.replays.eventsLoaded);
  const traces = useAppSelector((state) => state.replays.traces);
  const currentEvent = useAppSelector((state) => state.replays.currentEvent);
  const [highlightUptoTimestamp, setHighlightUptoTimestamp] = useState(0); // State to manage highlight timestamp
  const summaryTreeLoadState = useAppSelector((state) => state.replays.summaryTreeLoadState);
  const [loadingCompleteTraceIds, setLoadingCompleteTraceIds] = useState<string[]>([]);
  const rootPrefixes = useAppSelector((state) => state.replays.rootPrefixes);
  const childNodes = useAppSelector((state) => state.replays.childNodes);
  const nodeStatus = useAppSelector((state) => state.replays.nodeStatus);

  const [searchParams] = useSearchParams();
  const [dimensions, setDimensions] = useState(getPlayerDimensions);

  const [isHTTPMode, setIsHTTPMode] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [selectedStepIndex, setSelectedStepIndex] = useState(-1);
  const testStepsListRef = React.createRef<TestStepsListRef>();

  const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

  const [countdown, setCountdown] = useState(30);
  const countdownRef = useRef<NodeJS.Timeout | null>(null);

  const handleCreateTestOpenDialog = () => setCreateTestDialogOpen(true);
  const handleCreateTestCloseDialog = () => setCreateTestDialogOpen(false);
  const [play, setPlay] = useState(true);
  const [timestamp, setTimestamp] = useState<number | undefined>(undefined);
  const [miniPlayerPosition, setMiniPlayerPosition] = useState({ x: 10, y: window.innerHeight - 320 });
  const [miniReplayerDimensions, setMiniReplayerDimensions] = useState({ width: 300, height: 250 });
  const selectedProject = useAppSelector((state) => state.common.selectedProject);

  const ItemType = "BOX";

  const [, drop] = useDrop({
    accept: ItemType,
    drop: (_, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      if (delta) {
        setMiniPlayerPosition((prevPosition) => ({
          x: prevPosition.x + delta.x,
          y: prevPosition.y + delta.y,
        }));
      }
    },
  });

  useEffect(() => {
    if ((!events || events?.length === 0) && eventsLoaded) {
      countdownRef.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(countdownRef.current!);
            window.location.reload(); // Force a refresh
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdownRef.current) {
        clearInterval(countdownRef.current);
      }
    };
  }, [eventsLoaded, events]);

  useEffect(() => {
    if (eventsPotentiallyIncomplete) {
      countdownRef.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(countdownRef.current!);
            window.location.reload(); // Force a refresh
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdownRef.current) {
        clearInterval(countdownRef.current);
      }
    };
  }, [eventsPotentiallyIncomplete]);

  useEffect(() => {
    const sessionId = searchParams.get("session_id");
    const projectId = searchParams.get("project_id");
    if (projectId) {
      dispatch(setSelectedProject(projectId));
    }
    if (!LocalStorageManager.getDetailedUserInfo()) {
      let redirect = "";
      if (sessionId) {
        redirect = `flow=replay&session_id=${sessionId}`;
      }
      if (projectId) {
        redirect += `&project_id=${projectId}`;
      }
      window.location.href = BASE_APP_URL + "/signin?" + redirect;
    }

    const resizeHandler = () => {
      setDimensions(getPlayerDimensions());
    };

    const debouncedResizeHandler = debounce(resizeHandler, 300);

    window.addEventListener("resize", debouncedResizeHandler);

    debouncedResizeHandler();

    if (projectId) {
      LocalStorageManager.setProjectId(projectId);
    }
    if (sessionId) {
      const fetchAllSessionEvents = async () => {
        let paginationToken: Uint8Array | string | undefined = undefined;
        let paginationState = "CAN_CONTINUE";

        const maxCalls = 10;
        let callCount = 0;
        dispatch(
          listTracesForRecordedSession({
            session_recording_tracking_id: sessionId,
          })
        );

        while (paginationState === "CAN_CONTINUE" && callCount < maxCalls) {
          const request: IListSessionEventsRequest = {
            sessionRecordTrackingId: sessionId,
            paginationRequest: {
              pageSize: 2,
              paginationToken,
            },
          };

          const response = await dispatch(listSessionEvents(request)).unwrap();

          // Update pagination state and token
          paginationState = response.paginationResult.state;
          paginationToken = response.paginationResult.paginationToken;
          callCount++;
        }
      };

      fetchAllSessionEvents();
    }

    return () => {
      window.removeEventListener("resize", debouncedResizeHandler);
    };
  }, [searchParams]);

  useEffect(() => {
    Object.keys(summaryTreeLoadState).forEach((traceId) => {
      // Check if state changed to COMPLETE from a different state
      if (summaryTreeLoadState[traceId] === TreeLoadState.COMPLETE && !loadingCompleteTraceIds.includes(traceId)) {
        // Call setChildNodesMap on TestStepsList ref
        if (testStepsListRef.current) {
          testStepsListRef.current.setChildNodesMap(
            traceId,
            TreeLoadState.COMPLETE,
            rootPrefixes[traceId],
            childNodes[traceId],
            nodeStatus[traceId]
          );
        }
        // Update loadingCompleteTraceIds state
        setLoadingCompleteTraceIds((prevIds) => [...prevIds, traceId]);
      }
    });
  }, [summaryTreeLoadState]);

  const selectStepHandler = (index: number) => {
    console.log("Selecting step session replay", index);
    const selectedTraceId = traces[index]?.traceId;
    if (summaryTreeLoadState[selectedTraceId] != TreeLoadState.COMPLETE) {
      dispatch(loadTraceSummary(selectedTraceId));
    }
    setSelectedStepIndex(index);
    setTimestamp(currentEvent?.timestamp);
    setPlay(false);

    if (!isHTTPMode) {
      setIsTransitioning(true);
      setTimeout(() => {
        setIsHTTPMode(true);
        setIsTransitioning(false);
      }, 200); // match this with the transition duration}
    }
    dispatch(
      updateSelectedPrefix({
        traceId: selectedTraceId,
        prefix: "",
      })
    );
  };

  const floatingPlayerPlayHandler = (play: boolean) => {
    setPlay(play);
    setTimestamp(currentEvent?.timestamp);
  };

  const floatingPlayerMaximizeHandler = () => {
    // Reverse transition animations
    setIsTransitioning(true);
    setDimensions(getPlayerDimensions());
    setTimeout(() => {
      setIsHTTPMode(false);
      setIsTransitioning(false);
    }, 200); // Match the duration of the transition
  };

  useEffect(() => {
    // Update highlight timestamp based on currentEvent or default behavior
    if (currentEvent?.timestamp) {
      setHighlightUptoTimestamp(currentEvent.timestamp);
    } else {
      setHighlightUptoTimestamp(0); // Set default behavior here if needed
    }
  }, [currentEvent]);

  const handleCreateTest = (selectedTraces: ITraceDetail[]) => {
    const uid = generateUniqueID();
    SessionStorageManager.setTestStudioSteps(uid, createSteps(selectedTraces));
    window.open("/test-studio?uid=" + uid + (selectedProject ? "&project_id=" + selectedProject : ""), "_blank");
  };

  const selectPrefix = (traceId: string, pathPrefix: string) => {
    setSelectedStepIndex(traces.findIndex((trace) => trace.traceId === traceId));
    dispatch(updateSelectedPrefix({ traceId: traceId, prefix: pathPrefix }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box ref={drop} display="flex" flexDirection="column" flex={1} gap={1} overflow={"hidden"} className="fade-in">
        {eventsPotentiallyIncomplete && (
          <Box
            width="100%"
            sx={{ backgroundColor: "var(--secondary-color)" }}
            color="white"
            textAlign="center"
            py={0.1}
          >
            <Typography variant="body1">
              This is a recent session capture that is potentially incomplete. Refreshing in {countdown} secs.
            </Typography>
          </Box>
        )}
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          flex={1}
          minHeight={"2.3em"}
          maxHeight={"2.3em"}
          paddingY={1}
          border="1px solid" // Added this to explicitly define the border
          paddingX={2}
          marginX={2}
          marginTop={0.5}
          borderRadius={2}
          borderColor={"black"}
          bgcolor={"white"}
        >
          <Tooltip
            title={
              traces?.length === 0
                ? "No API requests captured. Please configure URL regex to capture to enable test creation"
                : ""
            }
          >
            <Button
              type="primary"
              className="primary-button"
              onClick={() => {
                traces?.length > 20 ? handleCreateTestOpenDialog() : handleCreateTest(traces);
              }}
              disabled={traces?.length === 0}
            >
              Create Test...
            </Button>
          </Tooltip>
        </Box>
        {(!events || events?.length === 0) && eventsLoaded ? (
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1} textAlign="center">
            <Typography variant="body1" color="textSecondary">
              Session not found yet. Refreshing in {countdown} secs. (The full UI recording will take ~1 minute to finalize and load.)
            </Typography>
          </Box>
        ) : (
          <Paper
            className="fade-in"
            elevation={3} // You can adjust this value for different levels of shadow
            sx={{
              display: "flex",
              marginX: 2,
              marginTop: "1px",
              marginBottom: "10px",
              border: "0.5px solid", // Explicitly define the border
              borderColor: "black",
              flexDirection: isHTTPMode ? "row-reverse" : "row",
              flexGrow: 1,
              minHeight: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                flexGrow: 1,
                transition: "transform 0.3s ease-in-out",
                overflow: "hidden", // Ensure content doesn't spill outside
              }}
            >
              {isHTTPMode ? (
                <HTTPReplay
                  key={selectedPrefix[traces[selectedStepIndex]?.traceId]}
                  traceId={traces[selectedStepIndex]?.traceId ?? ""}
                />
              ) : (
                <Replayer
                  play={true}
                  timestamp={timestamp}
                  dimensions={dimensions}
                  events={events}
                  areEventsLoading={eventsLoading}
                  showController={true}
                />
              )}
            </Box>

            <Card
              key={"side-nav"}
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: "21%",
                width: "21%",
                paddingX: "0px",
                overflowY: "auto",
                // Slide out to the left when transitioning
                ...(isTransitioning && !isHTTPMode ? { transform: "translateX(-300%)" } : {}),
                transition: "transform 0.3s ease-in-out",
              }}
            >
              {traces?.length > 0 ? (
                <TestStepsList
                  ref={testStepsListRef}
                  steps={traces?.map((trace) => ({
                    id: trace.traceId,
                    name: trace.entryOperation.operationName,
                    timestamp: new Date(trace.timestamp).getTime(),
                    context: {
                      internalContext: {
                        operation: trace.entryOperation,
                      },
                    },
                  }))}
                  selectedIndex={selectedStepIndex}
                  onSelectPrefix={(traceId, prefix) => selectPrefix(traceId, prefix)}
                  onSelectStep={selectStepHandler}
                  opacityWhenNonHighlighted={0.4}
                  highlightUptoTimestamp={
                    highlightUptoTimestamp ? highlightUptoTimestamp + eventToTraceBuffer : eventToTraceBuffer
                  }
                />
              ) : tracesLoading ? (
                <></>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column", // arrange content vertically
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%", // ensures the div takes the full height of the Card
                    textAlign: "center", // optional: to center text inside the div
                  }}
                >
                  <div>No API calls recorded... Make sure you have configured URL regex for HTTP interception.</div>
                  <br />
                  <a
                    href="https://www.testchimp.io/blog/capturing-sessions-with-testchimp-chrome-extension"
                    target="_blank"
                  >
                    Plugin Guide
                  </a>
                  <br />
                  <a href="https://www.testchimp.io/blog/getting-started-with-testchimp" target="_blank">
                    SDK Guide
                  </a>
                </div>
              )}
            </Card>
          </Paper>
        )}

        <CreateTestDialog
          open={createTestDialogOpen}
          onClose={handleCreateTestCloseDialog}
          traces={traces}
          onCreateTest={handleCreateTest}
        />
        {isHTTPMode && (
          <FloatingReplayer
            timestamp={timestamp}
            dimensions={miniReplayerDimensions}
            position={miniPlayerPosition}
            events={events}
            areEventsLoading={eventsLoading}
            showController={false}
            play={play}
            onPlay={floatingPlayerPlayHandler}
            onMaximize={floatingPlayerMaximizeHandler}
          />
        )}
      </Box>
    </ThemeProvider>
  );
};
export default SessionReplay;
