import React, { SyntheticEvent } from "react";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Autocomplete,
  Chip,
  Drawer,
} from "@mui/material";
import Select from "@mui/material/Select";
import {
  AssertionOperation,
  ExpressionSide,
  ExpressionType,
  IAddAssertionRequest,
  IConstantExpression,
  IPathPart,
  ISetConstantExpression,
  ISetVariableExpression,
  IVariableExpression,
} from "../../models/assertion";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  addAssertion,
  closeAddAssertionForm,
  setExpression,
  setOperation,
} from "../../features/assertionsSlice";
import { RootState } from "../../app/store";
import { LoadingButton } from "@mui/lab";
import { isEmpty } from "lodash";

const AddAssertions: React.FC<{ open: boolean }> = ({ open }) => {
  const dispatch = useAppDispatch();

  const queries = useAppSelector((state: RootState) => state.overlays.queries);

  const leftExpression = useAppSelector(
    (state: RootState) => state.assertions.leftExpression
  );

  const rightExpression = useAppSelector(
    (state: RootState) => state.assertions.rightExpression
  );

  const operation = useAppSelector(
    (state: RootState) => state.assertions.operation
  );

  const isAddAssertionLoading = useAppSelector(
    (state: RootState) => state.assertions.isAddAssertionLoading
  );

  const buildAssertionExpression = (
    expression: ISetConstantExpression | ISetVariableExpression
  ) => {
    if ("options" in expression) {
      const varExpression: IVariableExpression = {
        variableExpression: {
          attributeKey: expression.attributeKey,
          pathParts: expression.prefix.split("|").map((operation) => {
            const operationParts = operation.split(",");
            const pathPart: IPathPart = {
              operation: {
                resourceName: operationParts[0].slice(1),
                operationName: operationParts[1].slice(0, -1),
              },
            };
            return pathPart;
          }),
        },
      };
      return varExpression;
    } else {
      const constExpression: IConstantExpression = {
        constantExpression: {
          stringValue: expression.label,
        },
      };
      return constExpression;
    }
  };

  const addAssertionHandler = (e: SyntheticEvent) => {
    e.preventDefault();

    const request: IAddAssertionRequest = {
      assertion: {
        leftExpression: buildAssertionExpression(leftExpression!),
        rightExpression: buildAssertionExpression(rightExpression!),
        assertionOperation: operation,
      },
      testCase: {
        name: queries[0].filters.find((filter) => filter.key == "testName")!
          .value,
        suite: queries[0].filters.find((filter) => filter.key == "testSuite")!
          .value,
        type: queries[0].filters.find((filter) => filter.key == "testType")!
          .value,
      },
    };

    dispatch(addAssertion(request));
  };

  return (
    <Drawer variant="persistent" anchor="bottom" open={open}>
      <form>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          padding={4}
          paddingTop={5}
          sx={{ backgroundColor: "#FFFBF5" }}
        >
          <Grid item xs>
            <Autocomplete
              id="left-expression"
              freeSolo
              options={
                rightExpression && "options" in rightExpression
                  ? rightExpression.options
                  : []
              }
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? option
                  : (option! as Object).toString()
              }
              value={[leftExpression?.label]}
              inputValue={
                leftExpression?.type == ExpressionType.CONSTANT
                  ? leftExpression.label
                  : ""
              }
              onInputChange={(_, newValue) =>
                dispatch(
                  setExpression({
                    side: ExpressionSide.LEFT,
                    label: newValue,
                    type: ExpressionType.CONSTANT,
                  })
                )
              }
              multiple={leftExpression?.type == ExpressionType.VARIABLE}
              renderTags={(value) => {
                return value.map((option) => (
                  <Chip key={"left-expression-chip"} label={option} />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Left Expression"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <FormControl fullWidth>
              <InputLabel id="operation">Operation</InputLabel>
              <Select
                id="operation"
                value={operation}
                label="Operation"
                onChange={(e) =>
                  dispatch(setOperation(e.target.value as AssertionOperation))
                }
              >
                {Object.values(AssertionOperation).map((operation) => {
                  return (
                    <MenuItem key={operation} value={operation}>
                      {operation}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <Autocomplete
              id="right-expression"
              freeSolo
              options={
                leftExpression && "options" in leftExpression
                  ? leftExpression.options
                  : []
              }
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? option
                  : (option! as Object).toString()
              }
              value={[rightExpression?.label]}
              inputValue={
                rightExpression?.type == ExpressionType.CONSTANT
                  ? rightExpression.label
                  : ""
              }
              onInputChange={(_, newValue) =>
                dispatch(
                  setExpression({
                    side: ExpressionSide.RIGHT,
                    label: newValue,
                    type: ExpressionType.CONSTANT,
                  })
                )
              }
              multiple={rightExpression?.type == ExpressionType.VARIABLE}
              renderTags={(value) => {
                return value.map((option) => (
                  <Chip key={"right-expression-chip"} label={option} />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Right Expression"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <LoadingButton
              sx={{ borderRadius: 2, marginRight: 1 }}
              size="large"
              variant="contained"
              type="submit"
              disabled={isEmpty(leftExpression) || isEmpty(rightExpression)}
              loading={isAddAssertionLoading}
              onClick={addAssertionHandler}
            >
              Add
            </LoadingButton>
            <LoadingButton
              sx={{ borderRadius: 2 }}
              size="large"
              variant="outlined"
              onClick={() => dispatch(closeAddAssertionForm())}
            >
              Close
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
};

export default AddAssertions;
