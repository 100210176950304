import { ITestName } from "../../test-studio/models";
import client from "../client";
import { TimeWindow } from "./Common";



export type Filter = {
    timeWindow?: TimeWindow;
    limit: number;
}

interface ListTestCases {
    listTestCases: (request: ListTestCasesRequest) => Promise<any>;
}

interface ListTestInvocations {
    listTestInvocations: (request: ListTestInvocationsRequest) => Promise<any>;
}

export type ListTestCasesRequest = {
    scope?: Scope;
}

export type Scope = {
    resourceId?: number;
}

export type ListTestInvocationsRequest = {
    testCase: ITestName;
    filter: Filter;
}

export type TestInvocation = {
    id: string;
    timestamp: string;
    successTraceCount: number;
    failureTraceCount: number;
    successAssertions: number;
    failureAssertions: number;
}

export type ListTestInvocationsResponse = {
    invocations: TestInvocation[];
}

export type ListTestCasesResponse = {
    testCases: ITestName[];
}


const listTestCases: ListTestCases = {
    listTestCases: async (request: ListTestCasesRequest) => {
        try {
            let response = await client.post("/trackedtests/list_test_cases", request);
            return response.data;
        } catch (error) {
            console.error(`Error calling trackedtests.list_test_cases `, error);
            return { error: error };
        }
    },
};

const listTestInvocations: ListTestInvocations = {
    listTestInvocations: async (request: ListTestInvocationsRequest) => {
        try {
            let response = await client.post("/trackedtests/list_test_invocations", request);
            return response.data;
        } catch (error) {
            console.error(`Error calling trackedtests.list_test_invocations `, error);
            return { error: error };
        }
    },
};

export {
    listTestCases as listTestCases,
    listTestInvocations as listTestInvocations,
};
