import { Button, Paper, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { postData } from '../common/WebUtils';
import theme from '../common/theme';
import { Link } from 'react-router-dom';

const VerifyMail: React.FC = () => {
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const signinUrl = process.env.REACT_APP_BASE_APP_URL + "/signin";

    useEffect(() => {
        // Get the URLSearchParams object from the current URL
        const urlParams = new URLSearchParams(window.location.search);

        // Get the values of the 'orgId' and 'token' parameters from the URL
        const userId = urlParams.get('user_id');
        const token = urlParams.get('token');

        postData('admin/verify_user_email', { 'user_id': userId, 'token': token })
            .then(response => {
                // Check the response status
                if (response && response.responseHeader && response.responseHeader.status === 'SUCCESS') {
                    setErrorOccurred(false);
                    setIsVerified(true);
                } else {
                    // Set errorOccurred to true if the status is not SUCCESS
                    setErrorOccurred(true);
                }
            })
            .catch(error => {
                console.error(error);
                // Handle other errors here if needed
                setErrorOccurred(true);
            });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: '#f0f0f0' }}>
                <Paper style={{ padding: '20px', minWidth: '50vw', background: 'white', textAlign: 'center', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)' }}>
                    {errorOccurred ? (
                        <p>Oops, An error occurred. Please try again later.</p>
                    ) : (
                        isVerified ? (
                            <div>
                                <h3>Your email is successfully verified.</h3>
                                <a href={signinUrl}>
                                    <Button rel="noopener noreferrer">
                                        Sign In
                                    </Button></a>
                            </div>
                        ) : (
                            <p>Verifying Email...</p>
                        )
                    )}
                </Paper>
            </div>
        </ThemeProvider>
    );
};

export default VerifyMail;
