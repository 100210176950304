import { FC } from "react";
import HTTPMessage from "../../test-studio/components/HTTPMessage";
import { ReadonlyHTTPMessageProps } from "../../test-studio/models";
import useReadOnlyHTTPMessage from "../../test-studio/hooks/useReadOnlyHTTPMessage";

const ReadOnlyHTTPMessage: FC<ReadonlyHTTPMessageProps> = (props) => {
  const hookProps = useReadOnlyHTTPMessage(props);

  return <HTTPMessage {...props} {...hookProps} />;
};

export default ReadOnlyHTTPMessage;
