import {
  PayloadAction,
  createAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  AssertionOperation,
  ExpressionSide,
  IAddAssertionRequest,
  IAssertion,
  IDeleteAssertionRequest,
  IListAssertionsResponse,
  IListAssertiosnRequest,
  ISetConstantExpression,
  ISetVariableExpression,
} from "../models/assertion";
import Assertion from "../api/services/Assertion";
import { setExpressionFromOverlay } from "./overlaysSlice";
import { isEmpty } from "lodash";

export interface AssertionsState {
  isAddAssertionFormOpen: boolean;
  leftExpression?: ISetConstantExpression | ISetVariableExpression;
  rightExpression?: ISetConstantExpression | ISetVariableExpression;
  operation: AssertionOperation;
  assertions: IAssertion[];
  isAddAssertionLoading: boolean;
  isListAssertionLoading: boolean;
  isDeleteAssertionLoading: boolean;
  deleteAssertionDialog: {
    isOpen: boolean;
    id: number;
  };
}

const initialState: AssertionsState = {
  isAddAssertionFormOpen: false,
  leftExpression: undefined,
  rightExpression: undefined,
  operation: AssertionOperation.EQUALS,
  assertions: [],
  isAddAssertionLoading: false,
  isListAssertionLoading: false,
  isDeleteAssertionLoading: false,
  deleteAssertionDialog: {
    isOpen: false,
    id: -1,
  },
};

export const addAssertion = createAsyncThunk(
  "assertions/addAssertion",
  async (request: IAddAssertionRequest) => {
    return await Assertion.addAssertion(request);
  }
);

export const listAssertions = createAsyncThunk(
  "assertions/listAssertions",
  async (request: IListAssertiosnRequest) => {
    const response: IListAssertionsResponse =
      await Assertion.listAssertions(request);
    return response;
  }
);

export const deleteAssertion = createAsyncThunk(
  "assertions/deleteAssertion",
  async (request: IDeleteAssertionRequest, { dispatch }) => {
    await Assertion.deleteAssertion(request);
    dispatch(updateAssertions(request.assertionId));
  }
);

export const setExpression = createAction<ISetConstantExpression>(
  "assertions/setExpression"
);

const assertionsSlice = createSlice({
  name: "assertions",
  initialState,
  reducers: {
    openAddAssertionForm: (state) => {
      state.isAddAssertionFormOpen = true;
    },
    closeAddAssertionForm: (state) => {
      state.isAddAssertionFormOpen = false;
      assertionsSlice.caseReducers.resetAddAssertionForm(state);
    },
    resetAddAssertionForm: (state) => {
      state.leftExpression = initialState.leftExpression;
      state.rightExpression = initialState.rightExpression;
      state.operation = initialState.operation;
    },
    setOperation: (state, action: PayloadAction<AssertionOperation>) => {
      state.operation = action.payload;
    },
    openDeleteAssertionDialog: (state, action: PayloadAction<number>) => {
      state.deleteAssertionDialog = {
        isOpen: true,
        id: action.payload,
      };
    },
    closeDeleteAssertionDialog: (state) => {
      state.deleteAssertionDialog = {
        isOpen: false,
        id: -1,
      };
    },
    updateAssertions: (state, action: PayloadAction<number>) => {
      const assertionToBeDeletedIndex = state.assertions.findIndex(
        (assertion) => assertion.id == action.payload
      );
      state.assertions.splice(assertionToBeDeletedIndex, 1);
      assertionsSlice.caseReducers.closeDeleteAssertionDialog(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAssertion.pending, (state) => {
        state.isAddAssertionLoading = true;
      })
      .addCase(addAssertion.fulfilled, (state) => {
        state.isAddAssertionLoading = false;
        assertionsSlice.caseReducers.closeAddAssertionForm(state);
      })
      .addCase(addAssertion.rejected, (state) => {
        state.isAddAssertionLoading = false;
      })
      .addCase(deleteAssertion.pending, (state) => {
        state.isDeleteAssertionLoading = true;
      })
      .addCase(deleteAssertion.fulfilled, (state) => {
        state.isDeleteAssertionLoading = false;
      })
      .addCase(deleteAssertion.rejected, (state) => {
        state.isDeleteAssertionLoading = false;
      })
      .addCase(listAssertions.pending, (state) => {
        state.isListAssertionLoading = true;
      })
      .addCase(listAssertions.fulfilled, (state, { payload }) => {
        state.isListAssertionLoading = false;
        state.assertions = payload.assertions;
      })
      .addCase(listAssertions.rejected, (state) => {
        state.isListAssertionLoading = false;
      })
      .addCase(setExpression, (state, action) => {
        if (action.payload.side == ExpressionSide.LEFT) {
          state.leftExpression = isEmpty(action.payload.label)
            ? undefined
            : action.payload;
        } else {
          state.rightExpression = isEmpty(action.payload.label)
            ? undefined
            : action.payload;
        }
      })
      .addCase(setExpressionFromOverlay, (state, action) => {
        !state.isAddAssertionFormOpen &&
          assertionsSlice.caseReducers.openAddAssertionForm(state);

        if (action.payload.side == ExpressionSide.LEFT) {
          state.leftExpression = action.payload;

          if (state.rightExpression && "options" in state.rightExpression) {
            state.rightExpression.options = [];
          }
        } else {
          state.rightExpression = action.payload;

          if (state.leftExpression && "options" in state.leftExpression) {
            state.leftExpression.options = [];
          }
        }
      });
  },
});

export const {
  openAddAssertionForm,
  closeAddAssertionForm,
  resetAddAssertionForm,
  setOperation,
  updateAssertions,
  openDeleteAssertionDialog,
  closeDeleteAssertionDialog,
} = assertionsSlice.actions;

export default assertionsSlice.reducer;
