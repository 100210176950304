import { Box, Fab, Tab } from "@mui/material";
import React, { useState } from "react";
import AddAssertions from "./components/AddAssertions";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AssertionsList from "./components/AssertionsList";
import BaseOverlay from "../overlays/components/BaseOverlay";
import { fabStyle } from "../styles/overlay/styles";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import {
  closeAddAssertionForm,
  openAddAssertionForm,
} from "../features/assertionsSlice";
import { ExpressionSide, ExpressionType } from "../models/assertion";
import { prefixToExpressionLabel } from "./Utills";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { setExpressionFromOverlay } from "../features/overlaysSlice";

const AssertionStudio: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("Flows");

  const isAddAssertionFormOpen = useAppSelector(
    (state: RootState) => state.assertions.isAddAssertionFormOpen
  );

  const selectedTrace = useAppSelector(
    (state: RootState) => state.overlays.selectedTrace
  );

  const currentDiffCardContext = useAppSelector(
    (state: RootState) => state.overlays.currentDiffCardContext
  );

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const addAssertionHandler = () => {
    isAddAssertionFormOpen
      ? dispatch(closeAddAssertionForm())
      : dispatch(openAddAssertionForm());
  };

  const dispatch = useAppDispatch();

  const menuItems: ItemType[] = [
    {
      key: ExpressionSide.LEFT,
      label: "Set as left expression",
    },
    {
      key: ExpressionSide.RIGHT,
      label: "Set as right expression",
    },
  ];

  const onMenuClick = (key: string) => {
    dispatch(
      setExpressionFromOverlay({
        side: key as ExpressionSide,
        label: prefixToExpressionLabel(
          selectedTrace!.prefix,
          currentDiffCardContext!.attributeKey
        ),
        prefix: selectedTrace!.prefix,
        attributeKey: currentDiffCardContext!.attributeKey,
        type: ExpressionType.VARIABLE,
        options: Object.keys(currentDiffCardContext!.attributeValue.values),
      })
    );
  };

  return (
    <>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange}>
            <Tab label="Flows" value="Flows" />
            <Tab label="Assertions" value="Assertions" />
          </TabList>
        </Box>
        <TabPanel
          value="Flows"
          sx={{
            bgcolor: "#fff",
            display: "flex",
            minHeight: 0,
            paddingX: 0,
            paddingBottom: 0,
            marginBottom: isAddAssertionFormOpen ? "130px" : "0px",
            transition: "margin-bottom 0.2s",
          }}
        >
          <BaseOverlay menuItems={menuItems} onMenuClick={onMenuClick} />
          {!isAddAssertionFormOpen && (
            <Fab
              variant="extended"
              color="primary"
              aria-label="add"
              sx={fabStyle}
              onClick={addAssertionHandler}
            >
              <AddIcon /> Assertion
            </Fab>
          )}
        </TabPanel>
        <TabPanel value="Assertions" sx={{ bgcolor: "#fff" }}>
          <AssertionsList />
        </TabPanel>
      </TabContext>
      <AddAssertions open={isAddAssertionFormOpen} />
    </>
  );
};

export default AssertionStudio;
