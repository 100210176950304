import client from "../client";


export interface ContactSupportRequest {
    message:string;
}

export interface ContactSupportResponse {
}


const Admin = {
    contactSupport: async (request: ContactSupportRequest): Promise<ContactSupportResponse> => {
        const response = await client.post("/admin/contact_support", request);
        return response.data;
    },

}

export default Admin;
