import React, { Component } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import NamedPanel from './NamedPanel';
import { Typography } from "@mui/material";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

class ManualTimeRangeSelector extends Component {
    constructor(props) {
        let defaultStartTime = dayjs().subtract(dayjs.duration({ 'days': 1 }));
        let defaultEndTime = dayjs();
        super(props);
        this.state = {
            startTime: this.props.startTime ?? defaultStartTime,
            endTime: this.props.endTime ?? defaultEndTime,
        };
    }

    onTimeSelectionUpdate = (mode, newValue) => {
        if (mode == "start") {
            this.setState({ startTime: newValue }, () => this.props.onRangeSelection(this.state.startTime, this.state.endTime));
        }
        if (mode == "end") {
            this.setState({ endTime: newValue }, () => this.props.onRangeSelection(this.state.startTime, this.state.endTime));
        }
    }

    render() {
        const { startTime, endTime } = this.state;
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ display: 'flex', margin: '10px', justifyContent: "center", width: "100%" }} >
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', justifyContent: "center", width: "100%" }}>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '10px', justifyContent: "space-evenly" }}>
                            <DateTimePicker
                                label="Start time"
                                value={startTime} style={{ marginLeft: '8px' }}
                                onChange={(newValue) => this.onTimeSelectionUpdate('start', newValue)}
                            />
                            <DateTimePicker
                                label="End time"
                                value={endTime} style={{ marginLeft: '8px' }}
                                onChange={(newValue) => this.onTimeSelectionUpdate('end', newValue)}
                            />
                        </div>
                    </div>
                </div>
            </LocalizationProvider >
        );
    }
}

export default ManualTimeRangeSelector;
