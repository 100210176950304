import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Select, InputLabel, FormControl, Grid, ThemeProvider, MenuItem, Fab, Switch, FormControlLabel, TextField, Box } from '@mui/material';
import Scenarios, { Priority, SuggestTestScenarioDescriptionResponse, TestScenario, TestScenarioStatus } from '../api/services/Scenarios';
import theme from "../common/theme";
import 'react-quill/dist/quill.snow.css';
import { AutoAwesome } from '@mui/icons-material';
import { message } from 'antd';
import ReactQuill, { Quill } from 'react-quill';

interface EditTestScenarioDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (testScenario: TestScenario) => void;
    testScenarioToEdit?: TestScenario; // Added for edit mode
}

const EditTestScenarioDialog: React.FC<EditTestScenarioDialogProps> = ({ open, onClose, onSave, testScenarioToEdit }) => {
    const [newTitle, setNewTitle] = useState('');
    const [newDescription, setNewDescription] = useState<string>('{}');
    const [newPriority, setNewPriority] = useState<Priority | ''>('');
    const [testScenarioId, setTestScenarioId] = useState<number | undefined>(undefined);
    const [status, setStatus] = useState<TestScenarioStatus>(TestScenarioStatus.ACTIVE_TEST_SCENARIO);
    const quillRef = useRef<ReactQuill | null>(null);

    const priorities = {
        [Priority.P0]: 'P0',
        [Priority.P1]: 'P1',
        [Priority.P2]: 'P2',
        [Priority.P3]: 'P3',
    };

    useEffect(() => {
        if (testScenarioToEdit) {
            setNewTitle(testScenarioToEdit.title || '');
            setNewPriority(testScenarioToEdit.priority || '');
            setTestScenarioId(testScenarioToEdit.id);
            setNewDescription(testScenarioToEdit.description || '{}');
            setStatus(testScenarioToEdit.status || TestScenarioStatus.ACTIVE_TEST_SCENARIO);
        } else {
            setNewTitle('');
            setNewPriority('');
            setTestScenarioId(undefined);
            setNewDescription('');
            setStatus(TestScenarioStatus.ACTIVE_TEST_SCENARIO);
        }
    }, [testScenarioToEdit]);

    useEffect(() => {
        if (quillRef.current) {
            quillRef.current?.getEditor().setContents(JSON.parse(newDescription ?? "{}"));
        }
    }, [quillRef.current]);

    const handleSave = () => {
        const testScenario: TestScenario = {
            id: testScenarioId, // Include ID for edit mode
            title: newTitle,
            description: JSON.stringify(quillRef.current?.getEditor().getContents()),
            priority: newPriority || Priority.UNKNOWN_PRIORITY,
            userStoryId: testScenarioToEdit?.userStoryId,
            status: status,
        };
        onSave(testScenario);
        onClose(); // Close dialog after saving
    };

    const handleSuggestDescription = async () => {
        try {
            const response: SuggestTestScenarioDescriptionResponse = await Scenarios.suggestTestScenarioDescription({ scenarioId: testScenarioToEdit!.id!, existingDescription: JSON.stringify(quillRef.current?.getEditor().getContents()) });
            if (!response.description || response?.description?.length == 0) {
                message.error("Could not complete the description. Try again later");
            }
            quillRef.current?.getEditor().setContents(JSON.parse(response.description));
        } catch (error) {
            console.error("Error fetching suggested description:", error);
        }
    };

    const handleToggleArchive = () => {
        setStatus((prevStatus) =>
            prevStatus === TestScenarioStatus.ACTIVE_TEST_SCENARIO
                ? TestScenarioStatus.DELETED_TEST_SCENARIO
                : TestScenarioStatus.ACTIVE_TEST_SCENARIO
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        label="Title"
                        fullWidth
                        variant="outlined"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1em' }}>
                            <Button
                                color="primary"
                                variant='outlined'
                                size="small"
                                disabled={!newTitle || newTitle?.length == 0}
                                onClick={handleSuggestDescription}
                            >
                                Write for me...
                            </Button>
                        </div>
                        <ReactQuill
                            ref={quillRef}
                            theme="snow"
                            style={{
                                height: '12em', // Set the height to accommodate approximately 10 lines
                                maxHeight: '12em',
                                overflowY: 'inherit', // Enable vertical scrolling
                                marginBottom: '70px'
                            }}
                        />
                    </div>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel>Priority</InputLabel>
                            <Select
                                style={{width:"200px"}}
                                value={newPriority}
                                onChange={(e) => setNewPriority(e.target.value as Priority)}
                                label="Priority"
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {Object.entries(priorities).map(([value, label]) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={status !== TestScenarioStatus.DELETED_TEST_SCENARIO}
                                    onChange={handleToggleArchive}
                                />
                            }
                            label={status === TestScenarioStatus.DELETED_TEST_SCENARIO ? 'Archived' : 'Active'}
                            sx={{ alignSelf: 'flex-end' }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default EditTestScenarioDialog;