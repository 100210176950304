import React from "react";
import { ThemeColors } from "../styles/light-colors";

class DiffOptionsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 'VALUE_COUNT',
        };
    }

    handleOptionChange = (event) => {
        const selectedOption = event.target.value;
        this.setState({ selectedOption });
        this.props.onOptionChange(selectedOption);
    };

    render() {
        const { selectedOption } = this.state;
        const { disabled } = this.props;

        return (
            <div>
                <label style={{ color: ThemeColors.text }}>
                    <p>Diff Mode:</p>
                </label>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    <label style={{ color: ThemeColors.darkText, marginLeft: "10px" }}>
                        <input
                            disabled={disabled}
                            type="radio"
                            value="VALUE_COUNT"
                            checked={selectedOption === "VALUE_COUNT"}
                            onChange={this.handleOptionChange}
                        />
                        By Value Count
                    </label>
                    <label style={{ color: ThemeColors.darkText }}>
                        <input
                            disabled={disabled}
                            type="radio"
                            value="VALUE_PRESENCE"
                            checked={selectedOption === "VALUE_PRESENCE"}
                            onChange={this.handleOptionChange}
                        />
                        By Value Presence
                    </label>
                </div>
            </div>
        );
    }
}

export default DiffOptionsPanel;