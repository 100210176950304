import {
  Box,
  Button,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const Capacity = ({ open, close, updateCapacity }) => {
  const [newCapacity, setNewCapacity] = useState("");

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <Modal open={open}>
        <Box sx={styles}>
          <Typography variant="h5">Update Capacity</Typography>
          <Box mt={2} display="flex" flexDirection="column">
            <Typography>New Capacity</Typography>
            <TextField
              type="number"
              value={newCapacity}
              onChange={(e) => setNewCapacity(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Million EPM</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between" gap={1}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => updateCapacity(newCapacity)}
            >
              Ok
            </Button>
            <Button variant="outlined" fullWidth onClick={close}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Capacity;
