import React, { Component } from 'react';
import ManualTimeRangeSelector from './ManualTimeRangeSelector';

class TimeRangeSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 'by_time',
            startTime: this.props.startTime,
            endTime: this.props.endTime
        }
    }

    handleOptionChange = (event, option) => {
        this.setState({ selectedOption: option });
    }

    onRangeSelection = (startTime, endTime) => {
        this.props.onRangeSelection(startTime, endTime);
    }

    render() {
        const { selectedOption, startTime, endTime } = this.state;
        const { context } = this.props;
        return (
            <div style={{ display: 'flex', flexDirection: "column", margin: '10px', justifyContent: "center", width: "100%" }}>
                <div style={{ "height": "100%", "width": "100%" }}>
                    <ManualTimeRangeSelector onRangeSelection={this.onRangeSelection} startTime={startTime} endTime={endTime} />
                </div>
            </div>);
    }
}

export default TimeRangeSelector;
