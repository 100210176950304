import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IListSessionEventsRequest,
  IListSessionEventsResponse,
  IListTracesForRecordedSessionRequest,
  IListTracesForRecordedSessionResponse,
  ITraceDetail,
} from "../models/sessions";
import { eventWithTime } from "@rrweb/types";
import SessionEvents from "../api/services/SessionEvents";
import { PrefixSummary, PrefixSummaryTree, TraceCollectionSummaryTree } from "../models/overlays";
import Collectors from "../api/services/Collectors";
import { TreeLoadState } from "../test-studio/models";
import { getNodeStatus } from "../util/TraceUtils";

export interface ReplaysState {
  eventsLoading: boolean;
  eventsPotentiallyIncomplete: boolean;
  eventsLoaded: boolean;
  tracesLoading: boolean;
  events: eventWithTime[];
  traces: ITraceDetail[];
  summaryTreeLoadState: { [traceId: string]: TreeLoadState }
  currentEvent?: eventWithTime;
  selectedPrefix: { [traceId: string]: string };
  rootPrefixes: { [traceId: string]: string };
  // map of list of child path prefixes for a given pathPrefix of a trace
  childNodes: { [traceId: string]: { [pathPrefix: string]: string[] } }
  // PrefixSummary at a given path prefix of a trace
  prefixSummaries: { [traceId: string]: { [pathPrefix: string]: PrefixSummary } }
  nodeStatus:{[traceId: string]: { [pathPrefix: string]: string }}
}

export type UpdateSelectedPrefixPayload = {
  traceId: string;
  prefix: string;
}

const initialState: ReplaysState = {
  eventsLoading: false,
  eventsPotentiallyIncomplete: false,
  eventsLoaded: false,
  tracesLoading: true,
  selectedPrefix: {},
  events: [],
  traces: [],
  childNodes: {},
  summaryTreeLoadState: {},
  prefixSummaries: {},
  rootPrefixes: {},
  nodeStatus:{}
};

export const listSessionEvents = createAsyncThunk(
  "listSessionEvents",
  async (request: IListSessionEventsRequest) => {
    return (await SessionEvents.listSessionEvents(
      request
    )) as IListSessionEventsResponse;
  }
);

export const listTracesForRecordedSession = createAsyncThunk(
  "listTracesForRecordedSession",
  async (request: IListTracesForRecordedSessionRequest, thunkAPI) => {
    const response = (await SessionEvents.listTracesForRecordedSession(
      request
    )) as IListTracesForRecordedSessionResponse;
    let traces: ITraceDetail[] = response.traces;
    const maxSteps = 10;
    let selectedTraces = traces;
    if (traces.length > maxSteps) {
      selectedTraces = traces.slice(0, maxSteps); // Select the first 20 steps
    }
    selectedTraces.forEach(trace => {
      thunkAPI.dispatch(loadTraceSummary(trace.traceId));
    });
    return response;
  }
);

// Thunk to load trace summaries
export const loadTraceSummary = createAsyncThunk(
  'testExecutions/loadTraceSummary',
  async (traceId: string) => {
    const response = await Collectors.getTraceSummaryTreesForTrace({ traceId });
    if (response.trees && response.trees.length >= 1) {
      return { traceId, summaryTree: response.trees[0] as TraceCollectionSummaryTree }; // Assuming we take the first tree
    }
    return { traceId, summaryTree: undefined };
  }
);

const replaysState = createSlice({
  name: "replaysSlice",
  initialState: initialState,
  reducers: {
    updateCurrentEvent: (state, action: PayloadAction<eventWithTime>) => {
      state.currentEvent = action.payload;
    },
    updateSelectedPrefix: (state, action: PayloadAction<UpdateSelectedPrefixPayload>) => {
      state.selectedPrefix[action.payload.traceId] = action.payload.prefix;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listSessionEvents.pending, (state) => {
        state.eventsLoading = true;
        state.eventsLoaded = false;
      })
      .addCase(listSessionEvents.rejected, (state) => {
        state.eventsLoading = false;
      })
      .addCase(listSessionEvents.fulfilled, (state, action) => {
        state.eventsLoading = false;
        state.eventsLoaded = true;
        if (action.payload.paginationResult.state == 'POTENTIALLY_INCOMPLETE') {
          state.eventsPotentiallyIncomplete = true;
        }
        if (action.payload.events) {
          state.events = [
            ...state.events,
            ...action.payload.events?.map((event) => JSON.parse(event.payload)),
          ];
        }
      })
      .addCase(listTracesForRecordedSession.pending, (state) => {
        state.tracesLoading = true;
      })
      .addCase(listTracesForRecordedSession.rejected, (state) => {
        state.tracesLoading = false;
      })
      .addCase(listTracesForRecordedSession.fulfilled, (state, action) => {
        state.tracesLoading = false;
        state.traces = action.payload.traces ?? [];
      }).addCase(loadTraceSummary.fulfilled, (state, action: PayloadAction<{ traceId: string; summaryTree?: TraceCollectionSummaryTree }>) => {
        const { traceId, summaryTree } = action.payload;

        if (summaryTree) {
          const processPrefixSummaryTree = (traceId: string, tree: PrefixSummaryTree) => {
            const { prefix, prefixSummary, childPrefixSummaries } = tree;

            if (!state.childNodes[traceId]) {
              state.childNodes[traceId] = {};
            }
            if (!state.nodeStatus[traceId]) {
              state.nodeStatus[traceId] = {};
            }
            if (!state.prefixSummaries[traceId]) {
              state.prefixSummaries[traceId] = {};
            }

            state.childNodes[traceId][prefix] = childPrefixSummaries?.map(child => child.prefix);
            state.prefixSummaries[traceId][prefix] = prefixSummary;
            state.nodeStatus[traceId][prefix] = getNodeStatus(prefixSummary);
            childPrefixSummaries?.forEach(child => {
              processPrefixSummaryTree(traceId, child);
            });
          };
          state.rootPrefixes[traceId] = summaryTree.prefixSummaryTree.prefix;
          processPrefixSummaryTree(traceId, summaryTree.prefixSummaryTree);
          state.summaryTreeLoadState[traceId] = TreeLoadState.COMPLETE;
        }
      });
  },
});

export const {
  updateCurrentEvent,
  updateSelectedPrefix
} = replaysState.actions;
export default replaysState.reducer;
