import React from "react";
import { postData } from "../common/WebUtils";
import CircleIndicator from "../common/CircleIndicator";
import { encodeFilterList, getErrorRateColor } from "../common/Util";
import { IconButton, Link, Paper } from "@mui/material";
import { ThemeColors } from "../styles/light-colors";
import SubscriptionIcon from "../common/SubscriptionIcon";
import CachedIcon from "@mui/icons-material/Cached";
import { Select, Table, Dropdown, Menu, Tooltip, Modal } from "antd";
import ProgressBar from "../common/ProgressBar";
import { CaretDownOutlined } from "@ant-design/icons";
import LocalStorageManager from "../common/LocalStorageManager";
import { Org, Project } from "../auth/Authorization";
import { generateUniqueID } from "../util";
import "../styles/FlowListViewer.css";
import SessionStorageManager from "../common/SessionStorageManager";
import FlowListSkeleton from "./FlowListSkeleton";

const NO_TELEMETRY_MESSAGE =
  "<h3>It seems TestChimp isn't receiving telemetric data for your project yet. Please follow the guide <a href='https://www.testchimp.io/blog/getting-started-with-testchimp' target='_blank'>here</a> for instructions on how to send data to TestChimp.</h3>";
class FlowListViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orgs: [],
      resources: [],
      envToCoverageReportingConfig: {},
      prodEnvName: "prod",
      expandedRowKeys: [],
      orgToSelect: this.props.selectedOrg ?? "",
      resourceToSelect: this.props.selectedResource ?? "",
      selectedOrg: "",
      selectedResource: "",
      selectedResourceName: "",
      flowDetailList: this.props.flowDetailList ?? [],
      stdEnvToOrgEnvMap: new Map(),
      totalResultCount: 0,
      remainingResultCount: 0,
      infoMessage: "",
    };

    console.log("Init state ", this.state);
  }

  handleToggle = (flowRow, value) => {
    // Make the function call here using the subscriptionId and value parameters
    if (value) {
      postData("user/add_subscription", {
        subscription: { flow: { operation_id: flowRow.operationId } },
      })
        .then((response) => {
          if (response.subscriptionId) {
            let flowDetailList = this.state.flowDetailList;
            let rowToUpdate = flowDetailList.find(
              (obj) => obj.rowId === flowRow.rowId
            );
            rowToUpdate.subscriptionId = response.subscriptionId;

            this.setState({ flowDetailList: flowDetailList });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      postData("user/remove_subscription", {
        subscription_id: flowRow.flowSubscriptionId,
      })
        .then(() => {
          let flowDetailList = this.state.flowDetailList;
          let rowToUpdate = flowDetailList.find(
            (obj) => obj.rowId === flowRow.rowId
          );
          rowToUpdate.subscriptionId = 0;
          this.setState({ flowDetailList: flowDetailList });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  getLoadTestCoverageColor = (prod_qps, load_qps) => {
    if (prod_qps == 0) {
      return "gray";
    }
    if (!load_qps) {
      load_qps = 0;
    }
    let ratio = load_qps / prod_qps;
    if (ratio < 0.01) {
      return ThemeColors.error;
    }
    if (ratio >= 0.1) {
      return ThemeColors.success;
    }
    return ThemeColors.warning;
  };

  handleOrgSelectionEvent = (value) => {
    this.setState({ orgToSelect: null }, () => this.handleOrgSelection(value));
  };

  handleOrgSelection = (orgId) => {
    this.props.onOrgSelection(orgId);
    this.setState({ selectedOrg: orgId }, () => {
      LocalStorageManager.setProjectId(orgId);
      this.fetchResources();
    });
  };

  fetchResources = () => {
    const { resourceToSelect } = this.state;
    console.log("Resource to select ", resourceToSelect);
    postData("collectors/list_resources", {})
      .then((response) => {
        this.setState({ resources: response.resources }, () => {
          if (resourceToSelect) {
            this.handleResourceSelection(resourceToSelect);
          }
          const { resources } = this.state;
          if (!resources || resources?.length == 0) {
            this.setState({ infoMessage: NO_TELEMETRY_MESSAGE });
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  addEmptyChildListToFlow = (flow) => {
    if (flow.isExpandable) {
      return {
        ...flow,
        childFlowSummaries: [],
      };
    }
    return flow;
  };

  fetchFlowSummaries = () => {
    const pageSize = 3;
    const maxRequests = 20; // To avoid infinite loops, set a maximum number of requests
    let currentPage = 0;
    let paginationToken = null;
    this.setState({ flowDetailList: [] });

    const updateTable = (response) => {
      const flows = response.flows
        ? response.flows.map((flow) => this.addEmptyChildListToFlow(flow))
        : [];

      // Update the table with the current results from the response
      this.setState((prevState) => ({
        flowDetailList: [...prevState.flowDetailList, ...flows],
        totalResultCount:
          prevState.flowDetailList.length +
          response.paginationResult.remainingResultCount,
        remainingResultCount: response.paginationResult.remainingResultCount,
        envToCoverageReportingConfig: response.envToCoverageReportingConfig,
        prodEnvName: response.prodEnvName,
      }));
    };

    // To trigger a Loading results text in the flow list section.
    this.setState({
      totalResultCount: 0,
      remainingResultCount: 100,
    });

    const fetchPage = () => {
      if (currentPage >= maxRequests) {
        this.setState({
          totalResultCount: 0,
          remainingResultCount: 0,
        });
        return;
      }

      const request = {
        resource_id: this.state.selectedResource,
        pagination_request: {
          page_size: pageSize,
          pagination_token: paginationToken,
        },
      };

      postData("collectors/list_entry_points_with_details", request)
        .then((response) => {
          updateTable(response);
          if (response.paginationResult.state === "CAN_CONTINUE") {
            // Continue fetching next page
            currentPage++;
            paginationToken = response.paginationResult.paginationToken;
            fetchPage(); // Fetch next page recursively
          } else {
            // Reached END_OF_STREAM or max requests
            this.setState({
              remainingResultCount: 0,
              totalResultCount: 0,
              envToCoverageReportingConfig:
                response.envToCoverageReportingConfig,
              prodEnvName: response.prodEnvName,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          updateTable(); // Update the table with the current results even in case of an error
        });
    };

    // Start fetching the first page
    fetchPage();
  };

  onRowExpand = (expanded, record) => {
    const { expandedRowKeys } = this.state;
    this.setState({
      expandedRowKeys: !expanded
        ? expandedRowKeys.filter((id) => id !== record.rowId)
        : [...expandedRowKeys, record.rowId],
    });
    if (expanded && record.childFlowSummaries?.length == 0) {
      let request = {
        resource_id: this.state.selectedResource,
        entry_point: record.entryDeducedName,
        filters: record.filters,
        staleness: "FORCE_UPDATE",
      };
      postData("collectors/list_slices", request)
        .then((response) => {
          if (response.flows?.length > 0) {
            let childFlows = response.flows.map((flow) => {
              return this.addEmptyChildListToFlow(flow);
            });
            let flowDetailList = [...this.state.flowDetailList];
            this.updateWithChildFlows(flowDetailList, record.rowId, childFlows);
            this.setState({ flowDetailList: flowDetailList });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  updateWithChildFlows = (array, rowId, children) => {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];

      if (obj.rowId === rowId) {
        if (!obj.childFlowSummaries) {
          obj.childFlowSummaries = []; // create childFlowSummaries if it doesn't exist
        }

        obj.childFlowSummaries.push(...children); // add children to the array
        return true;
      }

      if (obj.childFlowSummaries?.length > 0) {
        let added = this.updateWithChildFlows(
          obj.childFlowSummaries,
          rowId,
          children
        );
        if (added) {
          return true;
        }
      }
    }
    return false;
  };

  handleResourceSelectionEvent = (value) => {
    this.setState({ resourceToSelect: null }, () =>
      this.handleResourceSelection(value)
    );
  };

  handleResourceSelection = (resourceId) => {
    const selectedResource = this.state.resources.find(
      (resource) => resource.id === resourceId
    );

    if (!selectedResource) {
      // Handle case when resource is not found
      console.log("Resource not found");
      return;
    }

    this.props.onResourceSelection(resourceId);
    this.setState(
      {
        selectedResource: resourceId,
        selectedResourceName: selectedResource.name,
      },
      () => {
        this.fetchFlowSummaries(false);
      }
    );
  };

  handleRowClick = (entryPoint, baseFilters, env, showSuccess) => {
    let url = `/explore?name=${entryPoint}&projectId=${this.state.selectedOrg}&resourceId=${this.state.selectedResource}`;
    if (!baseFilters) {
      baseFilters = [];
    }
    baseFilters.push({ key: "environment", operator: "EQUALS", value: env });
    baseFilters.push({
      key: "entryStatus",
      operator: "EQUALS",
      value: "STATUS_CODE_OK",
    });
    if (baseFilters.length > 0) {
      if (!showSuccess) {
        let filteredList = baseFilters.filter(
          (obj) => obj.key !== "entryStatus"
        );
        filteredList.push({
          key: "entryStatus",
          operator: "NOT_EQUALS",
          value: "STATUS_CODE_OK",
        });
        url =
          url +
          '&viewFilters="' +
          encodeFilterList(filteredList) +
          '"&diffFilters="' +
          encodeFilterList(baseFilters) +
          '"';
      } else {
        url = url + '&viewFilters="' + encodeFilterList(baseFilters) + '"';
      }
    }
    window.open(url, "_blank");
  };

  diffEnvs = (
    entryPoint,
    baseFilters,
    baseEnv,
    diffEnv,
    baseEnvShowSuccess,
    diffEnvShowSuccess
  ) => {
    let url = `/explore?name=${entryPoint}&projectId=${this.state.selectedOrg}&resourceId=${this.state.selectedResource}`;
    if (!baseFilters) {
      baseFilters = [];
    }
    let diffFilters = [...baseFilters];

    baseFilters.push({
      key: "environment",
      operator: "EQUALS",
      value: baseEnv,
    });
    baseFilters.push({
      key: "entryStatus",
      operator: baseEnvShowSuccess ? "EQUALS" : "NOT_EQUALS",
      value: "STATUS_CODE_OK",
    });

    diffFilters.push({
      key: "environment",
      operator: "EQUALS",
      value: diffEnv,
    });
    diffFilters.push({
      key: "entryStatus",
      operator: diffEnvShowSuccess ? "EQUALS" : "NOT_EQUALS",
      value: "STATUS_CODE_OK",
    });
    url =
      url +
      '&viewFilters="' +
      encodeFilterList(baseFilters) +
      '"&diffFilters="' +
      encodeFilterList(diffFilters) +
      '"';
    window.open(url, "_blank");
  };

  ignoreFlow = (resourceId, operationId) => {
    postData("collectors/add_ignored_entrypoint", {
      operation_id: operationId,
      resource_id: resourceId,
    })
      .then(() => { })
      .catch((error) => {
        console.error(error);
      });
  };

  generateApiTests = (resourceId, operationId) => {
    postData("test/generate_api_tests", {
      operation_id: operationId,
    })
      .then((data) => {
        Modal.success(data);
       })
      .catch((error) => {
        console.error(error);
      });
  };
  componentDidMount() {
    const { orgToSelect } = this.state;
    console.log("State", this.state);
    postData("user/get_projects", {})
      .then((response) => {
        this.setState(
          {
            orgs: response.projects,
          },
          () => {
            const { orgs } = this.state;
            if (orgToSelect) {
              this.setState({ selectedOrg: orgToSelect });
              this.handleOrgSelection(orgToSelect);
            } else {
              if (orgs ?? [].length == 1) {
                this.setState({ selectedOrg: orgs[0].id });
                this.handleOrgSelection(orgs[0].id);
              }
            }
            if (!orgs || orgs?.length == 0) {
              if (Org.isAdmin()) {
                this.setState({
                  infoMessage:
                    "<h3>Create a project via Menu -> Admin Settings</h3>",
                });
              } else {
                this.setState({
                  infoMessage:
                    "<h3>Contact your Organizations' Admin to create a new project.</h3>",
                });
              }
            } else {
              this.setState({
                infoMessage:
                  "<h3>Select a project and a resource to view the related entry flow details.</h3>",
              });
            }
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedResource != this.props.selectedResource) {
      this.setState({
        resourceToSelect: this.props.selectedResource,
      });
    }
  }

  renderEnvCoverage = (record, env) => {
    const { envToCoverageReportingConfig } = this.state;
    let coverageConfig = envToCoverageReportingConfig[env];
    if (coverageConfig?.reportingMode == "REPORT_PRESENCE") {
      return <CircleIndicator value={record.envCoverages[env].successQpm} />;
    } else {
      return <a>{record.envCoverages[env].successQpm.toFixed(2)}</a>;
    }
  };

  viewProdSummary = () => {
    // console.log("Diffing", operationId);
  };

  getResourceName(resourceId) {
    this.state.resources.filter((resource) => resource.id === resourceId);
  }

  render() {
    const { Option } = Select;
    const {
      totalResultCount,
      remainingResultCount,
      orgs,
      resources,
      selectedResource,
      flowDetailList,
      expandedRowKeys,
      prodEnvName,
    } = this.state;
    const envCoverages =
      flowDetailList.length > 0 ? flowDetailList[0].envCoverages ?? [] : []; // Assuming there is only one item in the data array

    let columns = [
      {
        title: "Flow",
        dataIndex: "displayName",
        key: "displayName",
        width: "300px",
        render: (text, record) => {
          const maxChars = 70; // Define your maximum character limit here
          const displayText = text.length > maxChars ? text.substring(0, maxChars) + '...' : text;

          return (
            <Tooltip title={text} placement="top">
              <Link
                href="#"
                underline="none"
                onClick={(e) => {
                  e.preventDefault();
                  SessionStorageManager.setOverlayQueries([
                    {
                      id: generateUniqueID(),
                      filters: [
                        {
                          key: "entryResourceName",
                          operator: "EQUALS",
                          value: this.state.selectedResourceName,
                        },
                        {
                          key: "entryDeducedName",
                          operator: "EQUALS",
                          value: record.entryDeducedName,
                        },
                        {
                          key: "environment",
                          operator: "EQUALS",
                          value: prodEnvName,
                        },
                      ],
                      time_window: {
                        relative_window: "86400s",
                      },
                      color: "#FF6900",
                    },
                  ]);
                  window.open("/overlays", "_blank");
                }}
              >
                {displayText}
              </Link>
            </Tooltip>
          );
        },
      },
      {
        title: "Actions",
        key: "actions",
        width: "100px", // Adjust the width as needed
        render: (text, record) => (
          <Dropdown
            overlay={
              <Menu>
                {Object.keys(envCoverages ?? []).map((key) => (
                  <Menu.Item
                    onClick={() => {
                      SessionStorageManager.setOverlayQueries([
                        {
                          id: generateUniqueID(),
                          filters: [
                            {
                              key: "entryResourceName",
                              operator: "EQUALS",
                              value: this.state.selectedResourceName,
                            },
                            {
                              key: "entryDeducedName",
                              operator: "EQUALS",
                              value: record.entryDeducedName,
                            },
                            {
                              key: "environment",
                              operator: "EQUALS",
                              value: `${key}`,
                            },
                          ],
                          time_window: {
                            relative_window: "86400s",
                          },
                          color: "#FF6900",
                        },
                      ]);
                      window.open("/overlays", "_blank");
                    }}
                  >
                    View {key} Execution Summary
                  </Menu.Item>))}
                {Project.isEditor() && (
                  <Menu.Item key="ignore">
                    <a
                      onClick={() =>
                        this.ignoreFlow(selectedResource, record.operationId)
                      }
                    >
                      Ignore Flow
                    </a>
                  </Menu.Item>
                )}
                  <Menu.Item key="gen_api_tests">
                    <a
                      onClick={() =>
                        this.generateApiTests(selectedResource, record.operationId)
                      }
                    >
                      Generate API tests
                    </a>
                  </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <IconButton aria-label="actions">
              <CaretDownOutlined style={{ fontSize: "16px" }} />
            </IconButton>
          </Dropdown>
        ),
      },
      {
        title: "QPM",
        dataIndex: "prod_qpm",
        key: "prod_qpm",
        width: "150px",
        align: "center",
        sorter: (a, b) =>
          (a.prodEnvCoverage?.totalQpm ?? 0) -
          (b.prodEnvCoverage?.totalQpm ?? 0),
        render: (text, record) =>
          (record.prodEnvCoverage?.totalQpm ?? 0).toFixed(2),
      },
      {
        title: "Error Rate",
        dataIndex: "errorRate",
        width: "150px",
        key: "errorRate",
        align: "center",
        sorter: (a, b) =>
          (a.prodEnvCoverage?.errorRate ?? 0) -
          (b.prodEnvCoverage?.errorRate ?? 0),
        render: (text, record) => {
          const handleClick = () =>
            this.handleRowClick(
              record.entryDeducedName,
              record.filters,
              prodEnvName,
              false
            );
          return (
            <a
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: getErrorRateColor(
                  record.prodEnvCoverage?.errorRate ?? 0
                ),
              }}
              onClick={handleClick}
            >
              {(record.prodEnvCoverage?.errorRate ?? 0).toFixed(2) + "%"}
            </a>
          );
        },
      },
      ...Object.keys(envCoverages)
        .filter((key) => key !== "Unspecified")
        .map((key) => ({
          title: key || "No Environment",
          dataIndex: key,
          key: key,
          width: "150px",
          render: (text, record) => this.renderEnvCoverage(record, key),
        })),
    ];

    columns.push({
      title: "Subscription",
      dataIndex: "flowSubscriptionId",
      width: "50px",
      key: "flowSubscriptionId",
      align: "center",
      render: (text, record) => (
        <SubscriptionIcon flowRow={record} onToggle={this.handleToggle} />
      ),
    });
    let flowDetailContent = <div></div>;
    if (flowDetailList?.length > 0) {
      flowDetailContent = (
        <Table
          rowKey="rowId"
          pagination={{ position: "bottomCenter", pageSize: 50 }}
          expandable={{
            onExpand: (expanded, record) => this.onRowExpand(expanded, record),
            childrenColumnName: "childFlowSummaries",
            columnTitle: "Expand",
            expandedRowKeys: expandedRowKeys,
            rowExpandable: () => true,
          }}
          columns={columns}
          dataSource={flowDetailList}
        />
      );
    } else {
      if (remainingResultCount > 0 && totalResultCount == 0) {
        flowDetailContent = <FlowListSkeleton />;
      } else {
        const { infoMessage } = this.state;
        let messageContent = <></>;
        if (infoMessage) {
          messageContent = (
            <div dangerouslySetInnerHTML={{ __html: infoMessage }} />
          );
        }
        flowDetailContent = (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              width: "100%",
              backgroundColor: ThemeColors.lightBackground,
            }}
          >
            {messageContent}
          </div>
        );
      }
    }

    let progressContent = <div></div>;
    if (remainingResultCount > 0) {
      progressContent = (
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <ProgressBar
            totalResults={totalResultCount}
            remainingResults={remainingResultCount}
          />
        </div>
      );
    }
    return (
      <div>
        <div className="filter-panel">
          <Select
            className="select"
            onChange={this.handleOrgSelectionEvent}
            placeholder="Select Project"
            value={this.state.selectedOrg}
          >
            {orgs?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>

          <Select
            className="select"
            onChange={this.handleResourceSelectionEvent}
            placeholder="Select Resource"
            value={this.state.selectedResource}
          >
            {resources?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>

          <IconButton
            style={{ color: "var(--cta-color)" }}
            className="icon-button"
            onClick={() => this.fetchFlowSummaries(true)}
          >
            <CachedIcon />
          </IconButton>
        </div>
        <div style={{ paddingTop: "5px" }}>
          <div>{progressContent}</div>
          <Paper style={{ marginLeft: "20px", marginRight: "20px" }}>
            {flowDetailContent}
          </Paper>
        </div>
      </div>
    );
  }
}

export default FlowListViewer;
