import React from "react";
import { decodeFilterList, parseTimeWindow } from "../common/Util";
import TraceCollectionViewer from "./TraceCollectionViewer";
import ExploreTabs from "./ExploreTabs";
import { Typography, Paper } from "@mui/material";
import { ThemeColors } from "../styles/light-colors";

class FlowExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attribMap: [],
      resourceAttribMap: [],
      context: {
        projectId: "",
        resourceId: "",
        entryPoint: "",
        selectedOperation: {},
        selectedPrefix: "",
        viewCollectionQuery: {},
        diffCollectionQuery: {},
        isDiffEnabled: false,
        diffMode: null,
        metricViewDuration: "1D",
        selectedViewMetric: null,
        viewExemplars: [],
        diffExemplars: [],
        selectedTraceIdForViewCollectionLogs: null,
        selectedTraceIdForDiffCollectionLogs: null,
        contextualLogsViewingEnabledForView: false,
        contextualLogsViewingEnabledForDiff: false,
      },
    };
    this.exploreTabsRef = React.createRef();
    this.traceCollectionViewerRef = React.createRef();
  }

  onTraceIdSelection = (collection, traceId) => {
    let context = this.state.context;
    if (collection == "view") {
      context.selectedTraceIdForViewCollectionLogs = traceId;
    } else if (collection == "diff") {
      context.selectedTraceIdForDiffCollectionLogs = traceId;
    }
    this.setState({ context: context });
  };

  onContextualLogsViewingModeUpdate = (collection, isEnabled) => {
    let context = this.state.context;
    if (collection == "view") {
      context.contextualLogsViewingEnabledForView = isEnabled;
    } else if (collection == "diff") {
      context.contextualLogsViewingEnabledForDiff = isEnabled;
    }
    this.setState({ context: context });
  };

  onTraceCollectionSelection = (conditions, exemplars) => {
    let context = this.state.context;
    context.viewCollectionQuery.filters = conditions;
    context.viewExemplars = exemplars;
    this.setState({ context: context });
  };

  onDiffCollectionSelection = (conditions, exemplars) => {
    let context = this.state.context;
    context.diffCollectionQuery.filters = conditions;
    context.diffExemplars = exemplars;
    this.setState({ context: context });
  };

  onDiffToggle = (isDiffEnabled, diffMode) => {
    let context = this.state.context;
    context.isDiffEnabled = isDiffEnabled;
    context.diffMode = diffMode;
    this.setState({ context: context });
  };

  updateAttributeSummaries = (
    operation,
    attribs,
    resourceAttribMap,
    prefix
  ) => {
    const { context } = this.state;
    const updatedContext = {
      ...context,
      selectedOperation: operation,
      selectedPrefix: prefix,
    };
    console.log("Received prefix", prefix);
    this.setState(
      {
        context: updatedContext,
        attribMap: attribs,
        resourceAttribMap: resourceAttribMap,
      },
      () => {
        console.log("Context after node selection", updatedContext);
      }
    );
  };

  componentDidMount = () => {
    const search = new URLSearchParams(window.location.search);
    if (search) {
      const entryPoint = search.get("name");
      const projectId = search.get("projectId");
      const resourceId = search.get("resourceId");
      let viewFilters = search.get("viewFilters");
      let diffFilters = search.get("diffFilters");
      let dw = search.get("dw");
      let vw = search.get("vw");
      let viewQuery = {};
      let diffQuery = {};
      if (!dw) {
        dw = "rw=86400s";
      }
      if (!vw) {
        vw = "rw=86400s";
      }
      let viewWindow = parseTimeWindow(vw);
      let diffWindow = parseTimeWindow(dw);
      if (viewFilters) {
        if (viewFilters.startsWith('"') && viewFilters.endsWith('"')) {
          viewFilters = viewFilters.substring(1, viewFilters.length - 1);
        }
        let conditions = decodeFilterList(viewFilters);
        viewQuery = {
          project_id: projectId,
          resource_id: resourceId,
          entry_point: entryPoint,
          filters: conditions,
          time_window: viewWindow,
        };
      }
      let isDiffEnabled = false;
      if (diffFilters) {
        if (diffFilters.startsWith('"') && diffFilters.endsWith('"')) {
          diffFilters = diffFilters.substring(1, diffFilters.length - 1);
        }
        let conditions = decodeFilterList(diffFilters);
        diffQuery = {
          project_id: projectId,
          resource_id: resourceId,
          entry_point: entryPoint,
          filters: conditions,
          time_window: diffWindow,
        };
        isDiffEnabled = true;
      }
      let context = {
        projectId: projectId,
        entryPoint: entryPoint,
        resourceId: resourceId,
        viewCollectionQuery: viewQuery,
        diffCollectionQuery: diffQuery,
        isDiffEnabled: isDiffEnabled,
      };
      this.setState({ context: context });
    }
  };

  onSelectionRange = (timeWindow, mode) => {
    let context = this.state.context;
    if (mode === "view") {
      context.viewCollectionQuery.time_window = timeWindow;
    } else if (mode === "diff") {
      context.diffCollectionQuery.time_window = timeWindow;
    }
    this.setState({ context: context }, () => {
      this.traceCollectionViewerRef.current.fetchAndUpdateViewTree();
    });
  };

  onContextUpdate = (context) => {
    this.setState({ context: context });
  };

  onDurationSelection = (duration) => {
    let context = this.state.context;
    context.metricViewDuration = duration;
    this.setState({ context: context });
  };

  onMetricSelection = (metricId) => {
    let context = this.state.context;
    context.selectedViewMetric = metricId;
    this.setState({ context: context });
  };

  render() {
    const { attribMap, resourceAttribMap, context } = this.state;

    const styles = {
      topContainer: {},
      traceViewContainer: {
        display: "flex",
        flexDirection: "row",
        border: "1px solid #f2f2f2",
        backgroundColor: ThemeColors.secondaryBackground,
        height: "100%",
        width: "100%",
      },
      leftColumn: {
        width: "30%",
        minWidth: "30em",
        overflowX: "auto",
        backgroundColor: ThemeColors.secondaryBackground,
        borderRight: "1px solid #f2f2f2",
      },
      rightColumn: {
        width: "70%",
        minWidth: "30em",
        overflowX: "auto",
        backgroundColor: ThemeColors.secondaryBackground,
      },
    };

    let content = (
      <div>
        <Paper style={styles.traceViewContainer}>
          <div style={styles.leftColumn}>
            <TraceCollectionViewer
              onContextUpdate={this.onContextUpdate}
              context={context}
              onTraceCollectionSelection={this.onTraceCollectionSelection}
              onDiffCollectionSelection={this.onDiffCollectionSelection}
              onDiffToggle={this.onDiffToggle}
              key={context.entryPoint}
              onNodeClick={this.updateAttributeSummaries}
              ref={this.traceCollectionViewerRef}
            />
          </div>
          <div style={styles.rightColumn}>
            <ExploreTabs
              ref={this.exploreTabsRef}
              flowToBotConvoItems={[]}
              context={context}
              attribMap={attribMap}
              resourceAttribMap={resourceAttribMap}
              onTraceIdSelection={this.onTraceIdSelection}
              onContextualLogsViewingModeUpdate={
                this.onContextualLogsViewingModeUpdate
              }
            />
          </div>
        </Paper>
      </div>
    );

    return (
      <div
        style={{
          paddingTop: "10px",
          height: "100vh",
          backgroundColor: ThemeColors.background,
        }}
      >
        {content}
        {/* <LoadingPopup open={showLoadingPopup} /> */}
      </div>
    );
  }
}

export default FlowExplorer;
