import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IAwareApiTest, IStep, ITestAssertion, ITestInvocationResult, MainTab, TestAssertionResult, TreeLoadState } from '../test-studio/models';
import { PrefixSummary, PrefixSummaryTree, TraceCollectionSummaryTree } from '../models/overlays';
import SessionEvents from '../api/services/SessionEvents';
import Collectors from '../api/services/Collectors';
import { ITraceDetail } from '../models/sessions';
import { Test } from '../api';
import { getNodeStatus } from '../util/TraceUtils';

interface TestExecutionState {
    traceDetails: ITraceDetail[];
    selectedStepIndex: number;
    selectedStep: IStep | undefined;
    selectedPrefix: { [traceId: string]: string }
    steps: IStep[];
    assertions: { [id: string]: ITestAssertion };
    assertionResults: { [id: string]: TestAssertionResult };
    // trace id to TraceCollectionSummaryTree map
    selectedTab: { [traceId: string]: MainTab };
    rootPrefixes: { [traceId: string]: string };
    // map of list of child path prefixes for a given pathPrefix of a trace
    childNodes: { [traceId: string]: { [pathPrefix: string]: string[] } }
    nodeStatus: { [traceId: string]: { [pathPrefix: string]: string } }
    // PrefixSummary at a given path prefix of a trace
    prefixSummaries: { [traceId: string]: { [pathPrefix: string]: PrefixSummary } }
    isEditTestEnabled: boolean;
    test: IAwareApiTest | undefined;
    summaryTreeLoadState: { [traceId: string]: TreeLoadState }
    isAssertResultsPopupOpen: boolean;
}

export type SetStepsPayload = {
    steps: IStep[];
};

export type UpdateSelectedPrefixPayload = {
    traceId: string;
    prefix: string;
}

const initialState: TestExecutionState = {
    traceDetails: [],
    rootPrefixes: {},
    selectedStepIndex: 0,
    selectedPrefix: {},
    selectedStep: undefined,
    steps: [],
    assertions: {},
    assertionResults: {},
    childNodes: {},
    nodeStatus: {},
    prefixSummaries: {},
    selectedTab: {},
    summaryTreeLoadState: {},
    isEditTestEnabled: false,
    isAssertResultsPopupOpen: false,
    test: undefined
};


// Thunk to load execution details
export const loadExecution = createAsyncThunk(
    'testExecutions/loadExecution',
    async (invocationId: string, thunkAPI) => {
        const response = await SessionEvents.listTracesForRecordedSession({ test_invocation_id: invocationId });
        // Dispatch loadTraceSummary for each trace
        let traces: ITraceDetail[] = response.traces;
        traces.forEach(trace => {
            thunkAPI.dispatch(loadTraceSummary(trace.traceId));
        });
        return { invocationId, traces: response.traces };

    }
);

// Thunk to load test
export const loadTest = createAsyncThunk(
    'testExecutions/loadTest',
    async (invocationId: string) => {
        const response = await Test.getAwareApiTest({ invocationId: invocationId });
        return { invocationId, response: response };
    }
);

// Thunk to load test invocation result
export const loadTestInvocationResults = createAsyncThunk(
    'testExecutions/loadTestInvocationResults',
    async (invocationId: string) => {
        const response = await Test.listTestInvocationResults({ invocationIds: [invocationId] });
        return { invocationId, response: response };
    }
);

// Thunk to load trace summaries
export const loadTraceSummary = createAsyncThunk(
    'testExecutions/loadTraceSummary',
    async (traceId: string) => {
        const response = await Collectors.getTraceSummaryTreesForTrace({ traceId });
        if (response.trees && response.trees.length >= 1) {
            return { traceId, summaryTree: response.trees[0] as TraceCollectionSummaryTree }; // Assuming we take the first tree
        }
        return { traceId, summaryTree: undefined };
    }
);

const testExecutionSlice = createSlice({
    name: 'testExecutions',
    initialState,
    reducers: {
        setSteps: (state, action: PayloadAction<SetStepsPayload>) => {
            state.steps = action.payload.steps;
        },
        updateSelectedTab: (state, action: PayloadAction<MainTab>) => {
            state.selectedTab[state.selectedStep!.id] = action.payload;
        },
        updateSelectedPrefix: (state, action: PayloadAction<UpdateSelectedPrefixPayload>) => {
            const selectedStepIndex = state.steps.findIndex(step => step.id === action.payload.traceId);
            testExecutionSlice.caseReducers.selectStep(state, { payload: selectedStepIndex, type: testExecutionSlice.actions.selectStep.type });
            state.selectedPrefix[action.payload.traceId] = action.payload.prefix;
        },
        setIsAssertResultsPopupOpen: (state, action: PayloadAction<boolean>) => {
            state.isAssertResultsPopupOpen = action.payload;
        },
        selectStep: (state, action: PayloadAction<number>) => {
            state.selectedStepIndex = action.payload;
            state.selectedStep = state.steps[action.payload];

            const mainTab = state.selectedTab[state.selectedStep!.id];

            if (!mainTab) {
                testExecutionSlice.caseReducers.updateSelectedTab(state, {
                    payload: MainTab.TEST_REQUEST,
                    type: testExecutionSlice.actions.updateSelectedTab.type,
                });
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadExecution.fulfilled, (state, action) => {
                const { traces } = action.payload;
                state.traceDetails = traces;
            })
            .addCase(loadTest.fulfilled, (state, action) => {
                state.test = action.payload.response.testConfig;
                state.test.assertions?.forEach(assertion => {
                    state.assertions[assertion.assertionId] = assertion;
                });
                state.isEditTestEnabled = true;
            })
            .addCase(loadTestInvocationResults.fulfilled, (state, action) => {
                let results: ITestInvocationResult[] = action.payload.response.invocationResults;
                if (results.length > 0) {
                    let result: ITestInvocationResult = results[0];
                    result.result.awareApiTestExecutionResult?.assertionResults?.forEach(assertionResult => {
                        state.assertionResults[assertionResult.assertionId] = assertionResult;
                    })
                }
            })
            .addCase(loadTraceSummary.fulfilled, (state, action: PayloadAction<{ traceId: string; summaryTree?: TraceCollectionSummaryTree }>) => {
                const { traceId, summaryTree } = action.payload;

                if (summaryTree) {
                    const processPrefixSummaryTree = (traceId: string, tree: PrefixSummaryTree) => {
                        const { prefix, prefixSummary, childPrefixSummaries } = tree;

                        if (!state.childNodes[traceId]) {
                            state.childNodes[traceId] = {};
                        }
                        if (!state.prefixSummaries[traceId]) {
                            state.prefixSummaries[traceId] = {};
                        }
                        if (!state.nodeStatus[traceId]) {
                            state.nodeStatus[traceId] = {};
                        }

                        state.childNodes[traceId][prefix] = childPrefixSummaries?.map(child => child.prefix);
                        state.prefixSummaries[traceId][prefix] = prefixSummary;
                        state.nodeStatus[traceId][prefix] = getNodeStatus(prefixSummary);

                        childPrefixSummaries?.forEach(child => {
                            processPrefixSummaryTree(traceId, child);
                        });
                    };
                    state.rootPrefixes[traceId] = summaryTree.prefixSummaryTree.prefix;
                    processPrefixSummaryTree(traceId, summaryTree.prefixSummaryTree);
                    state.summaryTreeLoadState[traceId] = TreeLoadState.COMPLETE;
                }
            });
    }
});

export const {
    setSteps,
    selectStep,
    updateSelectedTab,
    setIsAssertResultsPopupOpen,
    updateSelectedPrefix,
} = testExecutionSlice.actions;
export default testExecutionSlice.reducer;

