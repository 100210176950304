import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React, { useState } from "react";
import { isEqual } from "lodash";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  addGuard,
  closeAddGuardPopup as closeAddGuardPopup,
} from "../../features/overlaysSlice";
import { RootState } from "../../app/store";
import {
  AttributeLocator,
  OperationLocator,
  IAddGuardRequest,
} from "../../api/services/Guards";
import { Converters } from "../../util";

const AddGuardPopup: React.FC = () => {
  const enum LOCATION {
    EXACT = "exact",
    WITHIN = "within",
    ANYWHERE = "anywhere",
  }
  const dispatch = useAppDispatch();

  const conditionOperations: string[] = [
    "LESS_THAN",
    "EQUAL",
    "NOT_EQUAL",
    "GREATER_THAN",
    "CONTAINS",
    "NOT_CONTAINS",
    "ALL_GREATER_THAN",
    "ALL_LESS_THAN",
    "SOME_GREATER_THAN",
    "SOME_LESS_THAN",
  ];
  const conditionSeverities: string[] = ["INFO", "WARN", "FATAL"];

  const [conditionOperation, setConditionOperation] = useState(
    conditionOperations[0]
  );
  const [conditionValue, setConditionValue] = useState("");
  const [conditionSeverity, setConditionSeverity] = useState(
    conditionSeverities[0]
  );
  const [location, setLocation] = useState(LOCATION.EXACT);
  const [effectiveFrom, setEffectiveFrom] = useState(() =>
    dayjs().add(1, "day").toISOString()
  );

  const [additionalFiltersExpanded, setAddtionalFiltersExpanded] =
    useState(false);

  const isGuardOpen = useAppSelector(
    (state: RootState) => state.overlays.isGuardOpen
  );

  const currentDiffCardContext = useAppSelector(
    (state: RootState) => state.overlays.currentDiffCardContext
  );

  const selectedTrace = useAppSelector(
    (state: RootState) => state.overlays.selectedTrace
  );

  const selectedEntryPointHash = useAppSelector(
    (state: RootState) => state.overlays.selectedEntryPointHash
  );

  const entrypoint = useAppSelector(
    (state: RootState) =>
      state.overlays.entryPoints[selectedEntryPointHash]?.aggregate?.traceTree
        ?.operation
  );

  const addGuardLoading = useAppSelector(
    (state: RootState) => state.overlays.addGuardLoading
  );

  const onSubmitHandler = () => {
    let operation: OperationLocator;

    switch (location) {
      case LOCATION.EXACT: {
        operation = {
          pathPrefix: selectedTrace!.prefix,
        };
        break;
      }
      case LOCATION.WITHIN: {
        operation = {
          contextualOperation: {
            entrypoint: entrypoint,
            operation: selectedTrace!.operation,
          },
        };
        break;
      }
      case LOCATION.ANYWHERE: {
        operation = {
          contextualOperation: {
            operation: selectedTrace!.operation,
          },
        };
        break;
      }
      default: {
        console.log("Unexpected location");
        return;
      }
    }

    const attribLocator: AttributeLocator = {
      attributeKey: currentDiffCardContext!.attributeKey,
      operation: operation,
    };

    const typedValue = Converters.stringToTypedValue(conditionValue);

    const request: IAddGuardRequest = {
      config: {
        attribLocator: attribLocator,
        operation: conditionOperation,
        value: typedValue,
        severity: conditionSeverity,
        guardActivationCriteria: {
          timestamp: effectiveFrom,
        },
        additionalFilters: [],
      },
    };
    dispatch(addGuard(request));
    resetState();
  };

  const resetState = () => {
    setConditionOperation(conditionOperations[0]);
    setConditionValue("");
    setConditionSeverity(conditionSeverities[0]);
    setLocation(LOCATION.EXACT);
    setEffectiveFrom(dayjs().add(1, "day").toISOString());
    setAddtionalFiltersExpanded(false);
  };

  const onCancelHandler = () => {
    dispatch(closeAddGuardPopup());
    resetState();
  };

  return (
    <Dialog open={isGuardOpen} onClose={onCancelHandler}>
      <DialogTitle marginX={"auto"}>Add Guard</DialogTitle>
      <DialogContent>
        <form>
          <Grid paddingTop={2} container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="attribute"
                value={currentDiffCardContext?.attributeKey}
                disabled
                label="Attribute"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="condition-operation-label">
                  Operation
                </InputLabel>
                <Select
                  labelId="condition-operation-label"
                  id="condition-operation"
                  value={conditionOperation}
                  label="Operation"
                  onChange={(e) => setConditionOperation(e.target.value)}
                >
                  {conditionOperations.map((type) => {
                    return (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="condition-value"
                value={conditionValue}
                onChange={(e) => setConditionValue(e.target.value)}
                label="Value"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="condition-severity-label">Severity</InputLabel>
                <Select
                  labelId="condition-severity-label"
                  id="condition-severity"
                  value={conditionSeverity}
                  label="Condition"
                  onChange={(e) => setConditionSeverity(e.target.value)}
                >
                  {conditionSeverities.map((severity) => {
                    return (
                      <MenuItem key={severity} value={severity}>
                        {severity}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={location}
                onChange={(e) => setLocation(e.target.value as LOCATION)}
              >
                <FormControlLabel
                  value={LOCATION.EXACT}
                  control={<Radio />}
                  label="At current path prefix"
                />
                {!isEqual(entrypoint, selectedTrace?.operation) && (
                  <FormControlLabel
                    value={LOCATION.WITHIN}
                    control={<Radio />}
                    label={
                      <Stack direction={"row"} spacing={0.5}>
                        <Typography>At</Typography>
                        <Typography
                          bgcolor="#f2f2f8"
                          borderRadius={1}
                          paddingX={"0.3em"}
                        >
                          {selectedTrace?.operation.operationName}
                        </Typography>
                        <Typography> anywhere within </Typography>
                        <Typography
                          bgcolor="#f2f2f8"
                          borderRadius={1}
                          paddingX={"0.3em"}
                        >
                          {entrypoint?.operationName}
                        </Typography>
                      </Stack>
                    }
                  />
                )}
                <FormControlLabel
                  value={LOCATION.ANYWHERE}
                  control={<Radio />}
                  label="Anywhere"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    label="Effective from"
                    value={dayjs(effectiveFrom)}
                    onChange={(date) => setEffectiveFrom(date!.toISOString())}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                defaultExpanded
                expanded={additionalFiltersExpanded}
                onChange={(_, expanded) =>
                  setAddtionalFiltersExpanded(expanded)
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}
                >
                  <Typography variant="body1">Additional Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Stack spacing={1}>
                  {filters.map((filter, index) => {
                    return (
                      <Filter
                        key={index}
                        valueConfigs={valueConfigs}
                        keys={keys}
                        displayValues={displayValues}
                        index={index}
                        filter={filter}
                        onDelete={() => deleteFilterHandler(index)}
                        onChange={filterValueChangeHandler}
                      />
                    );
                  })}
                </Stack>
                <Box marginTop={1}>
                  <Button variant="outlined" onClick={addFilterHandler}>
                    Add Filter
                  </Button>
                </Box> */}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <LoadingButton
                  loading={addGuardLoading}
                  variant="contained"
                  onClick={onSubmitHandler}
                >
                  Create
                </LoadingButton>
                <Button
                  variant="outlined"
                  onClick={onCancelHandler}
                  disabled={addGuardLoading}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddGuardPopup;
