import React, { useState } from "react";
import { ISource, SpanAttribute, TraceTree } from "../../models/overlays";
import { Box, IconButton, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Stack from "@mui/material/Stack";
import Ring from "./Ring";
import { get } from "lodash";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import Tooltip from "@mui/material/Tooltip";
import { ThemeColors } from "../../styles/light-colors";
import { round } from "lodash";

interface TreeNodeProps {
  node: TraceTree;
}

const TreeNode: React.FC<TreeNodeProps> = ({ node }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const overlaysState = useAppSelector((state: RootState) => state.overlays);
  const queries = useAppSelector((state: RootState) => state.overlays.queries);
  const ringAttribute = useAppSelector(
    (state: RootState) => state.overlays.ringAttribute
  );

  const spanColorAttribute = useAppSelector(
    (state: RootState) => state.overlays.spanColorAttribute
  );

  const spanColorRange = useAppSelector(
    (state: RootState) => state.overlays.spanColorRange
  );

  const getErrorRate = (source: ISource): number => {
    if (!source) return 0;
    const errorRate = get(overlaysState, source.path)?.errorRate;
    return errorRate ? errorRate : 0;
  };

  const getFrequency = (source: ISource): number => {
    if (!source) return 0;
    const frequency = get(overlaysState, source.path)?.frequency;
    return frequency ? frequency : 0;
  };

  const getLatency = (source: ISource): number => {
    if (!source) return 0;
    const latency = get(overlaysState, source.path)?.latency;
    return latency ? latency : 0;
  };

  const getOverlayColor = (source: ISource): string => {
    if (!source) return "#fff";
    const parts = source.path.split(".").slice(0, 4);
    const parentPath = parts.join(".");
    const color = get(overlaysState, parentPath)?.traceColor;
    return color ? color : "#fff";
  };

  const getTreeNodeColor = (node: TraceTree) => {
    const baseOverlayId = queries[0].id;
    const baseOverlaySource = node.sources.find((source) =>
      source.path.includes(baseOverlayId)
    );

    if (baseOverlaySource) {
      const spanColorAttributeValue =
        getSpanColorAttributeValue(baseOverlaySource);
      if (spanColorAttributeValue < spanColorRange.min) {
        return ThemeColors.success;
      } else if (spanColorAttributeValue > spanColorRange.max) {
        return ThemeColors.error;
      }
      return ThemeColors.warning;
    } else {
      return ThemeColors.inactive;
    }
  };

  const getSpanColorAttributeValue = (source: ISource) => {
    let spanColorAttributeFunction;

    switch (spanColorAttribute) {
      case SpanAttribute.FREQUENCY:
        spanColorAttributeFunction = getFrequency;
        break;
      case SpanAttribute.ERROR_RATE:
        spanColorAttributeFunction = getErrorRate;
        break;
      case SpanAttribute.LATENCY:
        spanColorAttributeFunction = getLatency;
        break;
      default:
        console.error("Unknown ring attribute");
        spanColorAttributeFunction = getErrorRate;
    }

    return spanColorAttributeFunction(source);
  };

  const getRingAttributeValueLabel = (source: ISource) => {
    let ringAttributeFunction;

    switch (ringAttribute) {
      case SpanAttribute.FREQUENCY_SHORT_CODE:
        ringAttributeFunction = getFrequency;
        break;
      case SpanAttribute.ERROR_RATE_SHORT_CODE:
        ringAttributeFunction = getErrorRate;
        break;
      case SpanAttribute.LATENCY_SHORT_CODE:
        ringAttributeFunction = getLatency;
        break;
      default:
        console.error("Unknown ring attribute");
        ringAttributeFunction = getFrequency;
    }

    return round(ringAttributeFunction(source), 1).toString();
  };

  const tooltipTitle = (node: TraceTree) => {
    return (
      <>
        {node.sources.map((source) => {
          return (
            <Box key={source.nodeId}>
              <Box
                key="overlay-color"
                height={3}
                width="100%"
                bgcolor={getOverlayColor(source)}
              ></Box>
              <Box key="overlay-stats">
                <Typography>Frequency: {getFrequency(source)}</Typography>
                <Typography>Latency: {getLatency(source)}</Typography>
                <Typography>Errors: {getErrorRate(source)}</Typography>
              </Box>
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <Box
      key={node.key}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box display="flex" alignItems="center" gap={0.5}>
        <Typography color={getTreeNodeColor(node)}>{node.title}</Typography>
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        <Stack direction="row" spacing={1}>
          {node.sources.map((source) => {
            const ringAttributeValueLabel = getRingAttributeValueLabel(source);
            const traceColor = getOverlayColor(source);
            return (
              <Ring
                key={source.nodeId}
                color={traceColor}
                label={ringAttributeValueLabel}
              />
            );
          })}
        </Stack>
        <Tooltip title={tooltipTitle(node)}>
          <IconButton
            aria-label="delete"
            sx={{ visibility: isHovered ? "visible" : "hidden", padding: 0 }}
          >
            <InfoOutlinedIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default TreeNode;
