import React, { FC } from "react";
import { HTTPMessageSection, IGenerator, IVariable } from "../models";
import useGeneratorTextField from "../hooks/useGeneratorTextField";
import ComboTextField from "./ComboTextField";

interface GeneratorTextFieldProps {
  fieldKey: string;
  section: HTTPMessageSection;
  generator: IGenerator;
  variables: IVariable[];
  parameters: String[];
  envVariables: String[];
}

const GeneratorTextField: FC<GeneratorTextFieldProps> = (props) => {
  const hookProps = useGeneratorTextField();

  return <ComboTextField {...props} {...hookProps} />;
};

export default GeneratorTextField;
