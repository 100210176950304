import React, { useState, useEffect } from "react";
import { Table } from "antd";
import {
  Filter,
  listTestInvocations,
  ListTestInvocationsRequest,
  TestInvocation,
} from "../api/services/TrackedTests";
import { formatTimestamp } from "../util/Timestamps";
import { generateUniqueID } from "../util";
import SessionStorageManager from "../common/SessionStorageManager";
import { Link } from "@mui/material";
import { ITestName } from "../test-studio/models";

const InvocationsViewer: React.FC = () => {
  const [invocations, setInvocations] = useState<TestInvocation[]>([]);

  // Parse suite and test name from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const suite = urlParams.get("suite") || "";
  const testName = urlParams.get("testName") || "";
  const fetchInvocations = async () => {
    try {
      const testCase: ITestName = {
        name: testName,
        suite: suite,
      };
      const filter: Filter = {
        limit: 100,
      };
      const request: ListTestInvocationsRequest = {
        testCase: testCase,
        filter: filter,
      };
      const response = await listTestInvocations.listTestInvocations(request);
      setInvocations(response.invocations);
    } catch (error) {
      console.error("Error fetching invocations:", error);
    }
  };

  useEffect(() => {
    fetchInvocations();
  }, [suite, testName]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <p style={{ textAlign: "center" }}>
          Invocations for Suite: <b>{suite}</b>, Test Name: <b>{testName}</b>
        </p>
      </div>
      <Table<TestInvocation>
        dataSource={invocations}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text, record) => (
              <Link
                href="#"
                underline="hover"
                onClick={(e) => {
                  e.preventDefault();
                  SessionStorageManager.setOverlayQueries([
                    {
                      id: generateUniqueID(),
                      filters: [
                        {
                          key: "testInvocationId",
                          operator: "EQUALS",
                          value: `${record.id}`,
                        },
                      ],
                      color: "#FF6900",
                    },
                  ]);
                  window.open("/overlays", "_blank");
                }}
              >
                {text}
              </Link>
            ),
          },
          {
            title: "Execution Time",
            dataIndex: "timestamp",
            key: "timestamp",
            render: (timestamp: string) => formatTimestamp(timestamp),
          },
          {
            title: "Backend Assertions",
            dataIndex: "id",
            key: "backendAssertions",
            render: (text, record) =>
              `${record.successAssertions} / ${
                record.successAssertions + record.failureAssertions
              } passed`,
          },
          {
            title: "Execution Flows",
            dataIndex: "id",
            key: "executionFlows",
            render: (text, record) =>
              `${record.successTraceCount} / ${
                record.successTraceCount + record.failureTraceCount
              } passed`,
          },
        ]}
      />
    </div>
  );
};

export default InvocationsViewer;
