import { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Google } from '@mui/icons-material';
import '../styles/homestyles.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../common/theme';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { storeUserInfoInLocalStorage } from '../api/services/User';
import FirebaseService from '../auth/Firebase'
import { UserCredential } from 'firebase/auth';
import { getVersionedUrl } from '../common/Util';
import LocalStorageManager from '../common/LocalStorageManager';

const DEMO_PROJECT_ID = process.env.REACT_APP_DEMO_PROJECT_ID;
const DEMO_RESOURCE_ID = process.env.REACT_APP_DEMO_RESOURCE_ID;
const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const ExploreDemo = () => {

    useEffect(() => {
        handleSignin();
    }, [])


    const handleSignin = async () => {
        try {
            const user: UserCredential = await FirebaseService.signInWithEmailAndPassword("johnsmith@awaredemo.org", "3@@ert1q2");
            await storeUserInfoInLocalStorage();
            let redirectUrl: string = BASE_APP_URL + "";
            redirectUrl = redirectUrl + "?projectScope=" + DEMO_PROJECT_ID + "&resourceScope=" + DEMO_RESOURCE_ID
            window.location.href = redirectUrl;
        } catch (error) {
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div className={'center_ten_padding_top'}>
                <Box
                    component="form"
                    borderRadius={5}
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        p: 5
                    }}
                    border={'2px outset'}
                    boxShadow={'0 0 3px primary, 0 0 5px'}
                >
                    <Typography variant="body1">Redirecting to Demo Project...</Typography>
                </Box>
            </div>
        </ThemeProvider>
    );
}

export default ExploreDemo;