import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Test } from "../api";
import { IListSuggestedAssertionsRequest, IAssertionSuggestionGroup, ITestAssertion, IRawResponse } from '../test-studio/models';
import { selectTestStepExecutionRecords } from '../features/testsSlice'; // Adjust the import according to your file structure
import { RootState } from '../app/store';

interface AssertionSuggestionsState {
  isOpen: boolean;
  suggestionGroups: IAssertionSuggestionGroup[];
  selectedAssertions: { [key: string]: ITestAssertion[] };
  loading: boolean;
  error: string | null;
}

const initialState: AssertionSuggestionsState = {
  isOpen: false,
  suggestionGroups: [],
  selectedAssertions: {},
  loading: false,
  error: null,
};

export const fetchAssertionSuggestions = createAsyncThunk<
  IAssertionSuggestionGroup[],
  void,
  { state: RootState }
>('assertionSuggestions/fetchAssertionSuggestions', async (_, { getState, dispatch }) => {

  const state = getState();
  const executionRecords = selectTestStepExecutionRecords(state.tests);
  const steps = state.tests.steps;
  const testResponses = state.tests.testResponse;

  let showError = steps.some((step) => {
    const rawResponse = testResponses[step.id];
    return !rawResponse;
  });
  const request: IListSuggestedAssertionsRequest = {
    testStepRecords: executionRecords,
  };

  const response = await Test.listSuggestedAssertions(request);
  return response.suggestionGroups;
});

const assertionSuggestionsSlice = createSlice({
  name: 'assertionSuggestions',
  initialState,
  reducers: {
    openAssertionSuggestionsPopup(state) {
      state.isOpen = true;
    },
    closeAssertionSuggestionsPopup(state) {
      state.isOpen = false;
      state.selectedAssertions = {};
    },
    toggleGroupSelection(state, action: PayloadAction<string>) {
      const groupName = action.payload;
      const group = state.suggestionGroups.find(group => group.groupName === groupName);
      if (!group) return;

      const currentSelections = state.selectedAssertions[groupName] || [];
      const allSelected = currentSelections.length === group.suggestions.length;

      state.selectedAssertions[groupName] = allSelected ? [] : group.suggestions?.map(s => s.assertion);
    },
    toggleAssertionSelection(state, action: PayloadAction<{ groupName: string, assertion: ITestAssertion }>) {
      const { groupName, assertion } = action.payload;
      const currentSelections = state.selectedAssertions[groupName] || [];
      const newSelections = currentSelections.includes(assertion)
        ? currentSelections.filter(a => a !== assertion)
        : [...currentSelections, assertion];

      state.selectedAssertions[groupName] = newSelections;
    },
    clearSelections(state) {
      state.selectedAssertions = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssertionSuggestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssertionSuggestions.fulfilled, (state, action) => {
        state.loading = false;
        state.suggestionGroups = action.payload;
      })
      .addCase(fetchAssertionSuggestions.rejected, (state, action) => {
        state.loading = false;
        state.error = 'Failed to fetch assertion suggestions';
      });
  },
});

export const {
  openAssertionSuggestionsPopup,
  closeAssertionSuggestionsPopup,
  toggleGroupSelection,
  toggleAssertionSelection,
  clearSelections,
} = assertionSuggestionsSlice.actions;

export default assertionSuggestionsSlice.reducer;
