import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, styled } from '@mui/material';
import '../styles/homestyles.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../common/theme';
import OrganizationPreOobe from '../api/services/OrganizationPreOobe';
import { storeUserInfoInLocalStorage } from '../api/services/User';
import { LoadingButton } from '@mui/lab';

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const CreateOrg = () => {
    const [organizationName, setOrganizationName] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [isMessageError, setIsMessageError] = useState(false);
    const [isCreatingOrg, setIsCreatingOrg] = useState<boolean>(false);

    let timeoutId: NodeJS.Timeout | null = null;

    useEffect(() => {
    }, []);



    const handleCreateOrg = async () => {
        setIsCreatingOrg(true);
        const response = await OrganizationPreOobe.addOrganization(organizationName, referralCode);
        if (response.status == 200) {
            setIsMessageError(false);
            setMessage("Organization created successfully");
            const userInfo = await storeUserInfoInLocalStorage();
            window.location.href = BASE_APP_URL + "/admin-settings";
        } else {
            setIsMessageError(true);
            setMessage("An error occured when creating organization ");
        }
        setIsCreatingOrg(false);
    };

    const updateOrganizationName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const orgName: string = e.target.value;
        setOrganizationName(orgName);
    }

    const updateReferralCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const referralCode: string = e.target.value;
        setReferralCode(referralCode);
    }

    let messageColor = "green";
    if (isMessageError) {
        messageColor = "red";
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                className="fade-in"
                padding="20px"
                sx={{
                    background: 'radial-gradient(circle at 94.35% 89.61%, #FFFFFF 0%, 20%, rgba(255,255,255,0) 40%), radial-gradient(circle at 6.50% 88.03%, rgba(250,250,250,0.99) 0%, 25%, rgba(250,250,250,0) 50%), radial-gradient(circle at 6.16% 12.61%, #E3D5C5 0%, 42%, rgba(227,213,197,0) 70%), radial-gradient(circle at 71.54% 39.81%, #F0EAD6 0%, 42%, rgba(240,234,214,0) 70%), radial-gradient(circle at 48.90% 49.52%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%)'
                }}
            >
                <Typography variant='h5' style={{ marginBottom: "20px" }}>
                    Create Organization
                </Typography>
                <Box
                    p={4}
                    bgcolor="white"
                    borderRadius={4}
                    boxShadow={3}
                    maxWidth={400}
                    width="100%"
                    textAlign="center"
                >
                    <TextField
                        label="Organization Name"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={organizationName}
                        onChange={updateOrganizationName}
                        sx={{ marginBottom: "20px" }}
                    />
                    <TextField
                        label="Referral Code (Optional)"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={referralCode}
                        onChange={updateReferralCode}
                        sx={{ marginBottom: "20px" }}
                    />
                    <LoadingButton
                        loading={isCreatingOrg}
                        sx={{ mt: 3, paddingX: "10px", paddingY: "10x" }}
                        variant="contained"
                        color="primary"
                        onClick={handleCreateOrg}
                        disabled={!organizationName}
                    >
                        Create Organization
                    </LoadingButton>
                    <Typography variant="body2" sx={{ mt: 2, marginBottom: "10px" }}>
                        {message && <span style={{ color: messageColor }}>{message}</span>}
                    </Typography>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default CreateOrg;