import React, { useState, useEffect, useRef } from "react";
import { postData } from "../common/WebUtils";
import { ThemeColors } from "../styles/light-colors";
import UserOrgConfigViewer from "./UserOrgConfigViewer";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Paper,
  Box,
} from "@mui/material";
import { useSnackbar } from "../hooks/useSnackbar";
import { useLocation } from "react-router-dom";
import LocalStorageManager from "../common/LocalStorageManager";

const UserConfigViewer = (props) => {
  const [selectedProject, setSelectedProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectConfig, setProjectConfig] = useState({});
  const [activeTab, setActiveTab] = useState(null);
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const userOrgConfigViewerChild = useRef(); // Use useRef hook to create a ref

  const onHandleSave = (projectId, updatedConfig) => {
    postData("user/update_user_project_config", {
      project_id: projectId,
      config: updatedConfig,
    })
      .then((response) => {
        showSnackbar("User settings updated successfully");
      })
      .catch((error) => {
        showSnackbar(
          "An error occurred when updating the user settings. Please try later."
        );
      });
  };

  const onHandleCancel = () => {
    window.location.href = "/";
  };

  const handleOrgSelection = (event) => {
    LocalStorageManager.setProjectId(event.target.value);
    postData("user/get_user_project_config", {
      project_id: event.target.value,
    })
      .then((response) => {
        setProjectConfig(response.config);
        setSelectedProject(event.target.value);
        if (userOrgConfigViewerChild.current) {
          userOrgConfigViewerChild.current.update();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    postData("user/get_projects", {})
      .then((response) => {
        setProjects(response.projects);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []); // The empty dependency array ensures this effect runs only once on component mount

  let orgConfigContent = <div></div>;
  if (selectedProject) {
    orgConfigContent = (
      <UserOrgConfigViewer
        ref={userOrgConfigViewerChild} // Use the ref created with useRef
        onHandleSave={onHandleSave}
        onHandleCancel={onHandleCancel}
        key={projectConfig}
        projectId={selectedProject}
        config={projectConfig}
      />
    );
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      marginY={10}
      marginX={20}
    >
      <Paper
        sx={{
          width: "100%",
          padding: 5,
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
        }}
      >
        <FormControl fullWidth>
          <InputLabel>
            {!selectedProject ? "Select an Organization / Project" : ""}
          </InputLabel>
          <Select value={selectedProject} onChange={handleOrgSelection}>
            {projects?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedProject && (
          <UserOrgConfigViewer
            ref={userOrgConfigViewerChild} // Use the ref created with useRef
            onHandleSave={onHandleSave}
            onHandleCancel={onHandleCancel}
            key={projectConfig}
            projectId={selectedProject}
            config={projectConfig}
          />
        )}
      </Paper>
    </Box>
  );
};

export default UserConfigViewer;
