import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

const FullScreenBackdropLoader: React.FC = () => {
  return (
    <Backdrop sx={{ zIndex: 1201, color: "white" }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default FullScreenBackdropLoader;
