import { ItemType } from "antd/es/menu/hooks/useItems";
import { Guard } from "../../api/services/Guards";
import { QualifiedOperation } from "../../api/services/Common";

export enum SpanAttribute {
  FREQUENCY = "Frequency",
  FREQUENCY_SHORT_CODE = "F",
  ERROR_RATE = "Error rate",
  ERROR_RATE_SHORT_CODE = "E",
  LATENCY = "Latency",
  LATENCY_SHORT_CODE = "L",
}

export enum SortingAlgo {
  ASCENDING = "Ascending",
  DESCENDING = "Descending",
  ATTRIBDIFF = "AttribDiff",
}

export enum DrawerMode {
  ADD = "add",
  EDIT = "edit",
}

export interface ISource {
  nodeId: string;
  path: string;
}

export interface ITrace {
  id: string;
  self: string;
  sources: ISource[];
  prefix: string;
  operation: QualifiedOperation;
}

export interface IDecoratedValue {
  values: {
    [attributeValue: string]: {
      [overlayId: string]: number;
    };
  };
  guards: Guard[];
}

export interface ITraceData {
  nodeAttributeSummaries: {
    [attributeName: string]: IDecoratedValue;
  };
  resourceAttributeSummaries: {
    [attributeName: string]: IDecoratedValue;
  };
}

export interface DiffCardContext {
  attributeKey: string;
  attributeValue: IDecoratedValue;
}

export interface DiffCardProps {
  context: DiffCardContext;
  menuItems: ItemType[];
  onMenuClick: (key: string, props: DiffCardContext) => void;
}
