import React from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import { useAppSelector } from "../../app/hooks";
import { Dropdown } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import SessionStorageManager from "../../common/SessionStorageManager";
import { Converters, generateUniqueID } from "../../util";

interface AttributeProps {
  attributeKey: string;
  attributeValue: string;
  percentages: {
    [key: string]: number;
  };
}

const TestStudioUnapplicableKeys = [
  "testchimp.derived.num_occurrences",
  "testchimp.derived.error_rate",
  "testchimp.derived.avg_latency",
  "testchimp.derived.max_latency",
  "testchimp.derived.min_latency",
]; // Replace with your actual keys

const Attribute: React.FC<AttributeProps> = ({
  attributeKey,
  attributeValue,
  percentages,
}) => {
  const queries = useAppSelector((state) => state.overlays.queries);
  const selectedTrace = useAppSelector((state) => state.overlays.selectedTrace);
  const entrypoints = useAppSelector((state) => state.overlays.entryPoints);
  const selectedEntryPointHash = useAppSelector(
    (state) => state.overlays.selectedEntryPointHash
  );

  const isKeyUnapplicable = TestStudioUnapplicableKeys.includes(attributeKey);

  return (
    <Box>
      <Stack direction={"row"} alignItems={"center"}>
        <Tooltip title={attributeValue}>
          <Typography
            fontSize={14}
            color="#707070"
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            height={"1.5em"}
          >
            {attributeValue}
          </Typography>
        </Tooltip>
        {!isKeyUnapplicable && (
          <Dropdown
            menu={{
              items: [
                {
                  key: "TEST_STUDIO",
                  type: "group",
                  label: "Sessions",
                  children: Object.keys(percentages).map((overlayId) => ({
                    key: overlayId,
                    label: (
                      <ProgressBar
                        completed={"100%"}
                        bgColor={
                          queries.find((query) => query.id === overlayId)?.color
                        }
                        height="15px"
                        labelSize="12px"
                        isLabelVisible={false}
                      />
                    ),
                  })),
                },
              ],
              onClick: ({ key }) => {
                const query = queries.find((query) => query.id === key)!;
                const uid = generateUniqueID();
                SessionStorageManager.setSessionFindContext(uid,
                  {
                    query: {
                      filters: query.filters,
                      time_window: query.time_window,
                      sampling_mode: query.sampling_mode,
                      sampling_rate: query.sampling_rate,
                    },
                    context: {
                      attribute: {
                        operation: {
                          pathPrefix: selectedTrace!.prefix,
                        },
                        attributeKey: attributeKey,
                      },
                      attribValue:
                        Converters.stringToTypedValue(attributeValue),
                    }
                  },
                );
                window.open("/sessions?uid=" + uid, "_blank");
              },
            }}
          >
            <IconButton>
              <CaretDownOutlined style={{ fontSize: "20px" }} />
            </IconButton>
          </Dropdown>
        )}
      </Stack>
      <Stack spacing={1}>
        {Object.keys(percentages).map((overlayId, index) => {
          const percentage = percentages[overlayId].toFixed(2);
          return (
            <Box display="flex" alignItems="center" gap={1} key={index}>
              <Box width="90%">
                <ProgressBar
                  completed={percentage}
                  bgColor={
                    queries.find((query) => query.id === overlayId)?.color
                  }
                  height="15px"
                  labelSize="12px"
                  isLabelVisible={false}
                />
              </Box>
              <Box width="10%">
                <span>{percentage}</span>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default Attribute;
