import { IFilter } from "../../models/overlays";
import {
  BoolTypedValue,
  FloatTypedValue,
  StringTypedValue,
  IntTypedValue,
  TypedValue
} from "../../models/typed/models";
import client from "../client";
import {
  QualifiedOperation,
  Timestamp,
  TimeWindow,
  PaginationRequest,
  PaginationResult,
  getCurrentTimestamp,
} from "./Common";

export type EvaluatedGuard = {
  guard: Guard;
  evaluation: GuardEvaluation;
};

export type GuardEvaluation = {
  result: string;
  leftValue: string;
  rightValue: string;
};

export type Guard = {
  id: string;
  config: GuardConfig;
};

export type GuardWithLastEvaluationResult = {
  guard: Guard;
  evaluationDetail: GuardEvaluationDetail;
};

export type GuardEvaluationDetail = {
  evaluatedContext: EvaluationContext;
  evaluation: GuardEvaluation;
  evaluationTimestamp: Timestamp;
};

export type EvaluationContext = {
  resource?: string;
  resourceVersion?: string;
  timeWindow?: TimeWindow;
  environment?: string;
};

export type GuardConfig = {
  attribLocator: AttributeLocator;
  operation: string;
  value: TypedValue;
  severity: string;
  guardActivationCriteria: TimeStampGuardActivationCriteria;
  additionalFilters: IFilter[];
};

export type TimeStampGuardActivationCriteria = {
  timestamp: string;
};

export type AttributeLocator = {
  operation: OperationLocator;
  attributeKey: string;
};

export type OperationLocator = {
  pathPrefix?: string;
  contextualOperation?: ContextualOperation;
}

export type ContextualOperation = {
  entrypoint?: QualifiedOperation;
  operation: QualifiedOperation;
};

export type EvaluateGuardsRequest = {
  filter: GuardsFilter;
  evaluationContext: EvaluationContext;
};

export type EvaluateGuardsResponse = {
  guards: EvaluatedGuard[];
};

export type ListGuardsResponse = {
  guards: GuardWithLastEvaluationResult[];
  subscriptionStatus: string;
};

export type ListGuardsRequest = {
  filter: GuardsFilter;
  // Evaluation context is intentionally missing, since the objective of this usage is to just list the guards - not to get the guards evaluated.
};
export type DeleteGuardRequest = {
  id: string;
};

export type GuardsFilter = {
  resource?: string;
  operation?: string;
  entrypoint?: QualifiedOperation;
  guardIds?: string[];
};

export type ListGuardEvaluationsRequest = {
  guardId: string;
  paginationRequest?: PaginationRequest;
};

export type ListGuardEvaluationsResponse = {
  guardEvaluationDetails: GuardEvaluationDetail[];
  paginationResult?: PaginationResult;
  guardConfig: GuardConfig;
};

interface ListGuards {
  listGuards: (request: ListGuardsRequest) => Promise<any>;
}

interface UpdateGuardSubscription {
  updateGuardSubscription: (
    request: UpdateGuardSubscriptionRequest
  ) => Promise<any>;
}

interface EvaluateGuards {
  evaluateGuards: (request: EvaluateGuardsRequest) => Promise<any>;
}

interface DeleteGuard {
  deleteGuard: (request: DeleteGuardRequest) => Promise<any>;
}

interface ListGuardEvaluations {
  listGuardEvaluations: (request: ListGuardEvaluationsRequest) => Promise<any>;
}

export type UpdateGuardSubscriptionRequest = {
  resourceId: number;
  subscriptionStatus: string;
};

export type UpdateGuardSubscriptionResponse = {
  // Intentionally kept empty.
};

export interface IAddGuardRequest {
  config: GuardConfig;
}

const updateGuardSubscription: UpdateGuardSubscription = {
  updateGuardSubscription: async (request: UpdateGuardSubscriptionRequest) => {
    try {
      let response = await client.post("/guards/update_subscription", request);
      return response.data;
    } catch (error) {
      console.error(`Error calling guards.update_subscription `, error);
      return { error: error };
    }
  },
};

const listGuards: ListGuards = {
  listGuards: async (request: ListGuardsRequest) => {
    try {
      let response = await client.post("/guards/list", request);
      console.log("list guards", response);
      return response.data;
    } catch (error) {
      console.error(`Error calling guards.list `, error);
      return { error: error };
    }
  },
};

const evaluateGuards: EvaluateGuards = {
  evaluateGuards: async (request: EvaluateGuardsRequest) => {
    try {
      let response = await client.post("/guards/evaluate", request);
      return response.data;
    } catch (error) {
      console.error(`Error calling guards.evaluate `, error);
      return { error: error };
    }
  },
};

const deleteGuard: DeleteGuard = {
  deleteGuard: async (request: DeleteGuardRequest) => {
    try {
      let response = await client.post("/guards/delete", request);
      console.log("delete guards", response);
      return response.data;
    } catch (error) {
      console.error(`Error calling guards.delete `, error);
      return { error: error };
    }
  },
};

const listGuardEvaluations: ListGuardEvaluations = {
  listGuardEvaluations: async (request: ListGuardEvaluationsRequest) => {
    try {
      let response = await client.post("/guards/list_evaluations", request);
      return response.data;
    } catch (error) {
      console.error(`Error calling guards.list_evaluation `, error);
      return { error: error };
    }
  },
};

// Function to convert EvaluatedGuard to GuardWithLastEvaluationResult
const convertToGuardWithLastEvaluationResult = (
  evaluatedGuard: EvaluatedGuard,
  selectedResource: string,
  selectedReleaseVersion: string
): GuardWithLastEvaluationResult => {
  const evaluationDetail: GuardEvaluationDetail = {
    evaluatedContext: {
      resource: selectedResource,
      resourceVersion: selectedReleaseVersion,
      // Assuming other properties of EvaluationContext are not needed or have default values
    },
    evaluation: evaluatedGuard.evaluation,
    evaluationTimestamp: getCurrentTimestamp(), // Replace with function to get current timestamp
  };

  return {
    guard: evaluatedGuard.guard,
    evaluationDetail,
  };
};

// Function to transform the response of evaluateGuards
export const transformEvaluateGuardsResponse = (
  evaluatedGuards: EvaluatedGuard[],
  selectedResource: string,
  selectedReleaseVersion: string
): GuardWithLastEvaluationResult[] => {
  return evaluatedGuards?.map((evaluatedGuard) =>
    convertToGuardWithLastEvaluationResult(
      evaluatedGuard,
      selectedResource,
      selectedReleaseVersion
    )
  );
};

const Guards = {
  addGuard: async (request: IAddGuardRequest) => {
    const res = await client.post("/guards/create", request);
    return res.data;
  },
};

export default Guards;

export {
  evaluateGuards as evaluateGuards,
  listGuards as listGuards,
  deleteGuard as deleteGuard,
  updateGuardSubscription as updateGuardSubscription,
  listGuardEvaluations as listGuardEvaluations,
};
