export const sidePanelStyles = {
  width: 350,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: { width: 350, boxSizing: "border-box" },
};

export const mainContainerStyles = {
  bgcolor: "#EEEEEE",
  p: 1.5,
};

export const fabStyle = {
  position: "fixed",
  bottom: 32,
  right: 32,
};
