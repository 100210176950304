import React, { useState, useEffect } from "react";
import { Table, Input, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { message } from "antd";
import Scenarios, { Persona } from "../api/services/Scenarios";
import DeleteIcon from '@mui/icons-material/Delete';
import "../styles/ManagePersonasDialog.css";

interface ManagePersonasDialogProps {
    open: boolean;
    onClose: () => void;
    projectId: string;
    handleSaveOfPersonas: (updatedPersonas: Persona[]) => void;
}

const ManagePersonasDialog: React.FC<ManagePersonasDialogProps> = ({
    open,
    onClose,
    projectId,
    handleSaveOfPersonas,
}) => {
    const [personas, setPersonas] = useState<Persona[]>([]);
    const [newPersonas, setNewPersonas] = useState<Persona[]>([]);

    useEffect(() => {
        if (open) {
            fetchPersonas();
        }
    }, [open]);

    const fetchPersonas = async () => {
        try {
            const response = await Scenarios.listPersonas({});
            setPersonas(response.personas);
            if (!response.personas || response?.personas?.length == 0) {
                handleAddNewRow();
            }
        } catch (error) {
            console.error("Error fetching personas:", error);
        }
    };

    const handleInputChange = (index: number, field: keyof Persona, value: string, isExisting: boolean) => {
        const updateArray = isExisting ? [...personas] : [...newPersonas];
        updateArray[index] = { ...updateArray[index], [field]: value };

        if (isExisting) {
            setPersonas(updateArray);
        } else {
            setNewPersonas(updateArray);
        }
    };

    const handleAddNewRow = () => {
        setNewPersonas([...newPersonas, { title: '', description: '' }]);
    };

    const handleDeleteNewRow = (index: number) => {
        const updatedNewPersonas = newPersonas.filter((_, i) => i !== index);
        setNewPersonas(updatedNewPersonas);
    };

    const handleSuggestPersonas = async () => {
        try {
            const response = await Scenarios.suggestPersonas({});

            if (response?.suggestedPersonas) {
                const filteredPersonas = newPersonas.filter(persona => persona.title && persona.title.trim() !== "");
                const updatedNewPersonas = [
                    ...filteredPersonas,
                    ...response.suggestedPersonas
                ];

                setNewPersonas(updatedNewPersonas);

                message.success("Suggested personas added successfully.");
            } else {
                message.error("Failed to fetch suggested personas.");
            }
        } catch (error) {
            message.error("An error occurred while fetching suggested personas.");
        }
    };

    const handleSave = () => {
        const validPersonas = personas || [];
        const validNewPersonas = newPersonas || [];

        const updatedPersonas = validPersonas.filter(persona => persona.title);
        const addedPersonas = validNewPersonas.filter(persona => persona.title);

        if (addedPersonas.some(persona => !persona.title)) {
            message.error("Title must be filled out.");
            return;
        }

        const combinedPersonas = [...updatedPersonas, ...addedPersonas];

        handleSaveOfPersonas(combinedPersonas);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogContent>
                <label className="dialog-title">Define key user personas in your project...</label>
                <Table className="personas-table">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {personas?.map((persona, index) => (
                            <tr key={persona.id}>
                                <td>
                                    <TextField
                                        variant="standard"
                                        value={persona.title}
                                        onChange={(e) => handleInputChange(index, 'title', e.target.value, true)}
                                        required
                                        className="editable-input"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="standard"
                                        value={persona.description}
                                        onChange={(e) => handleInputChange(index, 'description', e.target.value, true)}
                                        required
                                        className="editable-input"
                                    />
                                </td>
                                <td style={{ width: '50px' }}></td>
                            </tr>
                        ))}
                        {newPersonas?.map((persona, index) => (
                            <tr key={`new-${index}`}>
                                <td>
                                    <Input
                                        value={persona.title}
                                        onChange={(e) => handleInputChange(index, 'title', e.target.value, false)}
                                        required
                                        className="editable-input"
                                    />
                                </td>
                                <td>
                                    <Input
                                        value={persona.description}
                                        onChange={(e) => handleInputChange(index, 'description', e.target.value, false)}
                                        required
                                        className="editable-input"
                                    />
                                </td>
                                <td style={{ width: '50px' }}>
                                    <Button onClick={() => handleDeleteNewRow(index)} style={{ color: "var(--dark-color)" }}>
                                        <DeleteIcon />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <Button onClick={handleAddNewRow} variant="contained" sx={{ color: "var(--dark-color)", backgroundColor: "var(--secondary-cta-color)", marginRight: "10px" }}>
                        Add New Persona
                    </Button>
                    <Button onClick={handleSuggestPersonas} variant="contained" sx={{ backgroundColor: "var(--cta-color)" }}>
                        Suggest Personas...
                    </Button>
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
                <Button onClick={onClose} sx={{ color: "var(--dark-color)" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ManagePersonasDialog;