import React, { Component } from 'react';
import { Dialog, CircularProgress } from '@mui/material';

class LoadingPopup extends Component {
    render() {
        const { open } = this.props;

        return (
            <Dialog open={open}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '32px' }}>
                    <CircularProgress color="primary" />
                </div>
            </Dialog>
        );
    }
}

export default LoadingPopup;
