import { Box, Button, Card, Tab, Tabs, ThemeProvider } from "@mui/material";
import {
  IStep,
  StepContext,
  TreeLoadState,
  emptyRawRequest,
  emptyRawResponse,
} from "./models";
import React, { useEffect, useState } from "react";
import { grey } from "@mui/material/colors";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { MainTab } from "./models";
import { FormatListChecks } from "mdi-material-ui";
import { TestStepsListRef } from "./components/TestStepsList";
import TestStepsList from "./components/TestStepsList";
import theme from "../common/theme";
import ReadOnlyHTTPMessage from "../session-replay/components/ReadOnlyHTTPMessage";
import { useLocation } from "react-router-dom";
import {
  loadExecution,
  loadTest,
  loadTestInvocationResults,
  selectStep,
  setIsAssertResultsPopupOpen,
  setSteps,
  updateSelectedPrefix,
  updateSelectedTab,
} from "../features/testExecutionsSlice";
import { ITraceDetail } from "../models/sessions";
import AssertionResultsModal from "./components/AssertionEvaluationsModal";
import SessionStorageManager from "../common/SessionStorageManager";
import AttributeSummaryTable from "./components/AttributeSummaryTable";
import { generateUniqueID } from "../util";
import { setSelectedProject } from "../features/commonSlice";

const TestExecutionViewer: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  // Per invocation state vars
  const selectedTab = useAppSelector(
    (state) => state.testExecutions.selectedTab
  );
  const traceDetails = useAppSelector(
    (state) => state.testExecutions.traceDetails
  );
  const steps = useAppSelector((state) => state.testExecutions.steps);
  const selectedStep = useAppSelector(
    (state) => state.testExecutions.selectedStep
  );
  const selectedStepIndex = useAppSelector(
    (state) => state.testExecutions.selectedStepIndex
  );
  const assertions = useAppSelector((state) => state.testExecutions.assertions);
  const childNodes = useAppSelector((state) => state.testExecutions.childNodes);
  const nodeStatus = useAppSelector((state) => state.testExecutions.nodeStatus);
  const selectedProject = useAppSelector((state) => state.common.selectedProject);

  const assertionResults = useAppSelector(
    (state) => state.testExecutions.assertionResults
  );
  const isAssertResultsPopupOpen = useAppSelector(
    (state) => state.testExecutions.isAssertResultsPopupOpen
  );
  const isEditTestEnabled = useAppSelector(
    (state) => state.testExecutions.isEditTestEnabled
  );
  const summaryTreeLoadState = useAppSelector(
    (state) => state.testExecutions.summaryTreeLoadState
  );
  const test = useAppSelector((state) => state.testExecutions.test);

  // Per trace state vars
  const prefixSummaries = useAppSelector(
    (state) => state.testExecutions.prefixSummaries
  );
  const selectedPrefix = useAppSelector(
    (state) => state.testExecutions.selectedPrefix
  );
  const rootPrefixes = useAppSelector(
    (state) => state.testExecutions.rootPrefixes
  );

  const [stepId, setStepId] = useState<string>("");
  const [invocationId, setInvocationId] = useState<string>("");
  const testStepsListRef = React.createRef<TestStepsListRef>();

  const [loadingCompleteTraceIds, setLoadingCompleteTraceIds] = useState<
    string[]
  >([]);


  useEffect(() => {
    console.log("Invocation id: ", invocationId);
    if (invocationId) {
      dispatch(loadExecution(invocationId)).then(() => {
        console.log("Steps: ", steps);
        if (steps?.length > 0) {
          dispatch(selectStep(0));
        }
      });
      dispatch(loadTest(invocationId));
      dispatch(loadTestInvocationResults(invocationId));
    }
  }, [invocationId]);

  useEffect(() => {
    if (!selectedStep && steps?.length > 0 && summaryTreeLoadState[steps[0].id] == TreeLoadState.COMPLETE) {
      dispatch(selectStep(0));
    }
  }, [steps, traceDetails, summaryTreeLoadState])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const invocationId = params.get("invocation_id");
    const selectedProject = params.get("project_id");
    setInvocationId(invocationId ?? "");
    if (selectedProject) {
      dispatch(setSelectedProject(selectedProject));
    }
  }, [dispatch, location.search]);

  const convertTraceDetailListToSteps = (
    traceDetails: ITraceDetail[]
  ): IStep[] => {
    // Your logic to convert traceDetails to steps
    return traceDetails?.map((traceDetail) => {
      // Example conversion logic, adjust as per your actual structure
      const step: IStep = {
        id: traceDetail.traceId ?? "",
        name: traceDetail.entryOperation.operationName,
        timestamp: new Date(traceDetail.timestamp).getTime(),
        context: getContextForTraceDetail(traceDetail),
      };
      return step;
    });
  };

  const handleSelectStep = (index: number) => {
    selectPrefix(steps[index].id, rootPrefixes[steps[index].id]);
  };

  const selectPrefix = (traceId: string, pathPrefix: string) => {
    dispatch(
      updateSelectedPrefix({
        traceId: traceId,
        prefix: pathPrefix,
      })
    );
  };

  const getContextForTraceDetail = (traceDetail: ITraceDetail): StepContext => {
    return {
      internalContext: {
        operation: traceDetail.entryOperation,
      },
    };
  };

  useEffect(() => {
    Object.keys(summaryTreeLoadState).forEach((traceId) => {
      // Check if state changed to COMPLETE from a different state
      if (
        summaryTreeLoadState[traceId] === TreeLoadState.COMPLETE &&
        !loadingCompleteTraceIds.includes(traceId)
      ) {
        // Call setChildNodesMap on TestStepsList ref
        if (testStepsListRef.current) {
          testStepsListRef.current.setChildNodesMap(
            traceId,
            TreeLoadState.COMPLETE,
            rootPrefixes[traceId],
            childNodes[traceId],
            nodeStatus[traceId]
          );
        }
        // Update loadingCompleteTraceIds state
        setLoadingCompleteTraceIds((prevIds) => [...prevIds, traceId]);
      }
    });
  }, [summaryTreeLoadState]);

  useEffect(() => {
    if (invocationId && steps.length == 0) {
      // TraceDetails are not yet converted to Steps (which is needed for populating the TestStepsView). Convert and set the steps.
      let steps: IStep[] = convertTraceDetailListToSteps(traceDetails);
      dispatch(
        setSteps({
          steps: steps,
        })
      );
    }
  }, [traceDetails]);

  useEffect(() => {
    if (invocationId && selectedStep) {
      const newStepId = selectedStep.id;
      if (newStepId != stepId) {
        setStepId(newStepId);
        dispatch(
          updateSelectedPrefix({
            traceId: newStepId,
            prefix: rootPrefixes[newStepId],
          })
        );
      }
    }
  }, [selectedStep]);

  const handleEditTest = () => {
    if (test) {
      const uid = generateUniqueID();
      SessionStorageManager.setTestStudioTest(uid, test);
      window.open("/test-studio?uid=" + uid + (selectedProject ? "&project_id=" + selectedProject : ""), "_blank");
    }
  };

  const viewAssertionResults = () => {
    dispatch(setIsAssertResultsPopupOpen(true));
  };

  const handleAssertResultsModalClose = () => {
    dispatch(setIsAssertResultsPopupOpen(false));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        key={"test-studio"}
        display={"flex"}
        flexDirection={"row"}
        flexGrow={1}
        minHeight={0}
      >
        <Card
          key={"side-nav"}
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: "21%",
            width: "21%",
            paddingY: 1,
            paddingX: 1,
          }}
        >
          <Box flexGrow={1} overflow={"auto"}>
            <TestStepsList
              ref={testStepsListRef}
              steps={invocationId ? steps ?? [] : []}
              selectedIndex={
                invocationId && selectedStepIndex ? selectedStepIndex : 0
              }
              onSelectStep={(index) => handleSelectStep(index)}
              onSelectPrefix={(traceId, prefix) =>
                selectPrefix(traceId, prefix)
              }
              highlightUptoTimestamp={0}
              opacityWhenNonHighlighted={1.0}
            />
          </Box>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "90%",
                border: "1px solid #ccc",
                padding: "16px",
                borderRadius: "8px",
                gap: "8px",
                marginTop: "70px", // Added margin to push the div below the floating button
              }}
            >
              <span style={{ flexShrink: 1 }}>
                <Button
                  variant="contained"
                  className="secondary-button"
                  startIcon={<ModeEditIcon />}
                  onClick={() => handleEditTest()}
                  style={{ flex: "0 0 auto", width: "!50px" }} // Fixed width for Save button
                  disabled={!isEditTestEnabled}
                >
                  Edit Test
                </Button>
              </span>
              <span style={{ flexShrink: 1 }}>
                <Button
                  variant="contained"
                  className="secondary-button"
                  startIcon={<FormatListChecks />}
                  onClick={() => viewAssertionResults()}
                  style={{ flex: "0 0 auto", width: "!50px" }} // Fixed width for Save button
                >
                  Assertions
                </Button>
              </span>
            </div>
          </div>
        </Card>
        {isAssertResultsPopupOpen && (
          <AssertionResultsModal
            assertionEvaluations={assertionResults}
            assertions={Object.values(assertions)}
            handleOk={handleAssertResultsModalClose}
          />
        )}
        <Box
          key={"main"}
          display={"flex"}
          position={"relative"}
          overflow={"hidden"}
          flexDirection={"column"}
          flexGrow={1}
          paddingX={2}
          paddingY={0.7}
          bgcolor={grey[200]}
        >
          <Tabs
            value={selectedTab[stepId] ?? MainTab.TEST_REQUEST}
            onChange={(_, value: MainTab) => dispatch(updateSelectedTab(value))}
          >
            <Tab
              key={MainTab.TEST_REQUEST}
              value={MainTab.TEST_REQUEST}
              label={"Request"}
            />
            <Tab
              key={MainTab.RESPONSE}
              value={MainTab.RESPONSE}
              label={"Response"}
            />
            <Tab
              key={MainTab.ATTRIBUTES}
              value={MainTab.ATTRIBUTES}
              label={"Attributes"}
            />
          </Tabs>
          {stepId && selectedTab[stepId] == MainTab.TEST_REQUEST && (
            <ReadOnlyHTTPMessage key={selectedPrefix[stepId] + "request"}
              message={
                prefixSummaries?.[stepId]?.[selectedPrefix[stepId]]
                  ?.requestResponsePairs?.[0]?.request ?? emptyRawRequest
              }
              isResponse={false}
            />
          )}
          {stepId && selectedTab[stepId] == MainTab.RESPONSE && (
            <ReadOnlyHTTPMessage key={selectedPrefix[stepId] + "response"}
              message={
                prefixSummaries?.[stepId]?.[selectedPrefix[stepId]]
                  ?.requestResponsePairs?.[0]?.response ?? emptyRawResponse
              }
              isResponse={true}
            />
          )}
          {selectedTab[stepId] == MainTab.ATTRIBUTES && (
            <AttributeSummaryTable
              attributeSummaries={
                prefixSummaries?.[stepId]?.[selectedPrefix[stepId]]
                  ?.attributeSummaries ?? []
              }
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default TestExecutionViewer;
