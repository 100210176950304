// useSnackbar.js
import React, {createContext, useContext, useState} from 'react';
import {Alert, Snackbar} from '@mui/material';

const SnackbarContext = createContext();

export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};

export const SnackbarProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('info');

    const showSnackbar = (message, severity = 'info') => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    };

    const closeSnackbar = () => {
        setOpen(false);
        setMessage('');
    };

    return (
        <SnackbarContext.Provider value={{showSnackbar}}>
            {children}
            <Snackbar open={open} autoHideDuration={3000} onClose={closeSnackbar}
                      anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}>
                <Alert onClose={closeSnackbar} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};
