import { Checkbox, Col, Flex, GetProp, Row, Typography, Input, AutoComplete, AutoCompleteProps, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { listTestSuites } from "../../features/testsSlice";
import { AwareTestType, GenerateTestCategory } from "../../test-studio/models";
import { updateAdditionalPrompt, updateSelectedCategories, updateTestSuite } from "../../features/testGenerationSlice";

const GeneralStep = () => {
  const dispatch = useAppDispatch();

  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const selectedCategories = useAppSelector((state) => state.testGeneration.selectedCategories);
  const additionalPrompt = useAppSelector((state) => state.testGeneration.additionalPrompt);
  const testSuite = useAppSelector((state) => state.testGeneration.testSuite);
  const [testSuitesOptions, setTestSuitesOptions] = useState<AutoCompleteProps["options"]>([]);

  const isGenerationPopupOpen = useAppSelector((state) => state.testGeneration.isGenerationPopupOpen);
  const testSuites = useAppSelector((state) => state.tests.testSuites);

  useEffect(() => {
    isGenerationPopupOpen && dispatch(listTestSuites({ type: AwareTestType.FS_TEST }));
  }, [isGenerationPopupOpen]);

  useEffect(() => {
    setTestSuitesOptions(testSuites.map((suite) => ({ value: suite })));
  }, [testSuites]);

  const onCategoryChange: GetProp<typeof Checkbox.Group, "onChange"> = (checkedValues) => {
    dispatch(updateSelectedCategories(checkedValues.map((value) => Number(value) as GenerateTestCategory)));
  };

  const onTestSuiteChange = (data: string) => {
    dispatch(updateTestSuite(data));
  };

  const onAdditionalPromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateAdditionalPrompt(e.target.value));
  };

  const onSelect = (data: string) => {
    console.log("onSelect", data);
  };

  const getGenerateTestCategoryDisplayString = (key: number) => {
    if (key === GenerateTestCategory.FUNCTIONAL) {
      return "Functional";
    }
    if (key === GenerateTestCategory.SECURITY) {
      return "Security";
    }
    if (key === GenerateTestCategory.VALIDATIONS) {
      return "Validations";
    }
    if (key === GenerateTestCategory.OPEN_ENDED) {
      return "Open ended";
    }
    return "";
  }

  const filterOptions = (searchText: string) => {
    const trimmedText = searchText.trim();
    const matchingTestSuites = !trimmedText
      ? testSuites
      : testSuites.filter((suite) => suite.toLowerCase().includes(searchText.toLowerCase()));
    return matchingTestSuites.map((suite) => ({ value: suite }));
  };

  return (
    <Flex
      vertical
      flex={1}
      style={{
        marginBottom: 12,
        paddingBottom: 12,
        paddingRight: 16,
        marginLeft: 16,
        overflow: "auto",
      }}
    >
      <Title level={5} style={{ marginBottom: "15px" }}>Categories</Title>
      <Checkbox.Group value={selectedCategories} onChange={onCategoryChange}>
        <Space direction="horizontal">
          {Object.keys(GenerateTestCategory)
            .filter((key) => !isNaN(Number(key)) && Number(key) != 0)
            .map((key) => (
              <Checkbox key={Number(key)} value={Number(key)}>
                {getGenerateTestCategoryDisplayString(Number(key))}
              </Checkbox>
            ))}
        </Space>
      </Checkbox.Group>
      <Title level={5} style={{ marginBottom: "15px" }}>Additional Prompt</Title>
      <TextArea value={additionalPrompt} onChange={onAdditionalPromptChange} rows={5} placeholder="Enter custom Prompt (Optional)..." />
      <Title level={5} style={{ marginBottom: "15px" }}>Test Suite <span style={{ color: "red" }}>*</span></Title>
      <AutoComplete
        value={testSuite}
        options={testSuitesOptions}
        onSelect={onSelect}
        onSearch={(text) => setTestSuitesOptions(filterOptions(text))}
        onChange={onTestSuiteChange}
        placeholder="Search or Enter Test Suite"
      />
    </Flex>
  );
};

export default GeneralStep;
