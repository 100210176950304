import Firebase from "../auth/Firebase";
import LocalStorageManager from "./LocalStorageManager";

const ENDPOINT_PREFIX = process.env.REACT_APP_ENDPOINT_PREFIX;

export function postData(url, data) {
  let projectId = LocalStorageManager.getProjectId();
  if (!projectId) {
    projectId = "";
  }
  let token = LocalStorageManager.getToken();
  let authToken;
  if (!token) {
    authToken = "";
  } else {
    authToken = `Bearer ${token}`;
  }
  url = ENDPOINT_PREFIX + url;
  console.log("request:", url);
  if (data) {
    console.log(JSON.stringify(data));
  }

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
      "Project-Id": projectId,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("received: ", data);
      return data;
    })
    .catch((error) => {
      console.error("There was a problem with the POST request:", error);
    });
}
