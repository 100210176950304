import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Project } from "../../../api";
import { useSnackbar } from "../../../hooks/useSnackbar";

const AddProject = ({ open, handleClose, org }) => {
  const { showSnackbar } = useSnackbar();
  const [projectValue, setProjectValue] = useState({
    project_name: "",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };


  const onProjectAddHandler = async () => {
    const response = await Project.add(projectValue);
    if (!response.error) {
      showSnackbar("Project addedd successfully", "success");
      handleClose();
    } else {
      console.error(response.error);
      showSnackbar("Project adding failed", "error");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h5">Add Project</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            marginTop: "1rem",
          }}
        >
          <TextField
            fullWidth
            label="Project Name"
            variant="outlined"
            onChange={(event) =>
              setProjectValue({
                ...projectValue,
                project_name: event.target.value,
              })
            }
          />
          <Box display="flex" justifyContent="flex-end" gap="1rem">
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={onProjectAddHandler}>
              Add Project
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddProject;
