import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const CopyableTextField = ({ value }) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value);
      console.log('Text copied to clipboard',value);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  return (
    <TextField
      value={value}
      fullWidth
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end" onClick={handleCopy} style={{ cursor: 'pointer' }}>
            <FileCopyIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CopyableTextField;
