import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { Dropdown, Mentions } from "antd";
import { HTTPMessageSection, IMenu } from "../models";
import { isEmpty } from "lodash";
import { CaretDownOutlined } from "@ant-design/icons";

type DecoratedAttributeSummary = {
  summary: {
    attributeValueSummaries: { [key: string]: { numOccurrences: number } };
  };
};

type AttributeSummaries = { [key: string]: DecoratedAttributeSummary };

interface AttributeSummaryTableProps {
  attributeSummaries: AttributeSummaries;
  menu?: IMenu;
}

const AttributeSummaryTable: React.FC<AttributeSummaryTableProps> = ({
  attributeSummaries,
  menu,
}) => {
  // Sort keys alphabetically
  const sortedKeys = Object.keys(attributeSummaries).sort();

  // Map sorted keys to table rows
  const rows = sortedKeys?.map((key) => {
    const summary = attributeSummaries[key].summary;
    const values = Object.keys(summary.attributeValueSummaries).join(", ");
    return { attribute: key, values: values };
  });

  return (
    <TableContainer
      component={Paper}
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
        height: "100%",
        bgcolor: "white",
        marginTop: 1.5,
      }}
    >
      <Table size="small">
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={row.attribute}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell width="170em">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>{row.attribute}</Typography>
                  {!isEmpty(menu) && (
                    <Dropdown
                      menu={menu.props}
                      onOpenChange={(open) =>
                        menu.handleOpen({
                          open,
                          context: {
                            section: HTTPMessageSection.BODY,
                            key: row.attribute,
                            value: row.values,
                            bodyPath: row.attribute,
                            modelKey: row.attribute,
                          },
                        })
                      }
                    >
                      <IconButton>
                        <CaretDownOutlined style={{ fontSize: "20px" }} />
                      </IconButton>
                    </Dropdown>
                  )}
                </Box>
              </TableCell>
              <TableCell>
                <Box flexGrow={1}>
                  <Mentions disabled readOnly value={row.values} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AttributeSummaryTable;
