import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Skeleton,
} from "@mui/material";
import { ArrowUpward, ArrowDownward, Update } from "@mui/icons-material";

class DimensionOrderTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedDimensions: this.props.sortedDimensions ?? [],
      records: [],
      config: this.props.config,
      projectId: this.props.projectId,
    };
  }

  customSort = (a, b) => {
    let semanticIds =
      this.state.config?.dimensionViewConfig?.orderedDimensions ?? [];
    const aIndex = semanticIds.indexOf(a.semanticId);
    const bIndex = semanticIds.indexOf(b.semanticId);
    if (aIndex === -1 && bIndex === -1) {
      return 0;
    }
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }
    return aIndex - bIndex;
  };

  getDimensionOrder = () => {
    const { records } = this.state;
    return records?.map((record) => record.semanticId);
  };

  update() {
    let records = [...this.state.sortedDimensions];
    const { config } = this.state;
    console.log("Config to sort on", config);
    records.sort(this.customSort);
    console.log("After sorting", records);
    this.setState({ records: records });
  }

  componentDidMount() {
    this.update();
  }

  handleMoveUp = (index) => {
    if (index > 0) {
      const { records } = this.state;
      [records[index - 1], records[index]] = [
        records[index],
        records[index - 1],
      ];
      this.setState({ records });
    }
  };

  handleMoveDown = (index) => {
    const { records } = this.state;
    if (index < records.length - 1) {
      [records[index], records[index + 1]] = [
        records[index + 1],
        records[index],
      ];
      this.setState({ records });
    }
  };

  getDisplayForOperation = (dimensionRecord) => {
    let qualifiedOp = dimensionRecord?.definitionScope;
    return qualifiedOp.operationName + " @ " + qualifiedOp.resourceName;
  };

  loadTable = (records, loading) => {
    return (
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Definition Scope</strong>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  <TableRow key={1}>
                    <TableCell>
                      <Skeleton width={"40%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton width={"80%"} />
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell>
                      <Skeleton width={"50%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton width={"70%"} />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                records?.map((record, index) => (
                  <TableRow key={index}>
                    <TableCell>{record.displayName}</TableCell>
                    <TableCell>{this.getDisplayForOperation(record)}</TableCell>
                    <TableCell>
                      <Box display="flex" gap="1rem">
                        <IconButton
                          aria-label="move up"
                          onClick={() => this.handleMoveUp(index)}
                        >
                          <ArrowUpward />
                        </IconButton>
                        <IconButton
                          aria-label="move down"
                          onClick={() => this.handleMoveDown(index)}
                        >
                          <ArrowDownward />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render() {
    const { records } = this.state;
    const loading = this.props.loading;

    if (loading) {
      return this.loadTable(records, loading);
    } else {
      if (records && records.length > 0) {
        return this.loadTable(records, loading);
      } else {
        return (
          <div>
            <h3>
              No BI dimensions are configured yet for this project.{" "}
              <a href="https://testchimp.io/blog/bi-dimensions" target="_blank">
                Read here
              </a>{" "}
              to learn more about BI dimensions.
            </h3>
          </div>
        );
      }
    }
  }
}

export default DimensionOrderTab;
