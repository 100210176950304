import React, { useState } from 'react';
import {
    Box,
    Chip,
    IconButton,
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Button,
    Modal,
    Paper,
} from "@mui/material";
import { findKey } from "lodash";

import { AssertionSource, ITestAssertion, TestAssertionEvaluation, TestAssertionOperation, TestAssertionResult } from '../models';
import TestExpressionUtils from '../../util/TestExpressionUtils';
import { Converters } from '../../util';
import { AutoAwesome, CheckCircleOutline, ExpandLess, ExpandMore } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { AssertionOperation } from '../../models/assertion';

interface AssertionResultsModalProps {
    assertionEvaluations: { [id: string]: TestAssertionResult };
    assertions: ITestAssertion[];
    handleOk: () => void;
}

const AssertionResultsModal: React.FC<AssertionResultsModalProps> = ({
    assertionEvaluations,
    assertions,
    handleOk,
}) => {
    const [expanded, setExpanded] = useState<{ [id: string]: boolean }>({});
    const [open, setOpen] = useState(true); // Initially open the modal

    const toggleExpand = (id: string) => {
        setExpanded({
            ...expanded,
            [id]: !expanded[id],
        });
    };

    const getEvalutionText = (result: TestAssertionResult, assertion: ITestAssertion) => {
        let str: string = "";
        if (result.evaluation == TestAssertionEvaluation.FAILED) {
            str += "Not true: ";
        }else{
            str+="True that: "
        }
        str += Converters.typedValueListToString(result.leftActualValue) + " ";
        str += findKey(TestAssertionOperation, (v) => v === assertion.assertionOperation)?.toLowerCase();
        if (assertion.assertionOperation != TestAssertionOperation.IS_EMPTY && assertion.assertionOperation != TestAssertionOperation.IS_NOT_EMPTY && assertion.assertionOperation != TestAssertionOperation.IS_NULL && assertion.assertionOperation != TestAssertionOperation.IS_NOT_NULL) {
            str += " " + Converters.typedValueListToString(result.rightActualValue);
        }
        return str;
    };

    const getResultSummaryText = (assertionEvaluations: TestAssertionResult[]): string => {
        // Count the number of passed assertions
        const passedCount = assertionEvaluations.filter(evaluation => evaluation.evaluation === TestAssertionEvaluation.PASSED).length;
        // Total number of assertions
        const totalCount = assertionEvaluations.length;
    
        // Construct the summary text
        return `${passedCount} out of ${totalCount} assertions passed`;
    };

    const handleClose = () => {
        setOpen(false); // Close the modal
        handleOk(); // Call handleOk function
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="assertion-results-modal"
            aria-describedby="assertion-results-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "900",
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="assertion-results-modal" variant="overline" component="h2" gutterBottom>
                   {getResultSummaryText(Object.values(assertionEvaluations))}
                </Typography>
                <Paper sx={{ overflowY: 'auto', maxHeight: "60vh", mt: 2, p: 2 }}>
                    {assertions?.map((assertion) => {
                        const result = assertionEvaluations[assertion.assertionId];

                        const isPassed = result.evaluation === TestAssertionEvaluation.PASSED;

                        return (
                            <Box key={assertion.assertionId} marginBottom={2}>
                                <ListItem button onClick={() => toggleExpand(assertion.assertionId)}>
                                    <ListItemIcon>
                                        {expanded[assertion.assertionId] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemIcon>
                                    <Box padding={1} marginLeft={-3} display="flex" flexDirection="row" gap={1} alignItems="center">
                                        <Chip
                                             sx={{
                                                bgcolor: 'var(--light-color)',
                                                color: 'black', // Adjust text color if necessary
                                            }}
                                            label={TestExpressionUtils.getChipText(assertion.leftExpression)}
                                        />
                                        <Chip
                                            sx={{color:"var(--black)"}}
                                            variant="outlined"
                                            label={findKey(TestAssertionOperation, (v) => v === assertion.assertionOperation)?.toLowerCase()}
                                        />
                                        {TestExpressionUtils.getChipText(assertion.rightExpression) && (
                                            <Chip
                                            sx={{
                                                bgcolor: 'var(--light-color)',
                                                color: 'black', // Adjust text color if necessary
                                            }}
                                            label={TestExpressionUtils.getChipText(assertion.rightExpression)}
                                            />
                                        )}
                                    </Box>
                                    {isPassed ? (
                                        <CheckCircleIcon sx={{ color: 'var(--cta-color)' }} />
                                    ) : (
                                        <CancelIcon style={{ color: 'red' }} />
                                    )}
                                </ListItem>
                                <Collapse in={expanded[assertion.assertionId]} timeout="auto" unmountOnExit>
                                    <Box marginLeft={2} marginTop={1}>
                                        <Typography variant="subtitle2">{getEvalutionText(result, assertion)}</Typography>
                                    </Box>
                                </Collapse>
                            </Box>
                        );
                    })}
                </Paper>
                <Box display="flex" justifyContent="flex-end" marginTop={3}>
                    <Button variant="contained" color="primary" onClick={handleOk}>
                        Ok
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AssertionResultsModal;
