import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  updateSamplingMode,
  updateSamplingRate,
} from "../../features/overlaysSlice";
import { RootState } from "../../app/store";
import { Mode } from "../../models/overlays";

const SamplingMode = () => {
  const dispatch = useDispatch();
  const samplingMode = useSelector(
    (state: RootState) => state.overlays.samplingMode
  );

  const samplingModeChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updateSamplingMode(event.target.value as Mode));
  };

  const manualSamplingRateChangeHandler = (value: number) => {
    dispatch(updateSamplingRate(value));
  };

  return (
    <Box>
      <FormControl>
        <RadioGroup
          row
          name="sampling-mode"
          value={samplingMode.mode}
          onChange={samplingModeChangeHandler}
        >
          <FormControlLabel
            value={Mode.DYNAMIC_SAMPLING}
            control={<Radio />}
            label="Dynamic Sampling"
          />
          <FormControlLabel
            value={Mode.FIXED_SAMPLING}
            control={<Radio />}
            label="Fixed Sampling"
          />
        </RadioGroup>
      </FormControl>
      {samplingMode.mode == Mode.FIXED_SAMPLING && (
        <Box marginTop={1}>
          <TextField
            type="number"
            label="Sampling Rate"
            inputProps={{ min: 0.1, max: 0.9, step: 0.1 }}
            fullWidth
            value={samplingMode.manualSamplingRate}
            onChange={(e) =>
              manualSamplingRateChangeHandler(parseFloat(e.target.value))
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default SamplingMode;
