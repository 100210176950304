import client from "../client";

interface Organization {
  getOrgByDomain: (
    domain: string
  ) => Promise<any>;
  addOrganization: (
    organizationName: string,
    referralCode:string
  ) => Promise<any>;
}

const OrganizationPreOobe: Organization = {
  getOrgByDomain: async (domain) => {
    console.log("checking " + domain);
    try {
      let org = await client.post("/admin/get_organization_by_domain", {
        domain: domain
      });
      console.log("received: ", org);
      return org;
    } catch (error) {
      console.error(`Error calling get organization for domain(${domain})`, error);
      return { error: error };
    }
  },
  addOrganization: async (organizationName,referralCode) => {
    try {
      let response = await client.post("/admin/add_organization", {
        org_name: organizationName,
        referral_code:referralCode
      });
      console.log("received: ", response);
      return response;
    } catch (error) {
      console.error(`Error calling add organization for domain(${organizationName})`, error);
      return { error: error };
    }
  },
};

export default OrganizationPreOobe;