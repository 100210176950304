import React from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { Box, Divider, Fab, Stack } from "@mui/material";
import { fabStyle } from "../styles/overlay/styles";
import { isEmpty } from "lodash";
import OverlayItem from "./components/OverlayItem";
import AddIcon from "@mui/icons-material/Add";
import {
  addAllFilters,
  createOverlayId,
  openDrawer,
  openAddGuardPopup,
  updateColor,
  updateDateRange,
  updateOverlayId,
  updateSamplingMode,
  updateSamplingRate,
  openAttributeSettingsPopup,
  updateQueries,
} from "../features/overlaysSlice";
import OverlayDrawer from "./components/OverlayDrawer";
import { IQueryWithMetaData } from "../models/explore";
import { NEW_OVERLAY_COLOR } from "../util";
import { Mode } from "../models/overlays/ISamplingMode";
import BaseOverlay from "./components/BaseOverlay";
import AddGuardPopup from "./components/AddGuardPopup";
import AttributeSettingsPopup from "./components/AttributeSettingsPopup";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { Project } from "../auth/Authorization";
import { DrawerMode } from "../models/overlays";

const Overlay: React.FC = () => {
  let menuItems:ItemType[]=[];
  // TODO re-enable these once guards / attrib settings are enabled.
 /* let menuItems: ItemType[] = [
    {
      key: "CREATE_GUARD",
      label: "Create a Guard",
    },
  ];

  Project.isEditor() &&
    menuItems.push({
      key: "ATTRIBUTE_SETTINGS",
      label: "Attribute Settings",
    });
*/
  const dispatch = useAppDispatch();

  const queries = useAppSelector((state: RootState) => state.overlays.queries);

  const onCreateOverlay = () => {
    dispatch(createOverlayId());
    dispatch(updateColor(NEW_OVERLAY_COLOR));
    dispatch(addAllFilters(queries[0].filters));
    dispatch(updateSamplingMode(Mode.DYNAMIC_SAMPLING));
    dispatch(updateDateRange({})); //setting timeWindow relative
    dispatch(openDrawer(DrawerMode.ADD));
  };

  const onEditOverlay = (overlayId: string) => {
    const query = queries.find(
      (query: IQueryWithMetaData) => query.id == overlayId
    );
    dispatch(updateOverlayId(query!.id));
    dispatch(updateColor(query!.color));
    query!.filters && dispatch(addAllFilters(query!.filters));
    query!.sampling_rate && dispatch(updateSamplingRate(query!.sampling_rate));
    query!.sampling_mode &&
      dispatch(updateSamplingMode(query!.sampling_mode as Mode));

    const timeWindow = query!.time_window;
    if (timeWindow && "fixed_window" in timeWindow) {
      dispatch(
        updateDateRange({
          start: timeWindow.fixed_window.start_time,
          end: timeWindow.fixed_window.end_time,
        })
      );
    } else {
      dispatch(updateDateRange({})); //setting timeWindow relative
    }

    dispatch(openDrawer(DrawerMode.EDIT));
  };

  const onDeleteOverlay = (overlayId: string) => {
    let updatedQueries = [...queries];

    const deletingOverlayIndex = updatedQueries.findIndex(
      (query: IQueryWithMetaData) => query.id == overlayId
    );
    updatedQueries.splice(deletingOverlayIndex, 1);

    dispatch(updateQueries(updatedQueries));
  };

  return (
    <>
      <Box
        key="overlay-list"
        bgcolor="white"
        padding={2}
        borderRadius={2}
        top="64px"
        zIndex={1000}
      >
        <Stack marginTop={1}>
          {isEmpty(queries)
            ? null
            : queries.map((query: IQueryWithMetaData, index: number) => {
                return (
                  <React.Fragment key={query.id}>
                    <OverlayItem
                      key={query.id}
                      overlayId={query.id}
                      onDeleteOverlay={onDeleteOverlay}
                      onEditOverlay={onEditOverlay}
                      filters={query.filters}
                      traceColor={query.color}
                      isBaseItem={index === 0}
                    />
                  </React.Fragment>
                );
              })}
        </Stack>
        <Divider sx={{ marginTop: "0 !important" }} />
      </Box>
      <BaseOverlay
        menuItems={menuItems}
        onMenuClick={(key: string) => {
          switch (key) {
            case "CREATE_GUARD": {
              dispatch(openAddGuardPopup());
              break;
            }
            case "ATTRIBUTE_SETTINGS": {
              dispatch(openAttributeSettingsPopup());
              break;
            }
            default: {
              console.error("Unknown menu item key");
            }
          }
        }}
      />
      <Fab
        color="primary"
        aria-label="add"
        sx={fabStyle}
        onClick={onCreateOverlay}
      >
        <AddIcon />
      </Fab>
      <OverlayDrawer />
      <AddGuardPopup />
      <AttributeSettingsPopup />
    </>
  );
};

export default Overlay;
