import {
  Box,
  Button,
  ButtonGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Dropdown, Popover } from "antd";
import Ring from "./Ring";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { SpanAttribute } from "../../models/overlays";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppDispatch, RootState } from "../../app/store";
import {
  updateRingAttribute,
  updateSpanColorAttribute,
  updateSpanColorRange,
} from "../../features/overlaysSlice";
import ColortedRangeSlider from "../../common/ColortedRangeSlider";
import { ThemeColors } from "../../styles/light-colors";
import { debounce } from "lodash";

const TreeSettings = () => {
  const ringMenuItems: ItemType[] = [
    {
      key: SpanAttribute.FREQUENCY_SHORT_CODE,
      label: "F - Frequency",
    },
    {
      key: SpanAttribute.ERROR_RATE_SHORT_CODE,
      label: "E - Error rate",
    },
    {
      key: SpanAttribute.LATENCY_SHORT_CODE,
      label: "L - Latency",
    },
  ];

  const spanColorMenuItems: ItemType[] = [
    {
      key: SpanAttribute.FREQUENCY,
      label: "Frequency",
    },
    {
      key: SpanAttribute.ERROR_RATE,
      label: "Error rate",
    },
    {
      key: SpanAttribute.LATENCY,
      label: "Latency",
    },
  ];

  const dispatch: AppDispatch = useAppDispatch();

  const ringAttribute = useAppSelector(
    (state: RootState) => state.overlays.ringAttribute
  );

  const spanColorAttribute = useAppSelector(
    (state: RootState) => state.overlays.spanColorAttribute
  );

  const spanColorRange = useAppSelector(
    (state: RootState) => state.overlays.spanColorRange
  );

  const rangeChangeHandler = (newValue: number[]) =>
    dispatch(updateSpanColorRange({ min: newValue[0], max: newValue[1] }));

  const debouncedRangeChangeHandler = debounce(rangeChangeHandler, 300);

  return (
    <ButtonGroup variant="contained">
      <Dropdown
        trigger={["click"]}
        arrow
        placement="topLeft"
        menu={{
          selectable: true,
          selectedKeys: [ringAttribute],
          items: ringMenuItems,
          onClick: ({ key }) => {
            dispatch(updateRingAttribute(key as SpanAttribute));
          },
        }}
      >
        <Tooltip title="Ring attribute">
          <Button
            variant="text"
            sx={{
              ":hover": {
                bgcolor: grey[200],
              },
            }}
          >
            <Ring color={"primary.main"} label={ringAttribute} />
          </Button>
        </Tooltip>
      </Dropdown>
      <Dropdown
        trigger={["click"]}
        arrow
        placement="topCenter"
        menu={{
          selectable: true,
          selectedKeys: [spanColorAttribute],
          items: spanColorMenuItems,
          onClick: ({ key }) => {
            dispatch(updateSpanColorAttribute(key as SpanAttribute));
          },
        }}
      >
        <Tooltip title="Span color attribute">
          <Button
            variant="text"
            startIcon={<AccountTreeIcon />}
            sx={{
              ":hover": {
                bgcolor: grey[200],
              },
              paddingLeft: "12px !important",
            }}
          >
            {spanColorAttribute}
          </Button>
        </Tooltip>
      </Dropdown>
      <Popover
        content={
          <ColortedRangeSlider
            width={"300px"}
            reverse={false}
            step={1}
            defaultValues={[spanColorRange.min, spanColorRange.max]}
            min={0}
            max={100}
            onRangeUpdate={debouncedRangeChangeHandler}
          />
        }
        placement="top"
        trigger="click"
      >
        <Tooltip title="Span color range">
          <Button
            variant="text"
            sx={{
              ":hover": {
                bgcolor: grey[200],
              },
            }}
          >
            <Stack
              spacing={1}
              direction={"row"}
              minWidth={170}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Box
                  bgcolor={ThemeColors.success}
                  height={14}
                  width={14}
                  marginRight={0.5}
                />
                <Typography variant="subtitle2">{`<${spanColorRange.min}`}</Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Box
                  bgcolor={ThemeColors.warning}
                  height={14}
                  width={14}
                  marginRight={0.5}
                />
                <Typography variant="subtitle2">{`${spanColorRange.min}-${spanColorRange.max}`}</Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Box
                  bgcolor={ThemeColors.error}
                  height={14}
                  width={14}
                  marginRight={0.5}
                />
                <Typography variant="subtitle2">{`>${spanColorRange.max}`}</Typography>
              </Box>
            </Stack>
          </Button>
        </Tooltip>
      </Popover>
    </ButtonGroup>
  );
};

export default TreeSettings;
