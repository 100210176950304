import { AwareTestType, ITestName, ITestNameWithId } from "../../test-studio/models";
import client from "../client";

// Enum definitions
export enum Priority {
    UNKNOWN_PRIORITY = 0,
    P0 = "P0",
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
}

export enum EpicStatus {
    UNKNOWN_EPIC_STATUS = 0,
    ACTIVE_EPIC = "ACTIVE_EPIC",
    INACTIVE_EPIC = "INACTIVE_EPIC",
    DELETED_EPIC = "DELETED_EPIC",
}

export enum UserStoryStatus {
    UNKNOWN_USER_STORY_STATUS = 0,
    IN_SCOPING = "IN_SCOPING",
    IN_DEVELOPMENT = "IN_DEVELOPMENT",
    IN_TESTING = "IN_TESTING",
    COMPLETED = "COMPLETED",
    DELETED_USER_STORY = "DELETED_USER_STORY",
}

export enum TestScenarioStatus {
    UNKNOWN_TEST_SCENARIO_STATUS = "UNKNOWN_TEST_SCENARIO_STATUS",
    ACTIVE_TEST_SCENARIO = "ACTIVE_TEST_SCENARIO",
    DELETED_TEST_SCENARIO = "DELETED_TEST_SCENARIO",
}

// Model interfaces
export interface Epic {
    id: number;
    externalId?: string;
    projectId?: string;
    title: string;
    description?: string;
    creationTimestamp?: string; // You can use Date if you prefer
    lastUpdatedTimestamp?: string;
    creatorUserId?: string;
    priority?: Priority;
    status?: EpicStatus;
}

export interface Persona {
    id?: number;
    projectId?: string;
    title?: string;
    description?: string;
    creationTimestamp?: string;
    lastUpdatedTimestamp?: string;
    creatorUserId?: string;
    priority?: Priority;
}

export interface Milestone {
    id: number;
    externalId?: string;
    projectId?: string;
    name?: string;
    creationTimestamp?: string;
    creatorUserId?: string;
    startTimestamp?: string;
    endTimestamp?: string;
}

export interface Sprint {
    id: number;
    externalId?: string;
    projectId?: string;
    name?: string;
    creationTimestamp?: string;
    creatorUserId?: string;
    startTimestamp?: string;
    endTimestamp?: string;
}

export interface UserStorySimple {
    id?: number;
    externalId?: string;
    epicId?: number;
    title?: string;
    primaryPersonaId?: number;
    creationTimestamp?: string;
    lastUpdatedTimestamp?: string;
    creatorUserId?: string;
    priority?: Priority;
    milestoneId?: number;
    sprintId?: number;
    status?: UserStoryStatus;
}

export interface UserStory extends UserStorySimple {
    description?: string;
}

export interface TestScenarioSimple {
    id?: number;
    externalId?: string;
    userStoryId?: number;
    title?: string;
    creationTimestamp?: string;
    lastUpdatedTimestamp?: string;
    creatorUserId?: string;
    priority?: Priority;
    status?: TestScenarioStatus;
    linkedTestCases?: ITestNameWithId[];
}

export interface TestScenario extends TestScenarioSimple {
    description?: string;
}

export interface SuggestTestScenariosRequest {
    userStoryId: number;
    newScenarios: TestScenario[];
}

export interface SuggestTestScenariosResponse {
    suggestedTestScenarios: TestScenario[];
}

export interface SuggestPersonasRequest {

}

export interface SuggestPersonasResponse {
    suggestedPersonas: Persona[];
}

export interface SuggestUserStoriesRequest {
    epicId: number;
    personaId: number;
    sprintId?: number;
    milestoneId?: number;
}

export interface SuggestUserStoriesResponse {
    suggestedUserStories: UserStory[];
}

export interface SuggestUserStoryDescriptionRequest {
    title?: string;
    personaId: number;
    epicId: number;
    existingDescription?: string;
}

export interface SuggestTestScenarioDescriptionRequest {
    scenarioId: number;
    existingDescription?: string;
}

export interface SuggestTestScenarioDescriptionResponse {
    description: string;
}

export interface SuggestUserStoryDescriptionResponse {
    description: string;
}

export interface GetProjectDescriptionRequest {

}

export interface GetProjectDescriptionResponse {
    description: string;
}

export interface UpdateProjectDescriptionRequest {
    description: string;
}

export interface UpdateProjectDescriptionResponse {

}

// Request and response interfaces
export interface ListEpicsRequest { }

export interface ListEpicsResponse {
    epics: Epic[];
}

export interface UpsertEpicRequest {
    title?: string;
    description?: string;
    priority?: Priority;
    status?: EpicStatus;
    id?: number;
    externalId?: string;
}

export interface UpsertEpicResponse {
    id: number;
}

export interface ListPersonasRequest { }

export interface ListPersonasResponse {
    personas: Persona[];
}

export interface UpsertPersonaRequest {
    title?: string;
    description?: string;
    priority?: Priority;
    id?: number;
}

export interface UpsertPersonaListRequest {
    personas: Persona[];
}

export interface UpsertPersonaListResponse {

}

export interface UpsertPersonaResponse {
    id: number;
}

export interface CreateMilestoneRequest {
    title?: string;
    startTimestamp?: string;
    endTimestamp?: string;
    externalId?: string;
}

export interface CreateMilestoneResponse {
    id: number;
}

export interface ListMilestonesRequest { }

export interface ListMilestonesResponse {
    milestones: Milestone[];
}

export interface UpsertMilestoneRequest {
    title?: string;
    startTimestamp?: string;
    endTimestamp?: string;
    externalId?: string;
    id?: number;
}

export interface UpsertMilestoneResponse {
    id: number;
}

export interface CreateSprintRequest {
    title?: string;
    startTimestamp?: string;
    endTimestamp?: string;
    externalId?: string;
}

export interface CreateSprintResponse {
    id: number;
}

export interface ListSprintsRequest { }

export interface ListSprintsResponse {
    sprints: Sprint[];
}

export interface UpsertSprintRequest {
    title?: string;
    startTimestamp?: string;
    endTimestamp?: string;
    externalId?: string;
    id?: number;
}

export interface UpsertSprintResponse {
    id: number;
}

export interface ListUserStoriesRequest {
    epicId?: number;
    sprintId?: number;
    milestoneId?: number;
    personaId?: number;
}

export interface ListUserStoriesResponse {
    userStoriesByPersona: Record<number, UserStoryByPersona>;
}

export interface GetUserStoryRequest {
    userStoryId: number;
}

export interface GetUserStoryResponse {
    userStory: UserStory;
}

export interface GetTestScenarioRequest {
    scenarioId: number;
}

export interface GetTestScenarioResponse {
    scenario: TestScenario;
}

export interface UserStoryByPersona {
    userStories: UserStoryWithTestScenarios[];
}

export interface UserStoryWithTestScenarios {
    userStory: UserStorySimple;
    testScenarios: TestScenarioSimple[];
}

export interface UpsertUserStoryListRequest {
    userStories: UserStory[];
}

export interface UpsertUserStoryListResponse {
}

export interface ListTestScenariosRequest {
    userStoryId: number;
}

export interface ListTestScenariosResponse {
    testScenarios: TestScenario[];
}

export interface UpsertTestScenarioListRequest {
    scenarios: TestScenario[];
}

export interface UpsertTestScenarioListResponse {
}


// Service functions
const Scenarios = {
    listEpics: async (request: ListEpicsRequest): Promise<ListEpicsResponse> => {
        const response = await client.post("/scenario/list_epics", request);
        return response.data;
    },

    upsertEpic: async (request: UpsertEpicRequest): Promise<UpsertEpicResponse> => {
        const response = await client.post("/scenario/upsert_epic", request);
        return response.data;
    },

    listPersonas: async (request: ListPersonasRequest): Promise<ListPersonasResponse> => {
        const response = await client.post("/scenario/list_personas", request);
        return response.data;
    },

    upsertPersona: async (request: UpsertPersonaRequest): Promise<UpsertPersonaResponse> => {
        const response = await client.post("/scenario/upsert_persona", request);
        return response.data;
    },
    upsertPersonaList: async (request: UpsertPersonaListRequest): Promise<UpsertPersonaListResponse> => {
        const response = await client.post("/scenario/upsert_persona_list", request);
        return response.data;
    },

    createMilestone: async (request: CreateMilestoneRequest): Promise<CreateMilestoneResponse> => {
        const response = await client.post("/scenario/create_milestone", request);
        return response.data;
    },

    listMilestones: async (request: ListMilestonesRequest): Promise<ListMilestonesResponse> => {
        const response = await client.post("/scenario/list_milestones", request);
        return response.data;
    },


    suggestTestScenarios: async (request: SuggestTestScenariosRequest): Promise<SuggestTestScenariosResponse> => {
        const response = await client.post("/scenario/suggest_test_scenarios", request);
        return response.data;
    },

    suggestUserStories: async (request: SuggestUserStoriesRequest): Promise<SuggestUserStoriesResponse> => {
        const response = await client.post("/scenario/suggest_user_stories", request);
        return response.data;
    },

    suggestUserStoryDescription: async (request: SuggestUserStoryDescriptionRequest): Promise<SuggestUserStoryDescriptionResponse> => {
        const response = await client.post("/scenario/suggest_user_story_description", request);
        return response.data;
    },

    suggestTestScenarioDescription: async (request: SuggestTestScenarioDescriptionRequest): Promise<SuggestTestScenarioDescriptionResponse> => {
        const response = await client.post("/scenario/suggest_test_scenario_description", request);
        return response.data;
    },

    suggestPersonas: async (request: SuggestPersonasRequest): Promise<SuggestPersonasResponse> => {
        const response = await client.post("/scenario/suggest_personas", request);
        return response.data;
    },

    upsertMilestone: async (request: UpsertMilestoneRequest): Promise<UpsertMilestoneResponse> => {
        const response = await client.post("/scenario/upsert_milestone", request);
        return response.data;
    },

    createSprint: async (request: CreateSprintRequest): Promise<CreateSprintResponse> => {
        const response = await client.post("/scenario/create_sprint", request);
        return response.data;
    },

    listSprints: async (request: ListSprintsRequest): Promise<ListSprintsResponse> => {
        const response = await client.post("/scenario/list_sprints", request);
        return response.data;
    },

    upsertSprint: async (request: UpsertSprintRequest): Promise<UpsertSprintResponse> => {
        const response = await client.post("/scenario/upsert_sprint", request);
        return response.data;
    },

    listUserStories: async (request: ListUserStoriesRequest): Promise<ListUserStoriesResponse> => {
        const response = await client.post("/scenario/list_user_stories", request);
        return response.data;
    },

    getUserStory: async (request: GetUserStoryRequest): Promise<GetUserStoryResponse> => {
        const response = await client.post("/scenario/get_user_story", request);
        return response.data;
    },

    getTestScenario: async (request: GetTestScenarioRequest): Promise<GetTestScenarioResponse> => {
        const response = await client.post("/scenario/get_test_scenario", request);
        return response.data;
    },

    upsertUserStoryList: async (request: UpsertUserStoryListRequest): Promise<UpsertUserStoryListResponse> => {
        const response = await client.post("/scenario/upsert_user_story_list", request);
        return response.data;
    },

    listTestScenarios: async (request: ListTestScenariosRequest): Promise<ListTestScenariosResponse> => {
        const response = await client.post("/scenario/list_test_scenarios", request);
        return response.data;
    },



    upsertTestScenarioList: async (request: UpsertTestScenarioListRequest): Promise<UpsertTestScenarioListResponse> => {
        const response = await client.post("/scenario/upsert_test_scenario_list", request);
        return response.data;
    },

    getProjectDescription: async (request: GetProjectDescriptionRequest): Promise<GetProjectDescriptionResponse> => {
        const response = await client.post("/scenario/get_project_description", request);
        return response.data;
    },

    updateProjectDescription: async (request: UpdateProjectDescriptionRequest): Promise<UpdateProjectDescriptionResponse> => {
        const response = await client.post("/scenario/update_project_description", request);
        return response.data;
    },
};

export default Scenarios;