import { Timestamp } from "../api/services/Common";

export function getDurationString(mins: number | undefined): string {
  if (!mins) {
    return "";
  }
  
  if (mins === 1) {
    return "1 min";
  }
  
  const hours = Math.floor(mins / 60);
  const remainingMins = mins % 60;

  if (hours > 0) {
    return `${hours} hours ${remainingMins} mins`;
  }

  return `${mins} mins`;
}

export function formatTimestamp(timestamp: string): string {
  const now = new Date();
  const time = new Date(timestamp);
  const secondsDiff = (now.getTime() - time.getTime()) / 1000; // Difference in seconds

  if (secondsDiff < 60) {
    return 'less than a minute ago';
  } else if (secondsDiff < 3600) {
    const minutes = Math.floor(secondsDiff / 60);
    return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
  } else if (secondsDiff < 86400) {
    const hours = Math.floor(secondsDiff / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (secondsDiff < 172800) { // Less than 2 days ago (1 day = 86400 seconds)
    const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    return `Yesterday (${formattedTime})`;
  } else {
    const formattedDate = time.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    return `${formattedDate} ${formattedTime}`;
  }
}


